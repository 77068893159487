import deviceService from '../../../service/deviceManage';
import tool from '../../../checkingTools/tools';

async function getByType(type){
    try {
        let result = await deviceService.getByType(type);         
        if(result.status!==200 || !result.data) return null;    // 
        return result.data;        
    } catch (error) {
        console.log("getByType Error");
        tool.exResponse(error);
        return null;
    }
}

async function getByTypenId(body){
    try {
        let result = await deviceService.getByTypenId(body);         
        if(result.status!==200 || !result.data) return [];    // 
        return result.data;        
    } catch (error) {
        console.log("getByTypenId Error");
        tool.exResponse(error);
        return [];
    }

}

async function getRegdevbyTy_devID(type, devID){
    try {
        let result = await deviceService.getRegdevbyTy_devID(type, devID);         
        if(result.status!==200 || !result.data) return [];    // 
        return result.data;        
    } catch (error) {
        console.log("getByTypenId Error");
        tool.exResponse(error);
        return [];
    }
}

async function getRegdevbyTy_devIdList(type, devIdList){
    try {
        let result = await deviceService.getRegdevbyTy_devIdList(type, devIdList);         
        if(result.status!==200 || !result.data) return [];    // 
        return result.data;        
    } catch (error) {
        console.log("getByTypenId Error");
        tool.exResponse(error);
        return [];
    }
}

async function V2_regDevByList(devList){
    try {
        let result = await deviceService.V2_regDevByList(devList);         
        if(result.status!==200 || !result.data) return [];    // 
        return result.data;        
    } catch (error) {
        console.log("V2_regDevByList Error");
        tool.exResponse(error);
        return [];
    }
}



const expModule = {  
    V2_regDevByList,
    getRegdevbyTy_devID,
    getRegdevbyTy_devIdList,
    getByTypenId,
    getByType,
};
export default expModule;