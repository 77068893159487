import http from "../httpService";



async function getThreshold(body) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/statusnode/getthresholdbydevid";
    // let body = obj;
    return await http.post(url, body);  
}

async function setThreshold(body) {    
  let url = process.env.REACT_APP_PUBLIC_URL+"/statusnode/setthreshold";
  // let body = obj;
  return await http.post(url, body);  
}



const expModule = {  
    getThreshold,
    setThreshold,
};
export default expModule;