import React, { Component } from 'react';
import controlDevFn from "../utilities/conntrolDevice/ctrlDevFn";

class CtrlDevManagePage extends Component {
    state = { 
        bufferIndex :0,
        aTableBuffer:[],
        aDisplayTable:[],
        nTotalDev:0,
     }

    async componentDidMount() {
        this.setState({bufferIndex:0})
        await this.loadN_Item(30, this.state.bufferIndex);         // load 30 item start from 0 position

    }

    loadN_Item=async (n, fromx)=>{
        let aTableBuffer = await controlDevFn.getNItemFromXindex(n, fromx);
        let nTotalDev = aTableBuffer[0].totalCount;
        this.setState({nTotalDev});
        aTableBuffer.shift()
        // console.log(aTableBuffer);
        await this.setState({aTableBuffer});
        let aDisplayTable = [...aTableBuffer];
        this.setState({aDisplayTable});
        // console.log(nloadedCtrlDev);
    }

    toCtrlDevPage=()=>{
        this.props.history.push({
          pathname: "/ctrldev/vfd",
          _data: {
            // _selBuildingId: this.state.selBuildingId,
            // _selT1Llist:tableMonList[ind]._id,
          },
        });
    }

    render() { 
        return ( <React.Fragment>
            <h1>Control Device Management</h1>
            <button onClick={this.toCtrlDevPage}> Back</button>
            <div className="card">
                <div className="card-body" id="flip-scroll">
                    <table className="cusTable" id="bdDeviceTable">
                    <thead>
                        <tr>
                        <th scope="col" id="cb"></th>
                        <th scope="col">#</th>
                        <th style={{ display: "none" }}>_id</th>
                        <th scope="col">Control Dev.</th>
                        <th scope="col">Type</th>
                        <th scope="col">Feedback Dev.</th>
                        <th scope="col">Type</th>
                        <th scope="col">inUse</th>
                        {/* <th style={{ display: "none" }}>Building</th> */}
                        <th scope="col">Amend By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.aDisplayTable.map((u, ind) => (
                        <tr key={u._id}>
                            <td>
                            <input type="checkbox"></input>
                            </td>
                            <td>{ind + 1}</td>
                            <td style={{ display: "none" }}>{u._id}</td>
                            {/* <td>{enm.getType_en(u.type)}</td>
                            <td>
                            {tableFn.dropDownWithFilter(
                                `selRow${ind}`,
                                this.state.devBdEnum,
                                u.devID,
                                u.type,
                                "select-custom"
                            )}
                            </td> */}
                            <td>
                            <input type="text" defaultValue={u.ctBdDev_id} />
                            </td>
                            <td>
                            <input type="text" defaultValue={u.ctNodeType} />
                            </td>
                            <td>
                            <input type="text" defaultValue={u.fbBdDev_id} />
                            </td>
                            <td>
                            <input type="text" defaultValue={u.fbNodeType} />
                            </td>
                            <td>
                            <input type="text" defaultValue={u.inUse} />
                            </td>
                            {/* <td style={{ display: "none" }}>{u.active}</td>
                            <td style={{ display: "none" }}>{u.buildingID}</td> */}
                            <td>{u.userAmmend}</td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment> );
    }
}
 
export default CtrlDevManagePage;