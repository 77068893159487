import React, { Component } from "react";
import NcInput from "./ncInput";

class NcForm extends Component {
  state = {
  };

//   handleChange = ({ currentTarget: input }) => {
//     const errors = { ...this.state.errors };
//     if (errorMessage) errors[input.name] = errorMessage;
//     else delete errors[input.name];

//     const data = { ...this.state.data };
//     data[input.name] = input.value;

//     this.setState({ data, errors });
//     // console.log("State: ",this.state.data);
//   };

  handleSubmit = (e) => {
    e.preventDefault();

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // console.log(errors);
    // if (errors) return;

    this.doSubmit();
  };

  renderNcButton(label) {
    return (
      <button className="ncButton">
        {label}
      </button>
    );
  }

  renderNcInput(name, label, type = "text") {

    return (
      <NcInput
        type={type}
        name={name}
        label={label}
        // onChange={this.handleChange}
        // error={errors[name]}
      />
    );
  }
}

export default NcForm;
