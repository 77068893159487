// import key from "../utilities/constantKeyName";

// const rtrhKeyList=[
//     {_id: key.rtrh_temp.key, name: key.rtrh_temp.name},
//     {_id: key.rtrh_humid.key, name: key.rtrh_humid.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const probeKeyList=[
//     {_id: key.porbe_temp.key, name: key.porbe_temp.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const dpmKeyList=[
//     {_id: key.dpm_ca.key, name: key.dpm_ca.name},
//     {_id: key.dpm_cb.key, name: key.dpm_cb.name},
//     {_id: key.dpm_cc.key, name: key.dpm_cc.name},
//     {_id: key.dpm_va.key, name: key.dpm_va.name},
//     {_id: key.dpm_vb.key, name: key.dpm_vb.name},
//     {_id: key.dpm_vc.key, name: key.dpm_vc.name},
//     {_id: key.dpm_f.key, name: key.dpm_f.name},
//     {_id: key.dpm_apa.key, name: key.dpm_apa.name},
//     {_id: key.dpm_apb.key, name: key.dpm_apb.name},
//     {_id: key.dpm_apc.key, name: key.dpm_apc.name},
//     {_id: key.dpm_apt.key, name: key.dpm_apt.name},
//     {_id: key.dpm_pfa.key, name: key.dpm_pfa.name},
//     {_id: key.dpm_pfb.key, name: key.dpm_pfb.name},
//     {_id: key.dpm_pfc.key, name: key.dpm_pfc.name},
//     {_id: key.dpm_pft.key, name: key.dpm_pft.name},
//     {_id: key.dpm_e.key, name: key.dpm_e.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const pressureKeyList=[
//     {_id: key.press_mA.key, name: key.press_mA.name},
//     {_id: key.press_p.key, name: key.press_p.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const usfKeyList=[
//     {_id: key.usf_fr.key, name: key.usf_fr.name},
//     {_id: key.usf_t1.key, name: key.usf_t1.name},
//     {_id: key.usf_t2.key, name: key.usf_t2.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const airFlowKeyList=[
//     {_id: key.af_fr.key, name: key.af_fr.name},
//     {_id: key.af_mA.key, name: key.af_mA.name},
//     {_id: key.af_12v.key, name: key.af_12v.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const switchStatusKeyList=[
//     {_id: key.ss_on.key, name: key.ss_on.name},
//     {_id: key.ss_rawAo.key, name: key.ss_rawAo.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

// const vfdControlKeyList=[
//     {_id: key.vfd_actHz.key, name: key.vfd_actHz.name},
//     {_id: key.vfd_kW.key, name: key.vfd_kW.name},
//     {_id: key.vfd_Hz_lowerLimit.key, name: key.vfd_Hz_lowerLimit.name},
//     {_id: key.vfd_pidKp.key, name: key.vfd_pidKp.name},
//     {_id: key.vfd_pidKi.key, name: key.vfd_pidKi.name},
//     {_id: key.vfd_pidKd.key, name: key.vfd_pidKd.name},
//     {_id: key.vfd_pidOutMin.key, name: key.vfd_pidOutMin.name},
//     {_id: key.vfd_pidOutMax.key, name: key.vfd_pidOutMax.name},
//     {_id: key.vfd_hzOutput.key, name: key.vfd_hzOutput.name},
//     {_id: key.vfd_pidSetpoint.key, name: key.vfd_pidSetpoint.name},
//     {_id: key.vfd_feedbackVal.key, name: key.vfd_feedbackVal.name},
//     {_id: key.vfd_ManualOutput.key, name: key.vfd_ManualOutput.name},
//     {_id: key.vfd_modbusId.key, name: key.vfd_modbusId.name},
//     {_id: key.vfd_nodeOpMode.key, name: key.vfd_nodeOpMode.name},
//     {_id: key.com_Batt.key, name: key.com_Batt.name},
//     {_id: key.com_RSSI.key, name: key.com_RSSI.name},
//     {_id: key.com_SNR.key, name: key.com_SNR.name},
// ]

function getKeyListByType(type){
    // console.log(type);
    if (!type) return [];
    // if(type === 1) return rtrhKeyList;
    // if(type === 2) return probeKeyList;
    // if(type === 3) return dpmKeyList;
    // if(type === 4) return pressureKeyList;
    // if(type === 6) return switchStatusKeyList;
    // if(type === 7) return vfdControlKeyList;
    // if(type === 1001) return usfKeyList;
    // if(type === 1002) return airFlowKeyList;
    return []
}

function getKeyListByType_V2(keyList, type){
    let filtered = keyList.filter(c=>c.sensorType===type);
    let list = [];
    for (const key of filtered) {
        list.push({
            name:key.dataName,
            _id:(key.dataIndex===-1)? key.dataType:`${key.dataType}_${key.dataIndex}`
        })
    }
    // console.log(list);
    return list;
}

const expModule = {
    getKeyListByType,
    getKeyListByType_V2,
};
export default expModule;