import React from 'react';

function TpDiaSending(props) {
    return (
        <div>
            <div className='autumn_dialogBoxContainer'>
                <div className='autumn_dialogBox autumn_dialogBox_fixHeight'>  
                    <div className='autumn_statusIcon autumn_sendBgc'>
                        <i className=' fa fa-paper-plane autumn_smallerIcon autumn_shiftLeft'>
                        </i>
                    </div>
                    <div className='autumn_statusMsg' 
                    style={{paddingLeft:"10px"}}>
                        {`Sending...`}
                    </div>                    
                </div>
            </div>
        </div>
    );
}

export default TpDiaSending;