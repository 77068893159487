import React, { Component } from "react";
import Icon from "../common/icon";
import tableFn from "../utilities/tableFunction";
import monlistFn from "../../component/utilities/monList/monList";
import t1listFn from "../../component/utilities/monList/t1List";
import { getDeletedArrItem, swapArray } from "../utilities/ArrayFn/arrayFn";
import monListNavFn from "../utilities/monList/monListNavBar";
import { ToastContainer, toast } from "react-toastify";

class AddT1_Page extends Component {
  state = {
    table_T1_List: [],
    db_T1_List: [],
    selBuildingID: 0,
    selT1Llist: 0,
    navBarInfo: {},
  };

  async componentDidMount() {
    let _selBuildingID = parseInt(await localStorage.getItem(`selBuildingID`));
    let selBuildingID = parseInt(_selBuildingID);
    // console.log(selBuildingID);
    await this.setState({ selBuildingID });
    let selT1Llist = await localStorage.getItem(`selT1Llist`);
    await this.setState({ selT1Llist });
    // console.log(selT1Llist);
    let navBarInfo = await monListNavFn.getMonListNavBarName();
    this.setState({ navBarInfo });
    await this.loadT1_ListToTable();
  }

  loadT1_ListToTable = async () => {
    try {
      let db_T1_List = await monlistFn.getT1ByMonList_ID(this.state.selT1Llist); // insert Monlist ID here
      let table_T1_List = [...db_T1_List];
      table_T1_List.sort(function (a, b) {
        return a.SortIndex - b.SortIndex;
      });
      this.setState({ db_T1_List });
      this.setState({ table_T1_List });
      //   console.log(table_T1_List);
    } catch (error) {
      console.log(error.message);
    }
  };

  /**Table format */
  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      name: row.cells[3].children[0].value,
      //   name: row.cells[3].innerHTML,
      SortIndex: row.cells[7].innerHTML,
      MonitorList_id: row.cells[8].innerHTML,
    };
    // console.log(column);
    return column;
  };

  /**Get Table Info */
  getTableInfo = () => {
    try {
      let tableInfo = tableFn.getInfoFromTableSkipLastN(
        "t1ListTable",
        this.tableColumn,
        1
      );
      // console.log(tableInfo);
      return tableInfo;
    } catch (error) {
      console.log(error.message);
    }
  };

  delT1 = async (ind) => {
    console.log("Delete");
    try {
      let table_T1_List = await this.getTableInfo();
      table_T1_List.splice(ind, 1);
      let idxp = 0;
      for (const t1Item of table_T1_List) {
        t1Item.SortIndex = idxp;
        idxp++;
      }
      this.setState({ table_T1_List });
      // console.log(table_T1_List);
    } catch (error) {
      console.log(error.message);
    }
  };

  /**Click Up Down */
  clickUp = (index) => {
    /**Switch index position */
    try {
      let table_T1_List = this.getTableInfo();
      if (index > 0) table_T1_List = swapArray(table_T1_List, index, index - 1);
      /**Modify sort index */
      let idxp = 0;
      for (const t1Item of table_T1_List) {
        t1Item.SortIndex = idxp;
        idxp++;
      }
      // console.log(tableMonList);
      this.setState({ table_T1_List });
    } catch (error) {
      console.log(error.message);
    }
  };

  clickDn = (index) => {
    try {
      // console.log(`Click Dn ${index}`);
      let table_T1_List = this.getTableInfo();
      // console.log(tableMonList);
      if (index + 1 < table_T1_List.length)
        table_T1_List = swapArray(table_T1_List, index, index + 1);
      /**Modify sort index */
      let idxp = 0;
      for (const t1Item of table_T1_List) {
        t1Item.SortIndex = idxp;
        idxp++;
      }
      this.setState({ table_T1_List });
    } catch (error) {
      console.log(error.message);
    }
  };

  insertNewList = async () => {
    // console.log("Insert New List");
    try {
      let table_T1_List = this.getTableInfo();
      let newT1Name = document.getElementById("newT1Name").children[0].value;
      let nMin_id = 0;
      for (const t1Item of table_T1_List) {
        if (parseInt(t1Item._id) <= nMin_id) {
          nMin_id = t1Item._id;
        }
      }
      if (newT1Name) {
        table_T1_List.push({
          MonitorList_id: this.state.selT1Llist /** replace 2 here */,
          name: newT1Name,
          SortIndex: table_T1_List.length,
          _id: nMin_id - 1,
        });
      }
      // console.log(tableMonList);
      let idxp = 0;
      for (const t1Item of table_T1_List) {
        t1Item.SortIndex = idxp;
        idxp++;
      }
      await this.setState({ table_T1_List });
      /**Clear name */
      document.getElementById("newT1Name").children[0].value = "";

      await this.save();
    } catch (error) {
      console.log(error.message);
    }
  };

  save = async () => {
    // console.log("Save");
    try {
      /**Get table info */
      let table_T1_List = this.getTableInfo();
      let db_T1_List = [...this.state.db_T1_List];

      /**Handle existing item*/
      let modifiedRow = tableFn.detectTableChangesSkipLastN(
        "t1ListTable",
        db_T1_List,
        "_id",
        this.tableColumn,
        true,
        1
      );
      // console.log(modifiedRow);
      for (const modifyItem of modifiedRow) {
        let updateSeccess = await t1listFn.updateT1List(modifyItem);
        updateSeccess
          ? toast.dark(`${modifyItem.name} update succesful`)
          : toast.error(`${modifyItem.name} update failed`);
      }

      /**Handle new add item */
      let newAddList = table_T1_List.filter((c) => c._id < 0);
      // console.log(newAddList);
      for (const newItem of newAddList) {
        let addSuccess = await t1listFn.addT1List(newItem);
        addSuccess
          ? toast.dark(`${newItem.name} add succesful`)
          : toast.error(`${newItem.name} add failed`);
      }

      /**hande delete */
      let deletedRow = getDeletedArrItem(db_T1_List, table_T1_List, "_id");
      // console.log(deletedRow);
      for (const delItem of deletedRow) {
        let delSuccess = await t1listFn.delT1List(delItem);
        delSuccess
          ? toast.dark(`${delItem.name} delete succesful`)
          : toast.error(`${delItem.name} delete succesful`);
      }

      /** load new mon list */
      await this.loadT1_ListToTable();
    } catch (error) {
      toast.error(error.message);
    }
  };

  toElementPage = async (ind) => {
    // console.log(`Go to page ${ind}`);
    let tableT1List = this.getTableInfo();
    // console.log(tableT1List[ind]);
    await localStorage.setItem("selEleList", tableT1List[ind]._id);
    await localStorage.setItem("selEleName", tableT1List[ind].name);
    // console.log(tableT1List[ind].name);
    this.props.history.push({
      pathname: "/monlist/addele",
      _data: {
        // _selBuildingId: this.state.selBuildingId,
        // _selT1Llist: this.state.selT1Llist,
        // _selEleList:tableT1List[ind]._id,
      },
    });
  };

  backToMonList = async () => {
    // console.log("Back to Mon List");
    // console.log(this.state);
    // console.log(this.state.selBuildingID);
    this.props.history.push({
      pathname: "/monlist/addlist",
      _data: {
        _selBuildingID: this.state.selBuildingID,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
  };

  render() {
    let _navInfo = this.state.navBarInfo;
    // console.log(_navInfo);
    return (
      <React.Fragment>
        <div>
          <h1>Monitoring List Setting</h1>
          {_navInfo.mmonListName ? (
            <h5 className="monlist-display">
              <div className="monlist-back" onClick={this.backToMonList}>
                {_navInfo.buildingName}
              </div>
              <div className="monlist-arr">{`>`}</div>
              <div className="monlist-cur">{`${_navInfo.mmonListName}`}</div>
            </h5>
          ) : (
            <div>
              <div>{"Loading..."}</div>
            </div>
          )}
          <Icon className="fa fa-save deactive" onClick={this.save}></Icon>
          <div className="card">
            <div className="card-body" id="flip-scroll">
              <table className="cusTable" id="t1ListTable">
                <thead>
                  <tr>
                    <th scope="col" id="cb"></th>
                    <th scope="col">#</th>
                    <th style={{ display: "none" }}>_id</th>
                    <th scope="col">Name</th>
                    <th scope="col">List</th>
                    <th scope="col">up</th>
                    <th scope="col">dn</th>
                    <th style={{ display: "none" }}>SortIndex</th>
                    <th style={{ display: "none" }}>MonitorList_id</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_T1_List.map((u, ind) => (
                    <tr key={u._id}>
                      <td>
                        <Icon
                          className="fa fa-trash deactive"
                          onClick={() => this.delT1(ind)}
                        ></Icon>
                      </td>
                      <td>{ind + 1}</td>
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td>
                        <input type="text" defaultValue={u.name} />
                      </td>
                      <td>
                        <Icon
                          className="fa fa-list"
                          onClick={() => this.toElementPage(ind)}
                        ></Icon>
                      </td>
                      <td>
                        <Icon
                          className="fa fa-arrow-up"
                          onClick={() => this.clickUp(ind)}
                        ></Icon>
                      </td>
                      <td>
                        <Icon
                          className="fa fa-arrow-down"
                          onClick={() => this.clickDn(ind)}
                        ></Icon>
                      </td>
                      <td style={{ display: "none" }}>{u.SortIndex}</td>
                      <td style={{ display: "none" }}>{u.MonitorList_id}</td>
                    </tr>
                  ))}
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td id="newT1Name">
                      <input type="text" placeholder="New List..." />
                    </td>
                    <td> </td>
                    <td colSpan="2">
                      <Icon
                        className="fa fa-plus deactive"
                        onClick={() => this.insertNewList()}
                      ></Icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default AddT1_Page;
