import React, { Component } from "react";
import Icon from "../common/icon";
import tableFn from "../utilities/tableFunction";
import offsetFn from "../utilities/offsetValue/offsetValueFn";
import enm from "../utilities/enum";
import sensorKey from "../utilities/sensorKey";
import cssFn from "../utilities/css/cssFunction";
import {sortTemplate} from '../utilities/ArrayFn/arrayFn';
import sensorFn from '../../function/sensor/sensorManagementFn';
import { ToastContainer, toast } from "react-toastify";

class OffsetPage extends Component {
  state = {
    sensorEnum: [],
    sensorKeyEnm: [],
    offsetDisplayTable: [],
    boxChecked: false,
    typeEnm: [],
    newTypeKeyList:[],
  };

  async componentDidMount() {
    /** Get type enm */
    let typeEnm =await enm.getDevTypeEnum();
    await this.setState({typeEnm});

    await this.genSensorSelList();
    // console.log(this.state.sensorEnum);
    let newTypeKeyList = await this.pullSensorKeyList();
    await this.setState({newTypeKeyList});

  }

  async pullSensorKeyList(){
    let sensorEnum = [...this.state.sensorEnum];
    let tyList = [];
    for (const sensor of sensorEnum) {
      tyList.push(sensor._id)
    }
    tyList = tyList.filter(c=>c!==0);
    
    let newTypeKeyList = await sensorFn.getParaList_inid_name_byTyList(tyList);    
    // console.log(newTypeKeyList);
    return newTypeKeyList
  }

  async genSensorSelList() {
    let sensorEnum = await enm.getSensorEnmObj("All");
    await this.setState({ sensorEnum });
  }

  getSensorKey(type) {
    let sensorKeyEnm = sensorKey.getKeyListByType(parseInt(type));
    if (!sensorKeyEnm) {
      sensorKeyEnm = [];
    } else {
      let allExist = sensorKeyEnm.find((c) => c.name === "All");
      if (!allExist) sensorKeyEnm.splice(0, 0, { _id: 0, name: "All" });
    }
    this.setState({ sensorKeyEnm });
    // console.log(sensorKeyEnm);
  }

  getNumberRange(start, end) {
    let _start = parseInt(start);
    let _end = parseInt(end);

    if (!_end && !_start) return [0, 0];
    if (!_end) return [_start, _start];
    if (!start) return [_end, _end];
    if (_start > _end) return [_end, _start];
    return [_start, _end];
  }

  handleSearch = async () => {
    let starID = document.getElementById("fil_devID").value;
    let endID = document.getElementById("fil_devID_end").value;
    let range = this.getNumberRange(starID, endID);
    // console.log(range);

    let inputType = document.getElementById("fil_Sensor").value;
    if (inputType === "0") inputType = null;
    let inputDataKey = document.getElementById("fil_Key").value;
    if (inputDataKey === "0") inputDataKey = null;
    /** call http service to get offet list to display on table */
    let searchPara = {
      type: inputType,
      DataKey: inputDataKey,
      devIDStart: range[0],
      devIDEnd: range[1],
    };
    // console.log("Here la");
    let offsetDisplayTable = await offsetFn.getOffsetBySearch(searchPara);
    
    let sortKeys = [
      { key: "devID", descOrder: false },
    ];
    offsetDisplayTable.sort((a, b) => sortTemplate(a, b, sortKeys));
    
    await this.setState({ offsetDisplayTable });
    // console.log(offsetDisplayTable);
  };

  filterSensorSel = () => {
    let selected = document.getElementById("fil_Sensor").value;
    this.getSensorKey(selected);
    // console.log(selected);
  };

  temp_fil_id = () => {
    // let text = document.getElementById("fil_devID").value;
    // console.log(text);
  };

  filterKeySel = () => {
    // console.log("Key Selected");
  };

  getKeyDisplayName = (type, dataKey) => {
    let list = sensorKey.getKeyListByType(type);
    let found = list.find((c) => c._id === dataKey);
    if (found) return found.name;

    /** New sensor type */
    let newTypeKeyList = [...this.state.newTypeKeyList];
    found = newTypeKeyList.find((c) => c._id === dataKey && c.sensorType === type);
    if (found) return found.name;

    return "N/A";
  };

  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      // type: row.cells[3].innerHTML,
      // devID:row.cells[4].children[0].value,
      // bd_id: document.getElementById(`selBdRow${ind - 1}`).value,
      offsetValue: row.cells[6].children[0].value,
    };
    // console.log(column);
    return column;
  };

  handleSave = async () => {
    // console.log("Save");
    /** Get modified table */
    try {
      let editedRow = tableFn.detectTableChanges(
        "offsetTable",
        this.state.offsetDisplayTable,
        "_id",
        this.tableColumn
      );
      // console.log(editedRow);
      /**Call service to update database */
      for (const row of editedRow) {
        let rel = await offsetFn.updateOffset(row);
        // console.log(rel);
        rel ? toast.dark(`Update Succesful`) : toast.error(`Update Failed`);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /**Add  new offset item*/
  changePage = () => {
    // console.log("Change Page");
    this.props.history.push("/offset/reg");
    // this.props.history.push("/setting/regDevice");
  };

  /**Delete */
  handleDelete = async () => {
    // console.log("Delete");
    let rowChecked = tableFn.rawChecked("offsetTable", this.tableColumn);
    // console.log(rowChecked);
    if (!rowChecked[0]) return;
    for (const row of rowChecked) {
      let rel = await offsetFn.del(row);
      rel ? toast.dark(`Delete Succesful`) : toast.error(`Delete Failed`);
    }
    this.handleSearch();
  };

  handleCheck = async () => {
    let rowChecked = tableFn.rawChecked("offsetTable", this.tableColumn);
    let checked = false;
    if (rowChecked[0]) checked = true;
    await this.setState({ boxChecked: checked });
  };

  render() {
    cssFn.cssShowStatus(!this.state.sensorKeyEnm[0], "keyDD", "cssHide");
    cssFn.cssShowStatus(this.state.sensorKeyEnm[0], "keyDD", "cssInlineBlock");
    // console.log(this.state.sensorKeyEnm);

    return (
      <React.Fragment>
        <h1>Offset Page</h1>
        <div style={{ display: "inline-block" }}>
          {tableFn.tableDropDownKeySel(
            `fil_Sensor`,
            // enm.getSensorEnmObj("All"),
            this.state.sensorEnum,
            0,
            "select-dd",
            () => this.filterSensorSel()
          )}
        </div>
        <div style={{ display: "inline-block" }}>
          <span>ID: </span>
          <input
            type="number"
            min="1"
            id="fil_devID"
            onChange={this.temp_fil_id}
          ></input>
          <span>To</span>
          <input
            type="number"
            min="1"
            id="fil_devID_end"
            onChange={this.temp_fil_id}
          ></input>
        </div>
        <div id="keyDD">
          {tableFn.tableDropDownKeySel(
            `fil_Key`,
            // enm.getSensorEnmObj("All"),
            this.state.sensorKeyEnm,
            0,
            "select-dd",
            () => this.filterKeySel()
          )}
        </div>
        <Icon
          className="fa fa-search deactive"
          onClick={() => this.handleSearch()}
        />
        <Icon className="fa fa-plus deactive" onClick={this.changePage}></Icon>
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleSave()}
        />

        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="offsetTable">
              <thead>
                <tr>
                  <th
                    id={this.state.boxChecked ? "" : "cb"}
                    onClick={() => this.handleDelete()}
                  >
                    {this.state.boxChecked && (
                      <i
                        id="delButton"
                        style={{ color: "ButtonFace" }}
                        className="fa fa-trash-o"
                      ></i>
                    )}
                  </th>
                  <th>#</th>
                  <th style={{ display: "none" }}>_id</th>
                  <th>Sensor</th>
                  <th>ID</th>
                  <th>Data Key</th>
                  <th>Offset Value</th>
                </tr>
              </thead>
              <tbody>
                {this.state.offsetDisplayTable.map((u, ind) => (
                  <tr key={u._id}>
                    <td onClick={() => this.handleCheck()}>
                      <input type="checkbox"></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>{enm.getType_en(this.state.typeEnm, u.type)}</td>
                    <td>{u.devID}</td>
                    <td>{this.getKeyDisplayName(u.type, u.DataKey)}</td>
                    <td>
                      <input type="text" defaultValue={u.offsetValue} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      <ToastContainer />
      </React.Fragment>
    );
  }
}

export default OffsetPage;
