import React from "react";
import Joi from "joi-browser";
import userFn from "../utilities/getUser";
import buildingFn from "../utilities/getBuilding";
import tableFn from "../utilities/tableFunction";
import CustomForm from "../common/customForm";
// import CustomForm from "./../common/customForm";
// import "  ./bDeviceReg.css";
import Icon from "./../common/icon";
import tool from "../../checkingTools/tools";
import teleIdFn from "../utilities/notificaton/telegramID";
import "./notification.css";

class RegTelegramPage extends CustomForm {
  state = {
    allUsers: [],
    allBuildings: [],

    userEnumList: [],
    bdEnumList: [],
    bPageLoaded: false,
    data: {
      userID: 0,
      bd_id: 0,
      telegramID: "",
    },
    errors: {},
  };

  async componentDidMount() {
    await this.getAllBuildings();
    await this.getAllUser();

    await this.getUserEnumList();
    await this.getBdEnumList();
    await this.setState({ bPageLoaded: true });
  }

  async getAllBuildings() {
    let allBuildings = await buildingFn.getAllBuilding();
    await this.setState({ allBuildings });
    //  console.log(this.state.allBuildings);
  }

  async getAllUser() {
    let allUsers = await userFn.getAllUser();
    await this.setState({ allUsers });
    //  console.log(this.state.allUsers);
  }

  async getUserEnumList() {
    let userEnumList = [];
    let users = [...this.state.allUsers];
    if (!users[0]) return;
    userEnumList.push({ _id: 0, name: "Common" });
    for (const bd of users) {
      let userEnum = { _id: bd._id, name: bd.username };
      userEnumList.push(userEnum);
    }
    await this.setState({ userEnumList });
    // console.log(this.state.userEnumList);
  }

  async getBdEnumList() {
    let bdEnumList = [];
    let buildings = [...this.state.allBuildings];
    if (!buildings[0]) return;
    bdEnumList.push({ _id: 0, name: "All" });
    for (const bd of buildings) {
      let bdEnum = { _id: bd._id, name: bd.building };
      bdEnumList.push(bdEnum);
    }
    await this.setState({ bdEnumList });
  }

  doSubmit = async () => {
    try {
      console.log(`Continue`);

      let data = { ...this.state.data };
      data.userID = document.getElementById("userSel").value;
      data.bd_id = document.getElementById("bdSel").value;
      console.log(data);

      let response = await teleIdFn.add(data);
      if (!response) return console.log("Register teleID failed");
      console.log("Register teleID Succesful");

      window.location = `/notification/teleid`;
    } catch (ex) {
      tool.exResponse(ex);
    }
  };

  changePage = () => {
    console.log("CP");
    this.props.history.push("/notification/teleid");
  };

  schema = {
    userID: Joi.number().required().label("User ID"),
    bd_id: Joi.number().required().label("Building ID"),
    telegramID: Joi.number().required().label("Telegram Chat ID"),
  };

  render() {
    return (
      <React.Fragment>
        <Icon
          className="fa fa-arrow-left deactive back"
          onClick={this.changePage}
        />
        <div className="regTeleID">
          <div className="regTeleIDCard">
            <h1>Register Telegram ID</h1>
            <form onSubmit={this.handleSubmit} className="form-regTeleID">
              <div>
                <label className="user">User</label>
              </div>
              <span className="user-dd">
                {tableFn.dropDown(
                  `userSel`,
                  this.state.userEnumList,
                  0,
                  "select-dd",
                  this.typeSelChange
                )}
              </span>
              <div>
                <label className="building">Building</label>
              </div>
              <span className="build-dd">
                {tableFn.dropDown(
                  `bdSel`,
                  this.state.bdEnumList,
                  0,
                  "select-dd",
                  this.typeSelChange
                )}
              </span>
              {this.renderCustomInput("telegramID", "Telegram Chat ID")}
              <div align="center">{this.renderCustomButton("Register")}</div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegTelegramPage;
