import React, { Component } from "react";
import controlDevFn from "../utilities/conntrolDevice/ctrlDevFn";
import timeFn from "../utilities/timeFn";
import loraPac from "../../function/genLoRaPackage";
import Icon from './../common/icon';
import { ToastContainer, toast } from "react-toastify";

class ControlDevicePage extends Component {
  state = {
    a_BoolToSend:[],
    a_FloatToSend:[],
    a_IntToSend:[],
    a_Int64ToSend:[],
  };

  setPara = async () => {
    // console.log("Set para");

    let body = {
      nodeType: 2001,
      nodeId: 1,
      dataType: "Schedule",
      a_float: [111800220032767, 211800220032767, 311800220032767],
      a_bool: [
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
      ],
      a_ScheOfWeek: [1, 2, 4, 5, 6, 7, 2],
      a_SpecialDateSche: [
        10130,
        120213,
        2550214,
        170725,
        5201120,
        391225,
        2550214,
        170725,
        2551120,
        391225,
      ],
      // ts: 11665552,
      ts: timeFn.getUnixNowForCRC(), // reduce the ts by 1.6 x 10^9
    };

    // let _body = {...body};
    let data = await controlDevFn.send(body);
    // rel?console.log("Succelful"):console.log("Failed");
    // eslint-disable-next-line
    data && data.ft == body.ts
      ? console.log("Succesful")
      : console.log("Failed");
  };

  setSettingTemplate = async () => {
    let devDetails = {
      devType: 2,
      id: 101,
      dir: 2, // from gateway to node
      fun: 1, // function code, 1=> set parameter 1
    };

    let payload = {
      pb: [1, 1],
      pf: [29.5, 28, 5, 3],
      // pi:[123456789, 987654321],
      // pn:[1612669989 ,
      //     1612669999 ,
      //     1612669989 ,
      //     1612669777],
    };

    let package_ = loraPac.genLoRaPackage(devDetails, payload);
    if (package_.error) {
      console.log(package_.message);
      return;
    }
    console.log(package_);
    package_.gwid = 10; // set the gateway ID
    let data = await controlDevFn.send(package_);

    // console.log(data);
    // console.log(package_);
    // eslint-disable-next-line
    data && data.ft == package_.ft
      ? console.log("Succesful")
      : console.log("Failed");
  };

  manualSetHz = async () => {
    let devDetails = {
      devType: 7,
      id: 1,
      dir: 2, // from gateway to node
      fun: 2, // function code, 1=> set parameter 1
    };
    let payload = {
      // pb:[1,1],
      pf: [35],
      // pi:[123456789, 987654321],
      // pn:[1612669989 ,
      //     1612669999 ,
      //     1612669989 ,
      //     1612669777],
    };

    let package_ = loraPac.genLoRaPackage(devDetails, payload);
    if (package_.error) {
      console.log(package_.message);
      return;
    }
    console.log(package_);
    package_.gwid = 10; // set the gateway ID
    let data = await controlDevFn.send(package_);

    // console.log(data);
    // console.log(package_);
    // eslint-disable-next-line
    data && data.ft == package_.ft
      ? console.log("Succesful")
      : console.log("Failed");
  };

  addBool=async ()=>{
    let a_BoolToSend = [...this.state.a_BoolToSend];
    a_BoolToSend.push(false);
    await this.setState({a_BoolToSend});
  }
  delbool=async ()=>{
    let a_BoolToSend = [...this.state.a_BoolToSend];
    a_BoolToSend.pop();
    await this.setState({a_BoolToSend});
  }

  addFloat=async()=>{
    let a_FloatToSend = [...this.state.a_FloatToSend];
    a_FloatToSend.push(0);
    await this.setState({a_FloatToSend});
  }

  delFloat=async()=>{
    let a_FloatToSend = [...this.state.a_FloatToSend];
    a_FloatToSend.pop();
    await this.setState({a_FloatToSend});
  }

  addInt=async()=>{
    let a_IntToSend = [...this.state.a_IntToSend];
    a_IntToSend.push(0);
    await this.setState({a_IntToSend});
  }

  delInt=async()=>{
    let a_IntToSend = [...this.state.a_IntToSend];
    a_IntToSend.pop();
    await this.setState({a_IntToSend});
  }

  addInt64=async()=>{
    let a_Int64ToSend = [...this.state.a_Int64ToSend];
    a_Int64ToSend.push(0);
    await this.setState({a_Int64ToSend});
  }

  delInt64=async()=>{
    let a_Int64ToSend = [...this.state.a_Int64ToSend];
    a_Int64ToSend.pop();
    await this.setState({a_Int64ToSend});
  }

  getDevDetails=()=>{
    try {
      //get info from HTML
      let devType = document.getElementById('nodeType').value;
      let id = document.getElementById('id').value;
      let dir = document.getElementById('dir').value;
      let fun = document.getElementById('fun').value;      
      let body={
        devType:parseInt(devType),
        id:parseInt(id),
        dir:parseInt(dir),
        fun:parseInt(fun)
      }
      // console.log(body);
      for (const key in body) {
          if (Number.isNaN(body[key])) return
      }
      return body;
    } catch (error) {
      console.log(error.message);
    }
  }

  getPb=()=>{
    let a_BoolToSend=[];
    for (let index = 0; index < this.state.a_BoolToSend.length; index++) {
      let boolSel = document.getElementById(`apbCheck${index}`).checked;
      a_BoolToSend.push(boolSel?1:0);
    }
    // console.log(a_BoolToSend);
    return a_BoolToSend;
  }
  
  getPf=()=>{
    let a_FloatToSend=[];
    for (let index = 0; index < this.state.a_FloatToSend.length; index++) {
      let fFloat = document.getElementById(`apfCheck${index}`).value;
      a_FloatToSend.push(parseFloat(fFloat));
    }
    // console.log(a_FloatToSend);
    return a_FloatToSend;
  }

  getPi=()=>{
    let a_IntToSend=[];
    for (let index = 0; index < this.state.a_IntToSend.length; index++) {
      let nInt = document.getElementById(`apiCheck${index}`).value;
      a_IntToSend.push(parseInt(nInt));
    }
    // console.log(a_IntToSend:);
    return a_IntToSend;
  }
  
  getPn=()=>{
    let a_Int64ToSend=[];
    for (let index = 0; index < this.state.a_Int64ToSend.length; index++) {
      let nInt64 = document.getElementById(`apnCheck${index}`).value;
      a_Int64ToSend.push(parseInt(nInt64));
    }
    // console.log(a_Int64ToSend:);
    return a_Int64ToSend;
  }

  sendCmd=async()=>{
    try {
      /**Get Device Details */
      let devDetails = this.getDevDetails();
      // console.log(devDetails);
      if (!devDetails) return console.log("Device Details Not Correct");

      /**get payload info */       
      let payload={};
      /**PB */
      let a_BoolToSend = this.getPb();
      await this.setState({a_BoolToSend});
      payload.pb = a_BoolToSend;
      /**PF */
      let a_FloatToSend= this.getPf();
      await this.setState({a_FloatToSend});
      payload.pf = a_FloatToSend;
      /**PI */
      let a_IntToSend= this.getPi();
      await this.setState({a_IntToSend});
      payload.pi = a_IntToSend;
      /**Pn */
      let a_Int64ToSend= this.getPn();
      await this.setState({a_Int64ToSend});
      payload.pn = a_Int64ToSend;
      
      // console.log(payload);
      /**Get gateway ID */
      let GwID = document.getElementById('Gwid').value;
      // console.log(`GW id: ${GwID}`);

      let package_ = loraPac.genLoRaPackage(devDetails, payload);
      if (package_.error) {
        console.log(package_.message);
        return;
      }
      console.log(package_);
      package_.gwid = parseInt(GwID); // set the gateway ID
      let data = await controlDevFn.send(package_);
  
      // eslint-disable-next-line
      data && data.ft == package_.ft
        ? toast.dark(`Succesful`)
        : toast.error(`Failed`);

    } catch (error) {
      console.log(error.message);
    }
  }

  render() {    
    return (
      <React.Fragment>
        <h1>Control Device</h1>
        <button onClick={this.setPara}> Send Schedule</button>

        <button onClick={this.setSettingTemplate}> Send Setting</button>
        <button onClick={this.manualSetHz}> Set Hz to 38</button>
        <div><span> </span></div>
        <div><span>Device Type: </span><input type = 'number' id="nodeType"></input></div>
        <div><span>ID: </span><input type = 'number' id="id"></input></div>
        <div><span>Gateway ID: </span><input type = 'number' id="Gwid"></input></div>
        <div><span>Direction: </span><input type = 'number' id="dir" defaultValue='2'></input></div>
        <div><span>Function: </span><input type = 'number' id="fun" defaultValue='3'></input></div>
        <div><span> </span></div>

        <div> 
          <span>Boolean (pb): </span>
          <Icon className="fa fa-plus deactive" onClick={this.addBool}></Icon>
          <Icon className="fa fa-minus deactive" onClick={this.delbool}></Icon>
        </div>
        {this.state.a_BoolToSend.map((c, ind)=>(
          <div key={`apb${ind}`}><span>{`Bool ${ind}: `}</span><input id={`apbCheck${ind}`} type="checkbox"></input></div>          
        ))}

        <div> 
          <span>Float (pf): </span>
          <Icon className="fa fa-plus deactive" onClick={this.addFloat}></Icon>
          <Icon className="fa fa-minus deactive" onClick={this.delFloat}></Icon>
        </div>
        {this.state.a_FloatToSend.map((c, ind)=>(
          <div key={`apf${ind}`}><span>{`Float ${ind}: `}</span><input id={`apfCheck${ind}`} type="number"></input></div>          
        ))}

        <div> 
          <span>Int (pi): </span>
          <Icon className="fa fa-plus deactive" onClick={this.addInt}></Icon>
          <Icon className="fa fa-minus deactive" onClick={this.delInt}></Icon>
        </div>
        {this.state.a_IntToSend.map((c, ind)=>(
          <div key={`api${ind}`}><span>{`Int ${ind}: `}</span><input id={`apiCheck${ind}`} type="number"></input></div>          
        ))}

        <div> 
          <span>Int64 (pn): </span>
          <Icon className="fa fa-plus deactive" onClick={this.addInt64}></Icon>
          <Icon className="fa fa-minus deactive" onClick={this.delInt64}></Icon>
        </div>
        {this.state.a_Int64ToSend.map((c, ind)=>(
          <div key={`apn${ind}`}><span>{`Int64 ${ind}: `}</span><input id={`apnCheck${ind}`} type="number"></input></div>          
        ))}
        <button onClick={this.sendCmd}> Send Command</button>
      <ToastContainer />
      </React.Fragment>
    );
  }
}

export default ControlDevicePage;
