import React, { useEffect, useRef, useState } from 'react';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import socketIOClient from "socket.io-client";
// import { isEmptyObj } from '../../../function/utilities/utilities';
import TpLoadingBar from './../../SharedTemplate/LoadingPattern/TpLoadingBar';

function TpPecOvv(props) {
    
    const [G_Lastdata, setG_Lastdata] = useState([]);
    const [G_DeviceInfo, setG_DeviceInfo] = useState({});
    const [G_SensorTimeOut, setG_SensorTimeOut] = useState(false);
    const [G_HideOvvItem, setG_HideOvvItem] = useState(false);
    const [G_LoadDone, setG_LoadDone] = useState(false);
    const [G_DisplayName, setG_DisplayName] = useState("");

    const timerRef = useRef(null);
    const pageClose = useRef(false);

    useEffect(()=>{
        timerRef.current = setInterval(async() => {
            // console.log("Refresh");
            await reloadData();
        }, 300000);       /** 300*1000s , 5 mins*/

        let socket;

        async function startUp(){
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            // console.log("devInfo :", props.devInfo);
            if(!props.devInfo) return;
            // console.log("props.devInfo", props.devInfo);
            setG_DisplayName(props.devInfo.displayName);
            let defInfo = await BdDevFn_Com.loadDeviceInfo(props.devInfo.bdDev_id)
            if(pageClose.current) return
            if(defInfo) setG_DeviceInfo(defInfo);
            // console.log("defInfo", defInfo);
            if(props.callFromDevDetails){
                setG_HideOvvItem(true);
            }
            await reloadData();

            setG_LoadDone(true);
            let{type, _id}= defInfo;
            // let topic = `v2_${type}_${_id}`;
            let topic = `v1_${type}_${_id}`;
            // console.log("Socket IO topic: ", topic);
            socket.on(topic, async (data) => {                                
                // let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
                // if(lastData) setG_Lastdata(lastData);
                await reloadData();
            });
        }
        startUp();

        return ()=>{
            socket.disconnect();
            clearInterval(timerRef.current);
            pageClose.current = true;
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const reloadData=async ()=>{
        try {
            let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
            // console.log("lastData", lastData);
            if(pageClose.current) return
            if(lastData) setG_Lastdata(lastData);            
            let sensorTimeout = BdDevFn_Com.sensorTimeout(lastData);
            setG_SensorTimeOut(sensorTimeout)
            // console.log("sensorTimeout", sensorTimeout);
        } catch (error) {
            console.log("reloadData err: ", error.message);
        }        
    }
    
    // const handleOnCmd=async ()=>{
    //     if (!props.fOnClick) return;
    //     if(isEmptyObj(G_DeviceInfo)) return;

    //     await props.fOnClick(G_DeviceInfo);
    // }
    
    // const handleOffCmd=async ()=>{
    //     if (!props.fOffClick) return;
    //     if(isEmptyObj(G_DeviceInfo)) return;

    //     await props.fOffClick(G_DeviceInfo);
    // }
    
    const handleZoomToDetail=(dev)=>{
        // console.log("dev", dev);
        if(!props.handleZoomToDetail) return;
        // console.log("Pass Internal");
        // props.handleZoomToDetail(38, 999, 9999);
        props.handleZoomToDetail(dev.type, dev.devID, dev._id);
    }
    

    return (
        <div>            
            <div className={`${G_SensorTimeOut?"autumn_WarningCard":"autumn_Card"} ${G_HideOvvItem?"autumn_ZoomCardSize_noOnOff":""}`}>            
                {!G_HideOvvItem &&<div className='autumn_CardTitle sortHor bothCenter'>
                    <div>
                        <div className={`autumn_smallTitle ${G_DeviceInfo.location?"":"hideDiv_WithSpace"}`}>
                            {G_DeviceInfo.location ? `${G_DeviceInfo.location}`:"Hidden"}
                        </div>
                        <div>
                            {G_DeviceInfo.name ? `${G_DeviceInfo.name} [${G_DeviceInfo.devID}]`:""}
                        </div>
                        <div className={`autumn_smallTitle autumn_smallTitleColor ${G_DisplayName?"":"hideDiv_WithSpace"}`}>
                            {G_DisplayName}
                        </div>
                    </div>
                    <div className='stayRight autumnBarIcon fa fa-bars hoverPointer'
                    style={{ color: "rgb(180, 180, 180)" }}
                    onClick={()=>handleZoomToDetail(G_DeviceInfo)}></div>
                </div>}

                
                {!G_LoadDone &&<div style={{marginTop:`${G_HideOvvItem? "30px" : "90px"}`}}><TpLoadingBar/></div>}
                {G_LoadDone && <div>
                    <div className='autumn_statusContainer'>
                        <div className='sortHor verCenter'>
                            <div className=''>
                                Run 
                            </div>
                            <div className='stayRight'>
                                {/* <div className='greenIndicator'></div> */}
                                <div className={G_Lastdata.pb_0===1?"greenIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div>
                        
                        <div className='sortHor verCenter'>
                            <div className=''>
                                Error
                            </div>
                            <div className='stayRight sortHor'>
                                {/* <div className={G_Lastdata.pb_11===1?"yellowIndicator":"idleIndicator"}></div> */}
                                <div className={G_Lastdata.pb_9===1?"redIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div>
                        <div className='sortHor verCenter '>
                            <div className=''>
                                Comp.
                            </div>
                            <div className='stayRight sortHor'>
                                <div className={G_Lastdata.pb_6===1?"greenIndicator":"idleIndicator"}></div>
                                {/* <div className={G_Lastdata.pb_2===1?"greenIndicator":"idleIndicator"}></div> */}
                                {/* {G_Lastdata.pb_14===0 && <div className={G_Lastdata.pb_3===1?"greenIndicator":"idleIndicator"}></div>}
                                {G_Lastdata.pb_14===0 && <div className={G_Lastdata.pb_4===1?"greenIndicator":"idleIndicator"}></div>} */}
                            </div>                        
                        </div>
                        
                        {/* <div className='sortHor verCenter '>
                            <div className=''>
                                Heater
                            </div>
                            <div className='stayRight sortHor'>
                                <div className={G_Lastdata.pb_5===1?"greenIndicator":"idleIndicator"}></div>
                                <div className={G_Lastdata.pb_6===1?"greenIndicator":"idleIndicator"}></div>
                                <div className={G_Lastdata.pb_7===1?"greenIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div> */}
                        {/* <div className='sortHor verCenter '>
                            <div className=''>
                                Blower | Pump
                            </div>
                            <div className='stayRight sortHor'>
                                <div className={G_Lastdata.pb_8===1?"greenIndicator":"idleIndicator"}></div>
                                <div className={G_Lastdata.pb_9===1?"greenIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div> */}
                        <div className='sortHor verCenter '>
                            <div className=''>
                                Return Temp.
                            </div>
                            <div className='stayRight sortHor autumn_tempDis autumnIndicatorSpace'>
                                <div>{isNaN(G_Lastdata.pf_1) ? "":`${G_Lastdata.pf_1.toFixed(1)} °C`} </div>
                            </div>                        
                        </div>
                        <div className='sortHor verCenter '>
                            <div className=''>
                                Return Humid.
                            </div>
                            <div className='stayRight sortHor autumn_tempDis autumnIndicatorSpace'>
                                <div>{isNaN(G_Lastdata.pf_0) ? "":`${G_Lastdata.pf_0.toFixed(1)} %`} </div>
                            </div>                        
                        </div>
                        
                    </div>
                    
                    {/* <div className='autumn_CtrlArea sortHor spaceAround'>
                        <div className='autumn_OnButton bothCenter hoverPointer'
                        onClick={handleOnCmd}>
                            <div className='fa fa-power-off fa-lg autumnButton'
                            style={{ color: "rgb(220, 220, 220)" }}></div>
                        </div>                    
                        <div className='autumn_OffButton bothCenter hoverPointer'
                        onClick={handleOffCmd}>
                            <div className='fa fa-power-off fa-lg'
                            style={{ color: "rgb(220, 220, 220)" }}></div>
                        </div>
                    </div> */}
                </div>}
            </div>
        </div>
    );
}

export default TpPecOvv;