import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import devFn from '../../utilities/deviceFunction/deviceFunction';
import regDev_V2fn from '../../utilities/devMgnt_v2_Fn/devMgnt_V2Fn';
import acFunction from '../Fun/acCtrl_Function';
import cssFn from '../../utilities/css/cssFunction';

class LogInAcDevicePage extends Component {
    state = { 
        controableDev:[], 
    }
    
    async componentDidMount() {
        await this.getDevExist();
        // console.log(this.state.controableDev);
        this.backButtonVisible();
    }

    backButtonVisible=()=>{
        cssFn.cssShowStatus(this.state.controableDev.length <= 0, 'backButton', "checkHidden");
    }

    getDevExist=async()=>{
        let controableDev = await acFunction.getDevUnderAccount();
        this.setState({controableDev});
    }

    changeDevID=()=>{
        let pairId = parseInt(document.getElementById('devIDInput').value);
        // console.log(pairId);
        if(Number.isNaN(pairId)) return 
        document.getElementById('devIDInput').value = pairId;
    }

    backTo=()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac/devsel",
            // _data: {
            //     devID,
            //     bdDev_id:matchdev._id, 
            //   // _selT1Llist:tableMonList[ind]._id,
            // },
          });
    }

    clickGo=async ()=>{
        try {
            /** Get Name */
            let displayName = document.getElementById('nameInput').value;
            let devID = parseInt(document.getElementById('devIDInput').value);
            let password = document.getElementById('passInput').value;
    
            // console.log(devID);
            // console.log(password);
            let matching = this.checkMatchingList(devID, password);
            if(!matching) return toast.error(`Invalid ID or Password`);
    
    
            let matchdev =await devFn.getByTypenId({type:16, devID:devID});
            if(!matchdev || !matchdev[0]) return toast.error(`Device Not Registered`);
            // console.log(matchdev);        
            
            /** insert into device owner DB */
            let devInfo={
                devID,
                bdDev_id:matchdev[0]._id,
                displayName,
                type:16,
                accessLevel:1,
            }
            // console.log(devInfo);
            let insertRel = await regDev_V2fn.regBdDev_V2(devInfo);
            if(insertRel!=='OK') return toast.error(insertRel);
    
            /** set local storage and change page */
            await localStorage.setItem("bdDev_id", matchdev[0]._id);
            await localStorage.setItem("devID", devID);
            // await localStorage.setItem("acDevPass", password);
    
            this.props.history.push({
                pathname: "/ctrldev/ac",
                _data: {
                    devID,
                    bdDev_id:matchdev._id, 
                  // _selT1Llist:tableMonList[ind]._id,
                },
              });
            // console.log(matching);
        } catch (error) {
            toast.error(error.message);
        }
    }

    checkMatchingList=(devID, password)=>{
        try {
            let passToMatch ='';
            switch (devID) {
                case 1: passToMatch='hellokitty';    break;
                case 2: passToMatch='hellokitty';    break;
                case 3: passToMatch='hellokitty';    break;
                case 11: passToMatch='2s539a';    break;
                case 12: passToMatch='2s8y9a';    break;
                case 13: passToMatch='487sys';    break;
                case 14: passToMatch='5dkfjo';    break;
                case 15: passToMatch='3189as';    break;
                case 16: passToMatch='9yqi2j';    break;
                case 17: passToMatch='8kjhk2';    break;
            
                default:
                    break;
            }
    
            return (password === passToMatch && passToMatch !== '');
        } catch (error) {
            toast.error(error.message);
        }
    }

    render() { 
        return ( <>
            <div 
                className="spaceBetween"
                style={{
                    margin:'15px 10px',
                }}>
                <div
                    id='backButton'
                    onClick={this.backTo} 
                    className="framelessIcon">
                    <i className='fa fa-chevron-left buttonIcon'></i>
                </div>
                <div style={{
                    // backgroundColor:"blue",
                    fontSize:'25px',
                    margin:'-10px',
                    }}>
                    Select Device
                </div>
                <div 
                    className="framelessIcon"
                    onClick={this.clickGo}
                    style={{
                        // flex:1,
                        // float:'right',
                        alignSelf:'flex-end',                    
                        // backgroundColor:"green"
                }}>
                    <i className='fa fa-arrow-right buttonIcon'></i>
                </div>
            </div>
            <div>
                <div
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Name
                    </div>
                    <input 
                        id='nameInput'
                        // onChange={this.changeDevID}
                        className='inputField'
                        // type='Number' 
                        placeholder="<Device Name>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>  
                <div
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Device ID
                    </div>
                    <input 
                        id='devIDInput'
                        onChange={this.changeDevID}
                        className='inputField'
                        type='Number' 
                        placeholder="<Device ID>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>     
                <div
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Password
                    </div>
                    <input 
                        id='passInput'
                        // onChange={this.changePairID}
                        className='inputField'
                        type='password' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>     

            </div>
        <ToastContainer />
        </> );
    }
}
 
export default LogInAcDevicePage;