import React from "react";
import { Chart, Line } from "react-chartjs-2";
import querybdDev from "../../service/queryBdDevData";
import tools from "../../checkingTools/tools";
import Icon from "./../common/icon";
import tableFn from "../utilities/tableFunction";
import sensorKey from "../utilities/sensorKey";
import lineListFn from "../utilities/lineListFn";
import offsetFn from "../utilities/offsetValue/offsetValueFn";
import "./graph.css";
import { roundDecimalPoint } from "../utilities/conversion";
import NcForm from "./../common/ncForm";
import cssFn from "../../component/utilities/css/cssFunction";
import SensorFn from '../../function/sensor/sensorManagementFn';


Chart.defaults.global.legend.display = false;

class Graph extends NcForm {
  state = {
    graphPara: {},
    lines: [],
    loaded: false,
    queryList: [],
    dataLen: 480,
    settingDisplay: "none",
    // iconCName: "fa fa-chevron-down",
    minMaxScale: [
      { min: undefined, max: undefined },
      { min: undefined, max: undefined },
    ],
    yAxesDisplayStatus: [{ hidden: false }, { hidden: false }],
  };

  _isMounted = false;

  // schema = {
  //   lowerLimit1: Joi.number().required().label("Min"),
  //   upperLimit1: Joi.number().required().label("Max"),
  //   lowerLimit2: Joi.number().required().label("Min"),
  //   upperLimit2: Joi.number().required().label("Max"),
  // };

  async componentDidMount() {
    // console.log(this.props.newSenPara); 
    this._isMounted = true;
    try {
      await this.setState({ loaded: false });
      let graphPara = { ...this.props.graphPara };
      // console.log(graphPara);
      if (this._isMounted) await this.setState({ graphPara });
      await this.updateBdDevFromDB(480);
      if (this._isMounted) await this.setState({ loaded: true });
    } catch (error) {
      console.log(error.message);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log("Change");
    if (
      this.props.unixStart !== prevProps.unixStart ||
      this.props.unixEnd !== prevProps.unixEnd
    )
      await this.refresh();
  }

  async refresh(_min) {
    try {
      await this.setState({ loaded: false });
      let dataLen = _min;
      if (!dataLen) dataLen = this.state.dataLen;
      await this.setState({ dataLen });
      await this.updateBdDevFromDB(dataLen);
      await this.setState({ loaded: true });
    } catch (error) {
      console.log(error.message);
    }
  }

  async updateBdDevFromDB(dataLen) {
    try {
      await this.getLines();
      let queryList = this.getTybdDevID();
      // console.log(queryList);
      // if(queryList[0]){       // prevent no data come in 1st few min
      for (let i = 0; i < queryList.length; i++) {
        let lineData = await this.queryBdDevData(queryList[i], dataLen);
        // console.log(lineData);
        queryList[i].data = lineData;
      }
      // }
      if (this._isMounted) this.setState({ queryList });
      this.getLineData();
    } catch (error) {
      console.log(error.message);
    }
  }

  async queryBdDevData(queryArr, min) {
    try {
      // let emptyArray = [];
      if (!queryArr.type || !queryArr.bdDev_id) return;
      let result;
      // console.log("Query Data");
      // console.log(this.props.unixEnd);
      if (this.props.unixStart === "" || this.props.unixEnd === "")
        result = await querybdDev.getLastNMin(
          queryArr.type,
          queryArr.bdDev_id,
          min
        );
      else
        result = await querybdDev.getTimeToTime(
          queryArr.type,
          queryArr.bdDev_id,
          this.props.unixStart,
          this.props.unixEnd
        );

      // if (result.status === 200) return result.data; // previous coding
      if (result.status === 200) {
        /** Handle offset value here */
        /** get device offset list here */
        let offsetList = [];
        for (const data of result.data) {
          let found = offsetList.find(
            (c) => c.type === data.type && c.devID === data.devID
          );
          if (!found) {
            // console.log("After");
            let result = await offsetFn.getOffsetByIdnKey(data);
            if (!result[0]) result = [{ type: data.type, devID: data.devID }];
            offsetList.push(...result);
          }

          /**Check  through the offset list*/
          for (const offsetItem of offsetList) {
            if (offsetItem.DataKey) {
              // contain offset
              if (
                data.hasOwnProperty(offsetItem.DataKey) &&
                data.type === offsetItem.type &&
                data.devID === offsetItem.devID
              ) {
                // data[offsetItem.DataKey] += offsetItem.offsetValue;
                let valueAfterOffset =
                  data[offsetItem.DataKey] + offsetItem.offsetValue;
                data[offsetItem.DataKey] = roundDecimalPoint(
                  valueAfterOffset,
                  2
                );
              }
            }
          }
        }
        // console.log(offsetList);
        return result.data;
      }
      // console.log("Here Correct");
      return [];
    } catch (error) {
      console.log("error");
      tools.exResponse(error);
      return [];
    }
  }

  getTybdDevID() {
    // this function will prevent query multiple command from same devices
    try {
      let lines = [...this.state.lines];
      let a_obj = [];
      for (const line of lines) {
        let duplicated = a_obj.find(
          (e) => e.type === line.type && e.bdDev_id === line.bdDev_id
        );
        // console.log(duplicated);
        if (!duplicated) {
          let obj = {
            type: line.type,
            bdDev_id: line.bdDev_id,
          };
          a_obj.push(obj);
        }
      }
      return a_obj;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  async getLines() {
    try {
      let lines = [];
      if (this.state.graphPara.line1_id)
        lines.push(this.state.graphPara.line1_id);
        // console.log(this.state.graphPara.line1_id);
      if (this.state.graphPara.line2_id)
        lines.push(this.state.graphPara.line2_id);
      if (this.state.graphPara.line3_id)
        lines.push(this.state.graphPara.line3_id);
      this.setState({ lines });
    } catch (error) {
      console.log(error.message);
      return []
    }
  }

  genLineColor() {
    const mychart = document.getElementById(`myChart_${this.props._index}`);
    if (!mychart) return;
    const ctx = mychart.getContext("2d");
    // const ctx = canvas.getContext("2d");
    const gradient1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
    const gradient2 = ctx.createLinearGradient(0, 0, 0, mychart.height);

    gradient1.addColorStop(0, "rgba(75,192,192,0.4)");
    gradient1.addColorStop(1, "rgba(75,192,192,0)");
    gradient2.addColorStop(0, "rgba(116,39,116,0.4)");
    gradient2.addColorStop(1, "rgba(116,39,116,0)");
    // console.log(gradient1);
    return [
      {
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: gradient1,
      },
      {
        borderColor: "rgba(116,39,116,1)",
        backgroundColor: gradient2,
      },
      {
        borderColor: "rgba(75,192,0,1)",
        backgroundColor: "rgba(75,192,0,0.4)",
      },
    ];
  }

  tescoKulimExceptionCase(id, key) {
    if (
      id === 21 &&
      (key === "ActivePower_A" ||
        key === "ActivePower_B" ||
        key === "ActivePower_C" ||
        key === "ActivePower_Total")
    )
      return true;
    return false;
  }

  getLineData() {
    try {
      let queryList = [...this.state.queryList];
      let lines = [...this.state.lines];
      let lineDatas = [];
      for (const line of lines) {
        let queryatches = queryList.filter((e) => {
          return e.bdDev_id === line.bdDev_id && e.type === line.type;
        });

        let singleLineData = [];
        if (queryatches[0]) {
          for (const keyData of queryatches[0].data) {
            let oneData = {
              x: keyData.unix * 1000,
              // y: keyData[line.dataKey],
              // y:(line.bdDev_id===21)?keyData[line.dataKey]/1000:keyData[line.dataKey],
              y: this.tescoKulimExceptionCase(line.bdDev_id, line.dataKey)
                ? keyData[line.dataKey] / 1000
                : keyData[line.dataKey],
            };
            // console.log(line);
            singleLineData.push(oneData);
          }
        }
        if (singleLineData[0]) lineDatas.push(singleLineData);
      }
      // console.log(lineDatas);
      return lineDatas;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  genDataSet() {
    try {
      let lineColor = this.genLineColor();
      let lines = [...this.state.lines];
      let _data = this.getLineData();
      if (!_data[0]) return;
      // console.log(_data);
      let datasets = [];
      if (lines[0]) {
        // make sure lines is not empty array.
        for (let i = 0; i < lines.length; i++) {
          let dataset = {
            label: `${lines[i].name}`,
            yAxisID: `${lines[i].dataKey}`,
            data: _data[i],
            fill: "start",
            shadow: "true",
            pointRadius: "2",
            pointStyle: "circle",
            borderWidth: "1",
            hidden: this.state.yAxesDisplayStatus[i].display,
            borderColor: lineColor[i].borderColor,
            backgroundColor: lineColor[i].backgroundColor,
          };
          datasets.push(dataset);
        }
      }
      return datasets;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  genLastReading() {
    try {
      let _data = this.getLineData();
      let lastReading = [];
      for (let i = 0; i < _data.length; i++) {
        if (_data) lastReading[i] = _data[i][0].y;
      }
      return lastReading;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  genxAxesOptions() {
    return [
      {
        type: "time",
        time: { unit: "hour" },
        gridLines: { display: true, color: "#333333" },
      },
    ];
  }

  genyAxesOptions() {
    try {
      let lines = [...this.state.lines];
      // console.log(lines.length);
      let yAxeses = [];
      for (let i = 0; i < lines.length; i++) {
        let yAxes = {
          id: `${lines[i].dataKey}`,
          type: "linear",
          display: true,
          // eslint-disable-next-line
          position: i == 1 ? "right" : "left",
          gridLines: { display: true, color: "#333333" },
          scaleLabel: {
            display: true,
            labelString: lines[i].dataUnit,
          },
          ticks: {
            maxTicksLimit: 7,
            min: this.state.minMaxScale[i].min,
            max: this.state.minMaxScale[i].max,
            suggestedMin: lines[i].scaleBottom,
            suggestedMax: lines[i].scaleTop,
          },
        };
        yAxeses.push(yAxes);
      }
      // console.log(yAxeses);
      return yAxeses;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  getLinePara(type, key) {
    // console.log(key);
    const paraList = lineListFn.defaultKeyPara;
    
    // console.log(paraList);
    let result = paraList.find(
      // eslint-disable-next-line
      (e) => e.dataKey == key && (!e.type || e.type == type)
    );
    // console.log(result);
    if(result) return result;

    /** New Type */
    // console.log(this.props.newSenPara);
    // let s_split = key.split('_');
    // console.log(s_split);
    
    let splitKey = SensorFn.splitSensorKey(key);
    // console.log(key);
    // console.log(splitKey);
    let filtered = this.props.newSenPara.find(c=>(c.sensorType ===type 
      // && c.defaultDisplay ===1 
      && c.dataType===splitKey.dataType
      && c.dataIndex===splitKey.dataIndex
      ));
    // console.log(filtered);
    let rel2 = lineListFn.lineDataCast(filtered);
    // console.log(rel2);
    return rel2
  }

  updateLinePara(linex_id, eleID) {
    let line_id = linex_id;
    let ty1 = document.getElementById(`${eleID}`).value;
    // console.log(line_id.type);
    // graph.line1_id.type
    let keyParaFromDB_Default = this.getLinePara(line_id.type, ty1);
    // console.log(keyParaFromDB_Default);
    for (const _key in line_id) {
      if (line_id.hasOwnProperty(_key)) {
        if (keyParaFromDB_Default.hasOwnProperty(_key)) {
          // console.log(_key);
          line_id[_key] = keyParaFromDB_Default[_key];
        }
      }
    }
    // console.log(line_id);
    return line_id;
  }

  linePara() {
    // const orderIndex = this.state.graphPara.orderIdx;
    const orderIndex = this.props._index;
    let graphPara = { ...this.state.graphPara };
    // console.log(graph);
    if (graphPara.line1_id) {
      graphPara.line1_id = this.updateLinePara(
        graphPara.line1_id,
        `ty1Sel_${orderIndex}`
      );
    }
    if (graphPara.line2_id) {
      graphPara.line2_id = this.updateLinePara(
        graphPara.line2_id,
        `ty2Sel_${orderIndex}`
      );
    }
    if (graphPara.line3_id) {
      graphPara.line3_id = this.updateLinePara(
        graphPara.line3_id,
        `ty3Sel_${orderIndex}`
      );
    }

    // console.log(graphPara);
    this.setState({ graphPara });
    // console.log(this.state.graphPara);
    return graphPara;
  }

  currentKeyList(lineIdx) {
    try {
      // if(lineIdx===3) console.log(lineIdx);
      if (!this.state.lines[lineIdx - 1]) return null;
      let type = this.state.lines[lineIdx - 1].type;
      //  console.log(this.state.lines[lineIdx-1]);
      ///???
      // console.log(this.props.newSenPara);
      // console.log(sensorKey.getKeyListByType(type));
      // console.log(sensorKey.getKeyListByType_V2(this.props.newSenPara, type));
      // console.log(this.state.lines[lineIdx - 1].dataKey);
      let obj = {
        keyList: sensorKey.getKeyListByType(type),
        key: this.state.lines[lineIdx - 1].dataKey,
      };

      // console.log(this.props.newSenPara);

      // console.log(this.state.lines);
      let obj_V2 = {
        keyList: sensorKey.getKeyListByType_V2(this.props.newSenPara, type),
        key: this.state.lines[lineIdx - 1].dataKey
      }

      // if(type===8){
      //   console.log(sensorKey.getKeyListByType(type));
      // }
      if (sensorKey.getKeyListByType(type).length===0) {
        // console.log(obj_V2);
        return obj_V2;
      }
      return obj;
    } catch (error) {
      console.log(error.message);
      return null;
    }
  }

  handleSetting = () => {
    // console.log("setting clicked");
    const setting = this.state.settingDisplay;
    setting === "none"
      ? this.setState({ settingDisplay: "block" })
      : this.setState({ settingDisplay: "none" });
  };

  doSubmit = async () => {
    // console.log("Enter");
    // this.setState({ minMaxScale: [{ min: 10, max: 100 }, { min: 20, max: 50 }] });

    try {
      let upperLimit1 = document.getElementById(
        `upperLimit1_${this.props._index}`
      ).value;
      let lowerLimit1 = document.getElementById(
        `lowerLimit1_${this.props._index}`
      ).value;
      let upperLimit2 = document.getElementById(
        `upperLimit2_${this.props._index}`
      ).value;
      let lowerLimit2 = document.getElementById(
        `lowerLimit2_${this.props._index}`
      ).value;
      // console.log(upperLimit1);
      // console.log(lowerLimit1);
      // console.log(upperLimit2);
      // console.log(lowerLimit2);

      let minMaxScale = [...this.state.minMaxScale];
      if (isFinite(String(lowerLimit1)) && lowerLimit1)
        minMaxScale[0].min = parseInt(lowerLimit1);
      if (isFinite(String(upperLimit1)) && upperLimit1)
        minMaxScale[0].max = parseInt(upperLimit1);
      if (isFinite(String(lowerLimit2)) && lowerLimit2)
        minMaxScale[1].min = parseInt(lowerLimit2);
      if (isFinite(String(upperLimit2)) && upperLimit2)
        minMaxScale[1].max = parseInt(upperLimit2);

      this.setState({ minMaxScale });
      // console.log(minMaxScale);
    } catch (ex) {
      console.log(ex);
    }
    this.handleSetting();
  };

  handleChange = (i, idx) => {
    let yAxesDisplayStatus = this.state.yAxesDisplayStatus;
    yAxesDisplayStatus[i].display = !yAxesDisplayStatus[i].display;

    this.setState({ yAxesDisplayStatus });
    cssFn.cssShowStatus(
      yAxesDisplayStatus[i].display,
      i === 0 ? `yblue_${idx}` : `ypurple_${idx}`,
      "grey"
    );
  };

  render() {
    // console.log("graph rerender");
    const orderIndex = this.state.graphPara.orderIdx;
    // console.log(canvas);
    const data = {
      // labels: this.state.datetime,
      // return {
      datasets: this.genDataSet(),
      // };
    };

    // console.log(data.datasets);

    const ty1Key = this.currentKeyList(1);
    // console.log(ty1Key);
    const ty2Key = this.currentKeyList(2);
    const ty3Key = this.currentKeyList(3);

    const options = {
      scales: {
        xAxes: this.genxAxesOptions(),
        yAxes: this.genyAxesOptions(),
      },
    };
    // let iconName = this.state.iconCName;
    let displayStatus = this.props.displayStatus;
    let settingDisplay = this.state.settingDisplay;
    let lastReading = this.genLastReading();

    let sDisplayValue_1;
    if (!isNaN(lastReading[0]))
      this.state.lines[0]
        ? (sDisplayValue_1 = `${lastReading[0]} ${this.state.lines[0].dataUnit}`)
        : (sDisplayValue_1 = `${lastReading[0]}`);
    let sDisplayValue_2;
    if (!isNaN(lastReading[1]))
      this.state.lines[1]
        ? (sDisplayValue_2 = `${lastReading[1]} ${this.state.lines[1].dataUnit}`)
        : (sDisplayValue_2 = `${lastReading[1]}`);

    // console.log(data.length);
    return this.state.lines[0] ? (
      <div>
        <div className="line">
          <div className="labelwrapper">
            <div className="yAxesLabels">
              <div className="dd-col">
                <div
                  className="indicator blue"
                  id={`yblue_${this.props._index}`}
                  onClick={() => this.handleChange(0, this.props._index)}
                ></div>
              </div>
              <div className="dd-col">
                {ty1Key &&
                  tableFn.tableDropDownKeySel(
                    `ty1Sel_${this.props._index}`,
                    ty1Key.keyList,
                    ty1Key.key,
                    "select-dd",
                    () => this.props.handleLinePara(this.linePara())
                  )}
              </div>
              <div className="dd-col">{sDisplayValue_1}</div>
            </div>
            <div className="yAxesLabels">
              <div className="dd-col">
                <div
                  className="indicator purple"
                  id={`ypurple_${this.props._index}`}
                  onClick={() => this.handleChange(1, this.props._index)}
                ></div>
              </div>
              <div className="dd-col">
                {ty2Key &&
                  tableFn.tableDropDownKeySel(
                    `ty2Sel_${this.props._index}`,
                    ty2Key.keyList,
                    ty2Key.key,
                    "select-dd",
                    () => this.props.handleLinePara(this.linePara())
                  )}
              </div>
              <div className="dd-col">{sDisplayValue_2}</div>
            </div>
            {ty3Key &&
              tableFn.tableDropDownKeySel(
                `ty3Sel_${orderIndex}`,
                ty3Key.keyList,
                ty3Key.key,
                "select-dd",
                () => this.props.handleLinePara(this.linePara())
              )}
          </div>
          <div className="icon-cols" style={{ display: displayStatus }}>
            <Icon
              className="fa fa-refresh deactive"
              onClick={() => this.refresh()}
            />
            <Icon
              className="fa fa-search-minus deactive"
              onClick={() => this.refresh(480)}
            />
            <Icon
              className="fa fa-search-plus deactive"
              onClick={() => this.refresh(60)}
            />
            <Icon
              className="fa fa-cog deactive"
              onClick={() => this.handleSetting()}
            />
          </div>
          <div className="modal" style={{ display: settingDisplay }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Settings</h4>
                  <span
                    className="close-btn"
                    onClick={() => this.handleSetting()}
                  >
                    &times;
                  </span>
                </div>
                <div className="modal-body">
                  <form onSubmit={this.handleSubmit} className="form-setting">
                    <label className="yaxis">Axis 1</label>
                    {/* {this.renderCustomInput("lowerLimit1", "Min")}
                    {this.renderCustomInput("upperLimit1", "Max")} */}
                    <div className="minmax">
                      {this.renderNcInput(
                        `lowerLimit1_${this.props._index}`,
                        "Min"
                      )}
                    </div>
                    <div className="minmax">
                      {this.renderNcInput(
                        `upperLimit1_${this.props._index}`,
                        "Max"
                      )}
                    </div>
                    <label className="yaxis">Axis 2</label>
                    {/* {this.renderCustomInput("lowerLimit2", "Min")}
                    {this.renderCustomInput("upperLimit2", "Max")} */}
                    <div className="minmax">
                      {this.renderNcInput(
                        `lowerLimit2_${this.props._index}`,
                        "Min"
                      )}
                    </div>
                    <div className="minmax">
                      {this.renderNcInput(
                        `upperLimit2_${this.props._index}`,
                        "Max"
                      )}
                    </div>
                    <div align="right">{this.renderNcButton("Set")}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* {tableFn.tableDropDownKeySel(`ty1Sel`,ty1Key, 1, ()=>this.props.handleLinePara(this.linePara()))} */}
          <Line
            id={`myChart_${this.props._index}`}
            // id={`myChart`}
            data={data}
            options={options}
          />
          {!this.state.loaded && !data.datasets && (
            <div className="graph-loader" align="center">
              <div className="graph-loader-1"></div>
              <div className="graph-loader-2"></div>
              <div className="graph-loader-3"></div>
            </div>
          )}
        </div>
      </div>
    ) : (
      <React.Fragment></React.Fragment>
    );
  }
}

export default Graph;
