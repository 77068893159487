import React, { Component } from "react";
import deviceService from "../../service/deviceManage";
import enm from "../utilities/enum";
import Icon from "../common/icon";
import tableFn from "../utilities/tableFunction";
import tool from "../../checkingTools/tools";
import { ToastContainer, toast } from "react-toastify";
// import sortTemplate from "../utilities/ArrayFn/arrayFn";

class RegDevice extends Component {
  state = {
    allDevices: [],
    filteredDevices: [],
    devDisplay: [],
    buildingEnmObj: [],
    devCount: "0",
    pageDisplay: [1, 20],
    filterCurrent: {
      sensor: 0,
      building: 0,
    },
    filterEnm: {
      sensor: [],
      building: [],
    },
    filterDisplay: [],
    filterdd: "none",
  };

  async componentDidMount() {
    /** Get type enm */
    let typeEnm =await enm.getDevTypeEnum();
    await this.setState({typeEnm});

    await this._getBuildingEnmList();
    await this._getallDevices();
    // await this._getDevicesByLimit(0, 20);
    await this._getDevDisplay(0, 20, this.state.allDevices);
    // await this._getDevicesCount();
    await this._getfilterEnm();
  }

  _getfilterEnm = async () => {
    let filterEnm = { sensor: [], building: [] };
    filterEnm.building = await enm.getBuildingEnmObj(1);
    filterEnm.building.unshift({ _id: -1, name: "All" });
    filterEnm.sensor = await enm.getSensorEnmObj("All");
    this.setState({ filterEnm });
  };

  _getBuildingEnmList = async () => {
    let result = await enm.getBuildingEnmObj();
    this.setState({ buildingEnmObj: result });
  };

  _getallDevices = async () => {
    try {
      let { data: allDevices } = await deviceService.getAll();
      this.setState({ allDevices });

      this.setState({ devCount: this.state.allDevices.length });
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  _getDevDisplay = async (start, end, allDevices) => {
    let devDisplay = [];
    for (let i = start; i < end; i++) {
      devDisplay.push(allDevices[i]);
    }
    this.setState({ devDisplay });
  };

  _getDevicesByLimit = async (start, limit) => {
    try {
      let { data: allDevices } = await deviceService.getByLimit(start, limit);
      this.setState({ allDevices });
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  _getDevicesCount = async () => {
    try {
      let { data: result } = await deviceService.countAll();
      let devCount = result.count;
      this.setState({ devCount });
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  handleChangeSel = (selID) => {
    let selValue = document.getElementById(`selRow${selID}`).value;
    console.log(`Changed Sel ${selValue}`);
  };

  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      type: enm.getType_n(this.state.typeEnm, row.cells[3].innerHTML),
      devID: row.cells[4].innerHTML,
      buildingID: document.getElementById(`selRow${ind - 1}`).value,
      battConst: row.cells[6].children[0].value,
      sleepAmp: row.cells[7].children[0].value,
      SimNumber: row.cells[8].children[0].value,
      // userAmmend:row.cells[9].innerHTML,
    };
    // console.log(column);
    return column;
  };

  tableColumn_del = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      type: enm.getType_n(this.state.typeEnm, row.cells[3].innerHTML),
      devID: row.cells[4].innerHTML,
      // buildingID:document.getElementById(`selRow${ind-1}`).value,
      battConst: row.cells[6].children[0].value,
      sleepAmp: row.cells[7].children[0].value,
      SimNumber: row.cells[8].children[0].value,
      // userAmmend:row.cells[9].innerHTML,
    };
    // console.log(column);
    return column;
  };

  handleBack = async () => {
    try {
    } catch (error) {
      tool.exResponse(error);
    }
  };

  handleUpdate = async () => {
    try {
      // console.log(this.state.allDevices);
      let modifiedRows = tableFn.detectTableChanges(
        "devicesTable",
        this.state.allDevices,
        "_id",
        this.tableColumn
      );
      if (!modifiedRows[0]) return console.log("No Changes");
      // call device update service
      for (const dev of modifiedRows) {
        if (!dev.SimNumber) dev.SimNumber = "N/A";
        let rel = await deviceService.update(dev);
        if (rel.status === 200) {
          console.log(`${rel.data.type}, ${rel.data.devID} update succesful`);
          toast.dark("Updated Succesfully!");
        } else {
          console.log(`${rel.data.type}, ${rel.data.devID} update failed`);
          toast.error("Update Failed!");
        }
      }
      await this._getallDevices();
    } catch (error) {
      tool.exResponse(error);
    }
  };

  changePage = () => {
    // this.props.history.push("/setting/regDevice");
    this.props.history.push("/v2devmanage/regdev");
    
  };

  handleDelete = async () => {
    // console.log("delete lioa");
    let rowChecked = tableFn.rawChecked("devicesTable", this.tableColumn_del);
    if (!rowChecked[0]) return console.log("Non row checked");
    for (const row of rowChecked) {
      try {
        // console.log(row);
        let rel = await deviceService.del(row);
        if (rel.status === 200) {
          toast.dark("Deleted Successfully!");
        } else {
          toast.error("Failed to Delete!");
        }
      } catch (ex) {
        tool.exResponse(ex);
      }
    }
    await this._getallDevices();
  };

  handleDevBack = async () => {
    let pageDisplay = [...this.state.pageDisplay];
    if (pageDisplay[0] === 1) return;

    pageDisplay[0] = pageDisplay[0] - 20;
    if (pageDisplay[1] === this.state.devCount)
      pageDisplay[1] = pageDisplay[1] - this.state.devDisplay.length;
    else pageDisplay[1] = pageDisplay[1] - 20;

    if (this.state.filteredDevices.length)
      await this._getDevDisplay(
        pageDisplay[0] - 1,
        pageDisplay[1],
        this.state.filteredDevices
      );
    else
      await this._getDevDisplay(
        pageDisplay[0] - 1,
        pageDisplay[1],
        this.state.allDevices
      );

    this.setState({ pageDisplay });
  };

  handleDevNext = async () => {
    let pageDisplay = [...this.state.pageDisplay];
    if (pageDisplay[1] === this.state.devCount) return;

    pageDisplay[0] = pageDisplay[0] + 20;
    pageDisplay[1] = pageDisplay[1] + 20;

    if (pageDisplay[1] > this.state.devCount)
      pageDisplay[1] = this.state.devCount;

    if (this.state.filteredDevices.length)
      await this._getDevDisplay(
        pageDisplay[0] - 1,
        pageDisplay[1],
        this.state.filteredDevices
      );
    else
      await this._getDevDisplay(
        pageDisplay[0] - 1,
        pageDisplay[1],
        this.state.allDevices
      );

    this.setState({ pageDisplay });
  };

  getNumberRange(start, end) {
    let _start = parseInt(start);
    let _end = parseInt(end);

    if (!_end && !_start) return [0, 0];
    if (!_end) return [_start, 999999];
    if (!start) return [0, _end];
    if (_start > _end) return [_end, _start];
    return [_start, _end];
  }

  toggleFilter = async () => {
    // console.log("togglefilter");
    this.state.filterdd === "none"
      ? await this.setState({ filterdd: "block" })
      : await this.setState({ filterdd: "none" });
    // let filterId = document.getElementById("filter-dropdown");
    // if (filterId)
    //   filterId.style.display === "none"
    //     ? await (filterId.style.display = "block")
    //     : await (filterId.style.display = "none");
  };

  filterSensorSel = async () => {
    // console.log("Filter Sensor");
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.sensor = document.getElementById("fil_Sensor").value;
    this.setState({ filterCurrent });
  };

  filterBuildingSel = async () => {
    // console.log("Filter Sensor");
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.building = document.getElementById("fil_Building").value;
    this.setState({ filterCurrent });
  };

  doFilter = async (filter, startID, endID, range) => {
    let filterDisplay = [];
    if (filter.sensor !== 0)
      filterDisplay.push(
        <div className="filter-display" key="filtersensor">
          Sensor: {enm.getType_en(this.state.typeEnm, filter.sensor)}
          <i
            className="fa fa-times"
            onClick={() => this.resetSensorFilter()}
          ></i>
        </div>
      );
    if (startID && endID)
      filterDisplay.push(
        <div className="filter-display" key="filterid">
          ID: {startID} to {endID}
          <i className="fa fa-times" onClick={() => this.resetIdFilter()}></i>
        </div>
      );
    else if (!startID && endID)
      filterDisplay.push(
        <div className="filter-display" key="filterid">
          ID: 0 to {endID}
          <i className="fa fa-times" onClick={() => this.resetIdFilter()}></i>
        </div>
      );
    else if (startID && !endID)
      filterDisplay.push(
        <div className="filter-display" key="filterid">
          ID: From {startID}
          <i className="fa fa-times" onClick={() => this.resetIdFilter()}></i>
        </div>
      );

    if (filter.building >= 0)
      filterDisplay.push(
        <div className="filter-display" key="filterbuilding">
          Building: {enm.getBuilding_en(filter.building)}
          <i
            className="fa fa-times"
            onClick={() => this.resetBuildingFilter()}
          ></i>
        </div>
      );

    if (filterDisplay.length) this.setState({ filterDisplay });
    else this.setState({ filterDisplay: [] });

    let allDevices = [...this.state.allDevices];
    let filteredDevices = allDevices.filter(
      (c) =>
        (c.type === filter.sensor || filter.sensor === 0) &&
        (c.buildingID === filter.building || filter.building === -1) &&
        ((c.devID >= range[0] && c.devID <= range[1]) ||
          (range[0] === 0 && range[1] === 0))
    );
    this.setState({ filteredDevices });
    this.setState({ devCount: filteredDevices.length });

    if (filteredDevices.length <= 20) {
      this.setState({ devDisplay: filteredDevices });
      let pageDisplay = [1, filteredDevices.length];
      this.setState({ pageDisplay });
    } else {
      let pageDisplay = [1, 20];
      this.setState({ pageDisplay });
      await this._getDevDisplay(0, 20, filteredDevices);
    }
  };

  handleFilter = async () => {
    // console.log("handlefilter");
    let filter = {};
    filter.sensor = parseInt(document.getElementById("fil_Sensor").value);
    filter.building = parseInt(document.getElementById("fil_Building").value);
    let startID = document.getElementById("fil_devID").value;
    let endID = document.getElementById("fil_devID_end").value;
    let range = this.getNumberRange(startID, endID);

    await this.doFilter(filter, startID, endID, range);

    this.toggleFilter();
  };

  resetSensorFilter = async () => {
    console.log("reset sensor");
    let sensor = document.getElementById("fil_Sensor");
    sensor.value = 0;
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.sensor = sensor.value;
    this.setState({ filterCurrent });

    let filter = {};
    filter.sensor = parseInt(document.getElementById("fil_Sensor").value);
    filter.building = parseInt(document.getElementById("fil_Building").value);
    let startID = document.getElementById("fil_devID").value;
    let endID = document.getElementById("fil_devID_end").value;
    let range = this.getNumberRange(startID, endID);

    await this.doFilter(filter, startID, endID, range);
  };

  resetBuildingFilter = async () => {
    let building = document.getElementById("fil_Building");
    building.value = -1;
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.building = 0;
    this.setState({ filterCurrent });

    let filter = {};
    filter.sensor = parseInt(document.getElementById("fil_Sensor").value);
    filter.building = parseInt(document.getElementById("fil_Building").value);
    let startID = document.getElementById("fil_devID").value;
    let endID = document.getElementById("fil_devID_end").value;
    let range = this.getNumberRange(startID, endID);

    await this.doFilter(filter, startID, endID, range);
  };

  resetIdFilter = async () => {
    let start = document.getElementById("fil_devID");
    let end = document.getElementById("fil_devID_end");
    start.value = null;
    end.value = null;

    let filter = {};
    filter.sensor = parseInt(document.getElementById("fil_Sensor").value);
    filter.building = parseInt(document.getElementById("fil_Building").value);
    let startID = document.getElementById("fil_devID").value;
    let endID = document.getElementById("fil_devID_end").value;
    let range = this.getNumberRange(startID, endID);

    await this.doFilter(filter, startID, endID, range);
  };

  handleSort = (att) => {
    if (att === "sensor") {
    }
  };

  render() {
    return (
      <React.Fragment>
        <h1>Device Management</h1>
        <Icon className="fa fa-plus deactive" onClick={this.changePage}></Icon>
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleUpdate()}
        />
        <Icon
          className="fa fa-trash-o deactive"
          onClick={() => this.handleDelete()}
        />
        <div className="filter-dd">
          {this.state.filterDisplay.length === 0 && (
            <div style={{ display: "inline-block" }}>Select Device</div>
          )}
          {this.state.filterDisplay.length !== 0 && (
            <div
              className="displayclass"
              style={{
                display: "inline-block",
                backgroundColor: "green",
                zIndex: 110,
              }}
            >
              {this.state.filterDisplay}
            </div>
          )}
          <div
            className="filter-selection"
            style={{
              backgroundColor: "red",
              opacity: "50%",
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
            onClick={() => this.toggleFilter()}
          ></div>
          {/* <div
              className="toggle-filter"
              onClick={() => this.toggleFilter()}
            ></div> */}
          <ul
            className="filterclass"
            id="filter-dropdown"
            style={{ display: this.state.filterdd }}
          >
            <li>
              <label>Sensor&nbsp;&nbsp;&nbsp;&nbsp;:</label>
              {tableFn.tableDropDownKeySel(
                `fil_Sensor`,
                this.state.filterEnm.sensor,
                this.state.filterCurrent.sensor,
                "select-dd",
                () => this.filterSensorSel()
              )}
            </li>
            <li>
              <label>ID From&nbsp;&nbsp;:&nbsp;&nbsp;</label>
              <input type="number" min="1" id="fil_devID"></input>
            </li>
            <li>
              <label>
                ID To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
              </label>
              <input type="number" min="1" id="fil_devID_end"></input>
            </li>
            <li>
              <label>Building&nbsp;&nbsp;:</label>
              {tableFn.tableDropDownKeySel(
                `fil_Building`,
                this.state.filterEnm.building,
                this.state.filterCurrent.building,
                "select-dd",
                () => this.filterBuildingSel()
              )}
              <button
                className="filter-btn"
                onClick={() => this.handleFilter()}
              >
                OK
              </button>
            </li>
          </ul>
          <div className="handlefilter" style={{ backgroundColor: "blue" }}>
            <i
              className="fa fa-filter deactive"
              onClick={() => this.toggleFilter()}
            />
          </div>
        </div>
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="devicesTable">
              <thead>
                <tr>
                  <th id="cb"></th>
                  <th>#</th>
                  <th style={{ display: "none" }}>ID</th>
                  <th>
                    Sensor{" "}
                    <i
                      className="fa fa-sort"
                      aria-hidden="true"
                      onClick={() => this.handleSort("sensor")}
                    ></i>
                  </th>
                  <th>ID</th>
                  <th>Building</th>
                  <th>Batt Const</th>
                  <th>Sleep Amp</th>
                  <th>Sim Number</th>
                  <th>Amend By</th>
                </tr>
              </thead>
              <tbody>
                {this.state.devDisplay.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input className="checkbox" type="checkbox"></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>{enm.getType_en(this.state.typeEnm, u.type)}</td>
                    <td>{u.devID}</td>
                    {/* <td>{tableFn.dropDown(`selRow${ind}`,this.state.buildingEnmObj, u.buildingID, ()=>this.handleChangeSel(ind))}</td> */}
                    <td>
                      {tableFn.dropDown(
                        `selRow${ind}`,
                        this.state.buildingEnmObj,
                        u.buildingID,
                        "select-custom"
                      )}
                    </td>
                    <td>
                      <input type="text" defaultValue={u.battConst} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.sleepAmp} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.SimNumber} />
                    </td>
                    <td>{u.userAmmend}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pageNumDisplay">
          <Icon
            className="fa fa-angle-left deactive"
            onClick={() => this.handleDevBack()}
          />
          {this.state.pageDisplay[0]}-{this.state.pageDisplay[1]} of{" "}
          {this.state.devCount}
          <Icon
            className="fa fa-angle-right deactive"
            onClick={() => this.handleDevNext()}
          />
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default RegDevice;
