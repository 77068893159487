import monListService from "../../service/monList/monList";

async function getMonList() {
  try {
    let bdID = await localStorage.getItem("buildingId");
    let { data: monList } = await monListService.getMonListByBdID(
      parseInt(bdID)
    ); /** replace here */
    if (monList) return monList;
    return [];
  } catch (error) {
    console.log("Get Building DB Error: ", error.message);
    return [];
  }
}

const expModule = {
  getMonList,
};
export default expModule;
