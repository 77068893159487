import bd_1 from '../../service/setting_Buildings';
import sensorFn from '../../function/sensor/sensorManagementFn'
import {sortTemplate} from './ArrayFn/arrayFn'

// const DevTypeEnum=[
//     {nType:1, enType:"RTRH"},
//     {nType:2, enType:"Probe Temp"},
//     {nType:3, enType:"DPM"},
//     {nType:4, enType:"Pressure"},
//     {nType:5, enType:"Gateway"},
//     {nType:6, enType:"Switch Status"},
//     {nType:7, enType:"VFD Control"},
//     {nType:1001, enType:"BTU Meter"},
//     {nType:1002, enType:"Air Flow"},
// ]

const buildingEnum = [
    { nBd: 0, enBd: "Idle"},
    { nBd: 1, enBd: "Mahsing - Star Avenue" },
    { nBd: 2, enBd: "Ipoh Home" },
    { nBd: 3, enBd: "KKM Seberang Perai Utara" },
    { nBd: 4, enBd: "Tesco Egate" },
    { nBd: 5, enBd: "Tesco Kulim" },
    { nBd: 17, enBd: "Penang House" },
    { nBd: 22, enBd: "SGS Shah Alam" },
    { nBd: 24, enBd: "AAE Office" },
    { nBd: 25, enBd: "Tesco Kajang" },
    { nBd: 26, enBd: "Mydin, Mydin - Parit Buntar" },
]

async function getDevTypeEnum(vendor_id=0){
    /**Pull from DB and merge with  DevTypeEnum*/
    let sensorList = await sensorFn.getSensorByVendor_id(vendor_id);
    let enmList = [];
    for (const sensor of sensorList) {
        enmList.push({
            nType:sensor.type,
            enType:sensor.name,
        })
    }
    return enmList;
}

async function getSensorEnmObj(s1stSelection){    
    let a_dev=[];
    
    let sensorEnmList = await getDevTypeEnum();

    // sensorEnmList.sort(a,b)=>arrFn.sortTemplate()
    
    for (const dev of sensorEnmList) {
        let devEnum = {_id:dev.nType,name:dev.enType}
        a_dev.push(devEnum);
    }
    // console.log(a_dev);
    let sortKeys = [
        { key: "name", descOrder: false },
      ];
      a_dev.sort((a, b) => sortTemplate(a, b, sortKeys));

    if(s1stSelection) a_dev.unshift({_id:0, name:s1stSelection});
    return a_dev;
}

async function getDeviceEnmObj(obj){    
    return await getSensorEnmObj(obj);


    // let a_dev=[];
    // for (const dev of DevTypeEnum) {
    //     let devEnum = {_id:dev.nType,name:dev.enType}
    //     a_dev.push(devEnum);
    // }
    // // console.log(a_dev);
    // return a_dev;
}

function getType_n(typeEnum, type){
    let matching = typeEnum.filter((en)=>{return en.enType===type});
    console.log(matching);     
    return matching[0].nType;
}

function getType_en(typeEnum, type){
    let matching = typeEnum.filter((en)=>{return en.nType===type});    
    return matching[0].enType;
}

function getBuilding_en(bd){
    let matching = buildingEnum.filter((en)=>{return en.nBd===bd});    
    return matching[0].enBd;
}

// function getType_en_template(arr, type){
//     console.log(arr);
//     console.log(type);
    
//     // let matching = arr.filter((en)=>{return en.nType==type});    
//     // return matching[0].enType;
// }

function displayEnm(a_obj, _id, idleName){
    // let {name:word} = a_obj.filter(o=>{return o._id===_id})   
    // console.log(a_obj);
    // console.log(_id);
    // eslint-disable-next-line
    let rel = a_obj.filter(o=>{return o._id==_id})   
    // console.log(rel);
    if(!rel[0]) {
        if(idleName) return idleName;
        return "Idle";
    }else{
        // console.log(rel[0].name);
        return rel[0].name;
    }
    // return word;
}

async function getBuildingEnmObj(buildingNameOnly, idleName){
    let buildings=[];
    // get all building list
    try {
        let {data:allBuildings} = await bd_1.getAll();       
        if(!idleName){
            buildings.push({_id:0, name:"Idle"});
        }else{
            buildings.push({_id:0, name:idleName});
        }
        for (const buidling of allBuildings) {
            
            let bd={
                _id:buidling._id,
                name:`${buidling.owner}, ${buidling.building} `,
            }
            if(buildingNameOnly) {bd.name=buidling.building};
            buildings.push(bd);
        }            
    } catch (ex) {        
        if (ex.response) {
            console.log(ex.response); 
        }else{
            console.log(ex.message); 
        }        
    }
    // console.log(buildings);
    return buildings;
}

function sortUserByUserName(a,b){
    if(a.username.toUpperCase() > b.username.toUpperCase()) return 1;
    if(a.username.toUpperCase() < b.username.toUpperCase()) return -1;
    return 0
}

function genUserEnum(users, defaultText) {
    let sortedUsers = users.sort(sortUserByUserName);
    let enmUsers = [];
    enmUsers.push({_id:-1, name:defaultText});
    for (const user of sortedUsers) {
        let enmUser = {
            _id:user._id,
            name:user.username,
        }
        enmUsers.push(enmUser);
    }
    return enmUsers;
}

// create on 201126 to assign building to customer user
function turnBuildingsToEnms(buildings, defaultText, bIncludeOwner) {
    // let sortedBuildings = buildings.sort(sortUserByUserName);
    let enmBuildings = [];
    if(defaultText) enmBuildings.push({_id:-1, name:defaultText});
    for (const building of buildings) {
        let enmUser = {
            _id:building._id,
            name:bIncludeOwner?`${building.owner}, ${building.building}`:building.building,
        }
        enmBuildings.push(enmUser);
    }
    return enmBuildings;
}


function genEnum(aList, defaultText, keyValue, keyName){    
    try {
        if(!Array.isArray(aList)) return 
        let enmList = [];
        if (defaultText) enmList = [{_id:-1, name:defaultText}];
        for (let index = 0; index < aList.length; index++) {
            let itemEnm = {
                _id:aList[index][keyValue],
                name:aList[index][keyName],
            }
            enmList.push(itemEnm);
        }
        return enmList;
    } catch (error) {
        console.log(error.message);
    }
}


const expModule = {  
    getDevTypeEnum,
    genEnum,
    getSensorEnmObj,
    turnBuildingsToEnms,
    genUserEnum,
    getDeviceEnmObj,
    getType_en,
    getType_n,
    getBuilding_en,
    displayEnm,
    getBuildingEnmObj,
};
export default expModule;
