import http from "../httpService";

async function getByBd_id(bd_id){
    let obj = {bd_id};
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/sublist/getbybd_id`;
    return await http.post(url, obj); 
}

async function add(obj) {
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/sublist/register`;
    //   console.log(obj);
    // const body = {   
    //     _id: obj._id,
    //     name: obj.name,
    //     userID: obj.userID,
    //     bd_id: obj.bd_id,
    //     telegramID: obj.telegramID,
    //   };
      // console.log("did enter");
    return await http.post(url, obj); 
  }

  async function update(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/sublist/update`;
    // const body = {   
    //     _id: obj._id,
    //     name: obj.name,
    //     userID: obj.userID,
    //     bd_id: obj.bd_id,
    //     telegramID: obj.telegramID,
    //   };
    return await http.post(url, obj);    
}


async function del(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/notify/sublist/del";
    const body = {
        _id:obj._id
      };
    return await http.post(url, body);    
}

const expModule = {  
    del,
    add,
    update,
    getByBd_id,
};
export default expModule;