import http from "../httpService";

async function getMonListByBdID(buildingID) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/getbybdID`;
  const body = {
    buildingID,
  };
  // console.log(body);
  return await http.post(url, body);
}

async function getMonListBy_id(_id) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/getmonlistby_id`;
  const body = {
    _id,
  };
  // console.log(body);
  return await http.post(url, body);
}

async function getAssignedMonListByUserID(UserID) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/getAssignedMonList`;
  const body = {
    UserID,
  };
  // console.log(body);
  return await http.post(url, body);
}

async function addMonList(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/monlist/addmonlist";
  //   console.log(obj);
  const body = {
    // _id: parseInt(obj._id),
    name: obj.name,
    buildingID: parseInt(obj.buildingID),
    SortIndex: parseInt(obj.SortIndex),
  };
  // console.log("did enter");
  return await http.post(url, body);
}

async function updateMonList(obj) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/updatemonlist`;
  const body = {
    _id: parseInt(obj._id),
    name: obj.name,
    buildingID: parseInt(obj.buildingID),
    SortIndex: parseInt(obj.SortIndex),
  };

  return await http.post(url, body);
}

async function delMonList(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/monlist/delmonlist";
  const body = {
    _id: obj._id,
  };
  return await http.post(url, body);
}

async function getT1ByMonList_ID(Monitoring_id) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/getT1bymonlist_id`;
  const body = {
    Monitoring_id,
  };
  // console.log(body);
  return await http.post(url, body);
}

async function getT1By_id(_id) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/gett1by_id`;
  const body = {
    _id,
  };
  // console.log(body);
  return await http.post(url, body);
}

async function getElementByT1_ID(T1_id) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/monlist/getelementbyt1_id`;
  const body = {
    T1_id,
  };
  // console.log(body);
  return await http.post(url, body);
}

async function addAssignedMonList(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/monlist/addassignedmonlist";
  //   console.log(obj);
  const body = {
    // _id: parseInt(obj._id),
    // name: obj.name,
    MonitorList_id: parseInt(obj.MonitorList_id),
    UserID: parseInt(obj.UserID),
  };
  // console.log("did enter");
  return await http.post(url, body);
}

async function delAssignedMonList(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/monlist/delassignedmonlist";
  const body = {
    _id: obj._id,
  };
  return await http.post(url, body);
}

const expModule = {
  getT1By_id,
  getMonListBy_id,
  getMonListByBdID,
  getT1ByMonList_ID,
  getElementByT1_ID,
  updateMonList,
  delMonList,
  addMonList,
  getAssignedMonListByUserID,
  addAssignedMonList,
  delAssignedMonList,
};
export default expModule;
