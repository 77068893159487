import React from "react";
import "./icon.css";

const DisplayIcon = ({ name, error, ...rest }) => {
  return (
    <div className="display-icon">
      <i {...rest}> </i>
    </div>
  );
};

export default DisplayIcon;
