import React, { useEffect, useState } from 'react';
import bitFn from  '../../../../src/function/bitFn/bitFn';
import shareFn from '../../SharedTemplate/BdDevFn';

function TpDowList(props) {
    // const G_scheDow = 126;

    const [G_scheIdx, setG_scheIdx] = useState(0);
    const [G_scheDow, setG_scheDow] = useState(0);
    // scheIdx={ind}
    // scheDow={u.DOW}
    useEffect(()=>{
        async function startUp(){
            // console.log(props.selScheInfo);
            loadDevInfo();
        }
        startUp();

        return ()=>{
            
        }
        // eslint-disable-next-line
    }, []);

    const loadDevInfo=()=>{
        let {scheIdx, scheDow} = props;
        setG_scheIdx(scheIdx);
        setG_scheDow(scheDow);
    }

    const handleOnClickDow=(nDowSel)=>{
        if(!props.dowClick) return
        let newDow = shareFn.toggleDow(G_scheDow, nDowSel);
        setG_scheDow(newDow);
        props.dowClick(G_scheIdx, newDow);
    }
    return (
        <div>
            <div className='autumn_dowBlockContainer'>
                <div className={`autumn_dowBlock autumn_weekEndText ${bitFn.getBit(G_scheDow, 0)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(0)}>S</div>
                <div className={`autumn_dowBlock ${bitFn.getBit(G_scheDow, 1)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(1)}>M</div>
                <div className={`autumn_dowBlock ${bitFn.getBit(G_scheDow, 2)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(2)}>T</div>
                <div className={`autumn_dowBlock ${bitFn.getBit(G_scheDow, 3)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(3)}>W</div>
                <div className={`autumn_dowBlock ${bitFn.getBit(G_scheDow, 4)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(4)}>T</div>
                <div className={`autumn_dowBlock ${bitFn.getBit(G_scheDow, 5)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(5)}>F</div>
                <div className={`autumn_dowBlock autumn_weekEndText ${bitFn.getBit(G_scheDow, 6)===1?"autumm_dowSelected":""}`}
                onClick={()=>handleOnClickDow(6)}>S</div>
            </div>    
        </div>
    );
}

export default TpDowList;