import devActSer from '../../../service/deviceActive/devActService';


async function getDevAcListByBd_id(bd_id){
    try {
        let body = {buildingID : bd_id}
        let result = await devActSer.getDevActiveListByBd_id(body);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}

async function updateDevActiveList(aList){
    try {
        let body = {List : aList}
        let result = await devActSer.updateDevActiveList(body);
        // console.log(result);
        if(result.status!==200) return false
        return (true);  // update succesful
    } catch (error) {
        console.log(error.message);
        return false
    }
}

const expModule = {  
    getDevAcListByBd_id,
    updateDevActiveList,
};
export default expModule;