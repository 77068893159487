import React, { Component } from "react";

class UserNotActive extends Component {
  render() {
    return (
      <h1>
        Account Not Activate! Please contact Aplouds Admin to activate your
        account.
      </h1>
    );
  }
}

export default UserNotActive;
