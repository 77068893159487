import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import './App.css';
import { ToastContainer } from "react-toastify";
import Login from './component/login';
import Logout from './component/logout';
import auth from "./service/authService";
// import Overview from './component/overview';
import ProtectedRoute from './component/common/protectedRoute';
import Navsidebar from './component/bars/navsidebar';
import DeviceManagement from './component/Setting/deviceManagement';
import UserRegister from './component/UserManagement/userRegistration';
import UserManagement from './component/UserManagement/userManagement';
import BuildingManagement from './component/Setting/buildingManagement';
import BuildingRegister from './component/Setting/buildingReg';
import DeviceRegister from './component/Setting/deviceReg';
import BuildingDeviceManagement from './component/buildingDevices/bDevicesManagement';
import BuildingDeviceRegister from './component/buildingDevices/bDeviceReg';
// import DataPresent_Building from './component/dataPresent/buildingList';
import BuildingData from './component/dataPresent/buildingData';
import DataDownloadPage from './component/excelzip/dataDownloadPage';
import BuildingSelect_BdDev from './component/buildingDevices/buildingSelectPage';
import BuildingAssignPage from './component/buildingAssign/buildingAssignPage';
import NotificationPage from './component/notification/notificationPage';
import TeleIDPage from './component/notification/teleIDPage';
import RegTelegramPage from './component/notification/regTeleID';
import RegNotifyPage from './component/notification/regNotifyPage';
import OffsetPage from './component/offsetPage/offsetPage';
import regOffsetPage from './component/offsetPage/regOffsetPage';
import EditProfile from './component/editProfile';
import ControlDevicePage from './component/controlDevice/controlDevice';
import Add_MonList from './component/MonitorListSetting/AddMonitorList';
import MonitorList from './component/MonitorList/monitorList';
import BuildingSelect from './component/buildingSelect';
import UserNotActive from './component/userNotActive';
import AddT1_Page from './component/MonitorListSetting/AddT1_List';
import AddEle_Page from './component/MonitorListSetting/AddEleList';
import MonListAssignPage from './component/MonitorListSetting/MonListAssignPage';
import { BdProvider } from './Context/bdContext_T1';
// import { BuildingProvider, BuildingContext, BuildingDispatchContext } from "./Context/BuildingProvider";
import SelectionPage from './component/selectionPage';
import VfdCtrlPage from './component/controlDevice/vfdControlPage';
import CtrlDevManagePage from './component/controlDevice/ctrlDevManagement';
import DeviceActivePage from './component/deviceActive/deviceActivePage';
import statusNodeThreshold from './component/statusNodeThreshold/statusNodeThresholdPage';
import devActTeleSubList from './component/notification/devActTeleSubList';
import battDiagPage from './component/battDiag/battDiag';
import addNewSensor from './component/sensorManagement/sensor_addNewPage';
import SensorListPage from './component/sensorManagement/SensorListPage';
import AcCtrl_Page from './component/AcControl/AcControl';
import AcheListPage from './component/AcControl/ScheListPage/ScheListPage';
import AddNewAcSchePage from './component/AcControl/addNewAcSchePage/addNewAcSchePage';
import AcCtrlSettingPage from './component/AcControl/AcCtrlSettingPage/AcCtrlSettingPage';
import LogInAcDevicePage from './component/AcControl/LogInAcDevPage/LoginAcDevPage';
import AcDevSelPage from './component/AcControl/acDevSelPage/AcDevSelPage';
import PR_RstPasswordPage from './component/passwordRecovery/rstPasswordPage';
import PR_InsertEmail from './component/passwordRecovery/insertEmailPage';
// import V2_DevList from './component/V2_DeviceManagement/V2_DeviceList';
import V2_RegDev from './component/V2_DeviceManagement/V2_RegDevice';
import V2_RegDevEdit from './component/V2_DeviceManagement/V2_RegDeviceEditPg';
import CtrlPageOvv from './Project/PDC_2022/CtrlPageOvv';
import DevZoom_ACPU from './Project/PDC_2022/DeviceZoomIn/DevZoom_ACPU';
import DevZoom_WCPU from './Project/PDC_2022/DeviceZoomIn/DedZoom_WCPU';
import DevZoom_PEC from './Project/PDC_2022/DeviceZoomIn/DedZoom_PEC';
import PDCMainPage from './Project/PDC_2022/MainPage/PDCMainPage';
import PDC_Overview from './Project/PDC_2022/PDC_Overview';
import ContactVendor_YSC from './Project/PDC_2022/MainPage/contactVendor_YSC';
import V2_UserList from './component/V2_UserManagement/V2_UserList';
import V2_CreateUser from './component/V2_UserManagement/V2_createUser';
import V2_SensorChecklist from './component/V2_DeviceManagement/V2_SensorChecklist';


function App(props) {
  const [G_bLoaded, setG_bLoaded] = useState(false);
  const refUser = useRef({});
  const Bd = {name: 'From App.js'};
  useEffect(()=>{     
    async function startUp(){
      refUser.current = auth.getCurrentUser();
      setG_bLoaded(true);
    }
    startUp();

    
    return ()=>{
        // Execute when page close
    }
    // eslint-disable-next-line
}, []);

  return (
    <div>
      {G_bLoaded && <React.Fragment>
        <ToastContainer />
        <BdProvider value = {Bd}>
          <div className="mainContent">
            {/* {!user && <h6> User: Not Logged in</h6>} */}
            {refUser.current &&
              <Navsidebar user={refUser.current} />}
            <Switch>
              
              <ProtectedRoute path="/ctrlpg/dev/acpu/:ht/:hi/:bdDev_id" component={DevZoom_ACPU} minAccessLvl="70" />
              <ProtectedRoute path="/ctrlpg/dev/wcpu/:ht/:hi/:bdDev_id" component={DevZoom_WCPU} minAccessLvl="70" />  
              <ProtectedRoute path="/ctrlpg/dev/pec/:ht/:hi/:bdDev_id" component={DevZoom_PEC} minAccessLvl="70" />            

              <ProtectedRoute path="/buildings/management/:bdID" component={BuildingDeviceManagement} minAccessLvl="10" />
              <ProtectedRoute path="/buildings/regdevice/:bdID" component={BuildingDeviceRegister} minAccessLvl="10" />
              <Route path="/passwordrecovery/rstpassword/:recoverycode" component={PR_RstPasswordPage}/>               
              <ProtectedRoute path="/ctrldev/ac/schelist" component={AcheListPage} minAccessLvl="60" />               
              <ProtectedRoute path="/ctrldev/ac/addnewsche" component={AddNewAcSchePage} minAccessLvl="60" />                             
              <ProtectedRoute path="/ctrldev/ac/setting" component={AcCtrlSettingPage} minAccessLvl="60" />                                           
              <ProtectedRoute path="/ctrldev/ac/logindev" component={LogInAcDevicePage} minAccessLvl="60" />       
              <ProtectedRoute path="/ctrldev/ac/devsel" component={AcDevSelPage} minAccessLvl="60" />                     
              <ProtectedRoute path="/notification/regNotify/:bdID" component={RegNotifyPage} minAccessLvl="70" />
              <ProtectedRoute path="/notification/list/:bdID" component={NotificationPage} minAccessLvl="70" />              


              <ProtectedRoute path="/datapresent/building" component={BuildingData} minAccessLvl="10" />
              <ProtectedRoute path="/buildings/select" component={BuildingSelect_BdDev} minAccessLvl="10" />
              <ProtectedRoute path="/user/management" component={UserManagement} minAccessLvl="10" />
              <ProtectedRoute path="/setting/buidlingManagement" component={BuildingManagement} minAccessLvl="10" />
              <ProtectedRoute path="/setting/regBuilding" component={BuildingRegister} minAccessLvl="10" />
              <ProtectedRoute path="/setting/regDevice" component={DeviceRegister} minAccessLvl="10" />
              <ProtectedRoute path="/notification/regteleid" component={RegTelegramPage} minAccessLvl="10" />
              <ProtectedRoute path="/notification/teleid" component={TeleIDPage} minAccessLvl="10" />
              <ProtectedRoute path="/notification/DeviceActive" component={DeviceActivePage} minAccessLvl="10" />
              <ProtectedRoute path="/notification/devactsublist" component={devActTeleSubList} minAccessLvl="10" />              
              <ProtectedRoute path="/offset/reg" component={regOffsetPage} minAccessLvl="10" />
              <ProtectedRoute path="/monlist/addlist" component={Add_MonList} minAccessLvl="10" />
              <ProtectedRoute path="/monlist/addt1" component={AddT1_Page} minAccessLvl="10" />
              <ProtectedRoute path="/monlist/addele" component={AddEle_Page} minAccessLvl="10" />  
              <ProtectedRoute path="/monlist/:monID" component={MonitorList} minAccessLvl="99" />
              <ProtectedRoute path="/ctrldev/vfd" component={VfdCtrlPage} minAccessLvl="10" /> 
              <ProtectedRoute path="/ctrldev/management" component={CtrlDevManagePage} minAccessLvl="10" /> 
              <ProtectedRoute path="/ctrldev/ac" component={AcCtrl_Page} minAccessLvl="60" />               
              <ProtectedRoute path="/statusnode/threshold" component={statusNodeThreshold} minAccessLvl="10" /> 
              <ProtectedRoute path="/diag/batt" component={battDiagPage} minAccessLvl="10" /> 
              <ProtectedRoute path="/sensormng/addnew" component={addNewSensor} minAccessLvl="10" />               
              <ProtectedRoute path="/sensormng/sensorlist" component={SensorListPage} minAccessLvl="10" />               
              <ProtectedRoute path="/passwordrecovery/insertemail" component={PR_InsertEmail} minAccessLvl="10" />
              {/* <ProtectedRoute path="/v2devmanage/devlist" component={V2_DevList} minAccessLvl="10" /> */}
              <ProtectedRoute path="/v2devmanage/regdev" component={V2_RegDev} minAccessLvl="10" />
              <ProtectedRoute path="/v2devmanage/regdevedit" component={V2_RegDevEdit} minAccessLvl="10" />
              <ProtectedRoute path="/v2usermanage/new" component={V2_CreateUser} minAccessLvl="10" />
              <ProtectedRoute path="/ctrlpg/ovv" component={CtrlPageOvv} minAccessLvl="70" />
              <ProtectedRoute path="/cusmainpg/pdc" component={PDCMainPage} minAccessLvl="80" />
              <ProtectedRoute path="/pdc/overview" component={PDC_Overview} minAccessLvl="80" />
              <ProtectedRoute path="/contact/ysc" component={ContactVendor_YSC} minAccessLvl="80" />       
              
              <ProtectedRoute path="/monlistassign" component={MonListAssignPage} minAccessLvl="10" />  
              <ProtectedRoute path="/offset" component={OffsetPage} minAccessLvl="10" />
              <ProtectedRoute path="/ctrldev" component={ControlDevicePage} minAccessLvl="10" />
              <ProtectedRoute path="/v2usermanage" component={V2_UserList} minAccessLvl="10" />
              <ProtectedRoute path="/v2sensorchecklist" component={V2_SensorChecklist} minAccessLvl="10" />

              {/* <ProtectedRoute path="/datapresent/building" component={DataPresent_Building} minAccessLvl="10" /> */}
              <ProtectedRoute path="/regdevice" component={DeviceManagement} minAccessLvl="10" />
              <ProtectedRoute path="/datadownload" component={DataDownloadPage} minAccessLvl="70" />
              <ProtectedRoute path="/buildingassign" component={BuildingAssignPage} minAccessLvl="10" />
              <ProtectedRoute path="/editProfile" component={EditProfile} minAccessLvl="10" />
              {/* <ProtectedRoute path="/buildingselect" component={BuildingSelect} minAccessLvl="10" /> */}
              <ProtectedRoute path="/selectpage" component={SelectionPage} minAccessLvl="99" />
              <ProtectedRoute path="/buildingselect" component={BuildingSelect} minAccessLvl="99" />
              
              <Route path="/notactive" component={UserNotActive} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              {/* <Route path="/overview" component={Overview} />  */}
              <Route path="/reguser" component={UserRegister} />
              {/* <AuthRoute path="/overview" component={Overview} /> */}
              {/* <ProtectedRoute path="/notification/list/:bdID" component={NotificationPage} minAccessLvl="10" />     */}
              {/* {user && !user.active &&
              <Redirect to="/notactive" />} */}
              {refUser.current && <Redirect exact from="/notification/list" to="/notification/list/0" />}
              {refUser.current && <Redirect to="/buildingselect" />}
              {!refUser.current && <Redirect to="/login" />}
            </Switch>
          </div>
        </BdProvider>
      </React.Fragment>}
    </div>
  );
}

export default App;

