import React from 'react';
import TpAcpuLayout from './NodeTemplate/TpAcpuLayout';
import TpWcpuLayout from './NodeTemplate/TpWcpuLayout';
import { FaBookOpen } from "react-icons/fa";
import TpLastReading from './NodeTemplate/TpLastReading';
import TpPecLayout from './NodeTemplate/TpPecLayout';
import TpBtuLayout from './NodeTemplate/TpBtuLayout';
import TpCtPumpLayout from './NodeTemplate/TpCtPumpLayout';

function PDC_Overview(props) {
    return (
        <div>
        <div className='pdc_overflowxScroll'>
            <div className='sortHor'>
            <div className='sortHor spaceAround pdc_marginLeft4pc flex_90'>
                <div className='pdc_borderTitle pdc_floorTitle'>Left Wing</div>
                <div className='pdc_borderTitle pdc_floorTitle'>Center Wing</div>
                <div className='pdc_borderTitle pdc_floorTitle'>Right Wing</div>
            </div>
                <div className='pdc_dropdown flex_1'>
                    <FaBookOpen className='pdc_dropdownbtn' />
                    <div className='pdc_dropdown_content'>
                        <div className='sortHor verCenter'>
                            <div className='pdc_runIndicator'></div>
                            <div>Run</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_resetErrIndicator pdc_run_bg'></div>
                            <div>Run with Resettable Error</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_critErrIndicator pdc_run_bg'></div>
                            <div>Run with Critical Error</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_stopIndicator'></div>
                            <div>Stop</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_resetErrIndicator'></div>
                            <div>Stop with Resettable Error</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_critErrIndicator'></div>
                            <div>Stop with Critical Error</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_comErrIndicator'></div>
                            <div>Modbus Error</div>
                        </div>
                        <div className='sortHor verCenter'>
                            <div className='pdc_offIndicator'></div>
                            <div>Offline</div>
                        </div>
                    </div>
                </div>
                </div>
            <div className='sortHor spaceAround pdc_card'>
                <div className='pdc_floorTitle'>Roof Level</div>
                <div className='sortHor flex_1 bothCenter'>
                    {/* <TpAcpuLayout name="CU-1a" type={36} bdDev_id={1758} />
                    <TpAcpuLayout name="CU-1b" type={36} bdDev_id={1758} /> */}
                    <div id='cu1a_1758' className='pdc_nodeCard'>CU-1a</div>
                    <div id='cu1b_1758' className='pdc_nodeCard'>CU-1b</div>
                    {/* <TpAcpuLayout name="CU-2a" type={36} bdDev_id={1759} />
                    <TpAcpuLayout name="CU-2b" type={36} bdDev_id={1759} /> */}
                    <div id='cu2a_1759' className='pdc_nodeCard'>CU-2a</div>
                    <div id='cu2b_1759' className='pdc_nodeCard'>CU-2b</div>
                    <div id='cupec_2450' className='pdc_nodeCard'>CU-PEC-1</div>
                </div>
                <div className='flexVerAllCenter flex_1' style={{ margin: "-5px 0"}}>
                    <div className='sortHor verCenter pdc_marginBottom5'>
                        <TpCtPumpLayout name="CT-1" type={3} bdDev_id={1527} />
                        <div className='pdc_readingCard sortHor verCenter'>
                        <TpCtPumpLayout name="PUMP-1" type={3} bdDev_id={1525} />
                        <TpLastReading devInfo={{ht: 33, bdDev_id: 1521}} param="pf_1" unit="psi" />
                        </div>
                    </div>
                    <div className='sortHor verCenter'>
                        <TpCtPumpLayout name="CT-2" type={3} bdDev_id={1528} />
                        <div className='pdc_readingCard sortHor verCenter'>
                        <TpCtPumpLayout name="PUMP-2" type={3} bdDev_id={1526} />
                        <TpLastReading devInfo={{ht: 33, bdDev_id: 1522}} param="pf_1" unit="psi" />
                        </div>
                    </div>
                </div>
                <div className='sortHor flex_1 bothCenter'></div>
            </div>
            <div className='sortHor spaceAround pdc_card'>
                <div className='pdc_floorTitle'>Level 4</div>
                    <div className='disFlex flexDirVer bothCenter flex_1'>
                        <div className='pdc_areaCard'>
                            <div className='disFlex bothCenter pdc_marginBottom10'>AHU 4-1</div>
                            <div className='sortHor bothCenter'>
                                <TpWcpuLayout name="WCPU-1" type={37} bdDev_id={1776} />
                                <TpWcpuLayout name="WCPU-2" type={37} bdDev_id={1777} />
                                {/* <div className='pdc_nodeCard_smallBorder'>BTU-1</div> */}
                                <div className='pdc_readingCard sortHor verCenter'>
                                <TpBtuLayout name="BTU-1" type={26} bdDev_id={1311} />
                                <TpLastReading devInfo={{ht: 26, bdDev_id: 1311}} param="pf_0" unit="m3/h" />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className='sortHor flex_1 bothCenter'></div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                    <div className='disFlex bothCenter pdc_marginBottom10'>AHU 4-2</div>
                    <div className='sortHor bothCenter'>
                        <TpWcpuLayout name="WCPU-3" type={37} bdDev_id={1778} />
                        <TpWcpuLayout name="WCPU-4" type={37} bdDev_id={1779} />
                        {/* <div className='pdc_nodeCard_smallBorder'>BTU-2</div> */}
                        <div className='pdc_readingCard sortHor verCenter'>
                        <TpBtuLayout name="BTU-2" type={26} bdDev_id={1312} />
                        <TpLastReading devInfo={{ht: 26, bdDev_id: 1312}} param="pf_0" unit="m3/h" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='sortHor spaceAround pdc_card'>
                <div className='pdc_floorTitle'>Level 3</div>
                <div className='sortHor flex_1 bothCenter'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>Server Room</div>
                        <div className='sortHor bothCenter'>
                            {/* <div className='pdc_nodeCard_smallBorder'>PEC-1</div> */}
                            <TpPecLayout name="PEC-1" type={49} bdDev_id={2450} idList={["pec_2450", "cupec_2450"]} />
                        </div>
                    </div>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU-3-1</div>
                        <div className='sortHor bothCenter'>
                            <TpWcpuLayout name="WCPU-3" type={37} bdDev_id={1774} />
                            <TpWcpuLayout name="WCPU-2" type={37} bdDev_id={1773} />
                            <TpWcpuLayout name="WCPU-1" type={37} bdDev_id={1772} />
                        </div>
                    </div>
                </div>
                <div className='sortHor flex_1 bothCenter'></div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                    <div className='disFlex bothCenter pdc_marginBottom10'>AHU 3-2</div>
                    <div className='sortHor bothCenter'>
                        <TpWcpuLayout name="WCPU-5" type={37} bdDev_id={1775} />
                        <TpWcpuLayout name="WCPU-4" type={37} bdDev_id={1704} />
                    </div>
                    </div>
                </div>
            </div>
            <div className='sortHor spaceAround pdc_card'>
                <div className='pdc_floorTitle'>Level 2</div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU 2-1</div>
                        <div className='sortHor bothCenter'>
                            <TpWcpuLayout name="WCPU-4" type={37} bdDev_id={1768} />
                            <TpWcpuLayout name="WCPU-3" type={37} bdDev_id={1767} />
                            <TpWcpuLayout name="WCPU-1" type={37} bdDev_id={1765} />
                            <TpWcpuLayout name="WCPU-2" type={37} bdDev_id={1766} />
                        </div>
                    </div>
                </div>
                <div className='sortHor flex_1 bothCenter'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>Roof Level 1</div>
                        <div className='sortHor bothCenter'>
                            {/* <TpAcpuLayout name="CU-3a" type={36} bdDev_id={1569} />
                            <TpAcpuLayout name="CU-3b" type={36} bdDev_id={1569} /> */}
                            <div id='cu3a_1569' className='pdc_nodeCard'>CU-3a</div>
                            <div id='cu3b_1569' className='pdc_nodeCard'>CU-3b</div>
                            {/* <TpAcpuLayout name="CU-4a" type={36} bdDev_id={1760} /> */}
                            <div id='cu4a_1760' className='pdc_nodeCard'>CU-4a</div>
                        </div>
                    </div>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU-2-2</div>
                        <div className='sortHor bothCenter'>
                            <TpAcpuLayout name="ACPU-4" type={36} bdDev_id={1760} idList={["acpu_1760", "cu4a_1760"]} />
                            <TpWcpuLayout name="WCPU-5" type={37} bdDev_id={1769} />
                        </div>
                    </div>
                </div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU 2-3</div>
                        <div className='sortHor bothCenter'>
                            <TpWcpuLayout name="WCPU-8" type={37} bdDev_id={1771} />
                            <div className='pdc_nodeCard_smallBorder'>WCPU-7</div>
                            <TpWcpuLayout name="WCPU-6" type={37} bdDev_id={1770} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='sortHor spaceAround pdc_card'>
                <div className='pdc_floorTitle'>Level 1</div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU-1-1</div>
                        <div className='sortHor bothCenter'>
                            <TpAcpuLayout name="ACPU-1" type={36} bdDev_id={1758} idList={["acpu_1758", "cu1a_1758", "cu1b_1758"]} />
                            <TpAcpuLayout name="ACPU-2" type={36} bdDev_id={1759} idList={["acpu_1759", "cu2a_1759", "cu2b_1759"]} />
                            <TpWcpuLayout name="WCPU-1" type={37} bdDev_id={1761} />
                            <TpWcpuLayout name="WCPU-2" type={37} bdDev_id={1762} />
                        </div>
                    </div>
                </div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU-1-2</div>
                        <div className='sortHor bothCenter'>
                            {/* <div className='pdc_nodeCard'>ACPU-3</div> */}
                            <TpAcpuLayout name="ACPU-3" type={36} bdDev_id={1569} idList={["acpu_1569", "cu3a_1569", "cu3b_1569"]} />
                        </div>
                    </div>
                </div>
                <div className='disFlex flexDirVer bothCenter flex_1'>
                    <div className='pdc_areaCard'>
                        <div className='disFlex bothCenter pdc_marginBottom10'>AHU-1-3</div>
                        <div className='sortHor bothCenter'>
                            <TpWcpuLayout name="WCPU-4" type={37} bdDev_id={1764} />
                            <TpWcpuLayout name="WCPU-3" type={37} bdDev_id={1763} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default PDC_Overview;