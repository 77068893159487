import React, { Component } from "react";
import auth from "../service/authService";
import buildings from "../service/setting_Buildings";
import assignedBuilding from "./utilities/assignBuilding";
// import buildingFn from "./utilities/getBuilding";
import getMonList from "./utilities/getMonList";
import monlistFn from "./utilities/monList/monList";
import Loader from "./common/loader";
import BdContext from "../Context/bdContext_T1";
import {ImSearch} from "react-icons/im";
import {IoClose} from "react-icons/io5";
import { sortTemplate } from "./utilities/ArrayFn/arrayFn";

class BuildingSelect extends Component {
  state = {
    buildings: [],
    buildings_Db: [],
    building_name: "",
    bLoaded: false,
  };

  static contextType = BdContext;

  async componentDidMount() {
    // async componentWillMount() {
      let bSkip = await this.skipPage_CusProject();
      if(bSkip) return
      /** skip page */
    this.setState({ bLoaded: false });
    let allBuildings = await this._getAllBuildings();
    await this._getDisplayBuildings(allBuildings);
    this.setState({ bLoaded: true });
  }

  setBdTokenManual = async(_bd_id, _bdName)=>{    
    // let buildings = [...this.state.buildings];
    // let building_id = buildings[idx]._id;
    // let building_name = buildings[idx].building;
    await localStorage.setItem("buildingId", _bd_id);
    await localStorage.setItem("buildingName", _bdName); // for mon list
    await localStorage.setItem("selBuildingName", _bdName); // fro add item
  }

  handleSearch=()=>{
    try {      
      let _BdList_DB = [...this.state.buildings_Db]
      let searchKeyword = document.getElementById("in_search").value.toUpperCase();
      if(!searchKeyword) {
        let filtered_Sorted= this.sortBuilding(_BdList_DB);
        this.setState({buildings:filtered_Sorted})
        // setG_BdList_Display(filtered_Sorted)
        return 
      }
      let filtered = _BdList_DB.filter (c=> c.building.toUpperCase().includes(searchKeyword) );      
      // console.log("filtered", filtered);
      let filtered_Sorted= this.sortBuilding(filtered);
      this.setState({buildings:filtered_Sorted})
      // setG_BdList_Display(filtered_Sorted)
    } catch (error) {
      console.log("Search Err : ", error.message);
    }
  }

  handleCancelSearch=()=>{
    try {
      let _BdList_DB = [...this.state.buildings_Db]
      document.getElementById("in_search").value = "";      
      let filtered_Sorted= this.sortBuilding(_BdList_DB);
      this.setState({buildings:filtered_Sorted})
      // setG_BdList_Display(filtered_Sorted)
    } catch (error) {
      console.log("Cancel Search Err : ", error.message);
    }
  }

  skipPage_CusProject = async () => {
    try {
      let user = await auth.getCurrentUser();
      if(user._id===129 || user._id===132 || user._id===135){        
        await this.setBdTokenManual(71, "PDC");
        /** direct to custome page */
        this.props.history.push({
          pathname: `/cusmainpg/pdc`,
          _data: {},
        });
        window.location.reload(false);  // call a refresh to stop data pulling
        return true
      }
      return false
    } catch (error) {
      console.log("skipPage_CusProject error : ", error.message);
      return false;
    }

  }

  sortByName(a, b) {
    let sA = a.building.toUpperCase();
    let sB = b.building.toUpperCase();
    if (sA < sB) return -1;
    if (sA > sB) return 1;
    return 0;
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      return allBuildings;
      // console.log(this.state.allBuildings);
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  _getDisplayBuildings = async (allBuildings) => {
    // console.log(allBuildings);
    try {
      let user = await auth.getCurrentUser();
      // console.log(user);
      let ownedBuildings_id = await assignedBuilding.getOwnerBuildings(user._id);
  
      let ownBuildings = [];
      if (user.accessLevel > 10) {
        if (!ownedBuildings_id[0]) return;
        for (const bd_id of ownedBuildings_id) {
          let matchBuilding = allBuildings.find(
            (c) => c._id === bd_id.buildingID
          );
          if (matchBuilding) ownBuildings.push(matchBuilding);
        }
      } else {
        ownBuildings = [...allBuildings];
      }
      // console.log(ownBuildings);
      // let buildings = ownBuildings.sort(buildingFn.sortBuildingByOwnerBuilding);
      let buildings = ownBuildings.sort((a, b) => this.sortByName(a, b));
      // console.log(ownBuildings);
  
      await this.setState({ buildings });
      await this.setState({ buildings_Db : buildings});
      // console.log(allBuilding);
      // console.log(this.state.allBuildings);
      // console.log(this.state.ownBuildings);
    } catch (error) {
      console.log(error.message);
    }
  };

  sortBuilding=(arrList)=>{
    let sortKeys = [
      { key: "building", descOrder: false },
    ];
    let _sortArr = [...arrList];
    _sortArr.sort((a, b) => sortTemplate(a, b, sortKeys));
    return _sortArr;
  }

  setBuilding = async (bdInfo) => {
    
    let building_id = bdInfo._id;
    let building_name = bdInfo.building;
    await localStorage.setItem("buildingId", building_id);
    await localStorage.setItem("buildingName", building_name); // for mon list
    await localStorage.setItem("selBuildingName", building_name); // fro add item

    let { setBd } = this.context;
    setBd(building_name);
    this.setState({ building_name });

    let monList = await getMonList.getMonList();
    const user = auth.getCurrentUser();
    // console.log(user);

    let assignedMonList = [];

    if (user.accessLevel > 10) {
      let ownedMonList = await monlistFn.getAssignedMonListByUserID(user._id);
      let assignedItem = {};
      for (const ownList of ownedMonList) {
        assignedItem = monList.find((c) => ownList.MonitorList_id === c._id);
        assignedMonList.push(assignedItem);
      }
    } else {
      assignedMonList = [...monList];
    }
    assignedMonList.sort((a, b) => a.SortIndex - b.SortIndex);
    /** Change Page */
    // eslint-disable-next-line
    let monList_id = assignedMonList[0] ? assignedMonList[0]._id : 0;
    this.props.history.push({
      pathname: `/selectpage`,
      // pathname: "/datapresent/building",
      _data: {
        // _selBuildingId: this.state.selBuildingId,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
    // window.location = `/monlist/${monList_id}`; // call a full reload
  };

  render() {
    let buildings = [...this.state.buildings];
    let buildings_Db = [...this.state.buildings_Db];
    // let currentTheme = this.context;
    // console.log(currentTheme);

    return (
      <React.Fragment>
        {!this.state.bLoaded && <Loader />}
        {this.state.bLoaded && !buildings_Db[0] && (
          <h1>No Buildings Assigned. Please contact admin.</h1>
        )}
        <div className="autumn_searchBarContainer">
          <div className="sortHor bothCenter">
            <ImSearch className="hoverPointer" onClick={this.handleSearch}/>
            <input id="in_search" className="autumn_searchInput" onChange={this.handleSearch}/>
            <IoClose className="autumn_redColorIcon hoverPointer" onClick={this.handleCancelSearch}/>
            
          </div>
        </div>
        
          {buildings.map((u, ind)=>(
            <div key={`bdSelKey_${ind}`} className="autumn_bdSelContiner hoverPointer" onClick={()=>this.setBuilding(u)}> {u.building} </div>
          ))} 

          {/* {buildings.map((u, ind) => (
            <li key={ind} onClick={() => this.setBuilding(ind)}>
              {u.building}
            </li>
          ))} */}
        
      </React.Fragment>
    );
  }
}

export default BuildingSelect;
