import React from "react";
import Joi from "joi-browser";
import deviceService from "../../service/deviceManage";
import bdDevService from "../../service/buildingDevicesService";
import enm from "../utilities/enum";
import tableFn from "../utilities/tableFunction";
import tool from "../../checkingTools/tools";
import CustomForm from "./../common/customForm";
import "./bDeviceReg.css";
import Icon from "./../common/icon";

class BuildingDeviceRegister extends CustomForm {
  state = {
    buildingID: "",
    allDevices: [],
    buildingEnmObj: [],
    deviceEnmObj: [],
    devBdEnum: [],
    data: {
      type: "1",
      devID: "0",
      location: "",
      name: "",
      buildingID: "0",
      remarks: "",
      active: 1,
    },
    errors: {},
  };

  schema = {
    type: Joi.number().required().label("Type"),
    devID: Joi.number().required().label("Device ID"),
    location: Joi.string().label("Location").allow(null, ""),
    name: Joi.string().label("Name"),
    buildingID: Joi.number().label("Building"),
    active: Joi.number().label("Active"),
    remarks: Joi.string().label("Remark").allow(null, ""),
  };

  _getDevOfBd_Enum = async () => {
    let devsInBuilding = [];
    try {
      let { data: allDevices } = await deviceService.getAll();
      let devicesAssignedToThisBuilding = allDevices.filter((d) => {
        // eslint-disable-next-line
        return d.buildingID == this.state.buildingID;
      });
      devsInBuilding.push({ _id: 0, name: "Idle", toMatch: "" });
      // let bdDevEnum = [];
      for (const dbDev of devicesAssignedToThisBuilding) {
        let newBdDev = {
          _id: dbDev.devID,
          name: dbDev.devID,
          toMatch: dbDev.type,
        };
        devsInBuilding.push(newBdDev);
      }
      return devsInBuilding;
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };
  
  // _getDevOfBd_Enum = async () => {
  //   let devsInBuilding = [];
  //   try {
  //     let { data: allDevices } = await deviceService.getAll();
  //     let devicesAssignedToThisBuilding = allDevices.filter((d) => {
  //       // eslint-disable-next-line
  //       return d.buildingID == this.state.buildingID;
  //     });
  //     devsInBuilding.push({ _id: 0, name: "Idle", toMatch: "" });
  //     // let bdDevEnum = [];
  //     for (const dbDev of devicesAssignedToThisBuilding) {
  //       let newBdDev = {
  //         _id: dbDev.devID,
  //         name: dbDev.devID,
  //         toMatch: dbDev.type,
  //       };
  //       devsInBuilding.push(newBdDev);
  //     }
  //     return devsInBuilding;
  //   } catch (error) {
  //     console.log("Get Building DB Error: ", error.message);
  //   }
  // };

  async componentDidMount() {
    let buildingID = this.props.match.params.bdID;
    await this._setBuildingId(buildingID);
    await this.setState({ buildingID });
    // tool.log(this.state.data);
    // get building DB from service
    let devBdEnum = await this._getDevOfBd_Enum();
    await this.setState({ devBdEnum });

    // await this._getBuildingEnmList();
    // await this._getallDevices();
    await this._getDeviceEnmList();
    // tool.log(this.state.deviceEnmObj);
  }


  _setBuildingId = async (id) => {
    let data = { ...this.state.data };
    data.buildingID = id;
    await this.setState({ data });
  };

  _getDeviceEnmList = async () => {
    let deviceEnmObj = await enm.getDeviceEnmObj();
    this.setState({ deviceEnmObj });
  };

  _getBuildingEnmList = async () => {
    let result = await enm.getBuildingEnmObj();
    this.setState({ buildingEnmObj: result });
    // console.log(this.state.buildingEnmObj);
  };

  _getallDevices = async () => {
    try {
      let { data: allDevices } = await deviceService.getAll();
      this.setState({ allDevices });
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  typeSelChange = () => {
    const sel = document.getElementById("tySel").value;
    // document.getElementById('idSel').value = 0;
    let data = { ...this.state.data };
    data.type = sel;
    data.devID = "0";
    this.setState({ data });
    console.log(`Change: ${sel}`);
  };

  idSelChange = () => {
    const sel = document.getElementById("idSel").value;
    let data = { ...this.state.data };
    data.devID = sel;
    this.setState({ data });
    console.log(`Change: ${sel}`);
  };

  doSubmit = async () => {
    try {
      console.log(`Continue`);

      let data = { ...this.state.data };
      console.log(data);

      let response = await bdDevService.add(this.state.data);
      console.log(response);

      window.location = `/buildings/management/${this.state.buildingID}`;
    } catch (ex) {
      tool.exResponse(ex);
    }
  };

  changePage = () => {
    this.props.history.push("/buildings/management/" + this.state.buildingID);
  };

  render() {
    let data = { ...this.state.data };
    // tool.log(this.state.devBdEnum);
    // console.log(data);
    return (
      <React.Fragment>
        <Icon
          className="fa fa-arrow-left deactive back"
          onClick={this.changePage}
        />
        <div className="bDevReg">
          <div className="bDevRegCard">
            <h1>Building Register Device</h1>
            <form onSubmit={this.handleSubmit} className="form-bDevReg">
              {/* {this.renderInput("type", "Type")} */}
              <div>
                <label className="devtype">Type</label>
                <label className="devid">ID</label>
              </div>
              <span className="devtype-dd">
                {tableFn.dropDown(
                  `tySel`,
                  this.state.deviceEnmObj,
                  1,
                  "select-dd",
                  this.typeSelChange
                )}
              </span>
              {/* {this.renderInput("devID", "ID")} */}
              <span className="devid-dd">
                {tableFn.dropDownWithFilter(
                  `idSel`,
                  this.state.devBdEnum,
                  data.devID,
                  data.type,
                  "select-dd",
                  this.idSelChange
                )}
              </span>
              {this.renderCustomInput("name", "Name")}
              {this.renderCustomInput("location", "Location")}
              {/* <a>Building</a>
                {tableFn.dropDown(`bdSel`,this.state.buildingEnmObj,0, this.buildingSelChange)} */}
              {this.renderCustomInput("remarks", "Remark")}
              <div align="center">{this.renderCustomButton("Register")}</div>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BuildingDeviceRegister;
