import sensorMngSvr from './../../service/sensor/SensorManagement';


async function updateSensorPara(...arg){
    try {
        // console.log({...arg});
        let {data, status} = await sensorMngSvr.updateSensorPara(...arg);
        // console.log(data);
        // console.log(status);
        if(status !== 200 || data!=="Update Success") return false
        return true
    } catch (error) {
        console.log(error.message); 
    }
}

async function updateSensorList(...arg){
    try {
        // console.log({...arg});
        let {data, status} = await sensorMngSvr.updateSensorList(...arg);
        // console.log(data);
        // console.log(status);
        if(status !== 200 || data!=="Update Success") return false
        return true
    } catch (error) {
        console.log(error.message); 
    }
}

async function getSensorByVendor_id(vendor_id=0){
    try {
        let result = await sensorMngSvr.getSensorListByVendorId(vendor_id);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}

function genSensorKey(sensor){
    /** Name in database */
    let rtnKey = (sensor.dataIndex===-1) ? `${sensor.dataType}` : `${sensor.dataType}_${sensor.dataIndex}`;
    return rtnKey;
}

function genSensorKey_BracketForm(sensor){
    /** Name in database */
    if(sensor.dataIndex===-1) return sensor.dataType;
    let rtnKey = `${sensor.dataType} [${sensor.dataIndex}]`;
    return rtnKey;
}

function genSensorKey_Unpacked(_dataType, _dataIndex){
    let sensor = {
        dataType: _dataType,
        dataIndex:_dataIndex,
    }
    let rtnKey = genSensorKey(sensor);
    return rtnKey;
}

function splitSensorKey(sKey){
    let arr = sKey.split('_');    
    let validateFail = 
        !arr[0] || 
        !arr[1] || 
        arr[2] ||
        !(arr[0] === "pb" ||
        arr[0] === "pi" ||
        arr[0] === "pn" ||
        arr[0] === "pf") ||
        isNaN(arr[1]); 

    if (validateFail) 
        return ({
            dataType:sKey,
            dataIndex:-1
        })
    let sensorInfo = {
        dataType : arr[0],
        dataIndex : parseInt(arr[1]),
    }
    return sensorInfo;
}

function getDataKey(data){
    if(data.dataIndex ===-1) return data.dataType;
    return `${data.dataType}_${data.dataIndex}`;
}

async function getParaList_inid_name_byTyList(tyList){
    let newSensor = await getParaByTypeList(tyList);
    // console.log(newSensor);
    let returnList = []
    for (const sensor of newSensor) {
        returnList.push({
            sensorType:sensor.sensorType,
            _id:genSensorKey(sensor),
            name:sensor.dataName,
        })
    }
    /** include old sensor */



    return returnList;
}

async function getParaByTypeList(tyList){
    try {
        let result = await sensorMngSvr.getParaByTypeList(tyList);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}

async function regNewSensor(...arg){
    try {
        // console.log({...arg});
        let {data, status} = await sensorMngSvr.regNewSensor(...arg);
        // console.log(data);
        // console.log(status);
        if(status !== 200 || data!=="Done") return false
        return true
    } catch (error) {
        console.log(error.message); 
    }
}


function getParaByKey(paras, sensorType, key, requiredKey){
    // console.log(paras);
    // console.log(sensorType);
    // console.log(key);

    let paraInfo = splitSensorKey(key);
    // let paras = [...this.state.newSensorParasInUse];
    let foundPara = paras.find(c=>c.sensorType === sensorType && c.dataType === paraInfo.dataType && c.dataIndex=== paraInfo.dataIndex);
    if (!foundPara) return

    if(requiredKey) return foundPara[requiredKey];
    return foundPara;
  }

async function getSensorListByTypeList(tyList){
    try {
        let result = await sensorMngSvr.getSensorListByTypeList(tyList);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}

const expModule = {  
    getParaByKey,
    getDataKey,
    updateSensorPara,
    updateSensorList,
    genSensorKey_BracketForm,
    regNewSensor,
    getSensorByVendor_id,
    getParaByTypeList,
    genSensorKey,
    splitSensorKey,
    getParaList_inid_name_byTyList,
    genSensorKey_Unpacked,
    getSensorListByTypeList,
};
export default expModule;