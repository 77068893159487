import http from "../httpService";

/** Get Element list is located at mon list */
async function updateEleList(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/eleList/updateelelist`;
    const body = {   
      _id: parseInt(obj._id),
      T1_id: parseInt(obj.T1_id),
      bdDev_id: parseInt(obj.bdDev_id),
      type: parseInt(obj.type),
      SortIndex: parseInt(obj.SortIndex),
    };
    return await http.post(url, body);    
}


async function delEleList(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/eleList/delelelist";
    const body = {
        _id:obj._id
      };
    return await http.post(url, body);    
}

async function addEleList(obj) {
    let url = process.env.REACT_APP_PUBLIC_URL+"/eleList/addelelist";
    //   console.log(obj);
    const body = {   
        // _id: parseInt(obj._id),
        T1_id: parseInt(obj.T1_id),
        bdDev_id: parseInt(obj.bdDev_id),
        type: parseInt(obj.type),
        SortIndex: parseInt(obj.SortIndex),
    };
      // console.log("did enter");
    return await http.post(url, body); 
}


const expModule = {  
    updateEleList,
    delEleList,
    addEleList,
};
export default expModule;