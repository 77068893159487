import http from "../httpService";

async function getDiag_byList(bdDev_List, nAveCount=10, nPeriod_s=(7*86400)){
    let obj = {
        bdDev_List,
        nAveCount,
        nPeriod_s
    };
    let url = `${process.env.REACT_APP_PUBLIC_URL}/diag/batt/bydevslist`;
    return await http.post(url, obj); 
}


const expModule = {  
    getDiag_byList,
};
export default expModule;