import React, { useState, useEffect } from 'react';
import "./V2_SensorChecklist.css";
import { IoMdAddCircle } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import v2SenOwnFn from "../../function/V2_SensorOwnerFn/V2_SensorOwnerFn";
import devFn from "../../component/utilities/deviceFunction/deviceFunction";
import { getUser_byIdList } from "../../service/V2_Auth";
import sensorMngFn from "../../function/sensor/sensorManagementFn";
import { FaFileExcel } from "react-icons/fa";
import TpDropdownTypeList from './TpDropdownTypeList';
import tableFn from "../../component/utilities/tableFunction";
import { CSVLink } from "react-csv";
import { FiPlus, FiMinus } from "react-icons/fi";
// import TpDevSearchBar from './TpDevSearchBar';

function V2_SensorChecklist() {
    const [G_devSearchInfo, setG_devSearchInfo] = useState([{type: null, typeDisplay: null, devID: null, type_dd: false}]);
    const [G_hideDevSearchBar, setG_hideDevSearchBar] = useState(false);
    const [G_sensorInfoList, setG_sensorInfoList] = useState([]);
    const [G_typeList, setG_typeList] = useState([]);
    // const [G_filteredTypeList, setG_filteredTypeList] = useState([]);
    const [G_loaded, setG_loaded] = useState(false);
    const [G_csvData, setG_csvData] = useState([]);

    const tableColumn = (row, ind) => {
        let column = {
          Type: row.cells[1].innerHTML,
          ID: row.cells[2].innerHTML,
          Building: row.cells[3].innerHTML,
          Owner: row.cells[4].innerHTML,
          SerialNo: row.cells[5].innerHTML,
          RegCode: row.cells[6].innerHTML,
        };
        // console.log(column);
        return column;
    };

    useEffect(() => {
        async function startUp() {
            let sensorList = await loadSensorTypeList();
            setG_typeList(sensorList);
            // setG_filteredTypeList(sensorList);
            setG_loaded(true);
        }
        startUp();
    }, [G_loaded]);

    const loadSensorTypeList = async () => {
        let allSensors = await sensorMngFn.getSensorByVendor_id();
        // console.log(allSensors);
        return allSensors;
    }

    // const handleSearch = (input_id) => {
    //     let _inputValue = document.getElementById(input_id).value;
        
    //     if(_inputValue === "") {
    //         setG_filteredTypeList(G_typeList);
    //         return;
    //     }
    //     let filtered1 = G_typeList.filter(c => c.name.toUpperCase().includes(_inputValue.toUpperCase()));
    //     let filtered2 = G_typeList.filter(c => c.type.toString().includes(_inputValue.toString()));

    //     let filtered = [...filtered1, ...filtered2];
    //     setG_filteredTypeList(filtered);
    // }

    const addDevSearchLine = () => {
        let _devSearchInfo = [...G_devSearchInfo];
        _devSearchInfo.push({type: null, devID: null});

        setG_devSearchInfo(_devSearchInfo);
    }

    const handleHideSearchBar = () => {
        console.log("hide searcbar");
        setG_hideDevSearchBar(!G_hideDevSearchBar);
    }

    const handleSearchDevInfo = async () => {
        let _devSearchInfo = [...G_devSearchInfo];
        // console.log(_devSearchInfo);
        let _sensorInfoList = [];

        for(let i = 0; i < _devSearchInfo.length; i++) {
            // let typeInput = document.getElementById(`v2_devType_${i}`).value;
            let devIdInput = document.getElementById(`v2_devId_${i}`).value;
            // console.log(_devSearchInfo[i].type, devIdInput);
            if(_devSearchInfo[i].type === null || devIdInput === '') {
                continue;
            }
            /** get devid list */
            let typeInfo = G_typeList.find(c => c.type === _devSearchInfo[i].type);
            // console.log(typeInfo);
            let devIdList = [];
            let tempDevIdList = devIdInput.split(',');
            // console.log(tempDevIdList);
            for(const devId of tempDevIdList) {
                if(devId.includes('-')) {
                    let startEnd = devId.split('-');
                    devIdList = [...devIdList, ...[...Array(parseInt(startEnd[1]) - parseInt(startEnd[0]) + 1).keys()].map(x => x + parseInt(startEnd[0]))];
                } else {
                    devIdList.push(parseInt(devId));
                }
            }
            // console.log(_devSearchInfo[i].type, devIdList);
            /** get reg code and serial no */
            let devInfoList = await devFn.getRegdevbyTy_devIdList(_devSearchInfo[i].type, [...new Set(devIdList)]);
            // console.log(devInfoList);
            /** get v2 bddev info */
            let bdDevList = await v2SenOwnFn.getBy_ty_devIdList(_devSearchInfo[i].type, [...new Set(devIdList)]);
            // console.log(bdDevList);
            /** get v2 bd info */
            let bdIdList = [...new Set(bdDevList.map(i => i.buidling_id))];
            // console.log(bdIdList);
            let bdList = await v2SenOwnFn.getBdInfoBy_idList(bdIdList);
            // console.log(bdList);
            /** get v2 owner info */
            let ownerIdList = [...new Set(bdDevList.map(i => i.owner_id))];
            // console.log(ownerIdList);
            let ownerList = await getUser_byIdList(ownerIdList);
            // console.log(ownerList.data);

            for(const devInfo of devInfoList) {
                let inBdDev = bdDevList.filter(c => { return c.type === devInfo.type && c.devID === devInfo.devID; });
                if(inBdDev.length) {
                    // console.log(inBdDev[0]);
                    let thisBd = bdList.find(c => c._id === inBdDev[0].buidling_id);
                    let thisOwner = ownerList.data.find(c => c._id === inBdDev[0].owner_id);
                    _sensorInfoList.push({
                        bdDev_id: inBdDev[0]._id,
                        type: devInfo.type,
                        typeName: typeInfo.name,
                        devID: devInfo.devID,
                        bd_id: thisBd._id,
                        bd_name: thisBd.name,
                        owner_id: thisOwner._id,
                        owner_name: thisOwner.username,
                        serialNo: devInfo.SerialNo,
                        regCode: devInfo.RegCode
                    });
                } else {
                    _sensorInfoList.push({
                        bdDev_id: null,
                        type: devInfo.type,
                        typeName: typeInfo.name,
                        devID: devInfo.devID,
                        bd_id: null,
                        bd_name: '-',
                        owner_id: null,
                        owner_name: '-',
                        serialNo: devInfo.SerialNo,
                        regCode: devInfo.RegCode
                    });
                }
            }
        }

        // console.log(_sensorInfoList);
        setG_sensorInfoList(_sensorInfoList);

        genDownloadData();
    }

    const toggleTypeList = (idx) => {
        let _devSearchInfo = [...G_devSearchInfo];
        _devSearchInfo[idx].type_dd = !_devSearchInfo[idx].type_dd;
        setG_devSearchInfo(_devSearchInfo);
    }

    const handleSelType = (typeInfo, idx) => {
        // console.log(typeInfo);
        let _devSearchInfo = [...G_devSearchInfo];
        _devSearchInfo[idx].type = typeInfo.type;
        document.getElementById(`v2_devType_${idx}`).value = `${typeInfo.name} [${typeInfo.type}]`;
        _devSearchInfo[idx].type_dd = false;
        setG_devSearchInfo(_devSearchInfo);
    }

    const genDownloadData = () => {
        let tableData = tableFn.getInfoFromTable("devicesTable", tableColumn);
        setG_csvData(tableData);
    }

    return (
        <div>
            <h1>V2 Sensor Checklist</h1>
            <div className='dev_searchbar_container verCenter'>
                {/* <TpDevSearchBar
                    devSearchInfo={G_hideDevSearchBar? [G_devSearchInfo[0]] : G_devSearchInfo}
                    hideDevSearchBar={G_hideDevSearchBar}
                    addDevSearchLine={addDevSearchLine}
                    handleHideSearchBar={handleHideSearchBar}
                /> */}
                <div className='sortHor verCenter' style={{ width: '90%' }}>
                    <div className='dev_searchbar sortVer'>
                        {G_devSearchInfo.map((u, ind) => (
                            <div className={G_hideDevSearchBar && ind > 0? 'dev_searchbar_hide' : 'dev_searchbar_container'} key={ind}>
                                <div className='sortHor dev_searchbar_subline'>
                                    {ind + 1 === G_devSearchInfo.length?
                                        <IoMdAddCircle className='dev_searchbar_icon hoverPointer' onClick={addDevSearchLine} /> : 
                                        <div className='emptyDiv_frontIndent'></div>}
                                    <div className='dev_searchbar_subtitle'>Type:</div>
                                    <TpDropdownTypeList
                                        typeList = {G_typeList}
                                        disTypeList = {u.type_dd}
                                        ind = {ind}
                                        toggleTypeList = {toggleTypeList}
                                        handleSelType = {handleSelType}
                                    />
                                </div>
                                {/* <div>
                                    <input type="text" id={`v2_devType_${ind}`} onChange={() => handleSearch(`v2_devType_${ind}`)} onClick={() => toggleTypeList(ind)}></input>
                                    {u.type_dd && <div className='dev_searchbar_dropdown'>
                                        {G_filteredTypeList.map((v, idx) => (
                                            <div key={idx} onClick={() => handleSelType(v, ind)}>{v.name} [{v.type}]</div>
                                        ))}
                                    </div>}
                                </div> */}
                                <div className='sortHor dev_searchbar_subline'>
                                    <div className='emptyDiv_frontIndent'></div>
                                    <div className='dev_searchbar_subtitle'>ID:</div>
                                    <input type="text" id={`v2_devId_${ind}`} placeholder='e.g. 1, 5-10' defaultValue={u.devID}></input>
                                </div>
                                {G_devSearchInfo.length > 1 && ind === 0 &&
                                    <div onClick={handleHideSearchBar} className='stayRight sortHor dev_searchbar_ddbtn'>
                                        {G_hideDevSearchBar === false && <FiMinus className='dev_searchbar_icon hoverPointer' />}
                                        {G_hideDevSearchBar === true && <FiPlus className='dev_searchbar_icon hoverPointer' />}
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    <BiSearch className='dev_searchbar_icon hoverPointer' onClick={handleSearchDevInfo} />
                </div>
                <CSVLink className='flexVerAllCenter stayRight hoverPointer noDecoration dev_exportcsv' style={{ color: '#c4beca'}} filename='sensor_checklist.csv' data={G_csvData}>
                    <FaFileExcel className='dev_searchbar_icon' />
                    <div>Export to csv</div>
                </CSVLink>
            </div>

            <div className="card">
            <div className="card-body" id="flip-scroll">
                <table className="cusTable" id="devicesTable">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Bddev ID</th>
                    <th>Sensor</th>
                    <th>ID</th>
                    <th>Building</th>
                    <th>Owner</th>
                    <th>Serial No</th>
                    <th>Reg Code</th>
                    {/* <th>Active</th> */}
                    </tr>
                </thead>
                <tbody>
                    {G_sensorInfoList.map((u, ind) => (
                    <tr key={ind}>
                        <td>{ind + 1}</td>
                        {/* <td style={{ display: "none" }}>{u._id}</td> */}
                        <td>{u.bdDev_id}</td>
                        <td>{u.typeName}</td>
                        <td>{u.devID}</td>
                        {/* <td>{tableFn.dropDown(`selRow${ind}`,this.state.buildingEnmObj, u.buildingID, ()=>this.handleChangeSel(ind))}</td> */}
                        <td>{u.bd_name}</td>
                        <td>{u.owner_name}</td>
                        <td>{u.serialNo}</td>
                        <td>{u.regCode}</td>
                        {/* <td>{u.userAmmend}</td> */}
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
            </div>
        </div>
    );
}

export default V2_SensorChecklist;