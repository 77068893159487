import http from "../httpService";


async function getNItemFromXIndex(obj) {  
  // console.log("1111Caclled1111111111");
  let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/getnfromx";
  // let body = obj;
  return await http.post(url, obj);  
}

async function send(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/send";
    // let body = obj;
    return await http.post(url, obj);  
}

async function setCtrlDev(obj) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/setctrldev";
  // let body = obj;
  return await http.post(url, obj);  
}

async function getCtrlDev(obj) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/getctrldev";
  // let body = obj;
  return await http.post(url, obj);  
}

async function v2sendCtrlCmd(topic, loRaPackage, urlSel) {  
  let obj={
    topic,
    loRaPackage,
    urlSel
  }
  let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/v2sendcmd";
  // let body = obj;
  return await http.post(url, obj);  
}

async function v2getCmdLog(cmdLog) {  
  let obj={
    cmdLog
  }
  let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/v2getcmdlog";
  // let body = obj;
  return await http.post(url, obj);  
}

const expModule = {  
    send,
    setCtrlDev,
    getCtrlDev,
    getNItemFromXIndex,
    v2sendCtrlCmd,
    v2getCmdLog,
};
export default expModule;