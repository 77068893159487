import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import resetPassFn from '../../function/userManagement/resetPassReqFn';
import timeFn from '../../component/utilities/timeFn';


class PR_RstPasswordPage extends Component {
    state={
        recoverycode:0,
        linkValidMins:60,
    }
    async componentDidMount() {
        let recoverycode = this.props.match.params.recoverycode;
        await this.setState({recoverycode});
        console.log(this.state.recoverycode);
    }

    changePassword=async ()=>{
        try {
            let password_1 = document.getElementById('pass1').value;
            let password_2 = document.getElementById('pass2').value;
            if(password_1 === "" ) return toast.error('Password Cannot Be Empty');
            if(password_1!==password_2) return toast.error('Password Not Same');

            console.log("Confirm Password");
            console.log(this.state.recoverycode);        
            /** check databases, make sure recovery code is valid */
            let reqInfo = await resetPassFn.getByRecoveryCode(this.state.recoverycode);
            console.log(reqInfo);
            if(!reqInfo) return toast.error('Invalid Link');
            /** make sure passwrod request is within 1h */
            console.log(reqInfo.unix);
            console.log(timeFn.getUnixNow());
            
            if(reqInfo.unix < (timeFn.getUnixNow() - this.state.linkValidMins*60)) return toast.error('Expired Link');
            /** update user password database*/
    
            reqInfo.password = password_1;
            console.log(reqInfo);
            let setNewRel = await resetPassFn.setNewPassword(reqInfo);
            console.log(setNewRel);
            if(setNewRel!=='OK') return toast.error(setNewRel.msg);
            toast.dark("Password Changed");
            
        } catch (error) {
            toast.error(error.message);
        }
    }

    handleChangePassword=async()=>{
        await this.changePassword();
    }

    render() { 
        return <div>
            <div>Hi User</div>            
            <input id='pass1' type='password'></input>
            <div>confirm password</div>
            <input id='pass2' type='password'></input>
            <div onClick={this.handleChangePassword}>Confirm</div>
        <ToastContainer />
        </div>;
    }
}
 
export default PR_RstPasswordPage;