import battDiagService from '../../service/battDiag/battDiagService';

async function getDiag_byList(...obj){
    try {
        // console.log(obj);
        let result = await battDiagService.getDiag_byList(...obj);
        // console.log(result);
        if(result.status!==200) return []
        return result.data;
    } catch (error) {
        console.log(error.message);
        return []
    }
}   


const expModule = {  
    getDiag_byList,
};
export default expModule;