import { Component } from "react";
import auth from "../service/authService";

class Logout extends Component {
  state = {};
  componentDidMount() {
    auth.logout();
    console.log("Logout!!");
    window.location = "/login";
  }
  render() {
    return null;
  }
}

export default Logout;
