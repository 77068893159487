import buildingService from '../../service/setting_Buildings';



function sortBuildingByOwnerBuilding(a, b){
    if (a.owner > b.owner) return 1;
    if (a.owner < b.owner) return -1;
    if (a.building > b.building) return 1;
    if (a.building < b.building) return -1;
    return 0;
  };

async function getAllBuilding(){
    let result = await buildingService.getAll();    
    if(result.status!==200) return null;    // 
    let sortedData = result.data.sort(sortBuildingByOwnerBuilding);
    return sortedData;
}




const expModule = {  
    sortBuildingByOwnerBuilding,
    getAllBuilding,

};
export default expModule;