import v1CtrlSer from '../../../service/V1/V1_Control/V1_ControlSer';


async function getV2_schedule(ht, hi){
    try {
        let result = await v1CtrlSer.getV2_schedule(ht, hi);
        // console.log(result);
        if(result.status===200 || result.status===203) return result.data;
        return null;        
    } catch (error) {
        console.log("getV2_schedule err : ", error.message);
        return
    }
}

async function V2_setSche(scheList, devInfo){
    try {
        let result = await v1CtrlSer.V2_setSche(scheList, devInfo);
        // console.log(result);
        if(result.status===200 || result.status===203) return result.data;
        return null;        
    } catch (error) {
        console.log("getV2_setSche err : ", error.message);
        return
    }
}

const expModule = {  
    getV2_schedule,
    V2_setSche,
};
export default expModule;