import React, { Component } from "react";

class TpInput extends Component {
  state = {};

  render() {
    let rest = { ...this.props };
    return (
      <>
        <div style={{ marginLeft: "10px" }}>
          <p style={{ display: "inline-block" }}>{this.props.title}</p>
          <input
            style={{
              display: "inline-block",
              marginLeft: 10,
            }}
            {...rest}
          ></input>
          <p
            style={{
              display: "inline-block",
              marginLeft: 10,
            }}
          >
            {this.props.unit}
          </p>
        </div>
      </>
    );
  }
}

export default TpInput;
