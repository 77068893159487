import React from "react";

const Input = ({ icon, name, label, error, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className={icon}></i>
          </span>
        </div>
        <input
          {...rest}
          name={name}
          id={name}
          className="form-control"
          placeholder={label}
        />
      </div>
      {error && <div>{error}</div>}
    </div>
  );
};

export default Input;
