function cssShowStatus(status, id, clssName) {
  try {
    let elementClass = document.getElementById(id).classList;
    let hasClass = elementClass.contains(clssName);
    if (status && !hasClass) {
      elementClass.add(clssName);
    } else if (!status && hasClass) {
      elementClass.remove(clssName);
    }
  } catch (error) {
    console.log(error.message);
  }
}

function cssRemoveClass(cn) {
  try {
    let elementClass = document.getElementsByClassName(cn);
    console.log(elementClass);
    for (const ele of elementClass) {
      ele.className = cn;
    }
    // let hasClass = elementClass.contains(clssName);
    // if (!hasClass) elementClass.add(clssName);
    // else elementClass.remove(clssName);
  } catch (error) {
    console.log(error.message);
  }
}

// function removeClass(nid, nClass){
//     const element = document.getElementById(nid);
//     element.classList.remove(nClass);
//   };
// function addClass(nid, nClass){
//     const element = document.getElementById(nid);
//     element.classList.add(nClass);
//   };

const expModule = {
  cssShowStatus,
  cssRemoveClass,
};
export default expModule;
