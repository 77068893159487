import React from 'react';

function TpDiaSuccess(props) {

    const handleCloseDialog=()=>{
        // console.log("handleCloseDialog (internal)");
        if(!props.onClickCloseDia) return
        props.onClickCloseDia();
    }

    return (
        <div>
            <div className='autumn_dialogBoxContainer'>
                <div className='autumn_dialogBox autumn_dialogBox_fixHeight'>  
                    <div className='autumn_closeBtn btnColor_Grey hoverPointer'
                    onClick={handleCloseDialog}>
                        <i className=' fa fa-minus'></i>
                    </div>
                    <div className='autumn_statusIcon autumn_successBgc'>
                        <i className=' fa fa-check autumn_smallerIcon'>
                        </i>
                    </div>
                    <div className='autumn_statusMsg'>
                        Success
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpDiaSuccess;