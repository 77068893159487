import React, { useEffect, useRef } from 'react';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import cssFn from "../../../component/utilities/css/cssFunction";
import socketIOClient from "socket.io-client";

function TpPecLayout(props) {
    const timerRef = useRef(null);
    const pageClose = useRef(false);

    useEffect(() => {
        timerRef.current = setInterval(async() => {
            // console.log("Refresh");
            await reloadStatus();
        }, 300000);       /** 300*1000s , 5 mins*/

        let socket;
        async function startUp() {
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            await reloadStatus();

            let{ type, bdDev_id }= props;
            // let topic = `v2_${type}_${_id}`;
            let topic = `v1_${type}_${bdDev_id}`;
            // console.log("Socket IO topic: ", topic);
            socket.on(topic, async (data) => {
                // console.log("reload");
                // console.log(data);                                
                // let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
                // if(lastData) setG_Lastdata(lastData);
                await reloadStatus();
            });
        }
        
        startUp();
        
        return ()=>{
            clearInterval(timerRef.current);
            socket.disconnect();
            pageClose.current = true;
            // alert("AddDevicePg Page Close");
        }
    });

    const reloadStatus = async () => {
        let devInfo = { ht: props.type, bdDev_id: props.bdDev_id };
        let lastData = await BdDevFn_Com.loadLastData(devInfo);
        // console.log(lastData);
        let sensorTimeout = BdDevFn_Com.sensorTimeout(lastData);

        let cnAdd = [];

        cssFn.cssShowStatus(false, `pec_${props.bdDev_id}`, "pdc_run_bg");
        cssFn.cssShowStatus(false, `pec_${props.bdDev_id}`, "pdc_run_border");
        cssFn.cssShowStatus(false, `pec_${props.bdDev_id}`, "pdc_error");
        // cssFn.cssShowStatus(false, `pec_${props.bdDev_id}`, "pdc_resetError");
        cssFn.cssShowStatus(false, `pec_${props.bdDev_id}`, "pdc_offline");
        // cssFn.cssShowStatus(false, `pec_${props.bdDev_id}`, "pdc_comError");

        if(sensorTimeout) {
            cnAdd.push("pdc_offline");
            cssFn.cssShowStatus(true, `pec_${props.bdDev_id}`, "pdc_offline");
        // } else if(lastData.pf_2 > 0) { //com error
        //     cssFn.cssShowStatus(true, `pec_${props.bdDev_id}`, "pdc_comError");
        } else{
            // check running
            if(lastData.pb_0 === 1) cnAdd.push("pdc_run_bg");
            // check err
            if(lastData.pb_9 === 1){  // error
                cnAdd.push("pdc_error")
                // cssFn.cssShowStatus(true, `pec_${props.bdDev_id}`, "pdc_error");
            // }else if(lastData.pb_11 === 1){  // resettable err
            //     cssFn.cssShowStatus(true, `pec_${props.bdDev_id}`, "pdc_resetError");
            } else if(lastData.pb_0 === 1) {  // run
                cnAdd.push("pdc_run_border");
                // cssFn.cssShowStatus(true, `pec_${props.bdDev_id}`, "pdc_run_border");
            }
        }

        for(const id of props.idList) {
            cssFn.cssShowStatus(false, id, "pdc_run_bg");
            cssFn.cssShowStatus(false, id, "pdc_run_border");
            cssFn.cssShowStatus(false, id, "pdc_error");
            cssFn.cssShowStatus(false, id, "pdc_offline");
            if(cnAdd.length) {
                for(const cn of cnAdd) {
                    cssFn.cssShowStatus(true, id, cn);
                }
            }
        }
    }

    return ( <div>
        <div id={`pec_${props.bdDev_id}`} className='pdc_nodeCard'>{props.name}</div>
    </div> );
}

export default TpPecLayout;