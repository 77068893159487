import http from "./httpService";

let url = process.env.REACT_APP_PUBLIC_URL+"/users/register";

export async function regUser(user) {
    //   console.log(user);
    const body = {
        username: user.username,
        email: user.email,
        password: user.password,
        name: user.name,
        company: user.company,
        phone: user.phone,
      };

    return await http.post(url, body);
    
  }