import React, { useEffect, useState } from 'react';
import bdDevFn from '../../../../src/function/V1_QueryData/V1_QueryBdDevData/V1_QueryBdDevData';
import { isEmptyArr } from '../../../function/utilities/utilities';
import { toast } from 'react-toastify';
import v1CtrlFn from '../../../../src/function/V1/V1_CtrlOvvFn/V1_CtrlOvvFn';

function TpGatewayPairDialog(props) {
    
    const [G_GwList, setG_GwList] = useState([]);
    const [G_GwSel, setG_GwSel] = useState(0);

    useEffect(()=>{
        async function startUp(){            
            let gwPair = loadGwPair();
            if(props.divInfo){
                let geList = await loadLast10Data(props.divInfo);
                setG_GwList(geList);
                presetOtherInputBox(geList, gwPair);
            }
        }
        startUp();

        return ()=>{
        }
        // eslint-disable-next-line
    }, []);

    const loadGwPair=()=>{
        if(!props.gwPair) return 0
        setG_GwSel(props.gwPair);
        return props.gwPair;
    }

    const handleClose=()=>{
        if(!props.dia_onclikCloseDia) return
        props.dia_onclikCloseDia();
    }

    const loadLast10Data=async(_devInfo)=>{
        try {
            let last10Data = await bdDevFn.getLastNData(_devInfo.type, _devInfo._id, 10);
            if(isEmptyArr(last10Data)) return;
            let GwList = [];
            for (const eachData of last10Data) {
                let found = GwList.find(c=>c === eachData.gwID);
                if(!found) GwList.push(eachData.gwID);
            }            
            return GwList;
        } catch (error) {
            console.log("Load last 10 data Err : ",error.message);
            return []
        }
    }

    const handleManualSet=async()=>{
        try {            
            let manaulId = getManualGwId();
            await setGwId(manaulId);
            // let {type, devID, _id} = props.divInfo;
            // if(!type || !devID || !_id) return toast ("Invalid device info");
            // if(G_GwSel===0 )return toast ("Invalid gateway info");
            // let setGwrel = await v1CtrlFn.setV1_ctrlGwPair(_id, manaulId);
            // if(setGwrel.errMsg) toast (setGwrel.errMsg);
            // if(setGwrel.success) {
            //     if(!props.setGwId) return toast ("Gateway display update error");
            //     props.setGwId(manaulId);
            //     props.dia_onclikCloseDia();
            // }

            // setPairGateway({bdDev_id, gwid})
        } catch (error) {
            console.log("Manual Set Gw Err: ", error.message);
        }
    }

    const setGwId=async(gw_id)=>{
        try {            
            let {type, devID, _id} = props.divInfo;
            if(!type || !devID || !_id) return toast ("Invalid device info");
            if(gw_id===0 )return toast ("Invalid gateway ID");
            let setGwrel = await v1CtrlFn.setV1_ctrlGwPair(_id, gw_id);
            if(setGwrel.errMsg) toast (setGwrel.errMsg);
            if(setGwrel.success) {
                if(!props.setGwId) return toast ("Gateway display update error");
                props.setGwId(gw_id);
                props.dia_onclikCloseDia();
            }
            // setPairGateway({bdDev_id, gwid})
        } catch (error) {
            console.log("Manual Set Gw Err: ", error.message);
        }
    }
    
    const getManualGwId = ()=>{
        let manaulGwId= parseInt(document.getElementById('dialog_inputGwId').value);
        return manaulGwId;
    }

    
    const presetOtherInputBox=(_GwList, gwPair)=>{
        let found  = _GwList.find(c=> c === gwPair);
        if(!found) document.getElementById('dialog_inputGwId').value = gwPair;
    }

    const selGwId=async(gwId)=>{
        await setGwId(gwId);
    }

    return (
        <div>
            <div className='autumn_dialogBoxContainer'>
                <div className='autumn_dialogBox '>  
                    <div className='autumn_closeBtn btnColor_Grey hoverPointer'
                    onClick={handleClose}>
                        <i className=' fa fa-minus'></i>
                    </div>

                    <div className='autumn_InputDialogSection'>
                        <div className='autumn_InputDialogTitle'>
                            Pair Gateway ID
                        </div>
                        <div className=''>
                            {G_GwList.map((u, ind)=>(
                                <div key={`suggestGwKey_${ind}`}
                                className='hoverPointer'
                                onClick={()=>selGwId(u)}>
                                    {G_GwSel !== u && <div className='autumn_ScheSelActionButton btnColor_Grey'>{u}</div>}
                                    {G_GwSel === u && <div className='autumn_ScheSelActionButton btnColor_Green autumn_ScheActionSelected'>{u}</div>}
                                </div>
                            ))} 

                        </div>
                        <div className='autumn_otherIdContainer'>
                            <div className='autumn_dialogSmallTitle'>Others:</div>
                            <div className='sortHor'>
                                <input id='dialog_inputGwId' className='autumn_blueText hoverPointer' type='number'/>
                                <div className='autumn_correctIcon btnColor_Green hoverPointer'
                                onClick={handleManualSet}>
                                    <div className='fa fa-check'></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TpGatewayPairDialog;