import React, { Component } from "react";
// import users from "../../service/userManagement";
import "./table.css";
import Icon from "./../common/icon";
import userService from "../../service/userManage";
import tableUti from "../utilities/tableFunction";
import { ToastContainer, toast } from "react-toastify";

class UserManagement extends Component {
  state = { allUsers: [] };

  async componentDidMount() {
    await this._getSetAllUser();
  }

  _getSetAllUser = async () => {
    try {
      let { data: allUsers } = await userService.getAll();
      // console.log("Get All User : ",allUsers);
      this.setState({ allUsers });
      // console.log(this.state.allUsers[0]);
    } catch (error) {
      console.log("Get User DB Error: ", error.message);
    }
  };

  // Update
  handleSave = async (tableName, stateData) => {
    let modifiedUsers = tableUti.detectTableChanges(
      tableName,
      stateData,
      "username",
      this.returnUser
    );
    // console.log(modifiedUsers);
    for (const userExist of modifiedUsers) {
      if (userExist.teleID === "") {
        userExist.teleID = 0;
      }
      // console.log(`user: ${userExist.teleID}`);
      try {
        let updateStatus = await userService.update(userExist);
        if (updateStatus.status === 200) {
          console.log(`${updateStatus.data.username} update succesful`);
          toast.dark("Updated Successfully!");
        } else {
          console.log(`${updateStatus.data.username} update failed`);
          toast.warn("Failed to Update!");
        }
      } catch (ex) {
        if (ex.response) {
          console.log(ex.response);
        } else {
          console.log(`ex: ${ex}`);
        }
      }
    }
    // set latest user to state
    await this._getSetAllUser();
  };

  handleDelete = async () => {
    // return the array of {username} which check box checked
    let userChecked = tableUti.rawChecked("userTable", this.returnUser);
    // delete the user returned in userChecked
    for (const user of userChecked) {
      try {
        if (user.teleID === "") {
          user.teleID = 0;
        }
        let updateStatus = await userService.delete(user);
        if (updateStatus.status === 200) {
          console.log(`${updateStatus.data.username} delete succesful`);
          toast.dark("Deleted Successfully!");
        } else {
          console.log(`${updateStatus.data.username} delete failed`);
          toast.warn("Failed to Delete!");
        }
      } catch (ex) {
        if (ex.response) {
          console.log(ex.response);
        } else {
          console.log(`ex: ${ex}`);
        }
      }
    }
    // set latest user to state
    await this._getSetAllUser();
  };

  returnUser = (row) => {
    let user = {
      username: row.cells[2].children[0].value,
      company: row.cells[3].children[0].value,
      accessLevel: row.cells[4].children[0].value,
      active: row.cells[5].children[0].value,
      teleID: row.cells[6].children[0].value,
      email: row.cells[7].children[0].value,
      phone: row.cells[8].children[0].value,
    };
    return user;
  };

  render() {
    return (
      <React.Fragment>
        <h1> User Management</h1>
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleSave("userTable", this.state.allUsers)}
        />
        <Icon
          className="fa fa-trash-o deactive"
          onClick={() => this.handleDelete()}
        />
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="userTable">
              <thead>
                <tr>
                  <th scope="col" id="cb"></th>
                  <th scope="col">#</th>
                  <th scope="col">Username</th>
                  <th scope="col">Company</th>
                  <th scope="col">Access Level</th>
                  <th scope="col">Active</th>
                  <th scope="col">Telegram ID</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
                {this.state.allUsers.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input className="checkbox" type="checkbox"></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td>
                      <input type="text" defaultValue={u.username} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.company} />
                    </td>
                    <td>
                      <input type="number" defaultValue={u.accessLevel} />
                    </td>
                    <td>
                      <input type="number" defaultValue={u.active} />
                    </td>
                    <td>
                      <input type="number" defaultValue={u.teleID} />
                    </td>
                    <td>
                      <input type="email" defaultValue={u.email} />
                    </td>
                    <td>
                      <input type="tel" defaultValue={u.phone} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default UserManagement;
