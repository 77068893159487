import React from "react";
import Joi from "joi-browser";
import deviceService from "../../service/deviceManage";
import enm from "../utilities/enum";
import tableFn from "../utilities/tableFunction";
import tool from "../../checkingTools/tools";
import "./deviceReg.css";
import CustomForm from "./../common/customForm";
import Icon from "./../common/icon";

class DeviceRegister extends CustomForm {
  state = {
    allDevices: [],
    buildingEnmObj: [],
    deviceEnmObj: [],
    data: {
      type: 1,
      devID: "",
      battConst: "0",
      sleepAmp: "0",
      buildingID: "0",
      SimNumber: "N/A",
    },
    errors: {},
  };

  schema = {
    type: Joi.number().required().label("Type"),
    devID: Joi.number().required().label("Device ID"),
    battConst: Joi.number().label("Battery Constant"),
    sleepAmp: Joi.number().label("Sleeping Amp"),
    buildingID: Joi.number().label("Building"),
    SimNumber: Joi.string().label("Sim Number"),
  };

  async componentDidMount() {
    await this._getBuildingEnmList();
    await this._getallDevices();
    await this._getDeviceEnmList();
    // console.log(this.state.deviceEnmObj);
  }

  _getDeviceEnmList = async () => {
    let deviceEnmObj = await enm.getDeviceEnmObj();
    // console.log(deviceEnmObj);
    await this.setState({ deviceEnmObj });
  };

  _getBuildingEnmList = async () => {
    let result = await enm.getBuildingEnmObj();
    this.setState({ buildingEnmObj: result });
    // console.log(this.state.buildingEnmObj);
  };

  _getallDevices = async () => {
    try {
      let { data: allDevices } = await deviceService.getAll();
      this.setState({ allDevices });
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  buildingSelChange = () => {
    const selBD = document.getElementById("bdSel").value;
    let data = { ...this.state.data };
    data.buildingID = selBD;
    this.setState({ data });
    // console.log(`Change: ${selBD}`);
  };

  typeSelChange = () => {
    const sel = document.getElementById("tySel").value;
    let data = { ...this.state.data };
    data.type = sel;
    this.setState({ data });
    console.log(`Change: ${sel}`);
  };

  doSubmit = async () => {
    try {
      let type = document.getElementById("tySel").value;
      let devID = document.getElementById("devID").value;
      console.log(`type: ${type}`);
      console.log(`devID: ${devID}`);
      // check owner and building name are not duplicated
      // eslint-disable-next-line
      const duplicated = this.state.allDevices.filter(
        // eslint-disable-next-line
        (b) => b.type == type && b.devID == devID
      );

      if (duplicated[0])
        return console.log(
          `Duplicated: ${duplicated[0].type}, ${duplicated[0].devID}`
        );

      console.log(`Continue`);

      let data = this.state.data;
      console.log(data);

      let response = await deviceService.add(this.state.data);
      console.log(response);

      window.location = "/regdevice";
    } catch (ex) {
      tool.exResponse(ex);
    }
  };

  changePage = () => {
    this.props.history.push("/regdevice");
  };

  render() {
    return (
      <React.Fragment>
        <Icon
          className="fa fa-arrow-left deactive back"
          onClick={this.changePage}
        />
        <div className="devregcont">
          <div className="devregcard">
            <h1>Register Device</h1>
            <form onSubmit={this.handleSubmit} className="form-devreg">
              {/* {this.renderInput("type", "Type")} */}
              <label>Type</label>
              <div className="devtype-dd">
                {tableFn.tableDropDownKeySel(
                  `tySel`,
                  this.state.deviceEnmObj,
                  1,
                  "select-dd",
                  this.typeSelChange
                )}
              </div>
              {this.renderCustomInput("devID", "Device ID")}
              {this.renderCustomInput("battConst", "Battery Constant")}
              {this.renderCustomInput("sleepAmp", "Sleeping Amp")}
              <label>Building</label>
              <div className="build-dd">
                {tableFn.tableDropDownKeySel(
                  `bdSel`,
                  this.state.buildingEnmObj,
                  0,
                  "select-dd",
                  this.buildingSelChange
                )}
                {/* {tableFn.dropDown(
                  `bdSel`,
                  this.state.buildingEnmObj,
                  0,
                  "select-dd",
                  this.buildingSelChange
                )} */}
              </div>
              {this.renderCustomInput("SimNumber", "Sim Number")}
              {/* {this.renderInput("accessLevel", "Access Level")}
                {this.renderInput("userAmmend", "Last Ammend By")} */}
              <div align="center">{this.renderCustomButton("Register")}</div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DeviceRegister;
