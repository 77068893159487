import loraFn from '../../function/genLoRaPackage';
import ctrlDevFn from '../../../src/component/utilities/conntrolDevice/ctrlDevFn';
import { isEmptyArr } from '../utilities/utilities';

const mqtt_ctrlTopic = "Gw/ServerCmd/";

async function v2_CtrlCmd(loraFunction, gwid, devInfo, payload, brokerSelected){
    // let mqttCtrlTopit = "Gw/ServerCmd/";        // from constant
    // let loraFunction = 2;
    try {
        let _topic = `${mqtt_ctrlTopic}${gwid}`;
        // console.log("_topic:", _topic);
        let{type, devID} = devInfo;
        if(!type) return {errMsg:"Invalid Device Type"};
        if(!devID) return {errMsg:"Invalid Device ID"};
        let devDetails={
            devType:type,
            id:devID,
            dir:3,
            fun:loraFunction
        }
        // 
        // let payload={
        //     pi:[1,1]
        // }

        let loraPackage =loraFn.genLoRaPackage(devDetails, payload, 2);     // 2 is version format
        if(loraPackage.error) return {errMsg:"Generate data package error"};
        loraPackage.gwid = gwid;
        let sendCmdRel = await ctrlDevFn.v2sendCtrlCmd(_topic, loraPackage, brokerSelected);        
        // console.log("sendCmdRel : ", sendCmdRel);
        return {success:sendCmdRel.success, loraPackage};
    } catch (error) {
        return {errMsg:`Exp Err : ${error.message}`};
    }

}

const validateNodeReply=(mqttMsg, cmdLog)=>{
    try {
        if(!mqttMsg.ht) return false
        if(!mqttMsg.hi) return false
        if(!mqttMsg.hf) return false
        if(!mqttMsg.hct) return false
        if(!mqttMsg.ft) return false
        
        // console.log("mqttMsg.ht :", mqttMsg.ht);
        // console.log("mqttMsg.hi :", mqttMsg.hi);
        // console.log("mqttMsg.hf :", mqttMsg.hf);
        // console.log("mqttMsg.hct :", mqttMsg.hct);
        // console.log("mqttMsg.ft :", mqttMsg.ft);
        
        let _G_CmdLog = {...cmdLog};
        // console.log("G_CmdLog", _G_CmdLog);
        if(_G_CmdLog.ht === mqttMsg.ht && _G_CmdLog.hi === mqttMsg.hi &&
            _G_CmdLog.hf === mqttMsg.hf && _G_CmdLog.hct === mqttMsg.hct &&
            _G_CmdLog.ft === mqttMsg.ft) {
                /** Command Sent Success  */
                return true;
            }
             
    } catch (error) {
        console.log("validateNodeReply Err : ", error.message);
        return false
    }
}


const checkCmdStatus_OnTimeout=async(cmdLogCurr)=>{
    // console.log("Timeout triggered");
    try {
        let cmdLog = await ctrlDevFn.v2getCmdLog(cmdLogCurr);
        // console.log("cmdLog", cmdLog);
        if(isEmptyArr(cmdLog)) return {comErr:true, errMsg:'DB Error'};

        if(cmdLog[0].GwAck ===0 && cmdLog[0].NodeAck === 0){
            return {comErr:true, errMsg:'Gateway failed'}
            // toast('Gateway Not Response');
        }else if(cmdLog[0].NodeAck === 0){
            return {comErr:true, errMsg:'Node failed'}
            // toast('Node Not Response');
        }
        return {comErr:true, errMsg:'Error'};
    } catch (error) {
        return {comErr:true, errMsg:'Exp Error'};
    }
}



const expModule = {  
    v2_CtrlCmd,
    validateNodeReply,
    checkCmdStatus_OnTimeout
};
export default expModule;