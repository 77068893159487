import React from "react";
import CustomForm from "./../common/customForm";
import Joi from "joi-browser";
import tool from "../../checkingTools/tools";
import Icon from "./../common/icon";
import tableFn from "../utilities/tableFunction";
import bdDevFn from "../utilities/getDevices";
import notifyFn from "../utilities/notificaton/notificationList";
import auth from "../../service/authService";
import timeFn from "../utilities/timeFn";
import "./notification.css";
import sensorFn from '../../function/sensor/sensorManagementFn';
import sensorKeyFn from '../utilities/sensorKey';

class RegNotifyPage extends CustomForm {
  state = {
    bdDevsEnum: [],
    targetEnum: [],
    bdDevInBd: [],
    currentBdID: 0,
    currentType: 0,
    currentDataKey: "",
    data: {
      // name: "",
      // userID: 0,
      // type: "1",
      // bdDev_id: "0",
      // DataKey: "",
      // DataUnit: "",
      UserSetpoint: 0,
      // AlarmSetpoint: 0,
      // AlarmType: "1",
      // StartUnix: 0,
      // EndUnix: 0,
      // AlarmRepeat: 1,
      Sensitivity: 1,
      // Active: 1,

      // type: "1",
      // devID: "0",
      // location: "",
      // name: "",
      // buildingID: "0",
      // remarks: "",
      // active: 1,
    },
    errors: {},
    newSensorParasInUse:[],

  };

  async componentDidMount() {
    await this.setState({ currentBdID: this.props.match.params.bdID });
    await this.getBdDevEnum();
    await this.getTargetEnum();
    await this.getUnitEnum();
    // console.log(this.state.currentBdID);
  }

  async getBdDevEnum() {
    let bdDevInBd = await bdDevFn.getBdDevBybdID(this.state.currentBdID);
    // console.log(bdDevInBd);    
    await this.setState({ bdDevInBd });

    let sensorTyInBd = new Set(bdDevInBd.map(item => item.type));
    console.log(sensorTyInBd);
    let newSensorParasInUse = await sensorFn.getParaByTypeList([...sensorTyInBd]);
    this.setState({newSensorParasInUse});
    console.log(newSensorParasInUse);
    let bdDevsEnum = bdDevFn.turnBdDevIntoEnm(bdDevInBd);
    // console.log(bdDevsEnum);
    await this.setState({ bdDevsEnum });
  }

  getTargetEnum() {
    /**------------get node type---------------*/
    let selBdDevId = document.getElementById("devSel").value;
    // console.log(selBdDevId);
    if (!selBdDevId) return;
    let bdDevInBd = [...this.state.bdDevInBd];
    // eslint-disable-next-line
    let found = bdDevInBd.find((c) => c._id == selBdDevId);
    if (!found || !found.type) return;
    let selType = found.type;
    this.setState({ currentType: selType });
    let targetEnum = sensorKeyFn.getKeyListByType_V2(this.state.newSensorParasInUse , selType);
    if(!targetEnum[0]) targetEnum = sensorKeyFn.getKeyListByType(selType);
    // let targetEnum = notifyFn.genCurrentKeyList(selType);

    console.log(targetEnum);
    this.setState({ targetEnum });
  }

  getUnitEnum() {
    let currentDataKey = document.getElementById("targetSel").value;
    this.setState({ currentDataKey });
    // console.log(currentDataKey);
  }

  schema = {
    // name: Joi.string().max(80).label("Name"),
    userID: Joi.number().label("Device ID"),
    type: Joi.number().label("Type"),
    bdDev_id: Joi.number().label("Building Device ID"),
    DataKey: Joi.string().max(80).label("Data Key").allow(null, ""),
    DataUnit: Joi.string().max(80).label("Unit").allow(null, ""),
    UserSetpoint: Joi.number().label("Building"),
    // AlarmSetpoint: Joi.number().label("Building"),
    AlarmType: Joi.string().label("Alarm Type").allow(null, ""),
    StartUnix: Joi.number().label("Start Time"),
    EndUnix: Joi.number().label("Start Time"),
    AlarmRepeat: Joi.number().label("Recurring"),
    Sensitivity: Joi.number().label("Sensitivity"),
    Active: Joi.number().label("Active"),

    // location: Joi.string().label("Location").allow(null, ""),
    // buildingID: Joi.number().label("Building"),
    // active: Joi.number().label("Active"),
    // remarks: Joi.string().label("Remark").allow(null, ""),
  };

  changePage = () => {
    // console.log(this.props.history);
    this.props.history.push("/notification/list/" + this.state.currentBdID);
  };

  devSelChange = async () => {
    await this.getTargetEnum();
    await this.getUnitEnum();
  };

  targetSelChange = async () => {
    await this.getUnitEnum();
  };

  doSubmit = async () => {
    try {
      console.log(`Continue`);

      let data = { ...this.state.data };
      data.type = this.state.currentType;
      data.bdDev_id = document.getElementById("devSel").value;
      data.DataKey = this.state.currentDataKey;
      data.AlarmType = document.getElementById("alarmTypeSel").value;
      // data.DataUnit = document.getElementById("unitSel").value;
      data.StartUnix = timeFn.cHH_mmToUnix(
        document.getElementById("startTime_i").value
      );
      data.EndUnix = timeFn.cHH_mmToUnix(
        document.getElementById("endTime_i").value
      );
      data.AlarmRepeat = document.getElementById("recurringSel").value;
      let userID = document.getElementById("privacySel").value;
      // eslint-disable-next-line
      if (userID != 0) {
        data.userID = auth.getCurrentUser()._id;
      } else {
        data.userID = 0;
      }
      let selectedKey = this.state.targetEnum.find(
        (c) => c._id === data.DataKey
      );
      if (selectedKey && selectedKey.name) {
        data.name = selectedKey.name;
      } else {
        data.name = "";
      }
      // data.AlarmSetpoint = notifyFn.getConversionFormula(
      //   data.type,
      //   data.DataKey,
      //   data.DataUnit,
      //   data.UserSetpoint
      // );
      data.AlarmSetpoint = data.UserSetpoint;
      data.Active = 1;

      console.log(data);

      let response = await notifyFn.reg(data);
      if (!response) return console.log("Register Failed");
      //   console.log(response);

      window.location = `/notification/list/${this.state.currentBdID}`;
    } catch (ex) {
      tool.exResponse(ex);
    }
  };

  render() {
    // let data = { ...this.state.data };

    return (
      <React.Fragment>
        <Icon
          className="fa fa-arrow-left deactive back"
          onClick={this.changePage}
        />
        <div className="regNotif">
          <div className="regNotifCard">
            <h1>Notification</h1>
            <form onSubmit={this.handleSubmit} className="form-regNotif">
              {/* {this.renderInput("type", "Type")} */}
              <div className="row">
                <div className="column one">
                  <label>Device</label>
                  <div className="device-dd">
                    {tableFn.dropDown(
                      `devSel`,
                      this.state.bdDevsEnum,
                      1,
                      "select-dd",
                      this.devSelChange
                    )}
                  </div>
                </div>
                <div className="column">
                  <label>Target</label>
                  {/* {this.renderInput("devID", "ID")} */}
                  <div className="target-dd">
                    {tableFn.dropDown(
                      `targetSel`,
                      this.state.targetEnum,
                      1,
                      "select-dd",
                      this.targetSelChange
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <label className="type">Type</label>
                  {/* <label className="devid">Target</label> */}
                  <div className="type-dd">
                    {tableFn.dropDown(
                      `alarmTypeSel`,
                      notifyFn.notifyConditionList,
                      1,
                      "select-dd",
                      this.targetSelChange
                    )}
                  </div>
                </div>
                <div className="column">
                  <label className="unit">Unit</label>
                  {/* <label className="devid">Target</label> */}
                  <div className="unit-dd">
                    {sensorFn.getParaByKey(this.state.newSensorParasInUse, this.state.currentType, this.state.currentDataKey, 'dataUnit') || "<Blank>"}
                    {/* {tableFn.dropDown(
                      `unitSel`,
                      notifyFn.getCurerntUnitList(
                        this.state.currentType,
                        this.state.currentDataKey,
                        this.state.newSensorParasInUse,
                      ),
                      1,
                      "select-dd"
                      // this.targetSelChange
                    )} */}
                  </div>
                </div>
              </div>
              {this.renderCustomInput("UserSetpoint", "User Setpoint")}
              <div className="row">
                <div className="column">
                  <label className="starttime">Start Time</label>
                  <div>
                    <input
                      id="startTime_i"
                      type="time"
                      defaultValue="08:00"
                    ></input>
                  </div>
                </div>
                <div className="column">
                  <label className="endtime">End Time</label>
                  <div>
                    <input
                      id="endTime_i"
                      type="time"
                      defaultValue="22:00"
                    ></input>
                  </div>
                </div>
              </div>
              {this.renderCustomInput("Sensitivity", "Sensitivity")}
              <div className="row">
                <div className="column">
                  <label className="recur">Recurring</label>
                  <div className="recur-dd">
                    {tableFn.dropDown(
                      `recurringSel`,
                      notifyFn.notifyRecurringList,
                      2,
                      "select-dd"
                      // this.targetSelChange
                    )}
                  </div>
                </div>
                <div className="column">
                  <label className="privacy">Privacy</label>
                  <div className="privacy-dd">
                    {tableFn.dropDown(
                      `privacySel`,
                      notifyFn.notifyPrivacyList,
                      0,
                      "select-dd"
                      // this.targetSelChange
                    )}
                  </div>
                </div>
              </div>
              {/* {this.renderCustomInput("location", "Location")}                  
                  {this.renderCustomInput("remarks", "Remark")} */}
              <div align="center">{this.renderCustomButton("Register")}</div>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegNotifyPage;
