import http from "./httpService";

async function getLastNData(ty, bdDevID, nCnt){
  // let url = process.env.REACT_APP_PUBLIC_URL+"/api/bddev/min/";    
let url = `${process.env.REACT_APP_PUBLIC_URL}/api/bddev/n/${ty}/${bdDevID}/${nCnt}`;
return await http.get(url);     
}

async function getLastNMin(ty, bdDevID, min){
    // let url = process.env.REACT_APP_PUBLIC_URL+"/api/bddev/min/";    
  let url = `${process.env.REACT_APP_PUBLIC_URL}/api/bddev/min/${ty}/${bdDevID}/${min}`;
  return await http.get(url);     
}

async function getTimeToTime(ty, bdDevID, t1, t2){
  // let url = process.env.REACT_APP_PUBLIC_URL+"/api/bddev/min/";    
  let url = `${process.env.REACT_APP_PUBLIC_URL}/api/bddev/ttotasc/${ty}/${bdDevID}/${t1}/${t2}`;
  return await http.get(url);     
}


const expModule = {  
    getLastNData,
    getTimeToTime,
    getLastNMin,
    // delete: deleteUser,
};
export default expModule;