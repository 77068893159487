import senOwnSer from "../../service/V2_SensorDevice/V2_SensorOwner";

async function getBy_ty_devIdList(...rest) {
    // console.log("Cone In");
    try {
      let rel = await senOwnSer.getBy_ty_devIdList(...rest);
      // console.log(rel);
      if (rel.statusText === "OK") {
        if (rel.data.length > 0) return rel.data;
        return [];
      }
      return { msg: "DB Err", data: rel.data };
    } catch (error) {
      console.log(error.message);
      return { msg: "DB Err. (Exp)" };
    }
}

async function getBdInfoBy_idList(bd_idList) {
    try {
      let rel = await senOwnSer.getBdInfoBy_idList(bd_idList);
      return rel.data;
    } catch (error) {
      console.log(error.message);
      return { errMsg: "Server Error" };
    }
  }

const expModule = {
    getBy_ty_devIdList,
    getBdInfoBy_idList
};

export default expModule;