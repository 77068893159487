// import React from "react";
import moment from "moment";
import "moment-timezone";

function getUnixNow(){
  return moment().unix();
}

function getUnixNowForCRC(){
  return (moment().unix() - 1600000000);
}


function getUnixDate(unixTime) {
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format("YYYY-MM-DD");
}

function getUnixDate_Fmt2(unixTime) {
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format("DD/MM/YYYY");
}


function getUnixTime(unixTime, b12h, bWithSS) {
  let tss = "";
  if (bWithSS) tss = ":ss";
  if (b12h) return moment(unixTime*1000).tz("Asia/Kuala_Lumpur").format(`hh:mm${tss} A`);
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format(`HH:mm${tss}`);
}

function cHH_mmToUnix(HHmm) {
  let sDate = moment().tz("Asia/Kuala_Lumpur").format("MM/DD/YYYY");
  // console.log(sDate);
  // let sDate = getUnixDate(now().unix);
  return moment(`${sDate} ${HHmm}`).tz("Asia/Kuala_Lumpur").unix();
}

function getUnix_startDate(sDate) {
  return moment(`${sDate} 00:00`).tz("Asia/Kuala_Lumpur").unix();
}

function getUnix_endDate(sDate) {
  return moment(`${sDate} 24:00`).tz("Asia/Kuala_Lumpur").unix();
}


function fGetTimeDiff_InDTMS(_start, _end){
  let start ;
  let end ;
  if(_start > _end){
      start = _end;
      end = _start;
  }else{
      start = _start;
      end = _end;
  }
  if(start === null) return "inf";
  let tDiff = end - start;
  let sTime = '';
  let _d = parseInt(tDiff/86400);
  let _remain = tDiff%86400;
  let _h = parseInt((_remain)/3600);
  _remain = _remain%3600;
  let _m = parseInt((_remain/60));
  _remain = _remain%60;
  // let _s = _remain;

  if (_d>0) sTime = `${_d}day `;
  // if (_d>1) sTime = sTime+`${_d}days `;
  if (_h>0) sTime = sTime+`${_h}hour `;
  if (_m>0) sTime = sTime+`${_m}min `;
  if(sTime ==='') sTime = '1min';
  // if (_s>0) sTime = sTime+`${_s}s `;
  // console.log(sTime);
  return sTime

}

function getTimeInInt_nonTz(unix_ms){
    let nHH = parseInt(moment(unix_ms).tz("Africa/Accra").format('HH'));
    let nMM = parseInt(moment(unix_ms).tz("Africa/Accra").format('mm'));

    return (nHH*100 + nMM);
}

function getDateInInt_nonTz(unix_ms){
  let nYY = parseInt(moment(unix_ms).tz("Africa/Accra").format('YY'));
  let nMM = parseInt(moment(unix_ms).tz("Africa/Accra").format('MM'));
  let nDD = parseInt(moment(unix_ms).tz("Africa/Accra").format('DD'));

  return (nYY*10000 + nMM*100 + nDD);
}


function getEventScheTimeFormat(unixTime) {
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format("D MMM YYYY, HH:mm");
}

function getWeeklyScheTimeFormat(unixTime) {
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format("HH:mm");
}

function date_time_toUnix(date, time){
  return moment(`${date} ${time}`).tz("Asia/Kuala_Lumpur").unix();
}


function time_toUnix(time) {
  let sDate = moment().tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD");
  // console.log(sDate);
  // let sDate = getUnixDate(now().unix);
  return moment(`${sDate} ${time}`).tz("Asia/Kuala_Lumpur").unix();
}



const expModule = {
  getDateInInt_nonTz,
  fGetTimeDiff_InDTMS,
  getUnixNow,
  getUnixNowForCRC,
  cHH_mmToUnix,
  getUnix_startDate,
  getUnix_endDate,
  getUnixDate,
  getUnixTime,
  getTimeInInt_nonTz,
  getUnixDate_Fmt2,
  getEventScheTimeFormat,
  getWeeklyScheTimeFormat,
  date_time_toUnix,
  time_toUnix,
};
export default expModule;
