import telegramIDService from '../../../service/notification/telegramID';
import tool from '../../../checkingTools/tools'

async function getAll(){
    try {
        let result = await telegramIDService.getAll();
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("getOwnerBuildings Err");
        tool.exResponse(error);
        return null;
    }
}

async function add(obj){
    try {
        let result = await telegramIDService.add(obj);
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("Add Telegram ID Err");
        tool.exResponse(error);
        return null;
    }
}

async function del(obj){
    try {
        let result = await telegramIDService.del(obj);
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("Add Telegram ID Err");
        tool.exResponse(error);
        return null;
    }
}

const expModule = {  
    del,
    add,
    getAll,
};
export default expModule;