import React, { useState, useEffect } from 'react'

function TpDropdownTypeList(props) {
    const [G_typeList, setG_typeList] = useState([]);
    const [G_filteredTypeList, setG_filteredTypeList] = useState([]);
    // const [G_loaded, setG_loaded] = useState(false);

    useEffect(() => {
        async function startUp() {
            if(!props.typeList) return;

            setG_filteredTypeList(props.typeList);
            setG_typeList(props.typeList);
            // setG_loaded(true);
        }
        startUp();
    }, [props]);

    const handleSearch = (input_id) => {
        let _inputValue = document.getElementById(input_id).value;
        // console.log(_inputValue);
        if(_inputValue === "") {
            setG_filteredTypeList(G_typeList);
            return;
        }
        let filtered1 = G_typeList.filter(c => c.name.toUpperCase().includes(_inputValue.toUpperCase()));
        let filtered2 = G_typeList.filter(c => c.type.toString().includes(_inputValue.toString()));

        let filtered = [...filtered1, ...filtered2];
        setG_filteredTypeList(filtered);
    }

    const toggleTypeList = (idx) => {
        if(props.toggleTypeList) props.toggleTypeList(idx);
    }

    const handleSelType = (typeInfo, idx) => {
        if(props.handleSelType) props.handleSelType(typeInfo, idx);
    }

    return ( 
        <div>
            <input style={{ width: '100%' }} type="text" id={`v2_devType_${props.ind}`} onChange={() => handleSearch(`v2_devType_${props.ind}`)} onClick={() => toggleTypeList(props.ind)}></input>
            {/* <input type="text" id={`v2_devType_${props.ind}`} onChange={() => handleSearch(`v2_devType_${props.ind}`)} onFocus={() => toggleTypeList(props.ind, true)} onBlur={() => toggleTypeList(props.ind, false)}></input> */}
            {props.disTypeList && <div className='dev_searchbar_dropdown'>
                {G_filteredTypeList.map((v, idx) => (
                    <div key={idx} onClick={() => handleSelType(v, props.ind)}>{v.name} [{v.type}]</div>
                ))}
            </div>}
        </div>
     );
}

export default TpDropdownTypeList;