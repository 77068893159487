import jwtDecode from "jwt-decode";
import http from "./httpService";

const tokenKey = "token";
let url = process.env.REACT_APP_PUBLIC_URL+"/users/login";

http.setJwt(getJwt());

// through user login page
export async function login(user) {
    const body = {
        username: user.username,
        password: user.password,
    };
    const { data: jwt } = await http.post(url, body);
    // console.log("registerUrl: ", registerUrl);
    // console.log("jwt: ", jwt);
    // console.log(token);
    await localStorage.setItem(tokenKey, jwt);
}

// After user register, direct loging
export function loginWithJwt(jwt){
    localStorage.setItem(tokenKey, jwt);
}

//logout, clear token key in localStorage
export function logout() {
    localStorage.removeItem(tokenKey);
}

//Check user using token
export function getCurrentUser() {
    try {
      const jwt = localStorage.getItem(tokenKey);
      return jwtDecode(jwt);
      // console.log(user);
    } catch (error) {
      return null;
    }
  }
  
export function getJwt() {
  return localStorage.getItem(tokenKey);
}


const expModule = {  
  login, 
  logout, 
  loginWithJwt, 
  getCurrentUser, 
  getJwt
};
export default expModule;

// export default { 
//   login, 
//   logout, 
//   loginWithJwt, 
//   getCurrentUser, 
//   getJwt
// };