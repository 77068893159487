import React, { useEffect, useState } from 'react';
import { scheAction } from '../Setting/ScheduleConstant';

function TpScheActionSelDialog(props) {
    // const G_SelAction = 3;

    const [G_SelAction, setG_SelAction] = useState(0);

    useEffect(()=>{
        async function startUp(){
            // console.log(props.selScheInfo);
            setSelectedAction();
        }
        startUp();

        return ()=>{
            
        }
        // eslint-disable-next-line
    }, []);

    const setSelectedAction = ()=>{
        setG_SelAction(props.selScheInfo.action);
    }

    const handleCloseDialog = ()=>{
        try {
            if(!props.dia_onclikCloseDia) return;
            props.dia_onclikCloseDia();            
        } catch (error) {
            console.log("Close Dialog Err:", error.message);
        }
    }

    const handleSelAction=(actionSelected)=>{
        try {
            if(!props.dia_onClikActionSel) return;
            let {scheType, scheIdx} = props.selScheInfo;
            props.dia_onClikActionSel(scheType, scheIdx, actionSelected.nType);      
        } catch (error) {
            console.log("Sel Action Err:", error.message);
        }
    }
    

    return (
        <div>
            <div className='autumn_dialogBoxContainer'>
                <div className='autumn_dialogBox '>  
                    <div className='autumn_closeBtn btnColor_Grey hoverPointer'
                    onClick={handleCloseDialog}>
                        <i className=' fa fa-minus'></i>
                    </div>
                    <div className='autumn_ScheSelActionButtonContainer'>
                        {scheAction.map((u, ind)=>(
                            <div key={`actKet_${ind}`}
                            onClick={()=>handleSelAction(u)}>
                                {G_SelAction !== u.nType && <div className='autumn_ScheSelActionButton btnColor_Grey'>{u.name}</div>}
                                {G_SelAction === u.nType && <div className='autumn_ScheSelActionButton btnColor_Green autumn_ScheActionSelected'>{u.name}</div>}
                            </div>
                        ))} 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpScheActionSelDialog;