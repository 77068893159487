import http from "../httpService";


async function regBdDev_V2(body) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/devmngv2/regbddev";
    return await http.post(url, body);  
}

async function getInvolveBdDev_2(body) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/devmngv2/getinvolvedev";
    return await http.post(url, body);  
}

async function getOwnDev_2(body) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/devmngv2/getowndev";
    return await http.post(url, body);  
}


const expModule = {  
    getOwnDev_2,
    regBdDev_V2,
    getInvolveBdDev_2,

};
export default expModule;