import http from "./httpService";


async function add(obj) {
    // let url = process.env.REACT_APP_PUBLIC_URL+"/linelist/add";
    //   console.log(obj);
    // const body = {   
    //     type: obj.type,
    //     bdDev_id: obj.bdDev_id,
    //     name: obj.name,
    //     scaleTop: obj.scaleTop,
    //     scaleBottom: obj.scaleBottom,
    //     upperLimit: obj.upperLimit,
    //     lowerLimit: obj.lowerLimit,
    //     dataKey: obj.dataKey,
    //     dataUnit: obj.dataUnit,
    //     userID: obj.userID,
    //   };
    //   // console.log("did enter");
    // return await http.post(url, body); 
  }
  

  async function getBy2id(obj) {
    let url = process.env.REACT_APP_PUBLIC_URL+"/graphList/getby2id";
      // console.log(obj);
      const body = {   
          userID: obj.userID,
          pageID: obj.pageID,
          bdDev_id:obj.bdDev_id,
        };
      // console.log("did enter");
    return await http.post(url, body); 
  }
  
  

const expModule = {  
    add,
    getBy2id,
    // delete: deleteUser,
};
export default expModule;
