import http from "../httpService";

async function insertSche(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/acctrl/insertsche";
    // let body = obj;
    return await http.post(url, obj);  
  }
  
async function getSche_byBdDevId(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/acctrl/getschebyid";
    // let body = obj;
    return await http.post(url, obj);  
  }

async function updateScheduleList(obj) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/acctrl/updateschelist";
  // let body = obj;
  return await http.post(url, obj);  
}

async function deleteScheduleList(obj) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/acctrl/deleteschelist";
  // let body = obj;
  return await http.post(url, obj);  
}


const expModule = {  
  deleteScheduleList,
  updateScheduleList,
  insertSche,
  getSche_byBdDevId
};
export default expModule;