import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';


async function zipFiles(filename, urls){
    const zip = new JSZip();
    // let count = 0;
    const zipFilename = filename+".zip";

    try {
        for (const url of urls) {
            // count++;
            let filename = `${url.sensor}_${url.location}_${url.name}.xlsx`;
            const file = await JSZipUtils.getBinaryContent(url.link)
            zip.file(filename, file, { binary: true});
        }         
        // zip.generateAsync({type:'blob'}).then(function(content) {
        //     saveAs(content, zipFilename)
        // })
        let zipContent = await zip.generateAsync({type:'blob'});
        // console.log(zipContent);
        let result = await saveAs(zipContent, zipFilename);
        // console.log(result);
        return result;

    } catch (error) {
        console.log(error);
    }

}


export default zipFiles;