import React, { Component } from 'react';
import './addNewAcSchePage.css';
// import V2_InputTy_1 from './../../../V2Component/inputField/InputTy1';
import ToggleButton from './../toggleButton/toggleButton';
// import { FaHourglassEnd } from 'react-icons/fa';
import cssFn from '../../utilities/css/cssFunction';
import { ToastContainer, toast } from "react-toastify";
import bitFn from '../../../function/bitFn/bitFn';
// import moment from "moment";
import timeFn from '../../utilities/timeFn';
import acControlFn from '../../utilities/conntrolDevice/acControlFn';
import acCtrlFunction from '../Fun/acCtrl_Function';

import loraPac from "../../../function/genLoRaPackage";
import controlDevFn from "../../utilities/conntrolDevice/ctrlDevFn";

class AddNewAcSchePage extends Component {
    state = { 
        bdDev_id : 0,
        devID : 0, 
        gwID : 0,
        dataAction:1,
        weeklySche:0,
        bloadButton:true,
        bHideSP:true,
        bHideEndTime:true,
        bHideWeekly:false,
     }

    async componentDidMount() {
        // console.log(this.props.location._data); 
        if(!await acCtrlFunction.loadRequiredInitValue(this.props.location._data, ['devID', 'bdDev_id', 'gwID'], this.fSetState, this.fChangePage)) return;
        await this.updateFieldDisplay(this.state.dataAction);
            // return;
        // }
        /** Load bdDevId from global storage*/
    }
    
    fChangePage=()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac/logindev",
            _data: {
            // _selBuildingId: this.state.selBuildingId,
            // _selT1Llist:tableMonList[ind]._id,
            },
        });
    }

    fSetState=async(name, value)=>{
        await this.setState({[name]:parseInt(value)});
    }

    selWeeklySche=async ()=>{
        // console.log("Click Weekly");
        this.setState({bloadButton:false});
        let weeklySche = 0;
        if(this.state.weeklySche === 0){
            weeklySche = 1;
        }
        await this.setState({weeklySche});
        // console.log(this.state.weeklySche);
        this.setState({bloadButton:true});
        
        cssFn.cssShowStatus(this.state.weeklySche === 0, 'dowBox', "hideWeeklySel");        
    }

    disableInput=()=>{
        return false;
    }
    
    selAction=async ()=>{
        // console.log('selAction');
        let dataAction = parseInt(document.getElementById('scheAction').value);       
        // console.log(dataAction);
        await this.setState({dataAction});
        // console.log(this.state.dataAction);

        await this.updateFieldDisplay(dataAction);
    }

    updateFieldDisplay=async (action)=>{
        /** hide end time if Action = 1,3,5 */
        let bHideEndTime=false;
        if(action === 1 || action === 3 || action === 5 ){
            bHideEndTime=true;
        }
        await this.setState({bHideEndTime});

        /** hide Setpoint if Action = 1,2,5,6 */
        let bHideSP = false;
        if(action === 1 || action === 2 || action === 5 || action === 6){
            bHideSP = true;
        }
        await this.setState({bHideSP});

        /** hide repeat weekly if action = 6 */
        let bHideWeekly = false;
        if(action === 6){
            bHideWeekly = true;
        }
        await this.setState({bHideWeekly});

        cssFn.cssShowStatus(this.state.bHideEndTime, 'endTimeField', "collapseField");
        cssFn.cssShowStatus(this.state.bHideSP, 'spField', "collapseField");
        cssFn.cssShowStatus(this.state.bHideWeekly, 'weeklyField', "collapseField");
        cssFn.cssShowStatus(this.state.bHideWeekly, 'dowBox', "collapseField");
    }

    clickSave=async ()=>{
        // console.log('Save');
        /** Add to database */
        let info = this.getTableInfo();
        if(!info) return console.log('Invalid input');
        // console.log(info);
        
        let dow = 0;
        for (let i = 0; i < 7; i++) {
            if (info.dow[i]) dow = bitFn.setBit(dow, i);            
        }

        info.bdDev_id = this.state.bdDev_id;
        info.ndow = dow;
        info.startUnix = info.startUnix_ms/1000;
        info.endUnix = info.endUnix_ms/1000;

        let rel = await acControlFn.insertSche(info);
        if(!rel) return toast.error("Connection Unstable");
        // console.log(rel);
        
        /** Save succesful, get all schedule from database */
        let allSche = await acControlFn.getScheBy_beDevid({bdDev_id:this.state.bdDev_id});
        // console.log(allSche);
        /** Filter schedule type */
        
        let sameTypeSche = allSche.filter(c=>(c.repeatWeekly === info.repeatWeekly));
        // console.log(sameTypeSche);

        /** Send LoRa command to Node */
        let dataStream = [];
        let loRaFn = 4;
        if(sameTypeSche[0].repeatWeekly){   // weekly
            for (const weekSche of sameTypeSche) {
                let tempWArr = acCtrlFunction.convertWeekScheToRaw(weekSche);
                dataStream.push(...tempWArr);
            }
        }else{      // Event
            for (const eventSche of sameTypeSche) {
                let tempEArr = acCtrlFunction.convertEventScheToRaw(eventSche);
                dataStream.push(...tempEArr);
            }
            loRaFn = 5;
        }

        // console.log(dataStream);
        /** Send LoRa */
        
        let payload = {};
        let devDetails = {
            devType: 16, // VFD type - = 7
            id: this.state.devID,  /** Value 1 need to change later */
            dir: 2, // from gateway to node
            fun: loRaFn, // function code, 1=> set parameter 1
        };

        // payload.pb = [1];   // smart control on
        // payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
        payload.pi = [...dataStream];
        payload.pn = [timeFn.getUnixNow()];

        let package_ = loraPac.genLoRaPackage(devDetails, payload);
        
        if (package_.error) return toast.error(`package_.message`);
        
        // console.log(package_);
        package_.gwid = this.state.gwID; // set the gateway ID

        /** Show update icon here */
        toast.dark("Uploading To Device");
        // cssFn.cssShowStatus(false, 'changeSPLoading', 'checkHidden');
        // console.log('sending Data');
        let data = await controlDevFn.send(package_);
      
          // eslint-disable-next-line
        if(data && data.ft == package_.ft){
            // toast.dark(`Succesful`);
            // await this.setState({bAcIsOn:false});
            toast.dark("Upload Done");
        }else{
            return toast.error(`Connection Unstable`);
        }        
        /**=============Send LoRa End============= */

        this.props.history.push({
            pathname: "/ctrldev/ac/schelist",
            _data: {
                bdDev_id: this.state.bdDev_id,
                gwID: this.state.gwID,
                devID: this.state.devID,
            },
          });
    }

    getTableInfo=()=>{
        try {
            let dataAction = document.getElementById('scheAction').value;
            let Setpoint =0;
            if(!this.state.bHideSP) Setpoint = parseFloat(document.getElementById('spInput').value);        
            let start = document.getElementById('startInput').value;
            
            let startUnix_ms = +new Date(start);
            let endUnix_ms=startUnix_ms+60000;  // set to 1 min after
            // console.log(startUnix_ms);
            
            if(!this.state.bHideEndTime){
                let end = document.getElementById('endInput').value;
                endUnix_ms = +new Date(end);
            }
            // this.state.weeklySche;        
            let dow=[];
            for (let i = 0; i < 7; i++) {
                if(this.state.bHideWeekly || this.state.weeklySche===0){
                    dow[i] = false;
                }else{
                    dow[i] = document.getElementById(`box${i}`).checked;                
                }                
            }    
            let inputError = false;
            /** Handle Start time */
            if(Number.isNaN(startUnix_ms)) {
                // console.log('startUnix_ms Is nan');
                cssFn.cssShowStatus(false, 'startWarning', "hideWeeklySel");
                inputError = true;
            }
            /** handle End time */
            if((Number.isNaN(endUnix_ms) || endUnix_ms <= startUnix_ms) && !this.state.bHideEndTime){
                // console.log('endUnix_ms Is nan');
                cssFn.cssShowStatus(false, 'endWarning', "hideWeeklySel");
                inputError = true;
            } 
            /** handle Setpoint */
            if((Number.isNaN(Setpoint) || Setpoint > 32 || Setpoint < 16) && !this.state.bHideSP) {
                // console.log('Setpoint Is nan'); 
                cssFn.cssShowStatus(false, 'spWarning', "hideWeeklySel");
                inputError = true;
            }
            
            if(inputError) return;

            let AcSche = {
                dataAction:parseInt(dataAction),
                startUnix_ms,
                endUnix_ms,
                Setpoint,
                repeatWeekly:this.state.weeklySche,
                dow
            };

            // this.convertIntoRawDataForm(AcSche);
            return AcSche;
        } catch (error) {
            toast.error(error.message);
        }
    }

    // convertIntoRawDataForm=(sche)=>{
    //     // console.log(sche);
    //     if(sche.repeatWeekly === 0){
    //         /** Event */
    //         let eScheRaw = this.convertEventScheToRaw(sche);
    //         // console.log(eScheRaw);
    //     }else{
    //         /** Weekly Sche */
    //         let weekSche = this.convertWeekScheToRaw(sche);
    //         // console.log(weekSche);
    //     }
    // }

    convertWeekScheToRaw=(sche)=>{
        // Setpoint: 23.44999
        // dataAction: 3
        // dow: (7) [true, false, true, false, false, false, false]
        // endUnix_ms: 1628939161000
        // repeatWeekly: 1
        // startUnix_ms: 1628939160000

        let dow = 0;
        for (let i = 0; i < 7; i++) {
            if (sche.dow[i]) dow = bitFn.setBit(dow, i);            
        }
        
        //cmd_wSche_SP4_Ac2_Dow3[i]
        let wSch_2 = Math.round(sche.Setpoint*10)*100000 +
        sche.dataAction * 1000 + 
        dow;
        
        let ST = timeFn.getTimeInInt_nonTz(sche.startUnix_ms);
        let ET = timeFn.getTimeInInt_nonTz(sche.endUnix_ms);
        // cmd_wSche_Iu1_Tf1_St4_Et4[i]
        let wSch_1 = 1000000000 + 
        0 + 
        ST * 10000 + 
        ET;
        
        return [wSch_1, wSch_2, 0]
    }

    convertEventScheToRaw=(sche)=>{
    //   cmd_eSche_Tf1_Ac2_Sd6[i] = F_GetSinglePi((byte*)&buf, i*3);
    //   cmd_eSche_Iu1_Sp3_Ed6[i] = F_GetSinglePi((byte*)&buf, (i*3)+1);
    //   cmd_eSche_Sp1_St4_Et4[i] = F_GetSinglePi((byte*)&buf, (i*3)+2);

        let SDate = timeFn.getDateInInt_nonTz(sche.startUnix_ms);
        let EDate = timeFn.getDateInInt_nonTz(sche.endUnix_ms);
        let STime = timeFn.getTimeInInt_nonTz(sche.startUnix_ms);
        let ETime = timeFn.getTimeInInt_nonTz(sche.endUnix_ms);

        //   cmd_eSche_Tf1_Ac2_Sd6[i]
        let eSche_1 = sche.dataAction * 1000000 +
        SDate;
        // let sp_1 = Math.round(sche.Setpoint*10)%1000;
        // console.log(sp_1);
        //   cmd_eSche_Iu1_Sp3_Ed6[i]
        let eSche_2 = 1000000000 + 
        (Math.round(sche.Setpoint*10)%1000)*1000000 +
        EDate;
        //   cmd_eSche_Sp1_St4_Et4[i]
        let eSche_3 = parseInt(sche.Setpoint/100)*100000000 + 
        STime*10000+
        ETime;
        // console.log(eSche_1);
        // console.log(eSche_2);
        // console.log(eSche_3);

        return [eSche_1, eSche_2, eSche_3]
    }

    changeStart=()=>{
        // console.log('Change Start');
        cssFn.cssShowStatus(true, 'startWarning', "hideWeeklySel");
    }
    changeEnd=()=>{
        // console.log('Change End');
        cssFn.cssShowStatus(true, 'endWarning', "hideWeeklySel");
    }
    changeSp=()=>{
        // console.log('Change End');
        cssFn.cssShowStatus(true, 'spWarning', "hideWeeklySel");
    }

    backTo=()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac/schelist",
            _data: {
                bdDev_id: this.state.bdDev_id,
                gwID: this.state.gwID,
                devID: this.state.devID,
            },
        });
    }

    render() { 
        return ( <>        
            <div 
                className="spaceBetween"
                style={{
                    margin:'15px 10px',
                }}
                >
                <div
                    onClick={this.backTo} 
                    className="framelessIcon">
                    <i className='fa fa-chevron-left buttonIcon'></i>
                </div>
                <div style={{
                    // backgroundColor:"blue",
                    fontSize:'25px',
                    margin:'-10px',
                    }}>
                    New Schedule
                </div>
                <div 
                    className="framelessIcon"
                    onClick={this.clickSave}
                    style={{
                        // flex:1,
                        // float:'right',
                        alignSelf:'flex-end',                    
                        // backgroundColor:"green"
                }}>
                    <i className='fa fa-check buttonIcon'></i>
                </div>
            </div>
            <div>                
                <div className='inputContainer'>
                    <div className='inputTitle'>
                        Action
                    </div>
                    <select id='scheAction' 
                        onChange={this.selAction}
                        className='selOptionTab'>
                        <option className='selOption' value='1'> {'On'} </option>
                        <option className='selOption' value='2'> {'On & Off'} </option>
                        <option className='selOption' value='3'> {'On, with Smart Control'} </option>
                        <option className='selOption' value='4'> {'On & Off with Smart Control'} </option>
                        <option className='selOption' value='5'> {'Off'} </option>
                        <option className='selOption' value='6'> {'Away'} </option>

                    </select>
                </div>
                <div className='inputContainer'>
                    <div className='sepLine'></div>
                </div>
                <div className='inputContainer'>
                    <div className='inputTitle'>
                        Start
                    </div>
                    <input 
                        id='startInput'
                        onChange={this.changeStart}
                        className='inputField'
                        type='datetime-local' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='startWarning' 
                        className='warningMsg hideWeeklySel'>** Invalid Input
                    </div>
                </div> 
                <div 
                    id='endTimeField'
                    className='inputContainer'>
                    <div className='inputTitle'>
                        End
                    </div>
                    <input 
                        id='endInput'
                        onChange={this.changeEnd}
                        className='inputField'
                        type='datetime-local' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='endWarning' 
                        className='warningMsg hideWeeklySel'>** Invalid Input, must greater than Start Time
                    </div>
                </div>
                <div
                    id='spField' 
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Setpoint, °C
                    </div>
                    <input 
                        id='spInput'
                        onChange={this.changeSp}
                        className='inputField'
                        type='Number' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>      
                <div                 
                    id='weeklyField'
                    className='inputContainer spaceBetween'
                    style={{
                        marginTop:'40px',
                        fontSize:'20px',                        
                    }}
                    >
                    <div>Repeat Weekly</div>
                    <div onClick={this.selWeeklySche}>
                        {/* <i className='fa fa-chevron-left buttonIcon'></i> */}
                        {this.state.bloadButton && <ToggleButton 
                            status={this.state.weeklySche}                            
                            ></ToggleButton>}
                    </div>
                </div>
                <div 
                    id='dowBox'
                    className='inputContainer hideWeeklySel'
                    style={{
                        // visibility:'hidden',
                        // marginTop:'-15px',
                        marginBottom:'0px'
                    }}
                    >
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Monday</div>
                        <div className='dowSel'>
                            <input id='box1' type='checkbox'></input>
                        </div>                        
                    </div>
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Tuesday</div>
                        <div className='dowSel'>
                            <input id='box2' type='checkbox'></input>
                        </div>                        
                    </div>
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Wednesday</div>
                        <div className='dowSel'>
                            <input id='box3' type='checkbox'></input>
                        </div>                        
                    </div>
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Thursday</div>
                        <div className='dowSel'>
                            <input id='box4' type='checkbox'></input>
                        </div>                        
                    </div>
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Friday</div>
                        <div className='dowSel'>
                            <input id='box5' type='checkbox'></input>
                        </div>                        
                    </div>
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Saturday</div>
                        <div className='dowSel'>
                            <input id='box6' type='checkbox'></input>
                        </div>                        
                    </div>
                    <div className='dowSelContainer spaceBetween'>
                        <div className='dowTitle'>Sunday</div>
                        <div className='dowSel'>
                            <input id='box0' type='checkbox'></input>
                        </div>                        
                    </div>
                </div>
            </div>
        <ToastContainer />
        </> );
    }
}
 
export default AddNewAcSchePage;