import offsetSrvs from '../../../service/offset/offsetService';
import tool from '../../../checkingTools/tools';


async function addOffset(body){
    try {
        /**Return true if succesful, return false if not success */
        /**Search DB make sure offset not duplicated */
        body.devIDStart =body.devID;
        body.devIDEnd= body.devID;
        
        let duplicated = await offsetSrvs.getBySearch(body);
        // console.log(duplicated);
        /**Reply status not OK */
        if(duplicated.status!==200){
            // console.log("Status Error");
            return "Server Error";
        } 
        /**Offset duplicated */
        if(duplicated.data[0]){
            // console.log("Offset duplicated");
            return "Offset Duplicated";
        } 
        // console.log(duplicated);
        /**Make sure Data not duplicated, insert data into DB */
        let insertDB = await offsetSrvs.addOffset(body);
        // console.log(insertDB);
        if(insertDB.status!==200) return "Insert Database Error"
        return false

        // let result = await offsetSrvs.getByIdnKey(body);    
        // if(result.status!==200 || !result.data) return null;    // 
        // return result.data;        
    } catch (error) {
        // console.log("Get");
        tool.exResponse(error);
        return null;
    }
}

async function getOffsetByIdnKey(body){
    try {
        let result = await offsetSrvs.getByIdnKey(body);    
        if(result.status!==200 || !result.data) return null;    // 
        return result.data;        
    } catch (error) {
        // console.log("Get");
        tool.exResponse(error);
        return null;
    }
}

async function getOffsetBySearch(body){
    try {
        let result = await offsetSrvs.getBySearch(body);    
        if(result.status!==200 || !result.data) return null;    // 
        return result.data;        
    } catch (error) {
        // console.log("Get");
        tool.exResponse(error);
        return null;
    }
}

async function updateOffset(body){
    try {
        let result = await offsetSrvs.update(body);    
        if(result.status!==200 || !result.data) return null;    // 
        return result.data;        
    } catch (error) {
        // console.log("Get");
        tool.exResponse(error);
        return null;
    }
}

async function del(obj){
    try {
        let result = await offsetSrvs.del(obj);
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("Add Offset Err");
        tool.exResponse(error);
        return null;
    }
}

const expModule = {  
    del,
    addOffset,
    updateOffset,
    getOffsetByIdnKey,
    getOffsetBySearch,
};
export default expModule;