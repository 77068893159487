import React, { Component } from 'react';
import WeekSche from './../weeklySche/weeklySche';
import ScheAction from './../ScheAction/ScheAction';
import ToggleButton from './../toggleButton/toggleButton';


class AcSchedule extends Component {
    state = {       
        repeatWeekly : 1,  // 1 = weekly, 0 = event
        dataAction:4,   //  1 on, 2 on & off, 3, on wit control, 4, on & off, with temp control, 5, 0ff, 6 away
        Setpoint:25.3,
        startTime:'09:00',
        endTime:'18:00',
        startDate:'10/8/2021',
        endDate:'11/8/2021',
        inUse:0,
        dow:[true, false,true, false,true, false,true],
        bLoaded : false,
    }

    async componentDidMount() {
        await this.getScheduleSetting();
        // console.log(this.state.Setpoint);
        await this.setState({bLoaded:true});
    }

    async getScheduleSetting(){
        if(this.props.schedule){
            let schedule = {...this.props.schedule};
            // console.log(schedule);
            for (const key in schedule) {
                if (Object.hasOwnProperty.call(schedule, key)) {
                    // const element = schedule[key];
                    await this.setState({[key]: schedule[key]});
                }
            }
            // console.log(this.state.active);
        }
    }

    handleDelete=()=>{
        // console.log("Delete");
        if(this.props.trigDelete) this.props.trigDelete();
    }

    handleActive=()=>{
        // console.log("On/Off");
        if(this.props.trigOnOff) this.props.trigOnOff();
    }

    render() { 
        let hideEndTime = this.state.dataAction===1 || this.state.dataAction===3 || this.state.dataAction===5;
        
        let wScheTime=`${this.state.startTime}`;
        if(this.state.dataAction===2 || this.state.dataAction===4) wScheTime = `${this.state.startTime} ~ ${this.state.endTime}`;
        return ( <>
            {this.state.bLoaded && <div className='spaceAround'
                style={{
                    padding:'8px 30px',
                    margin:'10px 0px',
                    // backgroundColor:'greenyellow',
                    alignItems:'normal'
                    
                }}>
                {this.props.onOff && <div style={{
                    // flex:'1', 
                    // backgroundColor:'red', 
                    // width:'12%',
                    width:this.props.onOff?'8%':'12%',
                    display:'flex', 
                    justifyContent:'center',
                    alignItems:'center'}}>
                        <div 
                            onClick={()=>this.handleDelete()}
                            className="framelessIcon">
                            <i 
                                style={{
                                    color:'rgb(201, 26, 3)',
                                    // fontSize:'18px'
                                }}
                                className='fa fa-trash buttonIcon'></i>
                        </div>         
                </div>}
                <div className='flexCenterColumn'
                    style={{
                        // width:'30%',
                        width:this.props.onOff?'20%':'20%',
                        // backgroundColor:'rosybrown',
                        // flex:'2',
                    }}>
                        <ScheAction status={this.state.dataAction} temp_SP={this.state.Setpoint}></ScheAction>
                </div>
                {this.state.repeatWeekly === 1 && <div style={{
                    // flex:'3'   
                    // width:'50%',
                    width:this.props.onOff?'55%':'50%',
                    // paddingLeft:'10px',
                    // backgroundColor:'red',
                    }}>
                    <div  className='flexCenter' style={{fontSize:'20px'}}>{wScheTime}</div>
                    <div className='flexCenter'>
                    {/* <div className='flexLeft'> */}
                        <WeekSche a_DowSel={this.state.dow}></WeekSche>                        
                    </div>
                </div>}
                
                {this.state.repeatWeekly === 0 && <div 
                    className='flexCenter'
                    // className='flexLeft'
                    style={{
                        // flex:'3'   
                        width:this.props.onOff?'55%':'50%',
                        // backgroundColor:'red',
                        // paddingLeft:'10px',
                        fontSize:'12px',
                }}>
                    <div className='flexCenterColumn'>
                        <div>{this.state.startDate}</div>
                        <div style={{marginTop:'-2px'}}>{this.state.startTime}</div>
                    </div>
                    {!hideEndTime && <div style={{margin:"0px 10px"}}>~</div>}
                    {!hideEndTime && <div className='flexCenterColumn'>
                        <div>{this.state.endDate}</div>
                        <div style={{marginTop:'-2px'}}>{this.state.endTime}</div>
                    </div>}
                </div>}
                {this.props.onOff && <div 
                    onClick={()=>this.handleActive()}
                    style={{
                    // flex:'1', 
                    // backgroundColor:'red', 
                    // width:'12%',
                    width:this.props.onOff?'12%':'12%',
                    display:'flex', 
                    justifyContent:'center',
                    alignItems:'center'}}>
                        <ToggleButton status={this.state.inUse}></ToggleButton>                    
                </div>}
            </div>}
        </> );
    }
}
 
export default AcSchedule;