import React, { useEffect, useRef, useState } from 'react';
import TpAcpuOvv from './DeviceTemplate/TpAcpuOvv';
import TpWcpuOvv from './DeviceTemplate/TpWcpuOvv';
import v1CtrlOvvFn from '../../function/V1/V1_CtrlOvvFn/V1_CtrlOvvFn';
import { isEmptyArr, sortTemplate } from '../../function/utilities/utilities';
import { toast } from 'react-toastify';
// import loraFn from '../../function/genLoRaPackage';
// import ctrlDevFn from '../../../src/component/utilities/conntrolDevice/ctrlDevFn';
import ctrlCmdLib from '../../function/DevControlLib/DevControlLib';
import socketIOClient from "socket.io-client";
import auth from "../../service/authService";
import TpPecOvv from './DeviceTemplate/TpPecOvv';

function CtrlPageOvv(props) {    
    const C_GuestDev_bdDev_id=[1569, 1768, 1769, 1770, 1772];
    const C_bd_id=71;
    const cmdTimeout = 10000;    // 10 sec
    const msgTimeout = 3000;    // 3 sec

    const [G_AreaDevList, setG_AreaDevList] = useState([]);    
    const [G_GuestDevList, setG_GuestDevList] = useState([]);
    const [G_ShowConfirmDialog, setG_ShowConfirmDialog] = useState(false);
    const [G_bSkipNext, setG_bSkipNext] = useState(false);
    // eslint-disable-next-line
    const [G_SendStage, setG_SendStage] = useState(0);
    const [G_StatusErrMsg, setG_StatusErrMsg] = useState("");
    const [currUser, setCurrUser] = useState({});
    
    const refSelectDev_Info = useRef({});
    const refActionCmd = useRef({});
    const timeoutRef = useRef(null);
    const statusMsgOtRef = useRef(null);
    const refCmdLog = useRef({});
    const refSending = useRef(false);

    useEffect(()=>{
        let socket;
        async function startUp(){
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);
            let bdOvvinfo = await v1CtrlOvvFn.getV1_CtrlOvv_area_dev(C_bd_id, 1);
            // console.log(bdOvvinfo);
            if(!bdOvvinfo || bdOvvinfo.errMsg) return

            sortDevByArea(bdOvvinfo);
            genGuestDevList(bdOvvinfo);
            let user = await auth.getCurrentUser();
            // console.log(user);
            setCurrUser(user);
            
            let topic = `v2_CtrlCmd`;
            socket.on(topic, data => {
                let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
                if(sentSuccess) {
                    clearTimeout(timeoutRef.current);
                    setG_SendStage(2);
                    /** trigger pass countdown */
                    statusMsgOtRef.current = setTimeout(clearStatus, msgTimeout);
                    // toast ("Success")
                }              
            });
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
            clearTimeout(timeoutRef.current);
            clearTimeout(statusMsgOtRef.current);            
            socket.disconnect();
            
            window.location.reload(false);  // call a refresh to stop data pulling
        }
        // eslint-disable-next-line
    }, []);

    
    const genGuestDevList=(_bdOvvinfo)=>{
        try {
            let {devList} = _bdOvvinfo;
            let filteredDevList = devList.filter(c => C_GuestDev_bdDev_id.includes(c.bdDev_id));
            // devList.filter(c => C_GuestDev_bdDev_id.includes(c.bdDev_id));
            // console.log("devList", devList);
            setG_GuestDevList(filteredDevList);
        } catch (error) {
            console.log(error);
        }
    }

    const sortDevByArea=(_bdOvvinfo)=>{
        try {
            let {areaList, devList} = _bdOvvinfo;
            if(!Array.isArray(areaList)) areaList = [];
            if(!Array.isArray(devList)) return
            let remainingDevList = [...devList];

            let devDisplayList = [];
            if(!isEmptyArr(areaList))  {
                for (const eachArea of areaList) {
                    let devInEachArea = remainingDevList.filter(c=>c.area_id === eachArea._id);
                    let sortKeys = [
                        { key: "sortIdx", descOrder: false },
                        { key: "unix", descOrder: false },
                    ];
                    devInEachArea.sort((a, b) => sortTemplate(a, b, sortKeys));
                    if(!isEmptyArr(devInEachArea)){
                        devDisplayList.push({areaName:eachArea.areaName, devList:devInEachArea});
                    }
                    /** remove inserted dev from remaining list */
                    remainingDevList = remainingDevList.filter(c=>c.area_id !== eachArea._id);
                }
            }
            if(!isEmptyArr(remainingDevList)){
                let sortKeys = [
                    { key: "sortIdx", descOrder: false },
                    { key: "unix", descOrder: false },
                ];
                remainingDevList.sort((a, b) => sortTemplate(a, b, sortKeys));
                devDisplayList.push({areaName:"Others", devList:remainingDevList})
            }
            // console.log(devDisplayList);
            setG_AreaDevList(devDisplayList);
        } catch (error) {
            console.log("sortDevByArea err: ", error.message);
        }
        
    }

    const handleCancelConfirmDialog=()=>{
        setG_ShowConfirmDialog(false);
    }

    const handleToggleSkipNext=()=>{
        setG_bSkipNext(!G_bSkipNext);
    }

    const handleOnXcpuCmd=async(info)=>{
        refActionCmd.current = 1;
        refSelectDev_Info.current = info;
        // console.log("info", info);
        if(!G_bSkipNext) return setG_ShowConfirmDialog(true);
        // if(!G_bSkipNext) return setG_ShowConfirmDialog(true);
        // console.log("refSending.current", refSending.current);
        if(refSending.current) return
        setG_SendStage(1);
        refSending.current=true;
        await sendCommand();
        refSending.current=false;
    }    
    const handleOffXcpuCmd=async(info)=>{
        refActionCmd.current = 2;
        refSelectDev_Info.current = info;
        if(!G_bSkipNext) return setG_ShowConfirmDialog(true);
        // if(!G_bSkipNext) return setG_ShowConfirmDialog(true);
        if(refSending.current) return
        setG_SendStage(1);
        refSending.current=true;
        await sendCommand();
        refSending.current=false;
    }
    const handleClickConfirmDialog=async ()=>{
        
        if(refSending.current) return
        refSending.current=true;
        await sendCommand();
        refSending.current=false;
    }
    const sendCommand=async()=>{
        /** get paired gw info */
        try {
            // console.log("Send Cmd");
            let gwPairInfo = await v1CtrlOvvFn.getV1_ctrlGwPair(refSelectDev_Info.current._id);
            if(gwPairInfo.errMsg) {
                clearStatus()
                return toast(`Cmd Err: ${gwPairInfo.errMsg}`)
            }
            let {gwPairList} = gwPairInfo;
            if(isEmptyArr(gwPairList)) {
                clearStatus()
                return toast(`No gateway pair to this device`);
            }
            let gwid = gwPairList[0].gwid;

            /** control command */
            let payload={};
            if(refActionCmd.current === 1)  payload={pi:[1,1]};
            if(refActionCmd.current === 2)  payload={pi:[2,2]};
            let cmdRel = await ctrlCmdLib.v2_CtrlCmd(2, gwid, refSelectDev_Info.current, payload, 1);      //
            
            if(!cmdRel.success) {
                clearStatus()
                return toast("Send Command Error");
            }
            setG_ShowConfirmDialog(true);
            setG_SendStage(1);
            /** timer to count command time out */
            refCmdLog.current = cmdRel.loraPackage;
            timeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
            // console.log("cmdRel", cmdRel);
        } catch (error) {
            console.log(error.message);
        } 
    }

    const fCmdTimeout=async()=>{
        let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
        if(!checkRel || !checkRel.errMsg) {
            setG_StatusErrMsg("Cmd DB Err");
        }
        if(checkRel.errMsg) {
            setG_StatusErrMsg(checkRel.errMsg);
            // toast(checkRel.errMsg);
        }
        setG_SendStage(3);
        clearTimeout(timeoutRef.current);
        /** trigger failed countdown */
        statusMsgOtRef.current = setTimeout(clearStatus, msgTimeout);
                
    }
    
    const clearStatus=()=>{
        // console.log("clearStatus triggered");
        setG_SendStage(0);
        setG_ShowConfirmDialog(false);
        clearTimeout(statusMsgOtRef.current);   
    }
    
    const handleZoomDetails=(ht, hi, bdDev_id)=>{
        let path = ""; 
        if(ht===36){
            path = `/ctrlpg/dev/acpu/${ht}/${hi}/${bdDev_id}`;
        }else if(ht===37){
            path = `/ctrlpg/dev/wcpu/${ht}/${hi}/${bdDev_id}`;
        }else if(ht===49){
            path = `/ctrlpg/dev/pec/${ht}/${hi}/${bdDev_id}`;
        }
        if(!path){
            return console.log("Empty Path");
        }
        // let path = `/ctrlpg/dev/acpu/36/11/1569`;
            // let path = `/ctrlpg/dev/acpu/${devInfo.type}/${devInfo.devID}/${devInfo._id}`;
        // console.log(path);
        props.history.push({pathname: path,
            _data: {},});
    }

    return (
        <div>            
            <div className={G_ShowConfirmDialog?"":"hideDiv_NoSpace"}>
                <div className='autumn_dialogBlurBg'> </div>
                {G_SendStage === 0 && <div className='autumn_dialogBoxContainer'>
                    <div className='autumn_dialogBox'>  
                        <div className='autumn_dialogBox_Message' >
                            {refActionCmd.current === 1 && <div className='autumn_dialogBox_Action'>Turn <span style={{color:"rgb(53, 227, 53)", margin:"0px"}}>On</span></div>} 
                            {refActionCmd.current === 2 && <div className='autumn_dialogBox_Action'>Turn <span style={{color:"red", margin:"0px"}}>Off</span></div>}
                            {refSelectDev_Info.current.location ==="" && <div>{`${refSelectDev_Info.current.name} [${refSelectDev_Info.current.devID}]`}</div>}
                            {refSelectDev_Info.current.location !=="" && <div>{`${refSelectDev_Info.current.location}, ${refSelectDev_Info.current.name} [${refSelectDev_Info.current.devID}]`}</div>}
                        </div>
                        <div className='sortHor verCenter hoverPointer'
                        onClick={handleToggleSkipNext}>
                            <div className={`autumn_CheckBox bothCenter ${G_bSkipNext?"fa fa-check":""} `}
                            style={{color:"rgb(69, 247, 102)"}}></div>
                            <div className='autumn_dialogBox_CbMsg'> Skip confirmation on next </div>
                        </div>
                        <div className='autumn_dialogButtonContainer'>
                            <div className='autumn_dialogButton btnColor_Green'
                            onClick={handleClickConfirmDialog}> Confirm </div>
                            <div className='autumn_dialogButton btnColor_Grey'
                            onClick={handleCancelConfirmDialog}> Cancel </div>
                        </div>
                    </div>
                </div>}
                {G_SendStage === 1 && <div className='autumn_dialogBoxContainer'>
                    <div className='autumn_dialogBox autumn_dialogBox_fixHeight'>  
                        <div className='autumn_statusIcon autumn_sendBgc'>
                            <i className=' fa fa-paper-plane autumn_smallerIcon autumn_shiftLeft'>
                            </i>
                        </div>
                        <div className='autumn_statusMsg' 
                        style={{paddingLeft:"10px"}}>
                            {`Sending...`}
                        </div>
                    </div>
                </div>}
                {G_SendStage === 2 && <div className='autumn_dialogBoxContainer'>
                    <div className='autumn_dialogBox autumn_dialogBox_fixHeight'>  
                        <div className='autumn_closeBtn btnColor_Grey hoverPointer'
                        onClick={clearStatus}>
                            <i className=' fa fa-minus'></i>
                        </div>
                        <div className='autumn_statusIcon autumn_successBgc'>
                            <i className=' fa fa-check autumn_smallerIcon'>
                            </i>
                        </div>
                        <div className='autumn_statusMsg'>
                            Success
                        </div>
                    </div>
                </div>}                
                {G_SendStage === 3 && <div className='autumn_dialogBoxContainer'>
                    <div className='autumn_dialogBox autumn_dialogBox_fixHeight'>  
                        <div className='autumn_closeBtn btnColor_Grey hoverPointer'
                        onClick={clearStatus}>
                            <i className=' fa fa-minus'></i>
                        </div>
                        <div className='autumn_statusIcon autumn_failedBgc'>
                            <i className=' fa fa-times'>
                            </i>
                        </div>
                        <div className='autumn_statusMsg'>
                            {G_StatusErrMsg}
                        </div>
                    </div>
                </div>}
            </div>

            {currUser._id === 135 ? <div className='flexDirVer autumm_OvvPage'>
                        {/* <div className='autumn_OvvAreaTitle'>{c.areaName}</div> */}
                        <div className='autumn_CardContianer'>
                        {G_GuestDevList.map((d,ind_d)=>
                            <div key={`dev_${ind_d}`}>                                
                                {(d.ht===31 || d.ht===36) && <TpAcpuOvv devInfo={d} fOnClick={handleOnXcpuCmd} fOffClick={handleOffXcpuCmd} handleZoomToDetail={handleZoomDetails}/>}
                                {(d.ht===32 || d.ht===37) && <TpWcpuOvv devInfo={d} fOnClick={handleOnXcpuCmd} fOffClick={handleOffXcpuCmd} handleZoomToDetail={handleZoomDetails}/>}
                            </div>
                        )}
                        </div>
            </div> : 
            <div className='flexDirVer autumm_OvvPage'>
                {G_AreaDevList.map((c,ind_c)=>
                    <div key={`area_${ind_c}`}>
                        <div className='autumn_OvvAreaTitle'>{c.areaName}</div>
                        <div className='autumn_CardContianer'>
                        {c.devList.map((d,ind_d)=>
                            <div key={`dev_${ind_c}_${ind_d}`}>                                
                                {(d.ht===31 || d.ht===36) && <TpAcpuOvv devInfo={d} fOnClick={handleOnXcpuCmd} fOffClick={handleOffXcpuCmd} handleZoomToDetail={handleZoomDetails}/>}
                                {(d.ht===32 || d.ht===37) && <TpWcpuOvv devInfo={d} fOnClick={handleOnXcpuCmd} fOffClick={handleOffXcpuCmd} handleZoomToDetail={handleZoomDetails}/>}
                                {d.ht===49 && <TpPecOvv devInfo={d} fOnClick={handleOnXcpuCmd} fOffClick={handleOffXcpuCmd} handleZoomToDetail={handleZoomDetails} />}
                            </div>
                        )}
                        </div>
                        {/* <div className='autumn_CardContianer'>
                            {ind_c === 2 && <TpPecOvv devInfo={dummyDevList} fOnClick={handleOnXcpuCmd} fOffClick={handleOffXcpuCmd} handleZoomToDetail={handleZoomDetails} />}
                        </div> */}
                    </div>
                )}
            </div>}
        </div>
    );
}

export default CtrlPageOvv;