import React, { Component } from 'react';
import './weeklySche.css'

class WeekSche extends Component {
    state = { 
        a_DowSelected:[],
    }

    componentDidMount() {
        this.getDowSelected();
    }

    getDowSelected(){
        let a_DowSelected = [];
        if(Array.isArray(this.props.a_DowSel)){
            a_DowSelected=[...this.props.a_DowSel];
            /** Switch  location*/
            let sunSel = a_DowSelected[0];
            a_DowSelected.shift();
            a_DowSelected.push(sunSel);

        }else{
            for (let i = 0; i < 7; i++) {
                a_DowSelected[i]=false;
            }
        }
        this.setState({a_DowSelected});
    }

    render() { 
        return ( 
            <>
            <div className="scheContainer">
                {this.state.a_DowSelected.map((u, ind)=>(
                    // u?<div>Hi</div>:<div>Ho</div>
                    <div 
                        key={`scheInd-${ind}`}
                        className='wScheIndicator'
                        style={{
                            backgroundColor:u?"green":"rgb(201, 26, 3)",
                            // border:u?'none':'1px solid grey',
                        }}
                    ></div>
                ))}
            </div>
            </>
        );
    }
}
 
export default WeekSche;

// class  extends Component {
//     state = {  }
//     render() { 
//         return (  );
//     }
// }
 
// export default ;