import React from "react";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { regUser } from "../../service/regUser";
import Form from "./../common/form";
import auth from "../../service/authService";
import "./userRegistration.css";
import TpPopUp from "../template/PopUp/TpPopUp";
import PopUpContent from "../template/PopUp/PopUpContent";
import CloseIcon from "../template/Icons/CloseIcon";
import DisplayIcon from "../template/Icons/DisplayIcon";

class UserRegister extends Form {
  state = {
    data: {
      username: "",
      email: "",
      password: "",
      name: "",
      company: "",
      phone: "",
    },
    errors: {},
    bShowPopUp: false,
  };

  schema = {
    username: Joi.string().required().min(6).max(80).label("Username"),
    email: Joi.string().email().max(80).required().label("Email"),
    password: Joi.string().required().min(8).max(80).label("Password"),
    name: Joi.string().required().min(3).max(80).label("Full name"),
    company: Joi.string().required().max(80).label("Company"),
    phone: Joi.string().max(80).required().label("Phone"),
  };

  doSubmit = async () => {
    try {
      console.log("Data: ", this.state.data);
      const response = await regUser(this.state.data);
      // localStorage.setItem("token", response.headers["x-auth-token"]);
      console.log("header Token: ", response);
      console.log("header Token: ", response.data);
      auth.loginWithJwt(response.data);
      this.setState({ bShowPopUp: true });
      // headers["x-auth-token"];
      // window.location = "/notactive";
      // console.log(response);

      // this.props.history.push("/notactive");
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        // toast.error("This username already been registered");
        console.log(ex.response);
        const errors = { ...this.state.errors };
        errors.username = ex.response.data; //  set .username, the error will display under username
        this.setState({ errors });
      }
    }
  };

  closePopUp = async () => {
    // this.setState({ bShowPopUp: false });
    window.location = "/login";
  };

  render() {
    return (
      <React.Fragment>
        {this.state.bShowPopUp && (
          <TpPopUp handleClickOutside={this.closePopUp}>
            {
              <div>
                <CloseIcon close={this.closePopUp}></CloseIcon>
                <DisplayIcon
                  style={{ color: "#12C00A" }}
                  className="fa fa-check-circle"
                ></DisplayIcon>
                <PopUpContent
                  content="Registration success. Contact admin for
                  activation."
                ></PopUpContent>
              </div>
            }
          </TpPopUp>
        )}
        {!this.state.bShowPopUp && (
          <div className="scontainer">
            <div className="sccard">
              <h1 className="sccard-title">Register</h1>
              <form className="form-signup" onSubmit={this.handleSubmit}>
                {this.renderInput("fa fa-user fa-fw", "username", "Username")}
                {this.renderInput("fa fa-envelope fa-fw", "email", "Email")}
                {this.renderInput(
                  "fa fa-key fa-fw",
                  "password",
                  "Password",
                  "password"
                )}
                {this.renderInput(
                  "fa fa-address-card fa-fw",
                  "name",
                  "Full name"
                )}
                {this.renderInput("fa fa-building fa-fw", "company", "Company")}
                {this.renderInput("fa fa-phone fa-fw", "phone", "Phone")}
                <div align="center">{this.renderButton("Register")}</div>
                <p className="signup">
                  Already have an account?
                  <Link to="/login" className="bsignin">
                    Sign In
                  </Link>
                </p>
                {this.renderCopyRight("2021", "Apsol Pro Sdn. Bhd.")}
              </form>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default UserRegister;
