
function displaya_obj(a_obj){
    for (const obj of a_obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                console.log(`[${key}]: ${obj[key]}`)
            }
        }                
    }
}
// import tool from '../../checkingTools/tools'
function exResponse(ex) {    
    if (ex.response) {
        console.log(ex.response); 
    }else{
        console.log(ex.message); 
    }
}

function log(msg){
    if(process.env.REACT_APP_DEVDEBUG==="true"){
        console.log(msg);
    }
}

const expModule = {  
    log,
    exResponse,
    displaya_obj,
};
export default expModule;
