import React, { Component } from "react";
import getMonList from "./utilities/getMonList";
import auth from "../service/authService";
import monlistFn from "./utilities/monList/monList";
import Loader from "./common/loader";

class SelectionPage extends Component {
  state = {
    monList: [],
    buildingName: "",
    bLoaded: false,
  };

  async componentDidMount() {
    let buildingName = await localStorage.getItem("buildingName");
    await this.setState({ buildingName });
    await this.setState({ bLoaded: false });
    await this._getMonList();
    await this.setState({ bLoaded: true });
  }

  _getMonList = async () => {
    let monList = await getMonList.getMonList();
    /** Filter authorize user
     * access level > 10 , refer to DB
     * < 10, can see everything
     */
    const user = auth.getCurrentUser();
    // console.log(user);

    let assignedMonList = [];

    if (user.accessLevel > 10) {
      let ownedMonList = await monlistFn.getAssignedMonListByUserID(user._id);
      let assignedItem = {};
      for (const ownList of ownedMonList) {
        assignedItem = monList.find((c) => ownList.MonitorList_id === c._id);
        if(assignedItem) assignedMonList.push(assignedItem);
      }
    } else {
      assignedMonList = [...monList];
    }

    assignedMonList.sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));

    this.setState({ monList: assignedMonList });
  };

  toMonList = (id) => {
    this.props.history.push({
      pathname: `/monlist/${id}`,
      // pathname: "/datapresent/building",
      _data: {
        // _selBuildingId: this.state.selBuildingId,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
  };

  render() {
    let monList = [...this.state.monList];

    return (
      <React.Fragment>
        {<h1>Data Monitoring</h1>}
        <h5 className="monlist-title">{this.state.buildingName}</h5>
        {!monList[0] && !this.state.bLoaded && <Loader />}
        {!monList[0] && this.state.bLoaded && (
          <h1>No Monitoring List found.</h1>
        )}
        {monList[0] && (
          <div>
            <ul className="build-sel">
              {monList.map((u, ind) => (
                <li key={ind} onClick={() => this.toMonList(u._id)}>
                  {u.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SelectionPage;
