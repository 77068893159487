// import React from 'react';
import deviceService from '../../service/buildingDevicesService';

async function getAllBdDevices(){
    try {
        let allDevices = await deviceService.getAll();
        return allDevices
    } catch (error) {
        console.log(error.message);
    }
}

async function getBdDevicesBy_idList(a_id){
    try {
        let {data:deviceList} = await deviceService.getBy_idList(a_id);    
        // console.log(deviceList);    
        return deviceList;
    } catch (error) {
        console.log(error.message);
    }
}

async function getBdDevBybdID(bdID){
    try {
        let {data:allBdDevices} = await getAllBdDevices();
        // console.log(allBdDevices);
        // eslint-disable-next-line
        let bdDevicecs = allBdDevices.filter((a)=>{return a.buildingID==bdID});
        return bdDevicecs;
    } catch (error) {
        console.log(error.message);
    }
}

function turnBdDevIntoEnm(bdDevs, defaultText){
    let bdDevsEnm = [];
    if(defaultText) bdDevsEnm.push({_id:-1, name:defaultText});
    for (const bdDev of bdDevs) {
        let devEnm = {
            _id:bdDev._id,
            name: bdDev.name,
        }
        bdDevsEnm.push(devEnm);
    }
    return bdDevsEnm;
}



const expModule = {  
    turnBdDevIntoEnm,
    getAllBdDevices,
    getBdDevBybdID,
    getBdDevicesBy_idList,
};
export default expModule;