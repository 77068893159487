import React, { Component } from "react";
import teleSubListFn from "../../function/Notification/teleSubListFn";
import buildings from "../../service/setting_Buildings";
import tableFn from "../utilities/tableFunction";
import {
  getDeletedArrItem,
  sortByName,
  sortByTy_Loc_Name,
} from "../utilities/ArrayFn/arrayFn";
import bdDevFn from "../utilities/getDevices";
import teleIdEnm from "../utilities/notificaton/telegramID";
import { trueFalseTo_1_0 } from "../utilities/conversion";
import enm from "../utilities/enum";
import Icon from "../common/icon";
import { ToastContainer, toast } from "react-toastify";

class DevActTeleSubscribeList extends Component {
  state = {
    allBuildings: [],
    allBdEnum: [],
    displayTable: [],
    initable: [],
    teleChatEnm: [],
    bd_id: 0,
    pos_id: 2,
    pos_active: 3,
    pos_chatId: 4,
    icon: "fa fa-floppy-o deactive",
    disabled: false,
  };

  async componentDidMount() {
    await this._getAllBuildings();
    await this.generateChatIdEnm();
    this.getBuildingEnm();
  }

  generateChatIdEnm = async () => {
    let teleIdList = await teleIdEnm.getAll();
    // console.log(teleIdList);
    /** Convert list into enm */
    let teleChatEnm = enm.genEnum(teleIdList, null, "_id", "name");
    this.setState({ teleChatEnm });
    // console.log(teleChatEnm);
  };

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      await this.setState({ allBuildings });
    } catch (error) {
      // console.log("Get Building DB Error: ", error.message);
    }
  };
  getBuildingEnm = () => {
    let allBuildings = [...this.state.allBuildings];
    let enmItem = {};
    let allBdEnum = [];
    for (const singleBd of allBuildings) {
      enmItem = {
        name: singleBd.building,
        _id: singleBd._id,
      };
      allBdEnum.push(enmItem);
    }
    allBdEnum.sort((a, b) => sortByName(a, b));
    this.setState({ allBdEnum });
  };

  getDevUnderBuilding = async (bd_id) => {
    /** select * from where Building_id = 23 */
    /**Gen enum list */
    let bdDevs = await bdDevFn.getBdDevBybdID(bd_id);
    // eslint-disable-next-line
    let devUnderBuilidng = bdDevs.filter((c) => c.type != 5);
    /** Sort Device by type by location by name */
    devUnderBuilidng.sort((a, b) => sortByTy_Loc_Name(a, b));
    return devUnderBuilidng;
  };

  handleBuildingSel = async () => {
    // try {
    let bd_id = parseInt(document.getElementById("buildingSel").value);
    // console.log(`bd_id : ${bd_id}`);
    await this.setState({ bd_id });
    await this.refreshTable();
    // let displayTable = await this.getTeleSubList(bd_id);
    // // console.log(teleSubList);
    // let initable = [...displayTable];
    // await this.setState({initable});
    // await this.setState({displayTable});
    // this.setCheckBoxDefaultValue();
    /** Present Table List */
    // } catch (error) {
    //     console.log(error.message);
    // }
  };

  refreshTable = async () => {
    let bd_id = this.state.bd_id;
    let displayTable = await this.getTeleSubList(bd_id);
    // console.log(teleSubList);
    let initable = [...displayTable];
    await this.setState({ initable });
    await this.setState({ displayTable });
    this.setCheckBoxDefaultValue();
  };

  setCheckBoxDefaultValue = () => {
    let displayTable = [...this.state.displayTable];
    for (let index = 0; index < displayTable.length; index++) {
      if (displayTable[index].active) {
        document.getElementById("teleSubTable").rows[index + 1].cells[
          this.state.pos_active
        ].children[0].checked = true;
      }
    }
  };

  insertNewChat = async () => {
    // console.log("Insert New Chat");
    // console.log(this.state.displayTable);
    let displayTable = [...this.state.displayTable];
    // let nLen = displayTable.length;
    // console.log(nLen);
    let newInsert = {
      active: 1,
      bd_id: this.state.bd_id,
      tele_id: -1,
      _id: (this.state.displayTable.length + 1) * -1,
    };
    displayTable.push(newInsert);
    await this.setState({ displayTable });
    // console.log(this.state.displayTable);
    this.setCheckBoxDefaultValue();
  };
  delChat = async (ind) => {
    // console.log(`Delete : ${ind}`);
    let displayTable = [...this.state.displayTable];
    displayTable.splice(ind, 1);
    await this.setState({ displayTable });
  };
  saveChat = async () => {
    try {
      /** Get table info */
      this.setState({ disabled: true });
      this.setState({ icon: "fa fa-spinner fa-spin" });
      let existingTableInfo = this.getTableInfo();
      // console.log(existingTableInfo);
      /** Filter duplicated data */
      let uniqueTable = Array.from(
        new Set(existingTableInfo.map((a) => a.tele_id))
      ).map((tele_id) => {
        return existingTableInfo.find((a) => a.tele_id === tele_id);
      });

      /** Update, existing line modified */
      let modifies = tableFn.detectTableChangesSkipLastN(
        "teleSubTable",
        this.state.initable,
        "_id",
        this.tableColumn,
        true,
        1
      );
      // console.log("Modified");
      // console.log(modifies);
      for (const item of modifies) {
        item.bd_id = this.state.bd_id;
        let rel = await teleSubListFn.update(item);
        rel
          ? toast.dark(`${this.getNameFromEnm(item.tele_id)} Edit Succesful`)
          : toast.error(`${this.getNameFromEnm(item.tele_id)} Edit Failed`);
      }

      /**hande delete */
      let deletedRow = getDeletedArrItem(
        this.state.initable,
        existingTableInfo,
        "_id"
      );
      // console.log("Deleted");
      // console.log(deletedRow);
      for (const item of deletedRow) {
        let rel = await teleSubListFn.del(item);
        rel
          ? toast.dark(`${this.getNameFromEnm(item.tele_id)} Delete Succesful`)
          : toast.error(`${this.getNameFromEnm(item.tele_id)} Delete Failed`);
      }

      /** Get new add item */
      let newAddList = uniqueTable.filter((c) => c._id < 0);
      // console.log("New Add");
      // console.log(newAddList);
      for (const item of newAddList) {
        item.bd_id = this.state.bd_id;
        let rel = await teleSubListFn.add(item);
        // console.log(rel);
        rel
          ? toast.dark(`${this.getNameFromEnm(item.tele_id)} Add Succesful`)
          : toast.error(`${this.getNameFromEnm(item.tele_id)} Add Failed`);
      }
      /** Refresh table after all done */
      this.setState({ icon: "fa fa-floppy-o deactive" });
      this.setState({ disabled: false });
      await this.refreshTable();
    } catch (error) {
      console.log(error.message);
    }
  };

  getNameFromEnm = (chatId) => {
    let teleChatEnm = [...this.state.teleChatEnm];
    let found = teleChatEnm.find((c) => c._id === parseInt(chatId));
    return found.name;
  };

  /**Get Table Info */
  getTableInfo = () => {
    try {
      let tableInfo = tableFn.getInfoFromTableSkipLastN(
        "teleSubTable",
        this.tableColumn,
        1
      );
      // console.log(tableInfo);
      return tableInfo;
    } catch (error) {
      console.log(error.message);
    }
  };
  /**Table format */
  tableColumn = (row, ind) => {
    let column = {
      _id: parseInt(row.cells[this.state.pos_id].innerHTML),
      // tele_id: parseInt(row.cells[this.state.pos_chatId].value),
      tele_id: parseInt(document.getElementById(`selRow${ind - 1}`).value),
      // selRow${ind}
      active: trueFalseTo_1_0(
        row.cells[this.state.pos_active].children[0].checked
      ),
      // bufferTime: parseFloat(row.cells[7].children[0].value)*60,
    };
    return column;
  };
  /**To-do
   * done 1. Load telegram list
   * done 2. Convert telegram list into enum
   * done 3. drop down selection for telegrm list
   * done 4. Add delete icon
   * done 5. click Add, add table
   * done 6. click delete, remove item from table
   * 7. click save, check table modified, then update databases
   */

  getTeleSubList = async (bd_id) => {
    let allSubList = await teleSubListFn.getByBd_id(bd_id);
    return allSubList;
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <h1>Telegram Subscribe List</h1>
          <label className="sel-label">Select building</label>
          <div style={{ fontSize: "25px" }}>
            {tableFn.tableDropDownKeySel(
              `buildingSel`,
              this.state.allBdEnum,
              1,
              "select-dd",
              () => this.handleBuildingSel()
            )}
          </div>
          <Icon
            className={this.state.icon}
            disabled={this.state.disabled}
            onClick={() => this.saveChat()}
          ></Icon>
          <div className="card">
            <div className="card-body" id="flip-scroll">
              <table className="cusTable" id="teleSubTable">
                <thead>
                  <tr>
                    {/* <th scope="col" id="cb"></th> */}
                    <th scope="col"></th>
                    <th scope="col">#</th>
                    <th style={{ display: "none" }}>_id</th>
                    {/* <th style={{ display: "none" }}>buildingID</th> 
                            <th scope="col">
                                <input id="Act_SelAll" type="checkbox" onClick={this.selAll}></input>
                            </th> */}
                    <th scope="col">Active</th>
                    <th scope="col">Telegram Chat Group</th>
                    <th scope="col">Set By</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.displayTable.map((u, ind) => (
                    <tr key={u._id}>
                      <td>
                        <Icon
                          className="fa fa-trash deactive"
                          onClick={() => this.delChat(ind)}
                        ></Icon>
                      </td>
                      <td>{ind + 1}</td>
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td>
                        <input id="Active_c" type="checkbox"></input>
                      </td>
                      <td>
                        {tableFn.dropDown(
                          `selRow${ind}`,
                          this.state.teleChatEnm,
                          u.tele_id,
                          "select-custom"
                        )}
                      </td>
                      <td>{u.userAmmend}</td>
                    </tr>
                  ))}
                  <tr>
                    <td> </td>
                    <td>
                      <Icon
                        className="fa fa-plus deactive"
                        onClick={() => this.insertNewChat()}
                      ></Icon>
                    </td>
                    <td> </td>
                    <td>
                      {/* {tableFn.dropDown(
                                        `newAddChat`,
                                        this.state.teleChatEnm,
                                        -1,
                                        "select-custom"
                                    )} */}
                    </td>
                    <td> </td>
                    {/* {this.state.bloaded_newSel ? (
                                <td>
                                    {tableFn.tableDropDownKeySel(
                                    `newAddBdDev`,
                                    this.state.newSelEnmList,
                                    0,
                                    "select-custom"
                                    // () => this.selNewBdDev()
                                    )}
                                </td>
                                ) : (
                                <td></td>
                                )}
                                <td colSpan="2">
                                <Icon
                                    className="fa fa-plus deactive"
                                    onClick={() => this.insertNewList()}
                                ></Icon>
                                </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default DevActTeleSubscribeList;
