import React, { Component } from 'react';
import SensorFn  from '../../function/sensor/sensorManagementFn';
import { sortTemplate } from '../utilities/ArrayFn/arrayFn';
import tableFn from '../utilities/tableFunction';
import { trueFalseTo_1_0 } from "../utilities/conversion";
import TpInput from './../template/InputText/TpInput';
import TpValueDisplay from './../template/ValueDisplay/TpValueDisplay';
import { ToastContainer, toast } from "react-toastify";

class SensorListPage extends Component {
    state = {         
        maxDefDisplayLine:2,    // default line to display on line graph
        sensorList:[],
        selSensor_type:0,
        currentParaList:[],
        typeList:[
            {_id:"pb", name:'BOOL [pb]'},
            {_id:"pf", name:'FLOAT [pf]'},
            {_id:"pi", name:'LONG [pi]'},
            {_id:"pn", name:'INT64 [pn]'},
        ],
        tableCol:{
            _id:1,
            dataName:2,
            dType:3,
            dataUnit:4,
            scaleBottom:5,
            scaleTop:6,
            defaultDisplay:7,
        },
        paraEnm_dd:[],
        loadedBattPara:'',
        bParaTableReload:false,
        bUpdateParaTableData:false,
        battInUse:false,
        displayPage:0,
     }

    async componentDidMount() {
        await this.loadSensorList();
    }

    loadSensorList=async()=>{
        let sensorList = await SensorFn.getSensorByVendor_id(0);
        
        let sortKeys = [{ key: "name", descOrder: false }];
        sensorList.sort((a, b) => sortTemplate(a, b, sortKeys));
        // console.log(sensorList);
        this.setState({sensorList});
    }
    
    showDetails=async (ind)=>{
        this.setState({displayPage:1});
        // console.log(ind);
        let sensorList = [...this.state.sensorList];
        // console.log();
        await this.setState({selSensor_type:sensorList[ind].type});
        // console.log(this.state.selSensor_type);
        await this.clickEdit();
    }

    genParaListEnm=()=>{
        try {
            let info = this.getTableInfo();
            let currentParaList = [...this.state.currentParaList];
            // console.log(currentParaList);
            // console.log(info);
            let list =[];
            // let index=0;
            for (const para of info) {
                let dbPara = currentParaList.find(c=>c._id===para._id)
                list.push({
                    _id:dbPara.sensorKey,
                    name:para.dataName,
                    // paraKey:SensorFn.genSensorKey(dbPara),
                });
                // index++;
            }
            return list;            
        } catch (error) {
            toast.error(error.message)
            // console.log(error.message);
            return [];
        }
    }

    clickEdit=async()=>{
        try {
            let selSensor_type = this.state.selSensor_type;
            /** load parameter in sensor management*/
            let currentParaList = await SensorFn.getParaByTypeList([selSensor_type]);
            // console.log(currentParaList);
            for (const para of currentParaList) {
                para.sensorKey = SensorFn.genSensorKey(para);                
            }            
            let sortKeys = [{ key: "sensorKey", descOrder: false }];
            currentParaList.sort((a, b) => sortTemplate(a, b, sortKeys));
            // console.log(currentParaList);
            await this.setState({bUpdateParaTableData:true});
            await this.setState({currentParaList});
            await this.setState({bUpdateParaTableData:false});
            
            this.updateCheckboxStatus(currentParaList);
    
            /** set sensor name when click edit sensor*/
            let sensorList=[...this.state.sensorList];
            let selSensor = sensorList.find(c=>c.type === selSensor_type);
            // console.log(selSensor);
            document.getElementById('sensorName').value = selSensor.name;            
            // document.getElementById('sensorType').value = selSensor.name;        
            /** set battery status checkbox */
            let battInUse = (selSensor.battKey!=='');
            await this.setState({battInUse});
            document.getElementById('runOnBatt').checked = battInUse;

            /** Start to reload battery selection bar */
            await this.setState({bParaTableReload:true});

            /** load battery parameter */
            let paraEnm_dd= this.genParaListEnm();
            await this.setState({paraEnm_dd});
            // console.log(paraEnm_dd);
            
            /** Set loaded battery para */
            await this.setState({loadedBattPara:selSensor.battKey});
            // console.log(this.state.loadedBattPara);

            /** re-display battery after reload */
            await this.setState({bParaTableReload:false});

            /** Change Display Page */
            this.setState({displayPage:2});
        } catch (error) {
            toast.error(error.message)
            // console.log(error.message);
        }
    }

    checkRunOnBatt=async ()=>{
        let battInUse = document.getElementById('runOnBatt').checked;
        await this.setState({battInUse});

    }

    updateCheckboxStatus=(paraList)=>{
        /** update checkbox status */
        try {
            let cnt=0;
            for (const para of paraList) {
                document.getElementById(`defDisCheckBox_${cnt}`).checked = (para.defaultDisplay===1);
                cnt++;
            }            
        } catch (error) {
            toast.error(error.message)
            // console.log(error.message);
        }
    }

    handleSave=async ()=>{      
        try {
            /** Check the quantity of default display check box */
            let tableSize = this.state.currentParaList.length;
            let checkCnt = 0;
            for (let i = 0; i < tableSize; i++) {
                if (document.getElementById(`defDisCheckBox_${i}`).checked) checkCnt++;                
            }
            if(checkCnt<1) 
                return toast.error("Default Display Need At Least 1.");
            else if(checkCnt > this.state.maxDefDisplayLine) 
                return toast.error(`Default Display Maximun ${this.state.maxDefDisplayLine}.`);




            /** Detect changes on sensor para list */
            let modifiedRows = tableFn.detectTableChanges(
                "sensorParaTable",
                this.state.currentParaList,
                "_id",
                this.tableColumn
            );
            // console.log(modifiedRows);
    



            /** Detect change on Selected Sensor */
            // console.log(this.state.sensorList);
            let currSensor = this.state.sensorList.find(c=>c.type === this.state.selSensor_type);
            // console.log(currSensor);

            /** Get sensor name */
            let name = document.getElementById('sensorName').value;
            // console.log(sensorName);
            /** Get Battery In Use CheckBox Status */
            let runOnBatt = document.getElementById('runOnBatt').checked;
            // console.log(runOnBatt);
            /** get battery key if any changes */
            let battKey='';
            if(runOnBatt) battKey = document.getElementById('battPara').value;

            let sensorInfo ={
                name,
                battKey,
                _id:currSensor._id,
            }
            // console.log(sensorInfo);

            let sensorDiff = false;
            for (const key in sensorInfo) {
                if (Object.hasOwnProperty.call(sensorInfo, key)) {
                    if(sensorInfo[key] !== currSensor[key]) {
                        sensorDiff=true;                    
                        break;
                    }
                }
            }
            // console.log(`Sensor Key Diff: ${sensorDiff}`);

            /** update Sensor List */
            let upadteErr = false;
            let modifyExecuted = false;
            if(sensorDiff){
                let sensorRel = await SensorFn.updateSensorList(sensorInfo);                
                if (!sensorRel) upadteErr=true;
                modifyExecuted = true;
            }

            if(modifiedRows.length > 0){
                let paraRel = await SensorFn.updateSensorPara(modifiedRows);   
                if(!paraRel) upadteErr=true;
                modifyExecuted = true;
            }

            upadteErr?toast.error(`Update Failed!`):toast.dark("Update Succesful!");

            /** if sensor update or para list update, reload list */
            if(modifyExecuted) {
                // console.log("load DB");
                await this.loadSensorList();
            }


        } catch (error) {
            toast.error(error.message);
            // console.log(error.message);
        }  
    }

    toPage= async(page)=>{
        await this.setState({displayPage:page});
    }
    
    /**Table format */
    tableColumn = (row, ind) => {
        let tableCol = {...this.state.tableCol};

        let column = {
            dataName: (row.cells[tableCol.dataName].children[0].value),
            // DataKey: document.getElementById(`selKeyRow${ind - 1}`).value,
            // dataUnit: (row.cells[tableCol.dataUnit].children[0].value),
            _id: parseInt(row.cells[tableCol._id].innerHTML),
            // dType: document.getElementById(`para_${ind-1}`).value,
            dataUnit: (row.cells[tableCol.dataUnit].children[0].value),
            // dataUnit: parseInt(row.cells[tableCol.dataUnit].children[0].checked),
            scaleBottom: parseFloat(row.cells[tableCol.scaleBottom].children[0].value),
            scaleTop: parseFloat(row.cells[tableCol.scaleTop].children[0].value),
            // scaleTop: parseInt(row.cells[tableCol.scaleTop].children[0].value),
            defaultDisplay:trueFalseTo_1_0(row.cells[tableCol.defaultDisplay].children[0].checked),
            // defaultDisplay: parseInt(row.cells[tableCol.defaultDisplay].children[0].value),
        };
        return column;
    };
    
    getTableInfo=()=>{
        let info = tableFn.getInfoFromTableSkipLastN('sensorParaTable', this.tableColumn);
        return info;
    }

    toAddNewPage=()=>{
        // console.log("To Add New");
        this.props.history.push({
            pathname: "/sensormng/addnew",
            _data: {},
        });
    }

    render() { 
        return ( <>
            <h1>Sensor List</h1>
            {<div style={{display:this.state.displayPage===0?'':'none'}}>
                <button className="universal-btn vfd" onClick={this.toAddNewPage} style={{ fontSize: "25px", display: 'inline-flex', }}>
                    Add New
                </button>
                <div className="card">
                    <div className="card-body">
                        <table className="cusTable" id="sensorListTable">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th style={{ display: "none" }}>_id</th>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>
                                    {/* <th scope="col">Type Key</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.sensorList.map((u, ind) => (
                                <tr key={`row_${ind}`}>     
                                    <td>{ind + 1}</td>        
                                    <td style={{ display: "none" }}>{u._id}</td>                                   
                                    <td>{u.name}</td>   
                                    <td><i 
                                        style={{
                                            // backgroundColor:"red",
                                            padding:"5px 10px"
                                        }}
                                        className="fa fa-edit" 
                                        onClick={()=>this.showDetails(ind)}
                                    /></td> 
                                    {/* <td>{u.type}</td>    */}
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}

            {<div style={{display:this.state.displayPage===2?'':'none'}}>
                <button className="universal-btn vfd" onClick={()=>this.toPage(0)} style={{ fontSize: "25px", display: 'inline-block', }}>
                    BACK
                </button>
                <TpInput id="sensorName" title={'Sensor Name:'}></TpInput>
                <TpValueDisplay id="sensorType" title="Type: " value={this.state.selSensor_type}></TpValueDisplay>
                <div className="card">
                    <div className="card-body">
                        <table className="cusTable" id="sensorParaTable">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th style={{ display: "none" }}>_id</th>
                                <th scope="col" style={{
                                    // justifyContent:'center',
                                    // alignItems:'center',
                                    }}>
                                        Name                                
                                </th>
                                <th scope="col">Data Type</th>
                                <th scope="col">Data Unit</th>
                                <th scope="col">Scale Bottom</th>
                                <th scope="col">Scale Top</th>
                                <th scope="col">Default Display</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.bUpdateParaTableData && this.state.currentParaList.map((u, ind) => (
                            <tr key={`row_${ind}`}>     
                                    <td>{ind + 1}</td>        
                                    <td style={{display:'none'}}>{u._id}</td>       
                                    <td>
                                        <input defaultValue={u.dataName} placeholder="name" onChange={this.paraNameChange}></input>                                    
                                    </td>   
                                    <td>
                                        {SensorFn.genSensorKey_BracketForm(u)}
                                    </td>     
                                    <td>
                                        <input defaultValue={u.dataUnit} placeholder="<blank>"></input>        
                                    </td>   
                                    <td>
                                        <input type='number' defaultValue={u.scaleBottom}></input>                                    
                                    </td>   
                                    <td>
                                        <input type='number' defaultValue={u.scaleTop}></input>
                                    </td>   
                                    <td>
                                        <input id={`defDisCheckBox_${ind}`} type='checkbox'></input>
                                    </td>   
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ alignItems:'center'}}>
                    <h3 style={{display:'inline-flex',}}>Running On Battery</h3>
                    <input 
                        id='runOnBatt' 
                        type='checkbox' 
                        onClick={this.checkRunOnBatt}
                    ></input>                
                </div>
                {this.state.battInUse && !this.state.bParaTableReload && <div style={{alignItems:'center'}}>
                    <h6 style={{display:'inline-flex',}} >Battery Parameter: </h6>                
                        {tableFn.tableDropDownKeySel(
                            `battPara`,
                            this.state.paraEnm_dd,
                            this.state.loadedBattPara,
                            "select-custom",
                            null,
                            // () => this.battParaSel()
                        )}      
                </div>}
                <button className="universal-btn vfd" onClick={this.handleSave} style={{ fontSize: "25px", display: 'inline-block', }}>
                    Save
                </button>
            </div>}
        <ToastContainer />
        </> );
    }
}
 
export default SensorListPage;