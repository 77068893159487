import v1CtrlOvvSer from '../../../service/V1/V1_CtrlOvvSer/V1_CtrlOvvSer';

async function getV1_CtrlOvv_area_dev(bd_id, ctrlPg){
    let result = await v1CtrlOvvSer.getV1_CtrlOvv_area_dev(bd_id, ctrlPg);
    // console.log(result);
    if(result.status===200 || result.status===203) return result.data;
    return null;
}

async function getV1_ctrlGwPair(bdDev_id){
    let result = await v1CtrlOvvSer.getV1_ctrlGwPair(bdDev_id);
    // console.log(result);
    if(result.status===200 || result.status===203) return result.data;
    return null;
}

async function setV1_ctrlGwPair(bdDev_id, gwid){
    let result = await v1CtrlOvvSer.setV1_ctrlGwPair(bdDev_id, gwid);
    // console.log(result);
    if(result.status===200 || result.status===203) return result.data;
    return null;
}





const expModule = {  
    getV1_CtrlOvv_area_dev,
    getV1_ctrlGwPair,
    setV1_ctrlGwPair,
};
export default expModule;