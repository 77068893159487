import React, { useState, useEffect, useRef } from 'react';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import cssFn from "../../../component/utilities/css/cssFunction";
import socketIOClient from "socket.io-client";

function TpAcpuLayout(props) {
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState(false);

    const timerRef = useRef(null);
    const pageClose = useRef(false);

    useEffect(() => {
        timerRef.current = setInterval(async() => {
            // console.log("Refresh");
            await reloadStatus();
        }, 300000);       /** 300*1000s , 5 mins*/

        let socket;
        async function startUp() {
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            await reloadStatus();
            setLoaded(true);

            let{ type, bdDev_id }= props;
            // let topic = `v2_${type}_${_id}`;
            let topic = `v1_${type}_${bdDev_id}`;
            // console.log("Socket IO topic: ", topic);
            socket.on(topic, async (data) => {
                // console.log("reload");
                // console.log(data);                                
                // let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
                // if(lastData) setG_Lastdata(lastData);
                await reloadStatus();
            });
        }
        
        startUp();
        
        return ()=>{
            clearInterval(timerRef.current);
            // console.log("test");
            //socket.disconnect();
            pageClose.current = true;
            // alert("AddDevicePg Page Close");
        }
    });

    const reloadStatus = async () => {
        let devInfo = { ht: props.type, bdDev_id: props.bdDev_id };
        let lastData = await BdDevFn_Com.loadLastData(devInfo);
        // console.log(lastData);
        // console.log(props.bdDev_id);
        let sensorTimeout = BdDevFn_Com.sensorTimeout(lastData);
        // console.log(sensorTimeout);
        let cnAdd = "";
        if(sensorTimeout) {
            cnAdd = "pdc_offline";
            // cssFn.cssShowStatus(false, `acpu_${props.bdDev_id}`, "pdc_run");
            // cssFn.cssShowStatus(false, `acpu_${props.bdDev_id}`, "pdc_error");
            // cssFn.cssShowStatus(true, `acpu_${props.bdDev_id}`, "pdc_offline");
        } else if(lastData.pb_1 === 1) {
            cnAdd = "pdc_error";
            // cssFn.cssShowStatus(false, `acpu_${props.bdDev_id}`, "pdc_run");
            // cssFn.cssShowStatus(false, `acpu_${props.bdDev_id}`, "pdc_offline");
            // cssFn.cssShowStatus(true, `acpu_${props.bdDev_id}`, "pdc_error");
        } else if(lastData.pb_0 === 1) {
            cnAdd = "pdc_run";
            // cssFn.cssShowStatus(false, `acpu_${props.bdDev_id}`, "pdc_error");
            // cssFn.cssShowStatus(false, `acpu_${props.bdDev_id}`, "pdc_offline");
            // cssFn.cssShowStatus(true, `acpu_${props.bdDev_id}`, "pdc_run");
        }
        for(const id of props.idList) {
            cssFn.cssShowStatus(false, id, "pdc_run");
            cssFn.cssShowStatus(false, id, "pdc_error");
            cssFn.cssShowStatus(false, id, "pdc_offline");
            cssFn.cssShowStatus(true, id, cnAdd);
        }
    }

    return ( 
        <div>
            <div id={`acpu_${props.bdDev_id}`} className='pdc_nodeCard'>{props.name}</div>
        </div>
    );
}

export default TpAcpuLayout;