// import buildings from "../../../service/setting_Buildings";
// import monListFn from '../../utilities/monList/monList';

async function getMonListNavBarName() {
  let navInfo = {};
  /** Get building Name */
  let selBuildingID = await localStorage.getItem(`selBuildingID`);
  let selBuildingName = await localStorage.getItem("selBuildingName");
  // console.log(selBuildingName);
  navInfo.buildingName = selBuildingName;
  navInfo.buildingID = selBuildingID;
  // let {data:building} = await buildings.getBuildingById(selBuildingID);
  // if (building[0]) {
  //     navInfo.buildingName = building[0].building;
  // }

  /** get Mon List name */
  let selT1Llist = await localStorage.getItem(`selT1Llist`);
  let selMonListName = await localStorage.getItem("selT1Name");
  navInfo.mmonListName = selMonListName;
  navInfo.mselMonId = selT1Llist;

  // let selMonItem = await monListFn.getMonlistBy_id(selT1Llist);
  // // console.log(selMonItem);
  // if (selMonItem[0]) {
  // }

  /**Get T1 name */
  let selEleList = await localStorage.getItem(`selEleList`);
  let selEleListName = await localStorage.getItem("selEleName");
  navInfo.t1Name = selEleListName;
  navInfo.tselT1Id = selEleList;

  // let SelT1Item = await monListFn.getT1By_id(selEleList);
  // // console.log(SelT1Item);
  // if (SelT1Item[0]) {
  // }

  return navInfo;
}

const expModule = {
  getMonListNavBarName,
};
export default expModule;
