import userService from '../../service/userManage';
import tool from '../../checkingTools/tools'


async function getAllUser(){
    try {
        let result = await userService.getAll(); 
        // console.log(result);
    
        if(result.status!==200) return null;    // 
        return result.data;        
    } catch (error) {
        console.log("getOwnerBuildings Err");
        tool.exResponse(error);
        return null;        
    }
}



const expModule = {  
    getAllUser,

};
export default expModule;