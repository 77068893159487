import React, { useState } from 'react';
import Icon from "./../common/icon";
import CustomInput from '../common/customInput';
import { regUser_V2 } from '../../service/V2_Auth';
import { toast } from 'react-toastify';

function V2_CreateUser(props) {
    const [G_usernameError, setG_usernameError] = useState("");
    const [G_passwordError, setG_passwordError] = useState("");
    const [G_cfmPasswordError, setG_cfmPasswordError] = useState("");

    const backToPrevious = () => {
        props.history.push({pathname: "/v2usermanage"});
    }

    const handleChange = (input_id) => {
        let _inputValue = document.getElementById(input_id).value;
        if(input_id === "username") {
            if(_inputValue.length < 6)
                setG_usernameError("Username must have at least 6 chars");
            else
                setG_usernameError("");
        } else if(input_id === "password") {
            if(_inputValue.length < 8)
                setG_passwordError("Password must have at least 8 chars");
            else
                setG_passwordError("");
        } else if(input_id === "cfmPassword") {
            let _pwValue = document.getElementById("password").value;
            if(_inputValue !== _pwValue) setG_cfmPasswordError("Passwords does not match");
            else setG_cfmPasswordError("");
        }
    }

    const handleCreateUser = async () => {
        try {
            if(G_usernameError === "" && G_passwordError === "" && G_cfmPasswordError === "") {
                let _username = document.getElementById("username").value.trim();
                let _password = document.getElementById("password").value;
                let _cfmPassword = document.getElementById("cfmPassword").value;
                if(_username.length && _password.length && _cfmPassword.length) {
                    let result = await regUser_V2({ username: _username, password: _password });
                    console.log(result);
                    backToPrevious();
                }
            }
        } catch (error) {
            toast(error.message);
        }
    }

    return (
        <div>
            <Icon
            className="fa fa-arrow-left deactive back"
            onClick={() => backToPrevious()}
            />
            <div className='flexVerAllCenter' style={{ height: "80vh", width: "100%" }}>
                <h1>Create New User</h1>
                <div style={{ margin: "30px 0" }}>
                    <CustomInput
                        type="text"
                        name="username"
                        label="Username"
                        onChange={() => handleChange("username")}
                        error={G_usernameError}
                    />
                    <CustomInput
                        type="password"
                        name="password"
                        label="Password"
                        onChange={() => handleChange("password")}
                        error={G_passwordError}
                    />
                    <CustomInput
                        type="password"
                        name="cfmPassword"
                        label="Confirm Password"
                        onChange={() => handleChange("cfmPassword")}
                        error={G_cfmPasswordError}
                    />
                    <div align="center" className="cusButton" onClick={handleCreateUser}>Confirm</div>
                </div>
            </div>
        </div>
     );
}

export default V2_CreateUser;