import React, { useEffect, useState } from 'react';

function TpDiaFailed(props) {
    // eslint-disable-next-line
    const [G_StatusErrMsg, setG_StatusErrMsg] = useState("Gateway Failed");

    useEffect(()=>{
        async function startUp(){
            if(props.diaErrMsg){
                setG_StatusErrMsg(props.diaErrMsg);
            }
        }
        startUp();

        return ()=>{

        }
        // eslint-disable-next-line
    }, []);
    

    const handleCloseDialog=()=>{
        // console.log("handleCloseDialog (internal)");
        if(!props.onClickCloseDia) return
        props.onClickCloseDia();
    }

    return (
        <div>
            <div className='autumn_dialogBoxContainer'>
                <div className='autumn_dialogBox autumn_dialogBox_fixHeight'>  
                    <div className='autumn_closeBtn btnColor_Grey hoverPointer'
                    onClick={handleCloseDialog}>
                        <i className=' fa fa-minus'></i>
                    </div>
                    <div className='autumn_statusIcon autumn_failedBgc'>
                        <i className=' fa fa-times'>
                        </i>
                    </div>
                    <div className='autumn_statusMsg'>
                        {G_StatusErrMsg}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpDiaFailed;