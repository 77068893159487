import eleListService from '../../../service/monList/eleList';
import tool from '../../../checkingTools/tools';


async function addEleList(body){
    try {
        let rel = await eleListService.addEleList(body);
        if (rel.status===200) return true; 
    } catch (error) {
        tool.exResponse(error);
    }
}

async function updateEleList(body){
    try {
        let rel = await eleListService.updateEleList(body);
        // console.log(rel);
        if (rel.status===200) return true;        
    } catch (error) {        
        tool.exResponse(error);
        return null;
    }
}

async function delEleList(obj){
    try {
        let result = await eleListService.delEleList(obj);
        if(result.status!==200) return null;  
        // console.log(result);
        return true;        
    } catch (error) {
        console.log("Delete Element List Err");
        tool.exResponse(error);
        return null;
    }
}

const expModule = {  
    addEleList,
    updateEleList,
    delEleList,
};
export default expModule;