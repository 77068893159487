import timeFn from '../../../../src/component/utilities/timeFn';

function toWeekScheLoRaFormat(weekSche){
    let DOW = weekSche.DOW;
    let action = weekSche.action;
    let inUse = weekSche.active;
    let schType = 1;
    let startUnix;
    let endUnix;
    let reserve1 = 0;

    if(action===3){     // holiday
        startUnix = timeFn.time_toUnix("00:00");
        endUnix = timeFn.time_toUnix("00:00");
    }else{      // on or off
        startUnix = weekSche.startUnix;
        endUnix = startUnix+60;     // set 1 min (60s) after
    }
    
    return ({startUnix, endUnix, schType, action, DOW, reserve1, inUse})

}

function toEventScheLoRaFormat(eventSche){
    let action = eventSche.action;
    let inUse = eventSche.active;
    let startUnix = eventSche.startUnix;
    let endUnix;
    let schType = 2;
    let reserve1 = 0;
    let DOW = 0;
    if(action===3){     // holiday
        endUnix=eventSche.endUnix;
    }else{      // on or off
        endUnix=startUnix+60;   // 1 min (60s) after 
    }

    return ({startUnix, endUnix, schType, action, DOW, reserve1, inUse})

}

function genSchePayload(scheList){
    let pi=[];
    let pb=[];
    for (const eachSche of scheList) {
        let {startUnix, endUnix, schType, action, DOW, reserve1, inUse} = eachSche;
        let scheType_Action = ((schType<<16) | action);
        let DOW_Reserve1 = ((DOW<<16) | reserve1);
        pi.push(startUnix);
        pi.push(endUnix);
        // pi.push(schType);
        // pi.push(action);
        pi.push(scheType_Action);
        // pi.push(DOW);
        // pi.push(reserve1);
        pi.push(DOW_Reserve1);
        pb.push(inUse);
    }
    
    let payload={pi, pb};
    return payload;
}

function getSche_DbFormat(weekSche, eventSche){
    try {
        let scheList_LoRaFormat=[];
        /** handle weekly schedule */
        let _WeeklyList = [...weekSche];
        for (const eachSche of _WeeklyList) {
            let eachSche_LoraFormat = toWeekScheLoRaFormat(eachSche);
            scheList_LoRaFormat.push(eachSche_LoraFormat);            
        }

        /** handle event schedule */
        let _EventList = [...eventSche];
        for (const eachEvent of _EventList) {
            let eachEvent_LoraFormat = toEventScheLoRaFormat(eachEvent);
            scheList_LoRaFormat.push(eachEvent_LoraFormat);
        }

        return scheList_LoRaFormat
    } catch (error) {
        console.log("getEvents_DbFormat err : ",error.message);
        return [];
    }
    
}

const sche_loraFormat_to_db=(data)=>{
    let {pb, pi} = data;
    let scheList_dbForm=[];
    for (let i = 0; i < 10; i++) {
        let scheType_Action = pi[(i*4)+2];
        let DOW_Reserve1 = pi[(i*4)+3];
        let singleSche={
            inUse : pb[i],
            startUnix : pi[(i*4)],
            endUnix : pi[(i*4)+1],
            action : scheType_Action>>16,
            schType : scheType_Action%65536,
            reserve1 : DOW_Reserve1>>16,
            DOW : DOW_Reserve1%65536,
        }
        if(singleSche.startUnix!==0 && singleSche.endUnix!==0 )    scheList_dbForm.push(singleSche);
    }
    // console.log(scheList_dbForm);
    return scheList_dbForm;
}

const expModule = {  
    toWeekScheLoRaFormat,
    toEventScheLoRaFormat,
    genSchePayload,
    getSche_DbFormat,
    sche_loraFormat_to_db,
};
export default expModule;