import React, { Component } from "react";
import Icon from "../common/icon";
import buildings from "../../service/setting_Buildings";
import tableUti from "../utilities/tableFunction";
import tools from "../../checkingTools/tools";
// import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

class RegBuilding extends Component {
  state = { allBuildings: [] };

  async componentDidMount() {
    await this._getAllBuildings();
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      this.setState({ allBuildings });
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  tableColumn = (row) => {
    let column = {
      _id: row.cells[2].innerHTML,
      owner: row.cells[3].innerHTML, // inner Html for non input
      building: row.cells[4].children[0].value,
      country: row.cells[5].children[0].value,
      state: row.cells[6].children[0].value,
      area: row.cells[7].children[0].value,
      postcode: row.cells[8].children[0].value,
      accessLevel: row.cells[9].children[0].value,
      userAmmend: row.cells[10].children[0].value,
    };
    return column;
  };

  handleUpdate = async () => {
    let modifiedRows = tableUti.detectTableChanges(
      "buildingTable",
      this.state.allBuildings,
      "_id",
      this.tableColumn
    );
    if (!modifiedRows[0]) return console.log("No Changes");
    //
    // console.log(modifiedRows);
    for (const row of modifiedRows) {
      try {
        let rel = await buildings.update(row);
        if (rel.status === 200) {
          console.log(
            `${rel.data.owner}, ${rel.data.building} update succesful`
          );
          toast.dark("Updated Successfully!");
        } else {
          console.log(`${rel.data.owner}, ${rel.data.building} update failed`);
          toast.error("Update Failed!");
        }
      } catch (ex) {
        tools.exResponse(ex);
      }
    }
    await this._getAllBuildings();
  };

  handleDelete = async () => {
    // console.log("Delete");
    let rowChecked = tableUti.rawChecked("buildingTable", this.tableColumn);
    if (!rowChecked[0]) return console.log("Non row checked");
    for (const row of rowChecked) {
      try {
        let rel = await buildings.del(row);
        if (rel.status === 200) {
          console.log(`${rel.data.building} delete succesful`);
          toast.dark("Deleted Succesfully!");
        } else {
          console.log(`${rel.data.building} delete failed`);
          toast.error("Failed to Delete!");
        }
      } catch (ex) {
        tools.exResponse(ex);
      }
    }
    await this._getAllBuildings();
  };

  changePage = () => {
    this.props.history.push("/setting/regBuilding");
  };

  render() {
    return (
      <React.Fragment>
        <h1>Buildings Management</h1>
        <Icon className="fa fa-plus deactive" onClick={this.changePage}></Icon>
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleUpdate()}
        />
        <Icon
          className="fa fa-trash-o deactive"
          onClick={() => this.handleDelete()}
        />
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="buildingTable">
              <thead>
                <tr>
                  <th id="cb"></th>
                  <th>#</th>
                  <th style={{ display: "none" }}>ID</th>
                  <th>Owner</th>
                  <th>Building</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>Area</th>
                  <th>Postcode</th>
                  <th>Access Level</th>
                  <th>Last Ammend</th>
                </tr>
              </thead>
              <tbody>
                {this.state.allBuildings.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input className="checkbox" type="checkbox"></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>{u.owner}</td>
                    <td>
                      {/* <Link to={`/buildings/management/${u._id}`}>
                        {u.building}
                      </Link> */}
                      <input type="text" defaultValue={u.building} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.country} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.state} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.area} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.postcode} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.accessLevel} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.userAmmend} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default RegBuilding;
