import React from "react";
import Joi from "joi-browser";
import buildings from "../../service/setting_Buildings";
// import Form from "../common/form";
import "./buildingReg.css";
import CustomForm from "./../common/customForm";
import Icon from "./../common/icon";

class BuildingRegister extends CustomForm {
  state = {
    allBuildings: [],
    data: {
      owner: "",
      building: "",
      country: "",
      state: "",
      area: "",
      postcode: "",
      // accessLevel: "",
      // userAmmend: ""
    },
    errors: {},
  };

  schema = {
    owner: Joi.string().required().min(2).max(80).label("Owner"),
    building: Joi.string().required().min(2).max(80).label("Building"),
    country: Joi.string().required().min(2).max(80).label("Country"),
    state: Joi.string().required().min(2).max(80).label("State"),
    area: Joi.string().required().min(2).max(80).label("Area"),
    postcode: Joi.string().required().min(5).max(80).label("Postcode"),
    // accessLevel: Joi.number().required().label("Access Level"),
    // userAmmend: Joi.string().required().min(3).max(80).label("Last Ammend By"),
  };

  async componentDidMount() {
    await this._getAllBuildings();
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      this.setState({ allBuildings });
      // for (const building of this.state.allBuildings) {
      //     for (const key in building) {
      //         if (building.hasOwnProperty(key)) {
      //             // const element = building[key];
      //             console.log(`[${key}]: ${building[key]}`)
      //         }
      //     }
      // }
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };
  doSubmit = async () => {
    try {
      let owner = document.getElementById("owner").value;
      let building = document.getElementById("building").value;
      console.log(`Owner: ${owner}`);
      console.log(`building: ${building}`);

      // check owner and building name are not duplicated
      const duplicated = this.state.allBuildings.filter(
        (b) =>
          b.owner.toUpperCase() === owner.toUpperCase() &&
          b.building.toUpperCase() === building.toUpperCase()
      );

      if (duplicated[0])
        return console.log(
          `Duplicated: ${duplicated[0].owner}, ${duplicated[0].building}`
        );

      console.log(`Continue`);
      //   const response = await regUser(this.state.data);
      const response = await buildings.reg(this.state.data);
      console.log(response);

      window.location = "/setting/buidlingManagement";

      // this.props.history.push("/");
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        // toast.error("This username already been registered");
        console.log(ex.response);
        const errors = { ...this.state.errors };
        errors.username = ex.response.data; //  set .username, the error will display under username
        this.setState({ errors });
      }
    }
  };

  changePage = () => {
    this.props.history.push("/setting/buidlingManagement");
  };

  render() {
    return (
      <React.Fragment>
        <Icon
          className="fa fa-arrow-left deactive back"
          onClick={this.changePage}
        />
        <div className="buildregcont">
          <div className="buildregcard">
            <h1>Register Building</h1>
            <form onSubmit={this.handleSubmit} className="form-buildreg">
              {this.renderCustomInput("owner", "Owner")}
              {this.renderCustomInput("building", "Building")}
              {this.renderCustomInput("country", "Country")}
              {this.renderCustomInput("state", "State")}
              {this.renderCustomInput("area", "Area")}
              {this.renderCustomInput("postcode", "Postcode")}
              {/* {this.renderInput("accessLevel", "Access Level")}
                {this.renderInput("userAmmend", "Last Ammend By")} */}
              <div align="center">{this.renderCustomButton("Register")}</div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BuildingRegister;
