import React, { useEffect, useState } from 'react';
// import TpCatSel from './TpCatSel';
import {VscGraphLine} from "react-icons/vsc";
import {RiRemoteControlLine, RiLayoutGridFill} from "react-icons/ri";
import pdcLogo from '../../../PictureFile/pdcIcon.png';
import auth from '../../../service/authService';
import YSCLogo from '../../../PictureFile/ysclogo.png';

function PDCMainPage(props) {
    const controlUser_idList = [129, 135];

    
    const [G_IsCtrlUser, setG_IsCtrlUser] = useState(false);

    useEffect(()=>{
        async function startUp(){
            /** check user level */
            let user = await auth.getCurrentUser();
            let found = controlUser_idList.find(c=>c===user._id);
            if(found) setG_IsCtrlUser(true);
        }
        startUp();

        return ()=>{

        }
        // eslint-disable-next-line
    }, []);

    const handleToControlPg=()=>{
        return props.history.push({pathname: "/ctrlpg/ovv"});
    }

    const handleToGraphPg=()=>{
        return props.history.push({pathname: "/selectpage"});
    }

    const handleToLayoutPg=()=>{
        return props.history.push({pathname: "/pdc/overview"});
    }

    const handleToContactVendorPg=()=>{
        return props.history.push({pathname: "/contact/ysc"});
    }

    return (
        <div>
            <div className='autumn_logoContainer'>
                <img src={pdcLogo} alt="PDC Logo"  className='autumn_logoFrame'/>
                {/* <div className='autumn_logoSub'>Hello</div> */}
            </div>

            <div className='sortHor autumn_MainPgItemContainer'>     
                <div>            
                    <div className='autumn_Card autumn_MainPgItemSel hoverPointer'
                    onClick={handleToLayoutPg}>
                        <div className='autumn_mainPgSelIcon'>
                            <div className='autumn_iconFrame'
                            style={{background:"var(--g1)"}}>
                                <RiLayoutGridFill/>
                            </div>
                        </div>
                        <div className='autumn_MainPgSelDesc'>Overview</div>            
                    </div>
                </div>             
                {G_IsCtrlUser && <div>            
                    <div className='autumn_Card autumn_MainPgItemSel hoverPointer'
                    onClick={handleToControlPg}>
                        <div className='autumn_mainPgSelIcon'>
                            <div className='autumn_iconFrame'
                            style={{background:"var(--g2)"}}>
                                <RiRemoteControlLine/>
                            </div>
                        </div>
                        <div className='autumn_MainPgSelDesc'>Control</div>            
                    </div>
                </div>     }
                <div>            
                    <div className='autumn_Card autumn_MainPgItemSel hoverPointer'
                    onClick={handleToGraphPg}>
                        <div className='autumn_mainPgSelIcon'                        >
                            <div className='autumn_iconFrame'
                            style={{background:"var(--g3)"}}>
                                <VscGraphLine style={{marginTop:"10px"}}/>
                            </div>
                        </div>
                        <div className='autumn_MainPgSelDesc'>Graph</div>            
                    </div>
                </div>
                <div>            
                    <div className='autumn_Card autumn_MainPgItemSel hoverPointer'
                    onClick={handleToContactVendorPg}>
                        <div className='autumn_mainPgSelIcon'>
                            <img src={YSCLogo} alt="YSC Logo"  className='pdc_logoFrame' width="80px" height="100px" />
                        </div>
                        <div className='autumn_MainPgSelDesc'>Contact Vendor</div>            
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PDCMainPage;