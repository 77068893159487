import teleSubList from './../../service/notification/teleSubList';

async function getByBd_id(bd_id){
    try {
        let result = await teleSubList.getByBd_id(bd_id);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}
async function add(obj){
    try {
        let result = await teleSubList.add(obj);
        // console.log(result);
        if(result.status!==200) return false
        return true;
    } catch (error) {
        console.log(error.message);
        return false
    }
}
async function del(obj){
    try {
        let result = await teleSubList.del(obj);
        // console.log(result);
        if(result.status!==200) return false
        return true;
    } catch (error) {
        console.log(error.message);
        return false
    }
}
async function update(obj){
    try {
        let result = await teleSubList.update(obj);
        // console.log(result);
        if(result.status!==200) return false
        return true;
    } catch (error) {
        console.log(error.message);
        return false
    }
}

const expModule = {  
    getByBd_id,
    add,
    del,
    update,
    // setStatusThreshold,
};
export default expModule;