function isEmptyArr(arr){
    if(!Array.isArray(arr)) return true;
    if(arr.length<1) return true;
    return false;
}

function isEmptyObj(obj){
    return (Object.keys(obj).length < 1);
}

function sortTemplate(a,b, sortKeys){
    if(!Array.isArray(sortKeys)) return 
    for (const sortObj of sortKeys) {
        if(typeof(a[sortObj["key"]])==='string'){
        // console.log('Is String');
        if(a[sortObj["key"]].toUpperCase().trim() < b[sortObj["key"]].toUpperCase().trim()) return (sortObj["descOrder"]) ? 1:-1;
        if(a[sortObj["key"]].toUpperCase().trim() > b[sortObj["key"]].toUpperCase().trim()) return (sortObj["descOrder"]) ? -1:1;
        }else{
        // console.log('Is Not String');
        if(a[sortObj["key"]] < b[sortObj["key"]]) return (sortObj["descOrder"]) ? 1:-1;
        if(a[sortObj["key"]] > b[sortObj["key"]]) return (sortObj["descOrder"]) ? -1:1;
        }
    }
    return 0;
}


exports.sortTemplate=sortTemplate;
exports.isEmptyArr=isEmptyArr;
exports.isEmptyObj=isEmptyObj;