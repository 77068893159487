const unitType = {
    temperature:{
        celsius:{type: "temperature", symbol:"°C", defToDis:defaultValue, disToDef:defaultValue},
        fahrenheit:{type: "temperature", symbol:"°F", defToDis:celsiusToFahrenheit, disToDef: fahrenheitToCelsius},
    },    
    humidity:{
        percentage:{type: "humidity", symbol:"RH %", defToDis:defaultValue, disToDef:defaultValue},
    },    
    voltage:{
        volt:{type: "voltage", symbol:"V", defToDis:defaultValue, disToDef:defaultValue},
    },
    flowRate:{
        m3ph:{type: "flowRate", symbol:"m³/h", defToDis:defaultValue, disToDef:defaultValue},
        m3ps:{type: "flowRate", symbol:"m³/s", defToDis:m3phToPs, disToDef:m3psToPh},
    },
    non:{
        non:{type: "non", symbol:" ", defToDis:defaultValue, disToDef:defaultValue},
    },    
    current:{
        amp:{type: "current", symbol:"A", defToDis:defaultValue, disToDef:defaultValue},        
        // amp:{type: "current", symbol:"A", defToDis:defaultValue, disToDef:defaultValue},
    },
    frequency:{
        hertz:{type: "frequency", symbol:"Hz", defToDis:defaultValue, disToDef:defaultValue},
    },
    pressure:{
        bar:{type: "pressure", symbol:"bar", defToDis:defaultValue, disToDef:defaultValue},
    },
    acticePower:{
        kilowatt:{type: "acticePower", symbol:"kW", defToDis:defaultValue, disToDef:defaultValue},
        watt:{type: "acticePower", symbol:"W", defToDis:kiloTo, disToDef:toKilo},
    },
    energy:{
        kiloWattHour:{type: "energy", symbol:"kWh", defToDis:defaultValue, disToDef:defaultValue},
    },

};

function toKilo(n){
    return n/1000;
}

function kiloTo(n){
    return n*1000;
}

function defaultValue(value){
    return value;
}

function fahrenheitToCelsius(f){
    return (f-32)*5/9;
}

function celsiusToFahrenheit(c){
    return (c*9/5)+32;
}

function m3phToPs(c){
    // return (c*3600);     // temprary disable unit data conversion
    return c
}

function m3psToPh(c){
    return (c/3600);
}

const expModule = {
    unitType,
};
export default expModule;