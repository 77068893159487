import React from "react";
import "./customInput.css";

const CustomInput = ({ name, label, error, ...rest }) => {
  return (
    <ul className="cusFormList">
      <li className="cusFormList_row">
        <label>{label}</label>
        <input {...rest} name={name} id={name} className="cusInput" />
        {error && <div className="alert alert-danger">{error}</div>}
      </li>
    </ul>
  );
};

export default CustomInput;
