import unitFn from './unitType';


const com_Batt = {name: "Battery", key:"battVoltage", defaultUnit: unitFn.unitType.voltage.volt};
const com_RSSI = {name: "RSSI", key:"RSSI", defaultUnit: unitFn.unitType.non.non};
const com_SNR = {name: "SNR", key:"SNR", defaultUnit: unitFn.unitType.non.non};
const rtrh_temp = {name: "Temperature", key:"temperature", defaultUnit: unitFn.unitType.temperature.celsius};
const rtrh_humid = {name: "R. Humidity", key:"humidity", defaultUnit: unitFn.unitType.humidity.percentage};
const porbe_temp = {name: "Temperature", key:"temperature", defaultUnit: unitFn.unitType.temperature.celsius};
const dpm_ca = {name: "Current L1", key:"CurrentA", defaultUnit: unitFn.unitType.current.amp };
const dpm_cb = {name: "Current L2", key:"CurrentB", defaultUnit: unitFn.unitType.current.amp };
const dpm_cc = {name: "Current L3", key:"CurrentC", defaultUnit: unitFn.unitType.current.amp };
const dpm_va = {name: "Voltage L1", key:"VoltageA_B", defaultUnit: unitFn.unitType.voltage.volt };
const dpm_vb = {name: "Voltage L2", key:"VoltageB_C", defaultUnit: unitFn.unitType.voltage.volt };
const dpm_vc = {name: "Voltage L3", key:"VoltageC_A", defaultUnit: unitFn.unitType.voltage.volt };
const dpm_f = {name: "Frequency", key:"DpmFrequency", defaultUnit: unitFn.unitType.frequency.hertz };
const dpm_apa = {name: "ActivePower L1", key:"ActivePower_A", defaultUnit: unitFn.unitType.acticePower.kilowatt};
const dpm_apb = {name: "ActivePower L2", key:"ActivePower_B", defaultUnit: unitFn.unitType.acticePower.kilowatt};
const dpm_apc = {name: "ActivePower L3", key:"ActivePower_C", defaultUnit: unitFn.unitType.acticePower.kilowatt};
const dpm_apt = {name: "ActivePower Total", key:"ActivePower_Total", defaultUnit: unitFn.unitType.acticePower.kilowatt};
const dpm_pfa = {name: "Power Factor L1", key:"PowerFactor_A", defaultUnit: unitFn.unitType.non.non};
const dpm_pfb = {name: "Power Factor L2", key:"PowerFactor_B", defaultUnit: unitFn.unitType.non.non};
const dpm_pfc = {name: "Power Factor L3", key:"PowerFactor_C", defaultUnit: unitFn.unitType.non.non};
const dpm_pft = {name: "Power Factor Total", key:"PowerFactor_Total", defaultUnit: unitFn.unitType.non.non};
const dpm_e = {name: "Energy", key:"ActiveEnergyDelivered", defaultUnit: unitFn.unitType.energy.kiloWattHour};
const press_mA = {name: "Current", key:"mA", defaultUnit: unitFn.unitType.non.non};
const press_p = {name: "Pressure", key:"pressure", defaultUnit: unitFn.unitType.pressure.bar};
const usf_fr = {name: "Flow Rate", key:"FlowPHour", defaultUnit: unitFn.unitType.flowRate.m3ph};
const usf_t1 = {name: "Inlet Temperature", key:"RTD1", defaultUnit: unitFn.unitType.temperature.celsius};
const usf_t2 = {name: "Outlet Temperature", key:"RTD2", defaultUnit: unitFn.unitType.temperature.celsius};
const af_mA = {name: "Current", key:"mA", defaultUnit: unitFn.unitType.temperature.celsius};
const af_fr = {name: "Flow Rate", key:"FlowRate", defaultUnit: unitFn.unitType.flowRate.m3ps};
const af_12v = {name: "DC Supply", key:"DcSupply12v", defaultUnit: unitFn.unitType.voltage.volt};

const ss_on = {name: "Status", key:"SwitchOn", defaultUnit: unitFn.unitType.non.non};
const ss_rawAo = {name: "Raw Analogue", key:"VoltageLevel", defaultUnit: unitFn.unitType.non.non};
/**VFD control */
const vfd_actHz = {name: "VFD Act Hz", key:"actHz", defaultUnit: unitFn.unitType.frequency.hertz};
const vfd_kW = {name: "VFD kW", key:"kW", defaultUnit: unitFn.unitType.acticePower.kilowatt};
const vfd_Hz_lowerLimit = {name: "VFD Set Hz", key:"Hz_lowerLimit", defaultUnit: unitFn.unitType.frequency.hertz};
const vfd_pidKp = {name: "Kp", key:"pidKp", defaultUnit: unitFn.unitType.non.non};
const vfd_pidKi = {name: "Ki", key:"pidKi", defaultUnit: unitFn.unitType.non.non};
const vfd_pidKd = {name: "Kd", key:"pidKd", defaultUnit: unitFn.unitType.non.non};
const vfd_pidOutMin = {name: "Min Hz", key:"pidOutMin", defaultUnit: unitFn.unitType.frequency.hertz};
const vfd_pidOutMax = {name: "Max Hz", key:"pidOutMax", defaultUnit: unitFn.unitType.frequency.hertz};
const vfd_hzOutput = {name: "Output Hz", key:"hzOutput", defaultUnit: unitFn.unitType.frequency.hertz};
const vfd_pidSetpoint = {name: "Set Point", key:"pidSetpoint", defaultUnit: unitFn.unitType.temperature.celsius};
const vfd_feedbackVal = {name: "Feedback", key:"feedbackVal", defaultUnit: unitFn.unitType.temperature.celsius};
const vfd_ManualOutput = {name: "Manual Output", key:"ManualOutput", defaultUnit: unitFn.unitType.frequency.hertz};
const vfd_modbusId = {name: "Modbus ID", key:"modbusId", defaultUnit: unitFn.unitType.non.non};
const vfd_nodeOpMode = {name: "Operation mode", key:"nodeOpMode", defaultUnit: unitFn.unitType.non.non};


const vfdKey = {
    vfd_actHz,
    vfd_kW ,
    vfd_Hz_lowerLimit,
    vfd_pidKp,
    vfd_pidKi,
    vfd_pidKd,
    vfd_pidOutMin,
    vfd_pidOutMax,
    vfd_hzOutput,
    vfd_pidSetpoint,
    vfd_feedbackVal,
    vfd_ManualOutput,
    vfd_modbusId,
    vfd_nodeOpMode,
}

const statusSwitchKey = {
    ss_on,
    ss_rawAo,
}
const rtrhKey = {
    rtrh_temp,
    rtrh_humid,
}
const probeKey = {
    porbe_temp,
}
const dpmKey = {
    dpm_ca,
    dpm_cb,
    dpm_cc,
    dpm_va,
    dpm_vb,
    dpm_vc,
    dpm_f,
    dpm_apa,
    dpm_apb,
    dpm_apc,
    dpm_apt,
    dpm_pfa,
    dpm_pfb,
    dpm_pfc,
    dpm_pft,
    dpm_e,
}
const pressureKey = {
    press_mA,
    press_p,
}
const usfKey = {
    usf_fr,
    usf_t1,
    usf_t2,
}
const comKey = {
    com_Batt, 
    com_RSSI,
    com_SNR,
}
const afKey = {
    af_mA,
    af_fr,
    af_12v,
}
const expModule = {
    ...rtrhKey,  
    ...probeKey,  
    ...dpmKey,
    ...pressureKey,
    ...usfKey,
    ...comKey,
    ...afKey,
    ...statusSwitchKey,
    ...vfdKey,
    rtrhKey,
    probeKey,
    dpmKey,
    pressureKey,
    usfKey,
    comKey,
    afKey,
    statusSwitchKey,
    vfdKey,
};

export default expModule;

// exports.rtrhKey=rtrhKey;

// const expModule = { 
//     usf_fr,
//     usf_t1,
//     usf_t2,
//     press_mA,
//     press_p,
//     dpm_ca,
//     dpm_cb,
//     dpm_cc,
//     dpm_va,
//     dpm_vb,
//     dpm_vc,
//     dpm_f,
//     dpm_apa,
//     dpm_apb,
//     dpm_apc,
//     dpm_apt,
//     dpm_pfa,
//     dpm_pfb,
//     dpm_pfc,
//     dpm_pft,
//     dpm_e,
//     com_Batt, 
//     com_RSSI,
//     com_SNR,
//     rtrh_temp,
//     rtrh_humid,
//     porbe_temp,

// };
// export default expModule;