import queryDataSer from '../../../service/queryBdDevData';
import bdDevSer from '../../../service/buildingDevicesService';

async function getLastNData(ht, bdDev_id, nCnt){
    // let url = process.env.REACT_APP_PUBLIC_URL+"/api/bddev/min/";    
    try {
        let rel = await queryDataSer.getLastNData(ht, bdDev_id, nCnt);
        // console.log("rel : ", rel);
        if(rel.status === 200) return rel.data;
        return false
    } catch (error) {
        console.log("Get Last Data Err");
        return false;
    }
}
async function getBdDevInfoBy_id(_id){
    // let url = process.env.REACT_APP_PUBLIC_URL+"/api/bddev/min/";    
    try {
        // let rel = await queryDataSer.getLastNData(ht, bdDev_id, nCnt);
        let rel = await bdDevSer.getById(_id);
        // console.log("rel : ", rel);
        if(rel.status === 200) return rel.data;
        return false
    } catch (error) {
        console.log("Get Last Data Err");
        return false;
    }
}

  
const expModule = {  
    getLastNData,
    getBdDevInfoBy_id,
};
export default expModule;