import http from "../httpService";

async function getByIdnKey(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/offset/getbyidnkey`;    
    const body = {   
        type: obj.type,
        devID: obj.devID,
    };
    return await http.post(url, body);    
}

async function getBySearch(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/offset/getbysearch`;    
    const body = {   
        type: obj.type,
        DataKey: obj.DataKey,
        devIDStart: obj.devIDStart,
        devIDEnd: obj.devIDEnd,
    };
    return await http.post(url, body);    
}


async function addOffset(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/offset/add`;    
    const body = {   
        type: obj.type,
        DataKey: obj.DataKey,
        devID: obj.devID,
        offsetValue: obj.offsetValue,
    };
    return await http.post(url, body);    
}

async function update(obj){
    console.log(obj);
    let url = `${process.env.REACT_APP_PUBLIC_URL}/offset/update`;    
    const body = {   
        _id: parseInt(obj._id),
        offsetValue: parseFloat(obj.offsetValue),
    };
    return await http.post(url, body);    
}

async function del(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/offset/del";
    const body = {
        _id:obj._id
      };
    return await http.post(url, body);    
}


const expModule = {  
    del,
    addOffset,
    update,
    getByIdnKey,
    getBySearch,

};
export default expModule;