import React from "react";
import * as FaIcons from "react-icons/fa";
import * as GoIcons from "react-icons/go";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as TbIcons from "react-icons/tb";
import getMonList from "../utilities/getMonList";
import monlistFn from '../../component/utilities/monList/monList';
import auth from "../../service/authService";

export const Sidebar = [
  {
    title: "Data Monitoring",
    path: "/selectpage",
    icon: <RiIcons.RiFolderChartLine style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 99,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Setting",
        path: "/monlist/addlist",
        icon: <MdIcons.MdSettings style={{ fill: '#f59800' }}/>,
        cName: "sub-nav-text",
        accessLevel: 10,
        color: "#FFFFFF"
  },
  {
    title: "Sensor",
    path: "/datapresent/building",
    icon: <GoIcons.GoGraph style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 10,
  },  
  {
    title: "Control",
    path: "/ctrldev/ac",
    icon: <RiIcons.RiRemoteControl2Line style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 60,
  },
  {
    title: "Data Download",
    path: "/datadownload",
    icon: <FaIcons.FaDownload style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 70,
  },
  // {
  //   title: "Setting",
  //   path: "/setting",
  //   icon: <FaIcons.FaCog />,
  //   cName: "nav-text",
  //   accessLevel: 10
  // },
  {
    title: "Device Management",
    path: "/regdevice",
    icon: <RiIcons.RiDeviceFill style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 7,
  },
  {
    title: "Building Management",
    path: "/setting/buidlingManagement",
    icon: <FaIcons.FaBuilding style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 7,
  },
  {
    title: "Devices Assign",
    path: "/buildings/select",
    icon: <MdIcons.MdDevices style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 7,
  },
  {
    title: "Building Assign",
    path: "/buildingassign",
    icon: <RiIcons.RiBuildingFill style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 7,
  },
  {
    title: "User Management",
    path: "/user/management",
    icon: <FaIcons.FaUsersCog style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "Telegram",
    path: "/notification/teleid",
    icon: <FaIcons.FaTelegramPlane style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 10,
  },
  {
    title: "Notification",
    path: "/notification/list",
    icon: <MdIcons.MdNotifications style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 70,
  },
  {
    title: "Calibration",
    path: "/offset",
    icon: <GoIcons.GoSettings style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 7,
  },
  {
    title: "Control Device",
    path: "/ctrldev",
    icon: <RiIcons.RiRemoteControl2Line style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "VFD Control",
    path: "/ctrldev/vfd",
    icon: <AiIcons.AiOutlineControl style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 7,
  },
  {
    title: "Device Connectivity",
    path: "/notification/DeviceActive",
    icon: <MdIcons.MdCastConnected style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "Status Node Setting",
    path: "/statusnode/threshold",
    icon: <MdIcons.MdSettingsApplications style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "Dev. Act Telegram",
    path: "/notification/devactsublist",
    icon: <FaIcons.FaTelegram style={{ fill: '#f59800' }}/>,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "Battery Diagnostic",
    path: "/diag/batt",
    icon: <MdIcons.MdBatteryUnknown style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
  // {
  //   title: "Sensor Manage",
  //   path: "/sensormng/addnew",
  //   icon: <GoIcons.GoGraph />,
  //   cName: "nav-text",
  //   accessLevel: 5,
  // },
  {
    title: "Sensor List",
    path: "/sensormng/sensorlist",
    icon: <GoIcons.GoGraph />,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "V2 User Management",
    path: "/v2usermanage",
    icon: <FaIcons.FaUsersCog style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
  {
    title: "V2 Sensor CheckList",
    path: "/v2sensorchecklist",
    icon: <TbIcons.TbChecklist style={{ fill: '#f59800' }} />,
    cName: "nav-text",
    accessLevel: 5,
  },
];

async function addSubNav() {
  let monList = await getMonList.getMonList();
  /** Filter authorize user
   * access level > 10 , refer to DB
   * < 10, can see everything
   */
  const user = auth.getCurrentUser();
  // console.log(user);
  
  let assignedMonList = [];

  if (user.accessLevel > 10) {
    let ownedMonList = await monlistFn.getAssignedMonListByUserID(user._id); 
    let assignedItem = {};
    for (const ownList of ownedMonList) {
      assignedItem = monList.find(c => ownList.MonitorList_id === c._id);
      assignedMonList.push(assignedItem);
    }
  } else {
    assignedMonList = [...monList];    
  }
    
  assignedMonList.sort((a, b) => (a.SortIndex > b.SortIndex ? -1 : 1));

  let submenu = [];
  if (assignedMonList[0]) {
    // eslint-disable-next-line
    assignedMonList.map((item) => {
      submenu.push({
        title: item.name,
        path: "/monlist/" + item._id,
        icon: <RiIcons.RiFileChartLine />,
        cName: "sub-nav-text",
        accessLevel: 99
      });
    });
  }

  // console.log(submenu);
// eslint-disable-next-line
  Sidebar.map((item) => {
    if (item.title === "Data Monitoring") {
      item.subNav = [
        {
        title: "Setting",
        path: "/monlist/addlist",
        icon: <MdIcons.MdSettings />,
        cName: "sub-nav-text",
        accessLevel: 10
      },
      ];
      // eslint-disable-next-line
      submenu.map((it) => {
        item.subNav.unshift(it);
      });
    }
  });
}

export async function sidebarSubnav() {
  await addSubNav();
  return true;
}

// const expModule = {
//   Sidebar,
//   addSubNav,
// };
// export default expModule;
