import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import XLSX from 'xlsx' ;
import { toast } from "react-toastify";
import '../../css/devManagement/regDev.css'
import excelDownload from '../../function/downloadExcel/excelFunction';

class V2_RegDev extends Component {
    state={
        headerFormat : ['No', 'type', 'ID', 'battConst', 'sleep_uA'],       // must same as V2_RegDeviceEditPg.jsx
        devExcel:[],
    }

    getDropZoneInfo=()=>{
        /** 1. create a drop zone at */
        /** 2. Add new info to database */
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        try {            

            /** handle upload file error */
            if (Array.isArray(rejectedFiles)) {
                if(rejectedFiles.length > 1) return toast.error('Uploaded File Too Many');
                if(rejectedFiles.length > 0) return toast.error('Invalid File');
            }
            
            /**=============== Read CSV ==============*/
            // console.log("handleExcelDrop")
            // setFileNames(acceptedFiles.map(file => file.name));
            acceptedFiles.forEach((file) => {
                // console.log("handleExcelDrop:forEach(file)")
                const reader = new FileReader() 
                const rABS = !!reader.readAsBinaryString;  // !! converts object to boolean 
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = (e) => {
                    // Do what you want with the file contents 
                    var bstr = e.target.result; 
                    var workbook = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
                    var sheet_name_list = workbook.SheetNames[0];
                    var jsonFromExcel = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list], {
                        raw: false,
                        dateNF: "MM-DD-YYYY",
                        header:1,
                        defval: ""
                    })
                    // console.log("jsonFromExcel object=")
                    // console.log(jsonFromExcel)

                    /** file read is not empty */
                    if(Array.isArray(jsonFromExcel)){
                        /** handle no header/ empty content */
                        if(jsonFromExcel.length < 2) return toast.error('Invalid Content');
                        
                        // console.log(jsonFromExcel[0]);
                        let uploadedHeaders = jsonFromExcel[0];
                        /** check header */
                        let headers = [...this.state.headerFormat];

                        /** Header got extra column / less column */
                        if (headers.length !== uploadedHeaders.length) return toast.error('Invalid Content Format');
                        for (let i = 0; i < headers.length; i++) {
                            if(headers[i] !== uploadedHeaders[i]) return toast.error('Invalid Content Format');
                            
                        }
                        /** Store content into array of object */
                        let devExcel = [];
                        for (let i = 1; i < jsonFromExcel.length; i++) {
                            let devInfo = {};
                            for (let j = 0; j < headers.length; j++) {                                
                                devInfo[this.state.headerFormat[j]] = jsonFromExcel[i][j];                                
                            }                            
                            devExcel.push(devInfo);
                        }
                        this.setState({devExcel});
                        // console.log("Header Pass");
                        // console.log(devExcel);
                        
                        /** direct to reg device edit page */
                        this.props.history.push({
                            // pathname: "/v2devmanage/devlist",
                            pathname: "/v2devmanage/regdevedit",
                            _data: {
                                devExcel
                            },
                        });
                    }

                }
                if (rABS) reader.readAsBinaryString(file);
                else reader.readAsArrayBuffer(file);
            })

        } catch (error) {
            console.log(error.message);
        }
    }


    dnTemplate=async()=>{
        // console.log('Download Template');
        let headerFormat = [...this.state.headerFormat];
        let datObj = {};
        for (const header of headerFormat) {
            datObj[header] = null;
        }

        let data=[datObj];
        //this.state.headerFormat
        await excelDownload(data, 'Register Sensor Template.xlsx');
        // console.log(rel);
        // await this.downloadAsExcel(this.state.headerFormat);
    }

    toRegEditPage=()=>{
        /** direct to reg device edit page */
        this.props.history.push({
            // pathname: "/v2devmanage/devlist",
            pathname: "/v2devmanage/regdevedit",
            // _data: {
            //     devExcel
            // },
        });
    }

    render() { 
        return (<div>   
            <Dropzone 
            
            onDrop={this.onDrop}
            accept=".xlsx, .xls, .csv"
            multiple={false}
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}
                    className='RegDevDropzone'>
                        <input {...getInputProps()} />
                        Drop File Here / Click To Choose File
                    </div>
                )}
            </Dropzone>
            <div className='dnTemplateFloatLeft'>
                <div className='underline' onClick={this.dnTemplate}>
                    Download template
                </div>
            </div>
            <div className='alignCenter'>
                <div className='downloadButton'
                onClick={this.toRegEditPage}> Insert Data </div>     
            </div>
        </div>);
    }
}
 
export default V2_RegDev;