import React, { Component } from "react";
import tableFn from "../utilities/tableFunction";
import cssFn from "../utilities/css/cssFunction";
import buildings from "../../service/setting_Buildings";
import bdDevFn from "../utilities/getDevices";
import { sortByName } from "../utilities/ArrayFn/arrayFn";
import qryBdDevService from "../../service/queryBdDevData";
import loraPac from "../../function/genLoRaPackage";
import controlDevFn from "../utilities/conntrolDevice/ctrlDevFn";
import { ToastContainer, toast } from "react-toastify";
import ctrlDevFn from "../utilities/conntrolDevice/ctrlDevFn";

class VfdCtrlPage extends Component {
  state = {
    allBuildings: [],
    allBdEnum: [],
    vfdEnm: [],
    fbEnm: [],
    selVfdInfo: {},
    bLoaded: false,
    bLoaded_Feedback: false,
    bManualMode: 1,
  };

  async componentDidMount() {
    await this._getAllBuildings();
    this.getBuildingEnm();
    this.handleOpSel();
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      await this.setState({ allBuildings });
      // console.log(this.state.allBuildings);
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  getBuildingEnm = () => {
    let allBuildings = [...this.state.allBuildings];
    let enmItem = {};
    let allBdEnum = [];
    for (const singleBd of allBuildings) {
      enmItem = {
        name: singleBd.building,
        _id: singleBd._id,
      };
      allBdEnum.push(enmItem);
    }
    allBdEnum.sort((a, b) => sortByName(a, b));
    this.setState({ allBdEnum });
  };

  opModeEnum = [
    { _id: 0, name: "Manual" },
    { _id: 1, name: "PID" },
  ];

  selVfdDevice = async () => {};

  displayPara = () => {
    try {
      // let pidActive = this.state.bManualMode
      cssFn.cssShowStatus(
        this.state.bManualMode !== 1,
        "manual_para",
        "cssHide"
      );
      cssFn.cssShowStatus(this.state.bManualMode !== 2, "pid_para", "cssHide");
      cssFn.cssShowStatus(
        this.state.bManualMode !== 3,
        "feedback_para",
        "cssHide"
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  handleOpSel = () => {
    this.displayPara();
  };

  getVfdDevUnderBuilding = async (bd_id) => {
    /** select * from where Building_id = 23 */
    /**Gen enum list */
    let bdDevs = await bdDevFn.getBdDevBybdID(bd_id);
    // eslint-disable-next-line
    let bdVfdCtrl = bdDevs.filter((c) => c.type == 7);
    // let bdVfdCtrl = bdDevs.filter(c=>c.type==7 && c.devID != 0);
    return bdVfdCtrl;
  };

  getTempDevUnderBuilding = async (bd_id) => {
    /** select * from where Building_id = 23 */
    /**Gen enum list */
    let bdDevs = await bdDevFn.getBdDevBybdID(bd_id);
    // eslint-disable-next-line
    let bdVfdCtrl = bdDevs.filter((c) => c.type == 1 || c.type == 2);
    return bdVfdCtrl;
  };

  toManagementPage = () => {
    this.props.history.push({
      pathname: "/ctrldev/management",
      _data: {
        // _selBuildingId: this.state.selBuildingId,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
  };
  //

  handleDevSel = async () => {
    await this.loadVdf();
    await this.loadFeedbackDevice();
  };

  bdDevToEnum = (bdVfdCtrl) => {
    let defaultText = "";
    if (!bdVfdCtrl[0]) defaultText = "N/A";
    let vfdEnm = bdDevFn.turnBdDevIntoEnm(bdVfdCtrl, defaultText);
    // console.log(vfdEnm);
    return vfdEnm;
  };

  loadVdf = async () => {
    let bdDevSel_id = document.getElementById("bdDevSel").value;
    await this.loadVfdInfo(7, bdDevSel_id);
    // console.log(bdDevSel_id);
  };

  loadVfdInfo = async (type, bdDev_id) => {
    if (bdDev_id < 0) return this.clearParaTable();
    let { data: vfdDevInfo } = await qryBdDevService.getLastNData(
      type,
      bdDev_id,
      1
    );
    // console.log(vfdDevInfo);
    /** set vfd info into column */
    // console.log(vfdDevInfo.hzOutput);
    if (!vfdDevInfo[0]) return this.clearParaTable();
    await this.setState({ selVfdInfo: vfdDevInfo[0] });
    this.fillUpParaTable(vfdDevInfo[0]);
  };

  clearParaTable = () => {
    // console.log('Clear');
    document.getElementById("gwid").value = "";
    document.getElementById("manualHz").value = "";
    document.getElementById("mbid").value = "";
    document.getElementById("sp").value = "";
    document.getElementById("Min").value = "";
    document.getElementById("Max").value = "";
    document.getElementById("Kp").value = "";
    document.getElementById("Ki").value = "";
    document.getElementById("Kd").value = "";
  };

  fillUpParaTable = (vfdDevInfo) => {
    document.getElementById("gwid").value = vfdDevInfo.gwID;
    document.getElementById("opModeSel").value = vfdDevInfo.nodeOpMode;
    document.getElementById("manualHz").value = vfdDevInfo.ManualOutput;
    document.getElementById("mbid").value = vfdDevInfo.modbusId;
    document.getElementById("sp").value = vfdDevInfo.pidSetpoint;
    document.getElementById("Min").value = vfdDevInfo.pidOutMin;
    document.getElementById("Max").value = vfdDevInfo.pidOutMax;
    document.getElementById("Kp").value = vfdDevInfo.pidKp;
    document.getElementById("Ki").value = vfdDevInfo.pidKi;
    document.getElementById("Kd").value = vfdDevInfo.pidKd;
  };

  getTableVfdInfo = () => {
    let devInof = {
      gwID: document.getElementById("gwid").value,
      // ManualOutput : document.getElementById('manualHz').value,
      modbusId: document.getElementById("mbid").value,
      pidSetpoint: document.getElementById("sp").value,
      pidOutMin: document.getElementById("Min").value,
      pidOutMax: document.getElementById("Max").value,
      pidKp: document.getElementById("Kp").value,
      pidKi: document.getElementById("Ki").value,
      pidKd: document.getElementById("Kd").value,
    };
    return devInof;
  };

  handleBuildingSel = async () => {
    let bd_id = document.getElementById("buildingSel").value;
    // console.log(bd_id);
    let vfdList = await this.getVfdDevUnderBuilding(bd_id);
    let vfdEnm = this.bdDevToEnum(vfdList);
    await this.setState({ bLoaded: false });
    await this.setState({ vfdEnm });
    await this.setState({ bLoaded: true });
    await this.loadVdf();

    /** Load feedback device link to current control device */
    // console.log(vfdList);
    this.loadFeedbackDevice();
    // ctrlDevFn.getCtrlDev();
  };

  loadFeedbackDevice = async () => {
    // console.log("Load Feedback Device");
    let ctDev_Sel = parseInt(document.getElementById("bdDevSel").value);
    let data = { ctBdDev_id: ctDev_Sel, ctNodeType: 7 };
    let result = await ctrlDevFn.getCtrlDev(data);
    /** set value into building drop down */
    let bdDevs = await bdDevFn.getBdDevicesBy_idList([result.fbBdDev_id]);

    /** clear drop feeback dropdown list when no beedback dev */
    if (!bdDevs || !bdDevs[0]) {
      document.getElementById("fbDevBuildings").value = -1;
      document.getElementById("fbDev_Sel").value = -1;
      return;
    }
    document.getElementById("fbDevBuildings").value = bdDevs[0].buildingID;

    await this.chooseFbBuilding();

    document.getElementById("fbDev_Sel").value = result.fbBdDev_id;
  };

  getOpModeFromPage = () => {
    let opModeSet = parseInt(document.getElementById("opModeSel").value);
    // console.log(opModeSet);
    console.log(this.state.selVfdInfo);
    return opModeSet;
  };

  getManualHz = () => {
    return parseFloat(document.getElementById("manualHz").value);
  };

  manualSet = async () => {
    // console.log('Send Command');
    let vfdDev = { ...this.state.selVfdInfo };
    console.log(vfdDev);
    let opMode = this.getOpModeFromPage();
    // let loraFn=0;
    let payload = {};
    let _manualHz = this.getManualHz();
    console.log(opMode);

    let devDetails = {
      devType: 7, // VFD type - = 7
      id: vfdDev.devID,
      dir: 2, // from gateway to node
      fun: 2, // function code, 1=> set parameter 1
    };
    payload.pf = [_manualHz];

    let package_ = loraPac.genLoRaPackage(devDetails, payload);
    if (package_.error) {
      console.log(package_.message);
      return;
    }
    console.log(package_);
    package_.gwid = vfdDev.gwID; // set the gateway ID
    let data = await controlDevFn.send(package_);

    // console.log(data);
    // console.log(package_);
    // eslint-disable-next-line
    data && data.ft == package_.ft
      ? toast.dark(`Succesful`)
      : toast.error(`Failed`);
  };

  updateSetting = async () => {
    let vfdDev = { ...this.state.selVfdInfo };
    console.log("update setting");
    let devDetails = {
      devType: 7, // VFD type - = 7
      id: vfdDev.devID,
      dir: 2, // from gateway to node
      fun: 3, // function code, 1=> set parameter 1
    };

    let devTable = this.getTableVfdInfo();

    let payload = {
      // pb:[1,1],
      pf: [
        parseFloat(devTable.pidSetpoint),
        parseFloat(devTable.pidOutMin),
        parseFloat(devTable.pidOutMax),
        parseFloat(devTable.pidKp),
        parseFloat(devTable.pidKi),
        parseFloat(devTable.pidKd),
      ],
      pi: [this.getOpModeFromPage(), parseInt(devTable.modbusId)],
      // pn:[1612669989 ,
      //     1612669999 ,
      //     1612669989 ,
      //     1612669777],
    };

    let package_ = loraPac.genLoRaPackage(devDetails, payload);
    if (package_.error) {
      console.log(package_.message);
      return;
    }
    // console.log(package_);
    package_.gwid = vfdDev.gwID; // set the gateway ID
    let data = await controlDevFn.send(package_);

    // eslint-disable-next-line
    data && data.ft == package_.ft
      ? toast.dark(`Succesful`)
      : toast.error(`Failed`);
  };

  toCtrlMode = async () => {
    cssFn.cssRemoveClass("nav-tab");
    cssFn.cssShowStatus(1, "ctrl", "active");
    await this.setState({ bManualMode: 1 });
    this.handleOpSel();
  };
  toSettingMode = async () => {
    cssFn.cssRemoveClass("nav-tab");
    cssFn.cssShowStatus(1, "stg", "active");
    await this.setState({ bManualMode: 2 });
    this.handleOpSel();
  };
  toSetFeedbackMode = async () => {
    cssFn.cssRemoveClass("nav-tab");
    cssFn.cssShowStatus(1, "fb", "active");
    await this.setState({ bManualMode: 3 });
    this.handleOpSel();
  };

  chooseFbBuilding = async () => {
    let bd_id = document.getElementById("fbDevBuildings").value;
    // console.log('Choose Building');
    let fbDevList = await this.getTempDevUnderBuilding(bd_id);
    // console.log(fbDevList);

    let fbEnm = this.bdDevToEnum(fbDevList);
    // console.log(fbEnm);
    await this.setState({ bLoaded_Feedback: false });
    await this.setState({ fbEnm });
    await this.setState({ bLoaded_Feedback: true });
    // await this.loadVdf();
    // fbEnm
  };

  setFeedbackDev = async () => {
    // console.log('Set Feedback Device');
    try {
      console.log("Set Feedback");
      /**get device set on table*/
      let selDev = parseInt(document.getElementById("fbDev_Sel").value);
      let CtrlDev = parseInt(document.getElementById("bdDevSel").value);

      console.log(selDev);

      let ctDev = {
        gwID: this.state.selVfdInfo.gwID,
        // fbNodeType: 1,
        fbBdDev_id: selDev,
        DataKey: "T",
        loraFun: 1,
        ctNodeType: 7,
        ctBdDev_id: CtrlDev,
        inUse: 1,
      };
      let result = await ctrlDevFn.setCtrlDev(ctDev);
      if (result.update) toast.dark(`Update Succesful`);
      else if (result.insert) toast.dark(`Insert Succesful`);
      else toast.error(`Failed`);

      // data && data.ft == package_.ft
      // ? toast.dark(`Succesful`)
      // : toast.error(`Failed`);

      console.log(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <h1> VFD control </h1>
          <div className="title-dd">
            <label className="vfd-label">Select building</label>
            <div style={{ fontSize: "25px" }}>
              {tableFn.tableDropDownKeySel(
                `buildingSel`,
                this.state.allBdEnum,
                1,
                "select-dd",
                () => this.handleBuildingSel()
              )}
            </div>
          </div>
          {this.state.bLoaded && (
            <div className="title-dd">
              <label className="vfd-label">Select device name</label>
              <div style={{ fontSize: "25px" }}>
                {tableFn.tableDropDownKeySel(
                  `bdDevSel`,
                  this.state.vfdEnm,
                  1,
                  "select-dd",
                  () => this.handleDevSel()
                )}
              </div>
            </div>
          )}
          <div className="vfdcard">
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item" role="presentation">
                <div
                  className="nav-tab active"
                  onClick={this.toCtrlMode}
                  id="ctrl"
                >
                  Control Mode
                </div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-tab" id="stg" onClick={this.toSettingMode}>
                  Setting Mode
                </div>
              </li>
              <li className="nav-item" role="presentation">
                <div
                  className="nav-tab"
                  onClick={this.toSetFeedbackMode}
                  id="fb"
                >
                  Feedback Device
                </div>
              </li>
            </ul>
            {/* <button onClick={this.toCtrlMode}> Control Mode</button>
              <button onClick={this.toSettingMode}> Setting Mode</button>
              <button onClick={this.toSetFeedbackMode}> Feedback Device</button> */}

            {/* <div> */}
            <div id="pid_para">
              <div id="pid">
                <div className="tab-label">Op Mode</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  {tableFn.tableDropDownKeySel(
                    `opModeSel`,
                    this.opModeEnum,
                    1,
                    "select-dd"
                    // () => this.handleOpSel()
                  )}
                </div>
              </div>
              {/* <div><span>Gateway ID: {this.state.selVfdInfo.gwID}</span></div> */}
              {/* <div><span>Gateway ID: {this.state.selVfdInfo.gwID}</span></div> */}
              <div id="pid">
                <div className="tab-label">Gateway ID</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="gwid"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Modbus ID</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="mbid"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Set Point</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="sp"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Min</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="Min"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Max</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="Max"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Kp</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="Kp"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Ki</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="Ki"></input>
                </div>
              </div>
              <div id="pid">
                <div className="tab-label">Kd</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="Kd"></input>
                </div>
              </div>
              <div align="right" id="pid">
                <button
                  className="universal-btn vfd"
                  onClick={this.updateSetting}
                >
                  Update Setting
                </button>
              </div>
            </div>
            {/* <div> */}
            <div id="manual_para">
              <div id="manual">
                <div className="tab-label">PID active</div>
                <div className="tab-content">:</div>
                <div className="tab-content pid">
                  {this.state.selVfdInfo.nodeOpMode}
                </div>
              </div>
              <div id="manual">
                <div className="tab-label">Manual Output(Hz)</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  <input type="number" id="manualHz"></input>
                </div>
              </div>
              <div align="right" id="manual">
                <button className="universal-btn vfd" onClick={this.manualSet}>
                  Manual Set Value
                </button>
              </div>
            </div>
            <div id="feedback_para">
              <div id="feedback">
                <div className="tab-label">Buildings (feedback dev.)</div>
                <div className="tab-content">:</div>
                <div className="tab-content">
                  {tableFn.tableDropDownKeySel(
                    `fbDevBuildings`,
                    this.state.allBdEnum,
                    1,
                    "select-dd",
                    () => this.chooseFbBuilding()
                  )}
                </div>
              </div>
              <div id="feedback">
                <div className="tab-label">Input Device</div>
                <div className="tab-content">:</div>
                {this.state.bLoaded_Feedback && (
                  <div className="tab-content">
                    {tableFn.tableDropDownKeySel(
                      `fbDev_Sel`,
                      this.state.fbEnm,
                      1,
                      "select-dd"
                      // () => this.handleOpSel()
                    )}
                  </div>
                )}
              </div>
              <div align="right" id="feedback">
                <button
                  className="universal-btn vfd"
                  onClick={this.setFeedbackDev}
                >
                  Save Feedback Sensor
                </button>
              </div>
            </div>
          </div>
          <button className="universal-btn vfd" onClick={this.toManagementPage}>
            Manage Page
          </button>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default VfdCtrlPage;
