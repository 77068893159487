import Excel from "xlsx";

function Workbook() {
    if (!(this instanceof Workbook))
        return new Workbook()

    this.SheetNames = []

    this.Sheets = {}
}

// eslint-disable-next-line
const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}


function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i=0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF

    return buf
}

function downloadAsExcel(data){
    // import('xlsx').then(Excel => {
        // console.log("Lai Liao");
        const wb = new Workbook()
        const ws = Excel.utils.json_to_sheet(data)

        wb.SheetNames.push('')
        wb.Sheets[''] = ws

        const wbout = Excel.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'})

        let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], {type:'application/octet-stream'}))

        // console.log(url);
        return url;                         // this line will return the url to use for zip
        // download(url, 'import.xlsx')     // this link will direct download excel
    // })
}

export default downloadAsExcel;