import React, { Component } from 'react';
import './ScheAction.css';

class ScheAction extends Component {
    state = { 
        status:2,   //  1 on, 2 on & off, 3, on wit control, 4, on & off, with temp control, 5, 0ff, 6 away        
        temp_SP:25.6,
        statusIcon:"",
        statusColor:"",
        statusText:'',
        bLoaded:false,
    }

    async componentDidMount() {
        await this.updateStatus();
        await this.updateTemp();
        await this.getStatusIcon();
        this.setState({bLoaded:true});
        // console.log(this.props.temp_SP);
        // console.log(this.state.statusText);
        // console.log(this.props.status);
    }

    async updateStatus(){
        if(this.props.status){
            await this.setState({status:this.props.status});
        }
    }
    async updateTemp(){
        if(this.props.temp_SP && this.state.status === 3){
            await this.setState({temp_SP:this.props.temp_SP});
        }
    }


    async getStatusIcon(){
        // console.log(this.state.status);
        switch (this.state.status) {
            case 1:
            case 2:     // on
                await this.setState({statusColor:"green"});
                // await this.setState({statusIcon:"fa fa-power-off"});
                await this.setState({statusText:"On"});
                break;
                
            case 3:
            case 4:     // on
                await this.setState({statusColor:"green"});
                await this.setState({statusText:`${this.props.temp_SP} °C`});
                break;
                
            case 5:     // off
                await this.setState({statusColor:"red"});
                // await this.setState({statusIcon:"fa fa-power-off"});
                await this.setState({statusText:"Off"});
                break;
            case 6:     // away
                await this.setState({statusColor:'var(--cus_orange)'});
                // await this.setState({statusIcon:"fa fa-plane"});
                await this.setState({statusText:"Away"});
                break;
            default:
                break;
        }
    }

    render() { 
        // console.log(this.state.statusIcon);
        // console.log(this.state.status);
        // let smartControlActive = this.state.status === 3 || this.state.status === 4;
        let smartControlActive = true;
        return ( <>
            {/* {!smartControlActive && <div className="onOffPushButton"
            style={{
                backgroundColor:this.state.statusColor,
                width:'30px',
                height:'30px',
                fontSize:'16px',
                }}><i className={this.state.statusIcon} style={{color:'white'}}></i></div>} */}
            {this.state.bLoaded && smartControlActive && <div style={{fontSize:"18px"}}>{`${this.state.statusText}` }</div>}
            {/* {smartControlActive && <div style={{fontSize:"20px"}}>{`${this.state.temp_SP} °C` }</div>} */}
        </> );
    }
}
 
export default ScheAction;