import http from "./httpService";

async function getAll() {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/all";
  return await http.get(url);    
}

async function getByLimit(start, limit) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/devicelist/bylimit/${start}/${limit}`;
  return await http.get(url);
}

async function getByType(type) {  
  // let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/bytype/:tp";
  let url = `${process.env.REACT_APP_PUBLIC_URL}/devicelist/bytype/${type}`;
  return await http.get(url);    
}

async function getByTypenId(body) {  
  // let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/bytype/:tp";
  let url = `${process.env.REACT_APP_PUBLIC_URL}/devicelist/getbytyndevid`;
  return await http.post(url, body);    
}

async function countAll() {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devicelist/countall";
  return await http.get(url); 
}

async function update(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/update";
  //   console.log(obj);
  const body = {      
      _id: obj._id,
      type: obj.type,
      devID: obj.devID,
      // password: user.password,
      battConst: obj.battConst,
      sleepAmp: obj.sleepAmp,
      SimNumber: obj.SimNumber,
      buildingID: obj.buildingID,
    };
    // console.log("did enter");
  return await http.post(url, body);    
}
  

async function add(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/register";
  //   console.log(obj);
  const body = {   
      type: obj.type,
      devID: obj.devID,
      // password: user.password,
      battConst: obj.battConst,
      sleepAmp: obj.sleepAmp,
      SimNumber: obj.SimNumber,
      buildingID: obj.buildingID,
    };
    // console.log("did enter");
  return await http.post(url, body); 
}

async function del(obj) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/del";
  const body = {
      _id:obj._id,
      type:obj.type,      
      devID:obj.devID,
      // owner: building.owner,
      // building: building.building,
      // country: building.country,
      // state: building.state,
      // area: building.area,
      // postcode: building.postcode,
    };
  return await http.post(url, body);    
}

async function getRegdevbyTy_devID(type, devID) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/getdevbytyndevid";
  //   console.log(obj);
  const body = {      
      type: type,
      devID: devID,
  };
    // console.log("did enter");
  return await http.post(url, body);    
}

async function getRegdevbyTy_devIdList(type, devIdList) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/getdevbytyndevidlist";
  //   console.log(obj);
  const body = {      
      type: type,
      devIdList: devIdList,
  };
    // console.log("did enter");
  return await http.post(url, body);    
}

async function V2_regDevByList(body) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/v2regdevlist";

  return await http.post(url, body);    
}



const expModule = {
  V2_regDevByList,
  getRegdevbyTy_devID,
  getRegdevbyTy_devIdList,
  getByTypenId,
  getByType,
  getByLimit,
  countAll,
    del,
    add,
    getAll,
    update,
    // delete: deleteUser,
};
export default expModule;

  // export default{
  //   update: updateUser,
  //   delete: deleteUser,
  // }