import React, { useEffect, useState } from 'react';
import timeFn from '../../../../src/component/utilities/timeFn';

function TpDateTimeSelDialog(props) {
    const [G_ScheType, setG_ScheType] = useState(2);

    useEffect(()=>{
        async function startUp(){
            // console.log(props.selBlock);
            let {scheType} = props.selBlock;
            setG_ScheType(scheType);
            setDefaultDateTime();
        }
        startUp();

        return ()=>{
            
        }
        // eslint-disable-next-line
    }, []);

    const setDefaultDateTime=()=>{        
        if(G_ScheType!==1) document.getElementById('dialog_inputStartDate').value = timeFn.getUnixDate(props.selBlock.prevUnix);
        document.getElementById('dialog_inputStartTime').value = timeFn.getUnixTime(props.selBlock.prevUnix, false, false);        
    }

    const handleBack=()=>{
        if(!props.dia_onClickBack) return
        props.dia_onClickBack();
    }
    const handleSet=()=>{
        try {
            if(!props.dia_onClickSet) return
            let {scheType, scheIdx, start_end} = props.selBlock;
            let setUnix;
            if(G_ScheType===1){     // weekly type
                let time = document.getElementById("dialog_inputStartTime").value;
                setUnix = timeFn.time_toUnix(time);
                // let {scheType, scheIdx, start_end} = props.selBlock;
                // props.dia_onClickSet(scheType, scheIdx, start_end, setUnix);
            }else{      // event Type
                let date = document.getElementById("dialog_inputStartDate").value;
                // console.log("date", date);
                let time = document.getElementById("dialog_inputStartTime").value;
                setUnix = timeFn.date_time_toUnix(date, time);
                // let {scheType, scheIdx, start_end} = props.selBlock;
            }
            props.dia_onClickSet(scheType, scheIdx, start_end, setUnix);
        } catch (error) {
            console.log("TpDateTimeSelDialog:", error.message);
        }
        
    }


    return (
        <div>
            <div className='autumn_dialogBoxContainer'>
                <div className='autumn_dialogBox'>  
                    {G_ScheType!==1 && <div className='autumn_InputDialogSection autumn_ExtraMargin'>
                        <div className='autumn_InputDialogTitle'>
                            Date
                        </div>
                        <input id='dialog_inputStartDate' className='autumn_blueText hoverPointer' type='date'/>
                    </div>}
                    <div className='autumn_InputDialogSection'>
                        <div className='autumn_InputDialogTitle'>
                            Time
                        </div>
                        <input id='dialog_inputStartTime' className='autumn_blueText hoverPointer' type='time'/>
                    </div>
                    <div className='sortHor spaceAround'>
                        <div className='autumn_dialogConfirmButton btnColor_Green hoverPointer'
                        onClick={handleSet}>Set</div>
                        <div className='autumn_dialogConfirmButton btnColor_Grey hoverPointer'
                        onClick={handleBack}>Back</div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default TpDateTimeSelDialog;