import http from "../httpService";


async function getDevActiveListByBd_id(body) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/notify/devact/getacklistbybdid";
    // let body = obj;
    return await http.post(url, body);  
}

async function updateDevActiveList(body) {    
  let url = process.env.REACT_APP_PUBLIC_URL+"/notify/devact/updatelist";
  // let body = obj;
  return await http.post(url, body);  
}
  
const expModule = {  
    getDevActiveListByBd_id,
    updateDevActiveList,
};
export default expModule;