import React, { Component } from 'react';


class TpTableHeaderTab extends Component {
    state = { sortState:0 }

    handleClick=async ()=>{
        try {
            // this.state.sortState ===1 ? this.setState({state:2}) : this.setState({state:2})
            let sortState = this.state.sortState;
            sortState++;
            if(sortState>2) sortState=0;
            await this.setState({sortState});
            this.props.onClick(this.state.sortState, this.props.sortKey);     
            // console.log(this.state.sortState);
        } catch (error) {
            
        }
    }

    presentSortIcon=(sortState)=>{

        switch (sortState) {
            case 1: return 'fa fa-sort-down';
            case 2: return 'fa fa-sort-up';
            default: return 'fa fa-sort'
        }
    }

    genSortIconColor=(sortState)=>{
        if(sortState===0) return '#696969'
        return 'lightgray'
    }

    render() { 

        return ( <>
            <div style={{
                // verticalAlign:'middle', 
                // backgroundColor:'red',
                display:'inline',
                padding :'0px 20px',                
                }}>
                <p style={{
                    display:'inline',
                    // backgroundColor:'orange', 
                    // verticalAlign:'middle'
                    }}>{this.props.children}</p>    
                <i style={{
                        display:'inline',
                        // backgroundColor:'blue',      
                        // verticalAlign:'middle', 
                        padding:'5px 10px',
                        color:this.genSortIconColor(this.state.sortState),
                    }} 
                    className={this.presentSortIcon(this.state.sortState)}
                    onClick={this.handleClick}></i>
            </div> 
        </> );
    }
}
 
export default TpTableHeaderTab;