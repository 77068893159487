import React, { Component } from 'react';
import './TpPopUpCss.css'

class TpPopUp extends Component {
    state = {  }

    clickEmptySpace=()=>{
        try {
            // console.log("Click Empty Space");
            this.props.handleClickOutside();            
        } catch (error) {
            
        }
    }
    clickInsideCard=()=>{
        try {
            // console.log("Click Inside card");
            this.props.handleClickInside();            
        } catch (error) {
            
        }
    }
    handleClick = () => {
        this.props.toggle();
    };

    render() { 
        return ( 
            <>
                <div className='popUpPage' onClick={this.clickEmptySpace}>
                </div>
                <div className="popUpCard" onClick={this.clickInsideCard}>
                    {this.props.children}
                </div>

            </>
        );
    }
}
 
export default TpPopUp;