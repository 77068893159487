import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
// eslint-disable-next-line
import * as HiIcons from "react-icons/hi";
import * as IoIcons from "react-icons/io";
// import * as RiIcons from "react-icons/ri";
// eslint-disable-next-line
import { Sidebar, sidebarSubnav } from "./sidebar";
import "./navsidebar.css";
import { IconContext } from "react-icons";
// eslint-disable-next-line
import Submenu from "./submenu";
// import EditProfile from "../editProfile";

import BdContext from "./../../Context/bdContext_T1";

class Navsidebar extends Component {
  state = {
    user: {},
    sidebar: false,
    bLoaded: false,
    building_name: "",
  };

  static contextType = BdContext;

  async componentDidMount() {
    let user = this.props.user;
    await this.setState({ user });
    this.setState({ bLoaded: false });
    // await sidebarSubnav();
    this.setState({ bLoaded: true });
    let building_name = await localStorage.getItem("buildingName");
    await this.setState({ building_name });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.sidebar !== this.state.sidebar) {
      // this.setState({ bLoaded: false });
      // let sideBarLoadDone = await sidebarSubnav();
      // if (sideBarLoadDone) {
      //   this.setState({ bLoaded: true });
      // }

      this.setState({ bLoaded: true });
    }
  }

  selBuilding = async () => {
    // let {setBd} = this.context;
    // setBd('');
    // console.log(setBd);
    // await localStorage.setItem("buildingName", '');
  };

  showSideBar = async () => {
    let sidebar = this.state.sidebar;
    sidebar
      ? await this.setState({ sidebar: false })
      : await this.setState({ sidebar: true });
  };

  render() {
    let user = { ...this.state.user };
    // console.log(user);
    let username = user.username;
    let firstchar = "";
    if (username) firstchar = username.charAt(0).toUpperCase();

    let bLoaded = this.state.bLoaded;
    // let building_name = localStorage.getItem("buildingName");
    // console.log(this.state.building_name);

    let { bd } = this.context;
    let _bd = bd === "Select Building" ? this.state.building_name : bd;

    // let pathname = window.location.pathname;
    // if (pathname==='/buildingselect') _bd='';
    // console.log(pathname);
    return (
      <React.Fragment>
        <nav className="navbar fixed-top navbar-expand-lg">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars
              onClick={() => this.showSideBar()}
              // style={{ fill: "#f59800" }}
            />
          </Link>
          <Link className="navbar-brand mb-0 h1" to="/"></Link>
          <div className="nav-item" id="navbarNavAltMarkup">
            {!user && (
              <NavLink className="nav-item nav-link" to="/login">
                Login
              </NavLink>
            )}
            {/* {user && (
            <NavLink className="nav-item nav-link" to="/logout">
              Logout
            </NavLink>
          )} */}
            {/* {user && (user.accessLevel===1) && <NavLink className="nav-item nav-link" to="/logout">
                            Level 1
                        </NavLink>} */}
          </div>
          {user.accessLevel < 100 && (
            <div onClick={this.selBuilding} className="nav-item-bdsel">
              <Link id="bdsel" to="/buildingselect">
                {/* <RiIcons.RiHomeGearLine className="bdsel-icon" /> */}
                <div className="bdsel-name">{_bd}</div>
              </Link>
            </div>
          )}
          <div className="nav-item ml-auto">
            <div className="icon-button">
              <div className="user">{firstchar}</div>
              <ul>
                {/* <li><Link to="/editProfile" className="dd-icon"><FaIcons.FaUserEdit className="profile-icon" />Edit Profile</Link></li> */}
                <li align="center">{username}</li>
                <hr className="separator"></hr>
                <Link to="/logout" className="dd-icon">
                  <li className="logout">
                    <IoIcons.IoIosLogOut className="profile-icon" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </nav>
        {user && (
          <React.Fragment>
            <div
              className={this.state.sidebar ? "overlay active" : "overlay"}
              onClick={() => this.showSideBar()}
            ></div>
            <nav
              className={this.state.sidebar ? "nav-menu active" : "nav-menu"}
            >
              <ul className="nav-menu-items">
                <li
                  key="close-sidebar"
                  className="navbar-toggle"
                  onClick={() => this.showSideBar()}
                >
                  <Link to="#" className="menu-bars-x">
                    <AiIcons.AiOutlineClose style={{ fill: "#3c5561" }} />
                  </Link>
                </li>
                {/* eslint-disable-next-line */}
                {bLoaded &&
                  // eslint-disable-next-line
                  Sidebar.map((item, index) => {
                    // if (user.accessLevel < item.accessLevel) {
                    //   return <Submenu item={item} key={index} user={user} />;
                    // }
                    if (user.accessLevel < item.accessLevel) {
                      return (
                        <Link
                          key={`link_${index}`}
                          to={item.path}
                          className="navbarlink"
                          onClick={() => this.showSideBar()}
                        >
                          <li key={index} className={item.cName}>
                            <IconContext.Provider value={{ color: "blue" }}>
                              {item.icon}
                            </IconContext.Provider>

                            <span>{item.title}</span>
                          </li>
                        </Link>
                      );
                    }
                  })}
              </ul>
            </nav>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Navsidebar;
