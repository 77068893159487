import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import './css/autumnTheme/autumnTheme.css';
import './css/displayCss.css';
import './css/Theme/colorVar.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./component/bars/navsidebar.css";
import "react-pro-sidebar/dist/css/styles.css";
import "./component/customcards.css";
import "react-toastify/dist/ReactToastify.css";
import "./component/custommodal.css";
import "./css/pdcTheme/pdcTheme.css";

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
