import React from 'react';
import YSCLogo from '../../../PictureFile/ysclogo.png';

function ContactVendor_YSC() {
    return (
        <div className=''>
                <div className='pdc_dialogBox_Title'>Contact Vendor</div>
                <div className='pdc_marginLeft30'>
                    <div className='pdc_marginBottom30'>
                        For any problems, queries or technical services involving Aplouds, please
                        email to <u>yhwhshammah@outlook.com</u> or you can contact us at:
                    </div>
                    <div className='sortHor pdc_marginBottom30'>
                        <img src={YSCLogo} alt="YSC Logo"  className='pdc_logoFrame pdc_marginRight30 pdc_marginTop15' width="95px" height="125px" />
                        <div className='disFlex flexDirVer pdc_companyDetails'>
                            <div><b>YSC Engineering Solutions Sdn. Bhd.</b></div>
                            <div>No.36, Lorong Mahsuri 3,</div>
                            <div>Bayan Baru,</div>
                            <div>11950 Bayan Lepas,</div>
                            <div>Pulau Pinang</div>
                            <div><u>Malaysia.</u></div>
                        </div>
                    </div>
                    <div className='pdc_marginBottom30'><b><u>Contact No</u></b>: +6019-402 7783 / +6017-402 7783</div>
                    <div>
                        **Please visit our website for more <b><u>information</u></b> and <b><u>solutions</u></b> related to 
                        <b> Energy Management and Solutions</b>:
                    </div>
                    <a href="https://www.yhwhshammahcreations.com">https://www.yhwhshammahcreations.com</a>
                </div>
        </div> 
    );
}

export default ContactVendor_YSC;