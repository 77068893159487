import React, { Component } from "react";
// import auth from '../../service/authService';
import bdFn from "../utilities/getBuilding";
import userFn from "../utilities/getUser";
import enm from "../utilities/enum";
import tableFn from "../utilities/tableFunction";
import assignBdFn from "../utilities/assignBuilding";
import tool from "../../checkingTools/tools";
import Icon from "../common/icon";
// import { Link } from "react-router-dom";

class BuildingAssignPage extends Component {
  state = {
    allBuildings: [],
    userEnum: [],
    nonAuthEnmList: [],
    ownedBuildings: [],
    ownedBuidlings_ids: [],
    selectedUserID: "0",
    selectedBuildingID: "0",
    someBuildingSelected: false,
    customerUser: [],
  };

  async componentDidMount() {
    await this.getUserList();
    // await this.getOwnedBuildings();
    // await this.genAssignBuildingDropList();
  }

  async getUserList() {
    let users = await userFn.getAllUser();
    let customerUser = users.filter((c) => c.accessLevel >= 10);
    this.setState({ customerUser });
    let userEnum = enm.genUserEnum(customerUser, "Select User");
    await this.setState({ userEnum });
    // console.log(enmUsers);
  }

  async getOwnedBuildings() {
    // console.log("did enter");
    let allBuildings = await bdFn.getAllBuilding();
    await this.setState({ allBuildings });
    // console.log(this.state.allBuildings);
    let ownedBuidlings_ids = this.state.ownedBuidlings_ids;
    if (!ownedBuidlings_ids) {
      this.setState({ ownedBuildings: [] });
      return null;
    }
    // console.log("bds");
    //get building owned
    let ownedBuildings = [];
    for (const building of allBuildings) {
      let match = ownedBuidlings_ids.find((c) => c.buildingID === building._id);
      if (match) ownedBuildings.push(building);
    }
    await this.setState({ ownedBuildings });
    // console.log(this.state.ownedBuildings);
    return ownedBuildings;
  }

  async addBuildingToUser() {
    let data = {
      buildingID: this.state.selectedBuildingID,
      userID: this.state.selectedUserID,
    };
    // eslint-disable-next-line
    let result = await assignBdFn.addOwnerBuilding(data);
    // console.log(result);
  }

  async genAssignBuildingDropList() {
    let allBuilding = [...this.state.allBuildings];
    let ownedBuildings = [...this.state.ownedBuidlings_ids];
    for (const bd_id of ownedBuildings) {
      // test splice purpose
      let i = 0;
      while (i < allBuilding.length) {
        // eslint-disable-next-line
        if (allBuilding[i]._id == bd_id.buildingID) {
          // console.log("Same");
          allBuilding.splice(i, 1);
        } else {
          ++i;
        }
      }
    }
    // console.log(allBuilding);
    let sortedBds = allBuilding.sort(bdFn.sortBuildingByOwnerBuilding);

    let nonAuthEnmList = enm.turnBuildingsToEnms(
      sortedBds,
      "Select building...",
      true
    );
    await this.setState({ nonAuthEnmList });
    // console.log(nonAuthEnmList);
  }

  async handleUserSel() {
    let selectedUserID = document.getElementById("userSel").value;
    await this.setState({ selectedUserID });
    await this.refreshTable();
  }

  async refreshTable() {
    await this.getSelectedUserBuildings();
    // console.log(this.state.selectedUserID);
    await this.getOwnedBuildings();
    await this.genAssignBuildingDropList();
    await this.handleCheckboxStatus();
  }

  async getSelectedUserBuildings() {
    let ownedBuidlings_ids = await assignBdFn.getOwnerBuildings(
      this.state.selectedUserID
    );
    await this.setState({ ownedBuidlings_ids });
    // console.log(this.state.ownedBuidlings_ids);
  }

  async handleSelBuilding() {
    let selectedBuildingID = document.getElementById("addBuilding").value;
    await this.setState({ selectedBuildingID });
    // console.log(this.state.selectedBuildingID);
  }

  async handleAddBuilding() {
    console.log("Add Clicked");
    if (this.state.selectedBuildingID <= 0 || this.state.selectedUserID <= 0)
      return console.log("User or Building not selected");
    await this.addBuildingToUser();
    await this.refreshTable();
  }

  async handleDelete() {
    console.log("Delete");
    let rowChecked = tableFn.rawChecked(
      "assignBuildingTable",
      this.tableColumn_del
    );
    // console.log(rowChecked);
    // console.log(this.state.selectedUserID);
    for (const selBuilding of rowChecked) {
      try {
        let reqBody = {
          userID: this.state.selectedUserID,
          buildingID: selBuilding._id,
        };
        let result = await assignBdFn.delOwnerBuilding(reqBody);
        console.log(result); /**Set Notify here */
        if (!result) return console.log("Delete Failed");
      } catch (error) {
        console.log("Delete Assign Building Error");
        tool.exResponse(error);
      }
      await this.refreshTable();
    }
  }

  tableColumn_del = (row) => {
    let column = {
      _id: row.cells[2].innerHTML,
      userID: row.cells[3].innerHTML,
      buildingID: row.cells[4].innerHTML,
    };
    // console.log(column);
    return column;
  };

  async handleCheckboxStatus() {
    // console.log("Checked");
    let rowChecked = tableFn.rawChecked(
      "assignBuildingTable",
      this.tableColumn_del
    );
    // console.log(rowChecked);
    if (rowChecked[0]) {
      await this.setState({ someBuildingSelected: true });
      return;
    }
    await this.setState({ someBuildingSelected: false });
  }

  toMonListAssign = (ind) => {
    // console.log(`This is No ${ind}`);
    let ownedBuildings = [...this.state.ownedBuildings];
    // console.log(ownedBuildings[ind]);
    // console.log( this.state.selectedUserID);
    // eslint-disable-next-line
    let selUser = this.state.customerUser.find(
      // eslint-disable-next-line
      (c) => c._id == this.state.selectedUserID
    );
    delete selUser.password;
    // console.log(selUser);

    this.props.history.push({
      pathname: "/monlistassign",
      _data: {
        _selUser: selUser,
        _selBuilding: ownedBuildings[ind],
      },
    });
  };

  render() {
    let deleteButtontVisible = this.state.someBuildingSelected;

    return (
      <React.Fragment>
        <h1>Building Assign</h1>
        <div>
          {tableFn.tableDropDownKeySel(
            `userSel`,
            this.state.userEnum,
            0,
            "select-dd",
            () => this.handleUserSel()
          )}
        </div>
        <div className="card">
          <div className="card-body">
            <table className="cusTable" id="assignBuildingTable">
              <thead>
                <tr>
                  <th onClick={() => this.handleDelete()}>
                    {deleteButtontVisible && (
                      <i
                        style={{ color: "ButtonFace", paddingLeft: "8px" }}
                        className="fa fa-trash-o deactive checkbox"
                      ></i>
                    )}
                  </th>
                  <th>#</th>
                  <th style={{ display: "none" }}>ID</th>
                  <th>Owner</th>
                  <th>Building</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.ownedBuildings.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input
                        className="checkbox"
                        type="checkbox"
                        onClick={() => this.handleCheckboxStatus()}
                      ></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>{u.owner}</td>
                    <td>{u.building}</td>
                    <td>
                      <Icon
                        className="fa fa-list"
                        onClick={() => this.toMonListAssign(ind)}
                      ></Icon>
                      {/* <Icon className="fa fa-plus deactive" onClick={this.temp}></Icon> */}
                    </td>
                  </tr>
                ))}
                {this.state.selectedUserID > 0 && (
                  <tr>
                    <td onClick={() => this.handleAddBuilding()}>
                      <i
                        style={{
                          color: "rgba(111, 252, 98, 0.75)",
                          paddingLeft: "8px",
                        }}
                        className="fa fa-plus-square"
                        // onClick={this.handleAdd}
                      ></i>
                    </td>
                    {/* <td></td> */}
                    <td colSpan="3">
                      <div
                        style={{
                          marginLeft: "-15px",
                          marginTop: "-15px",
                        }}
                      >
                        {tableFn.tableDropDownKeySel(
                          `addBuilding`,
                          this.state.nonAuthEnmList,
                          0,
                          "select-dd",
                          () => this.handleSelBuilding()
                        )}
                      </div>
                    </td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BuildingAssignPage;
