import React from "react";
import "./TpPopUpCss.css";

const PopUpSubContent = ({ subcontent, error, ...rest }) => {
  return (
    <div className="popup-subcontent" {...rest}>
      {subcontent}
    </div>
  );
};

export default PopUpSubContent;
