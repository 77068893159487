import React, { Component } from 'react';


class TpValueDisplay extends Component {
    state = {  }
    render() { 
        return ( <>
            <div >
                <p style={{display:'inline-flex'}}>{this.props.title}</p> 
                <p style={{padding:"0px 10px", display:'inline-flex'}}>:</p> 
                <p style={{display:'inline-flex'}}> {this.props.value}</p>
            </div>
        </> );
    }
}
 
export default TpValueDisplay;