import http from "../../httpService";

async function getV2_schedule(ht, hi) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/v2getschedule";
    let body = {ht, hi};
    // console.log("body", body);
    return await http.post(url, body);  
}

async function V2_setSche(scheList, devInfo) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/ctrldev/v2setschedule";
    let body = {scheList, devInfo};
    // console.log("body", body);
    return await http.post(url, body);  
}


const expModule = {
    getV2_schedule,
    V2_setSche,
};
export default expModule;