import React, { Component } from 'react';
import acControlFn from '../../utilities/conntrolDevice/acControlFn';
import acCtrlFn from '../Fun/acCtrl_Function';
import AcSchedule from './../AcSchedule/AcSchedule';
import { ToastContainer, toast } from "react-toastify";

import acCtrlFunction from '../Fun/acCtrl_Function';
import loraPac from "../../../function/genLoRaPackage";
import controlDevFn from "../../utilities/conntrolDevice/ctrlDevFn";
import timeFn from '../../utilities/timeFn';
import cssFn from '../../utilities/css/cssFunction';
// import AcFunction from '../Fun/acCtrl_Function';

class AcheListPage extends Component {
    state = { 
        devID:0,
        bdDev_id : 0,
        gwID:0,
        scheList_Db:[],     // copy of initial db list
        scheListDisplay:[],
        bLoaded:false,
        a_deleted_id:[],
     }

    async componentDidMount() {        
        // console.log(this.props.location._data); 
        if(!await acCtrlFunction.loadRequiredInitValue(this.props.location._data, ['devID', 'bdDev_id', 'gwID'], this.fSetState, this.fChangePage)) return;

        await this.getScheList();
        // console.log(this.state.scheListDisplay.length);
        cssFn.cssShowStatus(this.state.scheListDisplay.length >=8, 'addNewButton', "checkHidden");
    }    

    fChangePage=()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac/logindev",
            _data: {
            // _selBuildingId: this.state.selBuildingId,
            // _selT1Llist:tableMonList[ind]._id,
            },
        });
    }

    fSetState=async(name, value)=>{
        await this.setState({[name]:parseInt(value)});
    }

    getScheList = async()=>{
        try {
            // console.log('Excecuted');
            await this.setState({bLoaded:false});
            let existingSche = await acControlFn.getScheBy_beDevid({bdDev_id:this.state.bdDev_id});
            // await this.setState({existingSche})
            // console.log(this.state.existingSche);
            let scheListDisplay = [];
            // let scheListDisplay = acCtrlFn.sche_dbDataToDisplayFormat(existingSche[0]);
            for (const sche of existingSche) {
                scheListDisplay.push(acCtrlFn.sche_dbDataToDisplayFormat(sche));
            }
            await this.setState({scheListDisplay});
            
            let scheList_Db=[];
            if(Array.isArray(scheListDisplay)){
                for (let i = 0; i < scheListDisplay.length; i++) {
                    scheList_Db[i] = {...scheListDisplay[i]};
                }
                // console.log(scheList_Db);
            }
            
            await this.setState({scheList_Db});
            // console.log(this.state.scheListDisplay);
            await this.setState({bLoaded:true});
        } catch (error) {
            toast.error(error.message);
        }
    }

    toAddNewSchePage=()=>{
        // console.log("to add sche page");
        /** set local storage here: bddevID bdID*/
        this.props.history.push({
            pathname: "/ctrldev/ac/addnewsche",
            _data: {
                bdDev_id: this.state.bdDev_id,
                gwID: this.state.gwID,
                devID: this.state.devID,
            },
          });
    }

    backTo=async ()=>{
        try {            
            let modifyList = await this.determineActiveStatusChange();
            let a_deleted_id = [...this.state.a_deleted_id];
            let dataChanges = false;
            if(modifyList[0] || a_deleted_id[0]) dataChanges = true;

            if(dataChanges){
                /** trigger send command to Device 1st */
                let scheListDisplay = [...this.state.scheListDisplay];
    
                /** send WeeklySche */
                let weekScheList = scheListDisplay.filter(c=>(c.repeatWeekly === 1));
                // console.log('weeScheList : ', weekScheList);
                
                /** Send LoRa command to Node */
                let dataStream = [];
                let loRaFn = 4;
                // if(weekScheList[0]){   // weekly
                for (const weekSche of weekScheList) {
                    let tempWArr = acCtrlFunction.convertWeekScheToRaw(weekSche);
                    dataStream.push(...tempWArr);
                }
                // }
    
                // console.log(dataStream);
                /** Send LoRa */
                
                let payload = {};
                let devDetails = {
                    devType: 16, // VFD type - = 7
                    id: this.state.devID,  /** Value 1 need to change later */
                    dir: 2, // from gateway to node
                    fun: loRaFn, // function code, 1=> set parameter 1
                };
    
                // payload.pb = [1];   // smart control on
                // payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
                payload.pi = [...dataStream];
                payload.pn = [timeFn.getUnixNow()];
                // console.log(timeFn.getUnixNow());
    
                let package_ = loraPac.genLoRaPackage(devDetails, payload);
                
                if (package_.error) return toast.error(`package_.message`);
                
                // console.log(package_);
                package_.gwid = this.state.gwID; // set the gateway ID
    
                /** Show update icon here */
                toast.dark("Uploading To Device");
                // cssFn.cssShowStatus(false, 'changeSPLoading', 'checkHidden');
                // console.log('sending Data');
                let data = await controlDevFn.send(package_);
            
                // eslint-disable-next-line
                if(data && data.ft == package_.ft){
                    // toast.dark(`Succesful`);
                    // await this.setState({bAcIsOn:false});
                    toast.dark("Uploading 50%");
                }else{
                    return toast.error(`Connection Unstable`);
                }        
                /**=============Send LoRa End============= */
    
    
                /** Send Event Sche */
                let eventScheList = scheListDisplay.filter(c=>(c.repeatWeekly === 0));
                // console.log('eventScheList : ', eventScheList);
    
                /** ---------- Send LoRa command to Node -----------*/
                dataStream = [];
                loRaFn = 5;
                // if(weekScheList[0]){   // weekly
                for (const eSche of eventScheList) {
                    let tempWArr = acCtrlFunction.convertEventScheToRaw(eSche);
                    dataStream.push(...tempWArr);
                }
                // }
    
                // console.log(dataStream);
                /** Send LoRa */
                
                payload = {};
                devDetails = {
                    devType: 16, // VFD type - = 7
                    id: this.state.devID,  /** Value 1 need to change later */
                    dir: 2, // from gateway to node
                    fun: loRaFn, // function code, 1=> set parameter 1
                };
    
                // payload.pb = [1];   // smart control on
                // payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
                payload.pi = [...dataStream];
                payload.pn = [timeFn.getUnixNow()];
    
                package_ = loraPac.genLoRaPackage(devDetails, payload);
                
                if (package_.error) return toast.error(`send event package_.message`);
                
                // console.log(package_);
                package_.gwid = this.state.gwID; // set the gateway ID
    
                /** Show update icon here */
                // cssFn.cssShowStatus(false, 'changeSPLoading', 'checkHidden');
                // console.log('sending Data');
                data = await controlDevFn.send(package_);
            
                // eslint-disable-next-line
                if(data && data.ft == package_.ft){
                    // toast.dark(`Succesful`);
                    // await this.setState({bAcIsOn:false});
                    toast.dark("Upload Complete");
                }else{
                    return toast.error(`Connection Unstable`);
                }        
                /**=============Send LoRa End============= */
            }

            
            /** Send command to device success, update database */
            // console.log(this.state.a_deleted_id);


            let errCnt = 0;
            if(Array.isArray(modifyList) && modifyList[0]){
                errCnt = await this.updateDatabase(modifyList);
            }
            // console.log(errCnt);
            if(errCnt > 0) return toast.error(`${errCnt} Item Update Error`);
    
            /** Delete item in database */
            let delErrCnt = 0;
            let delList = [];
            for (const nIdNumber of a_deleted_id) {
                delList.push({_id:nIdNumber});
            }
            if(Array.isArray(delList) && delList[0]){
                delErrCnt = await acControlFn.deleteScheduleList(delList);
                // console.log(delErrCnt);
            }
            if(delErrCnt > 0) return toast.error(`${delErrCnt} Item Delete Error`);

            this.props.history.push({
                pathname: "/ctrldev/ac",
                _data: {
                    bdDev_id: this.state.bdDev_id,
                    gwID: this.state.gwID,
                    devID: this.state.devID,
                },
            });
        } catch (error) {
            toast.error(error.message);
        }
    }

    updateDatabase=async (_idList)=>{
        try {
            /** Get updated schedule */
            let scheListDisplay = this.state.scheListDisplay;
            let updatedSche = [];
            for (const _id of _idList) {
                let found = scheListDisplay.find(c=>c._id === _id);
                updatedSche.push(found);
            }
    
            let {errorCnt} = await acControlFn.updateScheduleList(updatedSche);
            // console.log('errorCnt : ',errorCnt);
            return errorCnt;
        } catch (error) {
            toast.error(error.message);
        }
    }

    deleteSche=async(ind)=>{
        try {
            // console.log("Outest", ind);
            this.setState({bLoaded:false});
            let scheListDisplay = [...this.state.scheListDisplay];
            // console.log(scheListDisplay);
            let a_deleted_id = [...this.state.a_deleted_id];
            a_deleted_id.push(scheListDisplay[ind]._id);
            scheListDisplay.splice(ind, 1);
            await this.setState({a_deleted_id})
            await this.setState({scheListDisplay})
            this.setState({bLoaded:true});
        } catch (error) {
            toast.error(error.message);
        }
    }
    scheActive=async (ind)=>{
        try {
            // console.log("On/Off", ind);
            this.setState({bLoaded:false});
            let scheListDisplay = [...this.state.scheListDisplay];
            if(scheListDisplay[ind].inUse===0){
                scheListDisplay[ind].inUse = 1;
            }else{
                scheListDisplay[ind].inUse = 0;
            }
            
            // console.log(scheListDisplay[ind].inUse);
            await this.setState({scheListDisplay})
            // console.log(this.state.scheListDisplay);
            // console.log(this.state.scheList_Db);
            // console.log(this.state.scheList_Db === this.state.scheListDisplay);
    
            this.setState({bLoaded:true});
        } catch (error) {
            toast.error(error.message);
        }
    }

    determineActiveStatusChange=async ()=>{
        try {
            // console.log(this.state.scheList_Db);
            let scheList_Db = [...this.state.scheList_Db];
            let scheListDisplay = [...this.state.scheListDisplay];
            // console.log(scheList_Db);
            // console.log(scheListDisplay);
            let modifiedStatus_id_List = [];
            for (const scheDis of scheListDisplay) {
                let found = scheList_Db.find(c=>c._id === scheDis._id);            
                // console.log(scheDis._id);
                // console.log(found._id);
                // console.log(found.inUse);
                // console.log(scheDis.inUse);
    
                if (found.inUse !== scheDis.inUse) {
                    modifiedStatus_id_List.push(found._id);
                }
            }
            // console.log(modifiedStatus_id_List);
            return modifiedStatus_id_List;
        } catch (error) {
            toast.error(error.message);
        }
    }
        

    render() { 
        return ( <>
            <div 
                className="spaceBetween"
                style={{
                    margin:'15px 10px 20px',
                }}
                >
                <div
                    onClick={this.backTo} 
                    className="framelessIcon">
                    <i className='fa fa-chevron-left buttonIcon'></i>
                </div>
                <div style={{
                    // backgroundColor:"blue",
                    fontSize:'25px',
                    margin:'-10px',
                    }}>
                    {`Schedule List (${this.state.scheListDisplay.length}/8)`}
                </div>
                <div 
                    id='addNewButton'
                    className="framelessIcon "
                    onClick={this.toAddNewSchePage}
                    style={{
                        // flex:1,
                        // float:'right',
                        alignSelf:'flex-end',                    
                        // backgroundColor:"green"
                }}>
                    <i className='fa fa-plus buttonIcon'></i>
                </div>
            </div>
            <div>                
                {this.state.bLoaded && this.state.scheListDisplay.map((u, ind)=>(
                    <div key={`lineContainer-${ind}`} >
                        <AcSchedule 
                            // key={`sche-${ind}`}
                            trigDelete={()=>this.deleteSche(ind)}
                            trigOnOff={()=>this.scheActive(ind)}
                            schedule={u}
                            onOff={true}
                            ></AcSchedule>
                        <div                    
                            style={{padding:'0px 20px'}}>
                            <div                        
                                // key={`line-${ind}`} 
                                className='seperateLine'
                                style={{borderBottom:'1px solid rgb(60, 60, 60)'}}
                            ></div>
                        </div>  
                    </div>
                ))}        
            </div>
        <ToastContainer />
        </> );
    }
}
 
export default AcheListPage;