import React, { Component } from "react";
import teleIdFn from "../utilities/notificaton/telegramID";
import userFn from "../utilities/getUser";
import buildingFn from "../utilities/getBuilding";
import tableFn from "../utilities/tableFunction";
import Icon from "../common/icon";
import teleidService from "../../service/notification/telegramID";
import tool from "../../checkingTools/tools";
import { ToastContainer, toast } from "react-toastify";

class TeleIDPage extends Component {
  state = {
    telegramIDs: [],
    allUsers: [],
    allBuildings: [],

    userEnumList: [],
    bdEnumList: [],

    bPageLoaded: false,
    someCheckboxChecked: false,
  };

  async componentDidMount() {
    //read database and displat on graph
    await this.refreshTable();

    this.setState({ bPageLoaded: true });
  }

  async refreshTable() {
    await this.getTeleIdfromDB();
    await this.getAllUser();
    await this.getAllBuildings();
    await this.getBdEnumList();
    await this.getUserEnumList();
  }

  getUserName(userID) {
    if (userID === 0) return "Common";
    let Users = [...this.state.allUsers];
    if (!Users[0]) return;
    let foundUser = Users.find((c) => c._id === userID);
    return foundUser.username;
  }

  getBuildingName(buildingID) {
    if (buildingID === 0) return "All";
    let buildings = [...this.state.allBuildings];
    if (!buildings[0]) return;
    let found = buildings.find((c) => c._id === buildingID);
    return found.building;
  }

  async getUserEnumList() {
    let userEnumList = [];
    let users = [...this.state.allUsers];
    if (!users[0]) return;
    userEnumList.push({ _id: 0, name: "Common" });
    for (const bd of users) {
      let userEnum = { _id: bd._id, name: bd.username };
      userEnumList.push(userEnum);
    }
    await this.setState({ userEnumList });
    // console.log(this.state.userEnumList);
  }

  async getBdEnumList() {
    let bdEnumList = [];
    let buildings = [...this.state.allBuildings];
    if (!buildings[0]) return;
    bdEnumList.push({ _id: 0, name: "All" });
    for (const bd of buildings) {
      let bdEnum = { _id: bd._id, name: bd.building };
      bdEnumList.push(bdEnum);
    }
    await this.setState({ bdEnumList });
  }

  async getAllBuildings() {
    let allBuildings = await buildingFn.getAllBuilding();
    await this.setState({ allBuildings });
    //  console.log(this.state.allBuildings);
  }

  async getAllUser() {
    let allUsers = await userFn.getAllUser();
    await this.setState({ allUsers });
    //  console.log(this.state.allUsers);
  }

  async getTeleIdfromDB() {
    let telegramIDs = await teleIdFn.getAll();
    // console.log(telegramIDs);
    await this.setState({ telegramIDs });
  }

  tableColumn_del = (row) => {
    let column = {
      _id: row.cells[2].innerHTML,
    };
    // console.log(column);
    return column;
  };

  async handleCheckboxStatus() {
    console.log("handleCheckboxStatus");
    let rowChecked = tableFn.rawChecked("teleIDtable", this.tableColumn_del);
    console.log(rowChecked);
    if (rowChecked[0])
      return await this.setState({ someCheckboxChecked: true });
    await this.setState({ someCheckboxChecked: false });
  }

  selUserChange(ind) {
    console.log(`Change USer ${ind}`);
    let userValue = document.getElementById(`selUserRow${ind}`).value;
    console.log(userValue);
  }

  selBdChange(ind) {
    console.log(`Change BD ${ind}`);
    let userValue = document.getElementById(`selBdRow${ind}`).value;
    console.log(userValue);
  }

  async handleSave() {
    let modifiedRows = this.detectChange();
    if (!modifiedRows[0]) return console.log("No Changes");
    for (const row of modifiedRows) {
      try {
        console.log(row);
        // return  // command out to prevent 
        let rel = await teleidService.update(row);
        (rel.status === 200) ? toast.dark(`Save Succesful`): toast.error(`Save Failed`);
      } catch (ex) {
        tool.exResponse(ex);
      }
    }
  }

  detectChange() {
    let modifiedRows = tableFn.detectTableChanges(
      "teleIDtable",
      this.state.telegramIDs,
      "_id",
      this.tableColumn
    );
    console.log(modifiedRows);
    return modifiedRows;
  }

  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      userID: document.getElementById(`selUserRow${ind - 1}`).value,
      name:row.cells[3].children[0].value,
      bd_id: document.getElementById(`selBdRow${ind - 1}`).value,
      telegramID: row.cells[6].children[0].value,
    };
    // console.log(column);
    return column;
  };

  changePage = () => {
    console.log("Change Page");
    this.props.history.push("/notification/regteleid");
    // this.props.history.push("/setting/regDevice");
  };

  handleDelete = async () => {
    console.log("delete");
    let rowChecked = tableFn.rawChecked("teleIDtable", this.tableColumn_del);
    for (const row of rowChecked) {
      try {
        let body = { _id: row._id };
        let result = await teleIdFn.del(body);
        (result) ? toast.dark(`Delete Succesful`): toast.error(`Delete Failed`);
      } catch (error) {
        console.log("Delete Assign Building Error");
        tool.exResponse(error);
      }
    }
    await this.refreshTable();
  };

  render() {
    let deleteButtontVisible = this.state.someCheckboxChecked;

    return (
      <React.Fragment>
        <h1>Telegram ID</h1>
        <Icon className="fa fa-plus deactive" onClick={this.changePage}></Icon>
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleSave()}
        />
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="teleIDtable">
              <thead>
                <tr>
                  <th
                    onClick={() => this.handleDelete()}
                    id={deleteButtontVisible ? "" : "cb"}
                  >
                    {deleteButtontVisible && (
                      <i
                        style={{ color: "ButtonFace", paddingLeft: "8px" }}
                        className="fa fa-trash-o deactive checkbox"
                      ></i>
                    )}
                  </th>
                  <th>#</th>
                  <th style={{ display: "none" }}>ID</th>
                  <th>Name</th>
                  <th>User</th>
                  <th>Building</th>
                  <th>Telegram ID</th>
                </tr>
              </thead>
              <tbody>
                {this.state.bPageLoaded &&
                  this.state.telegramIDs.map((u, ind) => (
                    <tr key={u._id}>
                      <td>
                        <input
                          className="checkbox"
                          type="checkbox"
                          onClick={() => this.handleCheckboxStatus()}
                        ></input>
                      </td>
                      <td>{ind + 1}</td>
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td>
                        <input type="text" defaultValue={u.name} />                        
                      </td>
                      <td>
                        {tableFn.tableDropDownKeySel(
                          `selUserRow${ind}`,
                          this.state.userEnumList,
                          u.userID,
                          "select-custom",
                          () => this.selUserChange(ind)
                        )}
                      </td>
                      {/* <td>{this.getBuildingName(u.bd_id)}</td> */}
                      <td>
                        {tableFn.tableDropDownKeySel(
                          `selBdRow${ind}`,
                          this.state.bdEnumList,
                          u.bd_id,
                          "select-custom",
                          () => this.selBdChange(ind)
                        )}
                      </td>
                      {/* <td>{u.telegramID}</td> */}
                      <td>
                        <input type="text" defaultValue={u.telegramID} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      <ToastContainer />
      </React.Fragment>
    );
  }
}

export default TeleIDPage;
