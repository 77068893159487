import React, { Component } from 'react'

const BdContext = React.createContext("T1 default")

class BdProvider extends Component {
  // Context state
  state = {
    bd: "Select Building",
  }

  // Method to update state
  setBd = (bd) => {
    this.setState((prevState) => ({ bd }))
  }

  render() {
    const { children } = this.props
    const { bd } = this.state
    const { setBd } = this

    return (
      <BdContext.Provider
        value={{
          bd,
          setBd,
        }}
      >
        {children}
      </BdContext.Provider>
    )
  }
}

export default BdContext;

export { BdProvider }