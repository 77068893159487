import React, { Component } from "react";
import monlistFn from "../../component/utilities/monList/monList";
import { ToastContainer, toast } from "react-toastify";

class MonListAssignPage extends Component {
  state = {
    selBuilding: {},
    selUser: {},
    monList: [],
    assignedMonList: [],
  };

  async componentDidMount() {
    let { _data: inheritData } = this.props.location;
    try {
      if (inheritData._selUser) {
        let selUser = inheritData._selUser;
        await this.setState({ selUser });
        // console.log(this.state.selUser);
      }
      if (inheritData._selBuilding) {
        let selBuilding = inheritData._selBuilding;
        await this.setState({ selBuilding });
        //  console.log(this.state.selBuilding);
      }
      await this.getMonListInBuilding();
      await this.getAssignedMonList();
      this.updateCheckBox();
    } catch (error) {
      console.log(error.message);
    }
  }

  getAssignedMonList = async () => {
    // console.log(this.state.selUser._id);
    let assignedMonList = await monlistFn.getAssignedMonListByUserID(
      this.state.selUser._id
    );
    // console.log(assignedMonList);
    this.setState({ assignedMonList });
  };

  updateCheckBox = async () => {
    let monList = [...this.state.monList];
    let assignedMonList = [...this.state.assignedMonList];
    // console.log(assignedMonList);
    // console.log(monList);
    let found;
    let ind = 0;
    for (const monItem of monList) {
      found = assignedMonList.find((c) => c.MonitorList_id === monItem._id);
      if (found) {
        // console.log("Yes");
        document.getElementById("monListTable").rows[
          ind + 1
        ].cells[0].children[0].checked = true;
      } else {
        // console.log("No");
        document.getElementById("monListTable").rows[
          ind + 1
        ].cells[0].children[0].checked = false;
      }
      ind++;
    }
  };

  getMonListInBuilding = async () => {
    let monList = await monlistFn.getMonlistByBuildingID(
      this.state.selBuilding._id
    );
    monList.sort((a, b) => a.SortIndex - b.SortIndex);
    // console.log(monList);
    this.setState({ monList });
  };

  getUserName = () => {
    if (!this.state.selUser.username) return `User: Not Selected`;
    return `User: ${this.state.selUser.username}`;
  };

  getHeader = () => {
    if (!this.state.selBuilding.building) return `Building: Not Selected`;
    return `${this.state.selBuilding.building} Monitoring List`;
  };

  handleCheckboxStatus = async (ind) => {
    let checkStatus = document.getElementById("monListTable").rows[ind + 1]
      .cells[0].children[0].checked;
    // console.log(this.state.monList[ind]);
    let obj = {
      MonitorList_id: this.state.monList[ind]._id,
      UserID: this.state.selUser._id,
    };
    // console.log(obj);
    if (checkStatus) {
      let addRel = await monlistFn.addAssignedMonList(obj);
      addRel ? toast.dark(`Added`) : toast.error(`Add Failed`);
    } else {
      let assignedMonList = [...this.state.assignedMonList];
      let found = assignedMonList.find(
        (c) =>
          c.MonitorList_id === obj.MonitorList_id && c.UserID === obj.UserID
      );
      if (found) obj._id = found._id;
      let delRel = await monlistFn.delAssignedMonList(obj);
      delRel ? toast.dark(`Deleted`) : toast.error(`Delete Failed`);
    }
    this.getAssignedMonList();
    // console.log(`Checked ${ind} , ${checkStatus}`);
  };

  render() {
    let sUserName = this.getUserName();
    let sHeader = this.getHeader();
    return (
      <React.Fragment>
        <h1>{sHeader}</h1>
        <h3>{sUserName}</h3>
        {/* <h3>{sBuilding}</h3> */}
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="monListTable">
              <thead>
                <tr>
                  <th scope="col" id="cb"></th>
                  <th scope="col">#</th>
                  <th style={{ display: "none" }}>_id</th>
                  <th scope="col">Name</th>
                  <th style={{ display: "none" }}>SortIndex</th>
                  <th style={{ display: "none" }}>buildingID</th>
                </tr>
              </thead>
              <tbody>
                {this.state.monList.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input
                        className="checkbox"
                        type="checkbox"
                        onClick={() => this.handleCheckboxStatus(ind)}
                      ></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>
                      <input type="text" defaultValue={u.name} />
                    </td>
                    <td style={{ display: "none" }}>{u.SortIndex}</td>
                    <td style={{ display: "none" }}>{u.buildingID}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default MonListAssignPage;
