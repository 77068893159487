import React from 'react';

function TpLoadingBar(props) {
    return (
        <div className='graph-loader-container'>
            <div className="graph-loader">
              <div className="graph-loader-1"></div>
              <div className="graph-loader-2"></div>
              <div className="graph-loader-3"></div>
            </div>
        </div>
    );
}

export default TpLoadingBar;