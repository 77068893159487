import React, { Component } from 'react';

class EditProfile extends Component {
    state = {  }
    render() { 
        return (  
            <div>
                <h1>Edit Profile</h1>
            </div>
          );
    }
}
 
export default EditProfile;