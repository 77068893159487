import assignBdService from '../../service/assignBuilding';
import tool from '../../checkingTools/tools'

async function getOwnerBuildings(userID){
    try {
        let result = await assignBdService.getByUserID(userID);
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("getOwnerBuildings Err");
        tool.exResponse(error);
        return null;
    }
}

async function addOwnerBuilding(body){
    try {
        let result = await assignBdService.add(body);    
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {        
        console.log("addOwnerBuilding Err");
        tool.exResponse(error);
        return null;
    }
}

async function delOwnerBuilding(body){
    try {
        let result = await assignBdService.del(body);    
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {        
        console.log("delOwnerBuilding Err");
        tool.exResponse(error);
        return null;
    }
}


const expModule = {  
    addOwnerBuilding,
    delOwnerBuilding,
    getOwnerBuildings,
};
export default expModule;