import buidlings from '../../service/setting_Buildings';
import {sortTemplate, sortByTy_Loc_Name} from "../../component/utilities/ArrayFn/arrayFn";
import bdDevFn from "../../component/utilities/getDevices";

async function getAllBdEnm(sort){
    /** get all buidlings*/
    let { data: allBds} = await buidlings.getAll();
    let bdsEnm = [];
    let enmItem = {};
    for (const Bd of allBds) {
        enmItem={
            name: Bd.building,
            _id: Bd._id,
        }
        bdsEnm.push(enmItem);
    }
    if(sort){
        let sortKeys = [{key:"name", descOrder:false}]
        bdsEnm.sort((a,b)=>sortTemplate(a,b,sortKeys));        
    }
    return bdsEnm;
}

async function getDevUnderBuilding(bd_id){
        /** select * from where Building_id = 23 */
        /**Gen enum list */
        let bdDevs = await bdDevFn.getBdDevBybdID(bd_id);
        // eslint-disable-next-line
        let devUnderBuilidng = bdDevs.filter(c=>c.type!=5 );
        /** Sort Device by type by location by name */
        devUnderBuilidng.sort((a,b)=>sortByTy_Loc_Name(a,b));
        return devUnderBuilidng
}


const expModule = {  
    getAllBdEnm,
    getDevUnderBuilding,
};
export default expModule;