function setBit(value, noBit){
    return (value | (1<<noBit))
}

function getBit(value, noBit){
    return ((value>>noBit) & 1)
}

const clearBit=(nNumber, nBit)=>{
    return (nNumber & ~(1<<nBit));
}


const expModule = {  
    setBit,
    getBit,
    clearBit
};
export default expModule;