import React, { Component } from "react";
import bdDevFn from "../utilities/getDevices";
import lineList from "../../service/lineList";
import graphListSer from "../../service/graphList";
import lineListFn from "../utilities/lineListFn";
import tools from "../../checkingTools/tools";
import Graph from "./../graph/graph";
import buildings from "../../service/setting_Buildings";
import buildingFn from "../utilities/getBuilding";
import assignedBuilding from "../utilities/assignBuilding";
import authSvs from "../../service/authService";
// import Icon from "./../common/icon";
import tableFn from "../utilities/tableFunction";
import enm from "../utilities/enum";
import { genBdDevName } from "../utilities/conversion";
import Loader from "../common/loader";
import sensorFn from '../../function/sensor/sensorManagementFn'

class BuildingData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allBuildings: [],
      ownBuildings: [],
      buildingEnmList: [],

      buildingID: "",
      currentBdDevices: [],
      autoGenLineList: [],
      dbLineList: [],
      displayLineList: [],
      dbGraphList: [],
      defaultGraphList: [],
      displayGraph: [],
      bStateLoaded: false,
      buildingName: "",
      // iconCName: "fa fa-chevron-down",
      iconCName: [],
      displayStatus: [],
      unixStart: "",
      unixEnd: "",
      newSensorParasInUse:[],
    };
    this.setLinePara = this.setLinePara.bind(this);
  }

  async componentDidMount() {
    try {
      await this._getAllBuildings();
      await this._getOwnBuildings();
      // console.log(this.state.ownBuildings);
      let buildingEnmList = enm.turnBuildingsToEnms(
        this.state.ownBuildings,
        null,
        false
      );
      await this.setState({ buildingEnmList });
      // console.log(this.state.buildingEnmList);

      if (!buildingEnmList[0]) {
        this.setState({ bStateLoaded: true });
        return;
      }
      await this.reloadPage();
    } catch (error) {
      console.log(error);
      tools.exResponse(error);
    }
  }

  async reloadPage() {
    try {
      this.setState({ bStateLoaded: false });
      let buildingID = document.getElementById("buildingSel").value;
      // let buildingID = this.props.match.params.bdID;
      // console.log(buildingID);
      await this.setState({ buildingID });
      let currentBdDevices = await bdDevFn.getBdDevBybdID(buildingID);
      // console.log(currentBdDevices);
      await this.setState({ currentBdDevices });
      let autoGenLineList = await this.genLineListBaseOnCurrentBdDev();
      // console.log(autoGenLineList);
      await this.setState({ autoGenLineList });
      // get linelist from databases
      let dbLineList = await this.getLineListFromDB();
      await this.setState({ dbLineList });
      // compare both line list. replace local with data base
      let displayLineList = this.mergeLineList();
      await this.setState({ displayLineList });
      // console.log(this.state.displayLineList);

      // gen default graphList
      let defaultGraphList = this.genGraphListDefault();
      await this.setState({ defaultGraphList });
      // console.log(defaultGraphList);
      // get from databases
      let dbGraphList = await this.getGraphListFromDB();
      await this.setState({ dbGraphList });
      // console.log(dbGraphList);
      //merge default and db graph
      let displayGraph = this.mergeGraphList();
      await this.setState({ displayGraph });
      console.log("displayGraph");
      let updatedGraphList = this.insertLineToGraph();
      await this.setState({ displayGraph: updatedGraphList });
      console.log("After insertLineToGraph");
      // present the final database

      this.setState({ bStateLoaded: true });

      let buildingName = await this.getCurrentBuildingName();
      await this.setState({ buildingName });
    } catch (error) {
      console.log(error);
      tools.exResponse(error);
    }
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      await this.setState({ allBuildings });
      // console.log(this.state.allBuildings);
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  _getOwnBuildings = async () => {
    let allBuildings = [...this.state.allBuildings];
    // console.log(allBuildings);
    let user = await authSvs.getCurrentUser();
    // console.log(user);
    let ownedBuildings_id = await assignedBuilding.getOwnerBuildings(user._id);
    let ownBuildings_ = [];
    if (user.accessLevel > 10) {
      for (const bd_id of ownedBuildings_id) {
        let matchBuilding = allBuildings.find(
          (c) => c._id === bd_id.buildingID
        );
        if (matchBuilding) ownBuildings_.push(matchBuilding);
      }
    } else {
      ownBuildings_ = [...allBuildings];
    }
    // console.log(ownBuildings_);
    let ownBuildings = ownBuildings_.sort(
      buildingFn.sortBuildingByOwnerBuilding
    );
    // console.log(ownBuildings);

    await this.setState({ ownBuildings });
    // console.log(allBuilding);
    // console.log(this.state.allBuildings);
    // console.log(this.state.ownBuildings);
  };

  async getCurrentBuildingName() {
    let { data } = await buildings.getBuildingById(this.state.buildingID);
    if (!data[0]) return null;
    return data[0].building;
  }

  mergeLineList() {
    // console.log(dbLineList);
    // console.log(this.state.dbLineList);
    // console.log(this.state.autoGenLineList);

    let dbLineList = [...this.state.dbLineList];
    let autoGenLineList = [...this.state.autoGenLineList];

    for (const line of dbLineList) {
      // test splice purpose
      let i = 0;
      while (i < autoGenLineList.length) {
        if (
          // eslint-disable-next-line
          autoGenLineList[i].bdDev_id == line.bdDev_id &&
          // eslint-disable-next-line
          autoGenLineList[i].dataKey == line.dataKey &&
          // eslint-disable-next-line
          autoGenLineList[i].type == line.type
        ) {
          autoGenLineList.splice(i, 1);
        } else {
          ++i;
        }
      }
    }
    // console.log(autoGenLineList);
    // console.log(dbLineList);
    let mergeArr = [...dbLineList, ...autoGenLineList];
    // console.log(mergeArr);
    return mergeArr;
  }

  mergeGraphList() {
    let dbGraphList = [...this.state.dbGraphList];
    let defaultGraphList = [...this.state.defaultGraphList];
    for (const dbGraph of dbGraphList) {
      let i = 0;
      while (i < defaultGraphList.length) {
        if (
          // eslint-disable-next-line
          defaultGraphList[i].bdDev_id == dbGraph.bdDev_id &&
          // eslint-disable-next-line
          defaultGraphList[i].pageID == dbGraph.pageID &&
          // eslint-disable-next-line
          defaultGraphList[i].userID == dbGraph.userID
        ) {
          defaultGraphList.splice(i, 1);
        } else {
          ++i;
        }
      }
    }
    let mergeArr = [...dbGraphList, ...defaultGraphList];
    return mergeArr;
  }

  insertLineToGraph() {
    // console.log("Enter liao leh");
    let displayLineList = [...this.state.displayLineList];
    let displayGraph = [...this.state.displayGraph];
    let updatedDisplayGraph = [];
    // console.log(displayGraph);
    for (const graph of displayGraph) {
      // console.log(graph);
      // console.log(displayLineList);
      let currentGraphKey = displayLineList.filter((ln) => {
        // eslint-disable-next-line
        return ln.bdDev_id == graph.bdDev_id;
      });
      // console.log(currentGraphKey);
      if (currentGraphKey[0]) graph.line1_id = currentGraphKey[0];
      // console.log(currentGraphKey[0]);
      if (currentGraphKey[1]) graph.line2_id = currentGraphKey[1];
      if (currentGraphKey[2]) graph.line3_id = currentGraphKey[2];
      updatedDisplayGraph.push(graph);
    }
    // console.log(updatedDisplayGraph);
    return updatedDisplayGraph;
  }

  genGraphListDefault() {
    let bdDevices = [...this.state.currentBdDevices];
    // console.log(bdDevices);
    // let lineLists = [...this.state.displayLineList];
    // console.log(lineLists);
    let graphLists = [];
    let idx = 0;
    for (const bdDev of bdDevices) {
      // console.log(bdDev);
      // let loc="";
      // if(bdDev.location){
      //   loc = `${bdDev.location}-`;
      // }
      idx++;
      let graphList = {
        userID: 0,
        bdDev_id: bdDev._id,
        pageID: 0,
        orderIdx: idx,
        name: genBdDevName(bdDev), // previous coding
        // name: `${loc}${bdDev.name} [${bdDev.devID}]`,
        graphType: 0, //default, full view
      };
      graphLists.push(graphList);
    }
    // console.log(graphLists);
    return graphLists;
  }

  async getGraphListFromDB() {
    let bdDevices = [...this.state.currentBdDevices];
    let grapgLists = [];
    let displayStatus = [...this.state.displayStatus];
    let iconCName = [...this.state.iconCName];
    let forIndex = 0;
    for (const bdDev of bdDevices) {
      // console.log(bdDev);
      let body = {
        bdDev_id: bdDev._id,
        pageID: 0,
        userID: 0,
      };
      let { data } = await graphListSer.getBy2id(body);
      for (const graph of data) {
        grapgLists.push(graph);
      }
      displayStatus[forIndex] = "none";
      iconCName[forIndex] = "fa fa-chevron-down";
      forIndex++;
    }
    this.setState({ displayStatus });
    this.setState({ iconCName });
    return grapgLists;
  }

  async getLineListFromDB() {
    let currentBdDevices = [...this.state.currentBdDevices];
    let dbLineList = [];
    for (const bdDev of currentBdDevices) {
      // console.log(bdDev);
      let body = {
        bdDev_id: bdDev._id,
        userID: 0,
      };
      // console.log(body);
      let { data } = await lineList.getByAccountId(body);
      for (const line of data) {
        dbLineList.push(line);
      }
    }
    return dbLineList;
  }

  async genLineListBaseOnCurrentBdDev() {
    /** ??? check total type of sensor on current building */
    // console.log(this.state.currentBdDevices);
    let currentBdDevices = [...this.state.currentBdDevices];
    let sensorTyInBd = new Set(currentBdDevices.map(item => item.type));
    // console.log(sensorTyInBd);

    let newSensorParasInUse = await sensorFn.getParaByTypeList([...sensorTyInBd]);
    this.setState({newSensorParasInUse});
    // console.log(newSensorParasInUse);

    let defaultLineList = [];
    for (const bdDev of this.state.currentBdDevices) {
      let allLineList = lineListFn.genLineListFromBdDevice(bdDev, newSensorParasInUse);
      for (const lineList of allLineList) {
        defaultLineList.push(lineList);
      }
    }
    console.log(defaultLineList);
    return defaultLineList;
  }

  async setLinePara(graph) {
    // console.log(graph);
    let displayGraph = [...this.state.displayGraph];
    // console.log(displayGraph);
    let index = displayGraph.findIndex((gr) => gr.orderIdx === graph.orderIdx);
    // console.log(index);
    displayGraph[index] = { ...graph };
    await this.setState({ displayGraph });
    // console.log(this.state.displayGraph);
  }

  changePage = () => {
    this.props.history.push("/datapresent/building");
  };

  async handleBuildingSel() {
    // console.log("Building Sel");
    await this.reloadPage();
  }

  handleDropdown = (_idx) => {
    let iconCName = [...this.state.iconCName];
    iconCName[_idx] === "fa fa-chevron-down"
      ? (iconCName[_idx] = "fa fa-chevron-up")
      : (iconCName[_idx] = "fa fa-chevron-down");
    this.setState({ iconCName });

    this.editDisplayStatus(_idx);
  };

  editDisplayStatus(_idx) {
    let displayStatus = [...this.state.displayStatus];
    // console.log(display);
    displayStatus[_idx] === "none"
      ? (displayStatus[_idx] = "")
      : (displayStatus[_idx] = "none");
    this.setState({ displayStatus });
    // display[_idx] === "none" ? this.setState({ displayStatus[_idx]: "" }) : this.setState({ displayStatus[_idx]: "none" });
    // console.log(this.state.displayStatus);
  }

  getKeyInGraph=(a)=>{
    console.log("In getKeyInGraph");
    if(!a || !a[0] || a[0].line1_id) return
    let newSensorParasInUse = [...this.state.newSensorParasInUse];
    let sensorKey = newSensorParasInUse.filter(c=>c.sensorType===a[0].line1_id.type);
    console.log(sensorKey);
    return sensorKey;
  }

  render() {
    let allGraph = [...this.state.displayGraph];
    // console.log(allGraph);
    let emptyBdList = this.state.bStateLoaded && !this.state.buildingEnmList[0];
    let iconName = [...this.state.iconCName];
    // console.log(this.state.newSensorParasInUse);
    // if(this.state.displayGraph[0]) graph1=this.state.displayGraph[0];
    // console.log(this.state.bStateLoaded);
    // return this.state.bStateLoaded ? (
    return (
      <React.Fragment>
        <div>
          {!emptyBdList && (
            <div style={{ fontSize: "25px" }}>
              {tableFn.tableDropDownKeySel(
                `buildingSel`,
                this.state.buildingEnmList,
                -1,
                "select-dd",
                () => this.handleBuildingSel()
              )}
            </div>
          )}
          {emptyBdList && <div>Monitoring List Is Empty</div>}
        </div>
        <div className="row">
          {this.state.bStateLoaded &&
            allGraph.map((a, ind) => (
              <React.Fragment key={`frag${ind}`}>
                {a.line1_id && (
                  <div className="column">
                    <div key={ind} className="card">
                      <h4 className="card-header">
                        <div
                          style={{ maxWidth: "88%", display: "inline-block" }}
                        >
                          {a.name}
                        </div>
                        <span id="header-dd">
                          <i
                            className={iconName[ind]}
                            onClick={() => this.handleDropdown(ind)}
                          />
                        </span>
                      </h4>
                      <div className="card-body">
                        <Graph
                          graphPara={a}
                          handleLinePara={this.setLinePara}
                          displayStatus={this.state.displayStatus[ind]}
                          _index={ind}
                          unixStart={this.state.unixStart}
                          unixEnd={this.state.unixEnd}
                          // newSenPara = {()=>this.getKeyInGraph(a)}
                          newSenPara = {this.state.newSensorParasInUse}
                        ></Graph>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
        {!this.state.bStateLoaded && <Loader />}
      </React.Fragment>
      // ) : (
      //   <h6>loading...</h6>
    );
  }
}

export default BuildingData;
