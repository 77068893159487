import React, { useEffect, useState, useRef } from 'react';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import socketIOClient from "socket.io-client";
import timeFn from "../../../component/utilities/timeFn";

function TpLastReading(props) {
    const [lastData, setLastData] = useState();

    const timerRef = useRef(null);
    const pageClose = useRef(false);

    useEffect(() => {
        timerRef.current = setInterval(async() => {
            // console.log("Refresh");
            await reloadLastData();
        }, 300000);       /** 300*1000s , 5 mins*/

        let socket;
        async function startUp(){
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            await reloadLastData();

            let{ devInfo } = props;
            let topic = `v1_${devInfo.ht}_${devInfo.bdDev_id}`;
            // console.log(topic);
            socket.on(topic, async (data) => {
                await reloadLastData();
            });
        }
        startUp();

        return ()=>{
            clearInterval(timerRef.current);
            socket.disconnect();
            pageClose.current = true;
            // alert("AddDevicePg Page Close");
        }
    });

    const reloadLastData = async () => {
        let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
        // console.log(lastData);
        if(lastData.unix + 10*60 < timeFn.getUnixNow()) setLastData("offline");
        else setLastData(`${lastData[props.param]} ${props.unit}`);
        // setLastData(lastData[props.param]);
    }


    return ( <div className={lastData === "offline"? 'pdc_nodeCard_noBorder pdc_warningFont' : 'pdc_nodeCard_noBorder'}>{lastData}</div> );
}

export default TpLastReading;