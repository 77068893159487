import React, { Component } from "react";
import "./loader.css";

class Loader extends Component {
  state = {};
  render() {
    return (
      <div className="wrapper">
        <div className="circle" style={{ backgroundColor: "#ff3300" }}></div>
        <div className="circle" style={{ backgroundColor: "#00fff2" }}></div>
        <div className="circle" style={{ backgroundColor: "#ff9900" }}></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <span>Loading</span>
      </div>
    );
  }
}

export default Loader;
