import t1ListService from '../../../service/monList/t1List';
import tool from '../../../checkingTools/tools';


/**Get T1 list is located at monList page */

async function addT1List(body){
    try {
        let rel = await t1ListService.addT1List(body);
        if (rel.status===200) return true; 
    } catch (error) {
        tool.exResponse(error);
    }
}

async function updateT1List(body){
    try {
        let rel = await t1ListService.updateT1List(body);
        // console.log(rel);
        if (rel.status===200) return true;        
    } catch (error) {        
        tool.exResponse(error);
        return null;
    }
}


async function delT1List(obj){
    try {
        let result = await t1ListService.delT1List(obj);
        if(result.status!==200) return null;  
        // console.log(result);
        return true;        
    } catch (error) {
        console.log("Delete Monitoring List Err");
        tool.exResponse(error);
        return null;
    }
}

const expModule = {  
    addT1List,
    updateT1List,
    delT1List,
};
export default expModule;