import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import dataFn from '../../../service/queryBdDevData';
import timeFn from '../../utilities/timeFn';
import loraPac from "../../../function/genLoRaPackage";
import controlDevFn from "../../utilities/conntrolDevice/ctrlDevFn";
// import dataFn from '../../service/queryBdDevData';
import AcFunction from '../Fun/acCtrl_Function';

class AcCtrlSettingPage extends Component {
    state = { 
        bdDev_id:0,
        acCtrlData:{} ,
        devID : 0,
        gwID: 0,
    }
    
    async componentDidMount() {
        // console.log(this.props.location._data); 
        if(!await AcFunction.loadRequiredInitValue(this.props.location._data, ['devID', 'bdDev_id', 'gwID'], this.fSetState, this.fChangePage)) return;

        await this.getDataBy_bydevId();
        this.updateFieldValue();            
        // };        
    }
    
    fChangePage=()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac/logindev",
            _data: {
            // _selBuildingId: this.state.selBuildingId,
            // _selT1Llist:tableMonList[ind]._id,
            },
        });
    }

    fSetState=async(name, value)=>{
        await this.setState({[name]:parseInt(value)});
    }


    // checkCurrentDev=async ()=>{
    //     let devID = parseInt(await localStorage.getItem(`devID`));
    //     let bdDev_id = parseInt(await localStorage.getItem(`bdDev_id`));
    //     let gwID = parseInt(await localStorage.getItem(`gwID`));
        
    //     if(!this.validNumber(devID) || !this.validNumber(bdDev_id) || !this.validNumber(gwID) ) {
    //         this.props.history.push({
    //             pathname: "/ctrldev/ac/logindev",
    //             _data: {
    //               // _selBuildingId: this.state.selBuildingId,
    //               // _selT1Llist:tableMonList[ind]._id,
    //             },
    //         });
    //         return
    //     }
        
    //     /** Set State */
    //     await this.setState({devID});
    //     await this.setState({bdDev_id});
    //     await this.setState({gwID});
    //     return true;
    // }
    
    // validNumber=(devID)=>{
    //     let num = parseInt(devID);
    //     return (!Number.isNaN(num) && num!==0);
    // }

    updateFieldValue=()=>{
        let acCtrlData = {...this.state.acCtrlData};
        if(acCtrlData.pi_3 && acCtrlData.pi_3!==0){        // pair unit valid
            document.getElementById('pairSensorInput').value = acCtrlData.pi_3;
        }         
        if(acCtrlData.pf_3){        // upper bound valid
            document.getElementById('ubInput').value = acCtrlData.pf_3;
        } 
        if(acCtrlData.pf_4){        // lower bound valid
            document.getElementById('lbInput').value = acCtrlData.pf_4;
        } 
    }

    async getDataBy_bydevId(){
        let {statusText, data} = await dataFn.getLastNData(16, this.state.bdDev_id, 1);
        if(statusText==='OK' && Array.isArray(data)){
            await this.setState({acCtrlData:data[0]});
        }
        // console.log(this.state.acCtrlData);
        // console.log(statusText);
    }

    clickSave=async()=>{
        // console.log("Save");
        /** ----------Send LoRa command to Node-------------- */
        let loRaFn = 2;
        
        let sensorPair = parseInt(document.getElementById('pairSensorInput').value);
        let upperBound = parseFloat(document.getElementById('ubInput').value);
        let lowerBound = parseFloat(document.getElementById('lbInput').value);
        /** Send LoRa */
        
        let payload = {};
        let devDetails = {
            devType: 16, // VFD type - = 7
            id: this.state.devID,  /** Value 1 need to change later */
            dir: 2, // from gateway to node
            fun: loRaFn, // function code, 1=> set parameter 1
        };

        // payload.pb = [1];   // smart control on
        payload.pf = [upperBound, lowerBound];
        payload.pi = [sensorPair];
        payload.pn = [timeFn.getUnixNow()];
        // console.log(timeFn.getUnixNow());

        let package_ = loraPac.genLoRaPackage(devDetails, payload);
        
        if (package_.error) return toast.error(`package_.message`);
        
        // console.log(package_);
        package_.gwid = this.state.gwID; // set the gateway ID

        /** Show update icon here */
        toast.dark("Uploading To Device");
        // console.log('sending Data');
        let data = await controlDevFn.send(package_);
    
        // eslint-disable-next-line
        if(data && data.ft == package_.ft){
            // toast.dark(`Succesful`);
            // await this.setState({bAcIsOn:false});
            toast.dark("Uploading Done");
        }else{
            return toast.error(`Connection Unstable`);
        }        
        /**=============Send LoRa End============= */

    }

    backTo=async()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac",
            _data: {
              // _selBuildingId: this.state.selBuildingId,
              // _selT1Llist:tableMonList[ind]._id,
            },
        });
    }

    changePairID=()=>{
        let pairId = parseInt(document.getElementById('pairSensorInput').value);
        // console.log(pairId);
        if(Number.isNaN(pairId)) return 
        document.getElementById('pairSensorInput').value = pairId;
    }

    changeUpBound=()=>{
        let pairId = parseFloat(document.getElementById('ubInput').value);
        document.getElementById('ubInput').value = Math.round(pairId*10)/10;
    }
    
    changeLowBound=()=>{
        let pairId = parseFloat(document.getElementById('lbInput').value);
        document.getElementById('lbInput').value = Math.round(pairId*10)/10;
    }

    render() { 
        return ( <>
            <div 
                className="spaceBetween"
                style={{
                    margin:'15px 10px',
                }}
                >
                <div
                    onClick={this.backTo} 
                    className="framelessIcon">
                    <i className='fa fa-chevron-left buttonIcon'></i>
                </div>
                <div style={{
                    // backgroundColor:"blue",
                    fontSize:'25px',
                    margin:'-10px',
                    }}>
                    Setting
                </div>
                <div 
                    className="framelessIcon"
                    onClick={this.clickSave}
                    style={{
                        // flex:1,
                        // float:'right',
                        alignSelf:'flex-end',                    
                        // backgroundColor:"green"
                }}>
                    <i className='fa fa-check buttonIcon'></i>
                </div>
            </div>
            <div>
                <div
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Pair Sensor ID
                    </div>
                    <input 
                        id='pairSensorInput'
                        onChange={this.changePairID}
                        className='inputField'
                        type='Number' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>     
                <div
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Upper Band
                    </div>
                    <input 
                        id='ubInput'
                        onBlur={this.changeUpBound}
                        className='inputField'
                        type='Number' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>   
                <div
                    className='inputContainer'>
                    <div className='inputTitle'>
                        Lower Band
                    </div>
                    <input 
                        id='lbInput'
                        onBlur={this.changeLowBound}
                        className='inputField'
                        type='Number' 
                        placeholder="<Blank>"
                    ></input>
                    <div
                        id='spWarning' 
                        className='warningMsg hideWeeklySel'>** Value must between 16 to 32
                    </div>
                </div>   
            </div>
        <ToastContainer />
        </> );
    }
}
 
export default AcCtrlSettingPage;