import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../service/authService";

const ProtectedRoute = ({
  path,
  component: Component,
  render,
  minAccessLvl,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      //   path={path}
      render={(props) => {
        if (!auth.getCurrentUser()) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        // else if (auth.getCurrentUser().accessLevel === 100 || !auth.getCurrentUser().active) {
        else if (!auth.getCurrentUser().active) {
          console.log("User Not Active");
          return <Redirect to="/notactive" />;
        } else if (auth.getCurrentUser().accessLevel > minAccessLvl) {
          return <Redirect to="/" />;
        }
        return Component ? <Component {...props} /> : render(props);
        // return <MovieForm {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
