import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from "../service/authService";
import { Link } from "react-router-dom";
import "./login.css";
import TpPopUp from "./template/PopUp/TpPopUp";
import DisplayIcon from "./template/Icons/DisplayIcon";
import PopUpContent from "./template/PopUp/PopUpContent";
import CloseIcon from "./template/Icons/CloseIcon";

class Login extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
    bShowPopUp: false,
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    // Call the server
    // console.log("Submitted");

    try {
      localStorage.setItem("buildingName", "Select Building");
      await auth.login(this.state.data);
      let user = auth.getCurrentUser();
      if (user.active === 0) this.setState({ bShowPopUp: true });
      else window.location = "/"; // call a full reload

      // console.log("Result", result);
      // this.props.history.push("/");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // console.log(ex);
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        errors.password = ex.response.data;
        this.setState({ errors });
      }
    }
    //   console.log(this.state.errors);
  };

  closePopUp = async () => {
    this.setState({ bShowPopUp: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.bShowPopUp && (
          <TpPopUp handleClickOutside={this.closePopUp}>
            {
              <div>
                <CloseIcon close={this.closePopUp}></CloseIcon>
                <DisplayIcon
                  style={{ color: "#E5D10D" }}
                  className="fa fa-exclamation-triangle"
                ></DisplayIcon>
                <PopUpContent
                  content="Account inactive. Contact admin for
                  activation."
                ></PopUpContent>
              </div>
            }
          </TpPopUp>
        )}
        {!this.state.bShowPopUp && (
          <div className="lcontainer">
            <div className="lccard">
              <h2 className="lccard-title">Aplouds</h2>
              <form className="form-signin" onSubmit={this.handleSubmit}>
                {this.renderInput("fa fa-user fa-fw", "username", "Username")}
                {this.renderInput(
                  "fa fa-key fa-fw",
                  "password",
                  "Password",
                  "password"
                )}
                <div align="center">{this.renderButton("Login")}</div>
                <p className="signup">
                  Don't have an account?
                  <Link to="/reguser" className="bsignup">
                    Sign Up Now
                  </Link>
                </p>
                {this.renderCopyRight("2021", "Apsol Pro Sdn. Bhd.")}
              </form>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Login;
