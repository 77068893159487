import http from "../httpService";


async function getBy_a_BdDevID(bd_DevIds){
  let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/list/get`;
  const body = {   
    a_BdDevID:bd_DevIds,
  };
  return await http.post(url, body); 
}

async function add(obj) {
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/list/register`;
    //   console.log(obj);
    const body = {   
        name: obj.name,
        userID: obj.userID,
        type: obj.type,
        bdDev_id: obj.bdDev_id,
        DataKey: obj.DataKey,
        DataUnit: obj.DataUnit,
        UserSetpoint: obj.UserSetpoint,
        AlarmSetpoint: obj.AlarmSetpoint,
        AlarmType: obj.AlarmType,
        StartUnix: obj.StartUnix,
        EndUnix: obj.EndUnix,
        // NotifiedUnix: null,
        // AckUnix: null,
        AlarmRepeat: obj.AlarmRepeat,
        Sensitivity: obj.Sensitivity,
        Active: obj.Active,
      };
      // eslint-disable-next-line
      if(body.DataUnit == '') body.DataUnit = ' ';
      // eslint-disable-next-line
      if(body.DataKey == '') body.AlarmType = ' ';
      // console.log("did enter");
    return await http.post(url, body); 
  }

async function update(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/list/update`;
    const body = {   
      _id: obj._id,
      name: obj.name,
      userID: obj.userID,
      type: obj.type,
      bdDev_id: obj.bdDev_id,
      DataKey: obj.DataKey,
      DataUnit: obj.DataUnit,
      UserSetpoint: obj.UserSetpoint,
      AlarmSetpoint: obj.AlarmSetpoint,
      AlarmType: obj.AlarmType,
      StartUnix: obj.StartUnix,
      EndUnix: obj.EndUnix,
      AlarmRepeat: obj.AlarmRepeat,
      Sensitivity: obj.Sensitivity,
      Active: obj.Active,
    };
// eslint-disable-next-line
    if(body.DataUnit == '') body.DataUnit = ' ';
    // eslint-disable-next-line
    if(body.DataKey == '') body.AlarmType = ' ';

    return await http.post(url, body);    
}


async function del(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/notify/list/del";
    const body = {
        _id:obj._id
      };
    return await http.post(url, body);    
  }

const expModule = {  
    getBy_a_BdDevID,
    del,
    add,
    update,
};
export default expModule;