import React, { Component } from "react";
import nodeThresFn from "../../function/nodeThresholdFn/nodeThresholdFn";
import { ToastContainer, toast } from "react-toastify";

class StatusThresholdPage extends Component {
  state = {
    timer: 0,
  };

  getThreshold = async () => {
    let devID = document.getElementById("devID").value;
    // console.log(`devID: ${devID}`);
    let result = await nodeThresFn.getStatusThreshold(devID);
    /**Set value if not empty*/
    if (result[0]) {
      document.getElementById("threshold").value = result[0].threshold;
      document.getElementById("invert").checked =
        result[0].invert === 1 ? true : false;
    } else {
      document.getElementById("threshold").value = "";
      document.getElementById("invert").checked = true;
    }
    // console.log(result);
  };

  insertID = async () => {
    // console.log("Change");
    // document.getElementById('threshold').value = '';
    // document.getElementById('invert').checked = false;
    if (this.state.Timer1) clearTimeout(this.state.Timer1);
    this.state.Timer1 = setTimeout(() => this.getThreshold(), 800);
    // console.log("End Hello");
  };

  setThreshold = async () => {
    // console.log('Set');
    let setting = {
      devID: parseInt(document.getElementById("devID").value),
      threshold: parseInt(document.getElementById("threshold").value),
      invert: document.getElementById("invert").checked ? 1 : 0,
    };
    let result = await nodeThresFn.setStatusThreshold(setting);
    // console.log(result);

    result ? toast.dark(`Succesful`) : toast.error(`Failed`);
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <h1>Status Node Threshold Setting</h1>
          <div className="card status-card">
            <div className="status-all">
              <div className="status-label">Status Node ID</div>
              <div className="status-content">:</div>
              <div className="status-content">
                <input
                  type="number"
                  id="devID"
                  onChange={this.insertID}
                ></input>
              </div>
            </div>
            <div className="status-all">
              <div className="status-label">Threshold</div>
              <div className="status-content">:</div>
              <div className="status-content">
                <input type="number" id="threshold"></input>
              </div>
            </div>
            <div className="status-all">
              <div className="status-label">Invert</div>
              <div className="status-content">:</div>
              <div className="status-content">
                <input type="checkbox" id="invert"></input>
              </div>
            </div>
            <div>
              <button
                className="universal-btn status"
                onClick={this.setThreshold}
              >
                Set
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default StatusThresholdPage;
