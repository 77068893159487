import queryDataFn from '../../function/V1_QueryData/V1_QueryBdDevData/V1_QueryBdDevData';
import timeFn from '../../component/utilities/timeFn';
import bitFn from '../../../src/function/bitFn/bitFn';

const sensorTimeOutInterval = 600;  // 10 mins = 600 s

async function loadDeviceInfo(_id){
    try {
        let devInfo = await queryDataFn.getBdDevInfoBy_id(_id);
        // console.log("devInfo: ", devInfo);
        if(!devInfo) return false;
        return devInfo[0];
    } catch (error) {
        console.log("loadDeviceInfo err: ", error.message);
        return false;
    }
}

const loadLastData=async(_devInfo)=>{
    try {
        // console.log("_devInfo :", _devInfo);
        /** load last data */
        let last2Data = await queryDataFn.getLastNData(_devInfo.ht, _devInfo.bdDev_id, 2);
        // console.log("last2Data", last2Data);
        if(!last2Data) return false;
        if(last2Data.length < 0) return []
        return last2Data[0]
        // setG_Lastdata(last2Data[0])
    } catch (error) {
        console.log(error.message);
        return false
    }        
}

const sensorTimeout=(lastData)=>{
    try {
        // console.log("_devInfo :", _devInfo);
        /** load last data */
        if(!lastData.unix) return false;
        // console.log("Unix now:", timeFn.getUnixNow());
        // console.log("Last Unix:", lastData.unix);
        if(timeFn.getUnixNow()-lastData.unix > sensorTimeOutInterval) return true
        return false;
        
    } catch (error) {
        console.log(error.message);
        return false
    }        
}


const toggleDow=(dowPrev, nDowSel)=>{
    let dow = dowPrev;
    // console.log(bitFn.getBit(dowPrev, nDowSel));
    if(bitFn.getBit(dowPrev, nDowSel)===1){
        dow = bitFn.clearBit(dowPrev, nDowSel);
    }else{
        dow = bitFn.setBit(dowPrev, nDowSel);
    }
    return dow;
}


const expModule = {  
    loadDeviceInfo,
    loadLastData,
    sensorTimeout,
    toggleDow,
};
export default expModule;