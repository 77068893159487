import userFn from '../../service/userManage'


async function resetPassReq(username){
    let resetRel = await userFn.resetPassReq(username);
    return resetRel;
}

async function setNewPassword(info){
    let result = await userFn.setNewPassword(info);
    // let result = await userFn.getByRecoveryCode("recoveryCode");
    // console.log(result);
    if(result.status!==200) return null;
    return result.data;
    // if(data[0]) return data[0];
    // return data;
}

async function getByRecoveryCode(recoveryCode){
    let result = await userFn.getByRecoveryCode(recoveryCode);
    // let result = await userFn.getByRecoveryCode("recoveryCode");
    // console.log(result);
    if(result.status!==200) return null;
    return result.data;
    // if(data[0]) return data[0];
    // return data;
}

const expModule = {  
    resetPassReq,
    getByRecoveryCode,
    setNewPassword,
};
export default expModule;