import React, { Component } from 'react';
import './AcControl.css'
import cssFn from '../utilities/css/cssFunction';
import dataFn from '../../service/queryBdDevData';
// import bdDevFn from '../utilities/getDevices';
import acControlFn from '../utilities/conntrolDevice/acControlFn';
import acCtrlFunction from './Fun/acCtrl_Function';
import { ToastContainer, toast } from "react-toastify";
import loraPac from "../../function/genLoRaPackage";
import controlDevFn from "../utilities/conntrolDevice/ctrlDevFn";

import AcSchedule from './AcSchedule/AcSchedule';
import timeFn from '../utilities/timeFn';
import AcFunction from './Fun/acCtrl_Function';


// import Separator from './../template/Lines/Separator';

class AcCtrl_Page extends Component {
    state = { 
        // a_Week:[1,2,3,4,5,6,7], 
        bdDev_id : 0,
        gwID:0,
        devID:0,
        acSchedule:[],
        bLoaded:false,
        bAcIsOn:false,
        bSmartCtrlActive:false,
        acCtrlData:{},
        displayData:{},
        prevSP:25,
        acSetpoint:25,

        sendCmdDelay:0,
        deviceName:'',
        pgRefreshInterval:0,
        bPauseUpdate:false,
    }


    componentWillUnmount(){
        clearTimeout(this.state.sendCmdDelay);
        clearInterval(this.state.pgRefreshInterval);
        this.setState(prevState => {
            return {
              ...prevState,
              pgRefreshInterval: 0,
            };
          });
    }

    async resetInterval(){
        /**Unpause the update */
        this.setState({bPauseUpdate:false});
        /**Clear Timer */
        clearInterval(this.state.pgRefreshInterval);
        this.setState(prevState => {
            return {
              ...prevState,
              pgRefreshInterval: 0,
            };
        });
        /** Start Timer Again */
        const newPgRefreshInterval = setInterval(   // restart timer
            async()=>this.refreshReading(), 
            10000);
        await this.setState(prevState => {
            return {
                ...prevState,
                pgRefreshInterval:newPgRefreshInterval
            };
        });
        
        console.log("Restart Interval");
    }

    async componentDidMount() {
        // console.log(this.props.location._data);
        try {
            if(!await AcFunction.loadRequiredInitValue(this.props.location._data, ['devID', 'bdDev_id'], this.fSetState, this.fChangePage)) return;
    
            /** check where device ownership still valid */
            let controlAbleDev = await AcFunction.getDevUnderAccount();
            let validDev = controlAbleDev.find(c=>c.bdDev_id === this.state.bdDev_id);
            if(!validDev) {
                this.fChangePage();
                return;
            }
            let deviceName = validDev.displayName;
            this.setState({deviceName});
            // console.log(validDev);
            
            // console.log(this.state.bdDev_id);
            // if(Object.hasOwnProperty.call(_data, 'devID')) console.log("devID : " , _data.devID);
    
            // console.log(devID);???
    
            await this.getDataBy_bydevId();     // get data 
            await this.handleDisplayData();     // process data
    
            await this.handleSchedule();
            this.updateOnOffButton();
            this.updateSmartCtrlCheckbox();
            this.updateSetpointDisplay();
            await this.setState({bLoaded:true});
            
            const newPgRefreshInterval = setInterval(   // restart timer
                async()=>this.refreshReading(), 
                10000);
            await this.setState(prevState => {
                return {
                    ...prevState,
                    pgRefreshInterval:newPgRefreshInterval
                };
            });
        } catch (error) {
            toast.error(error.message);
        }
    }

    fChangePage=()=>{
        this.props.history.push({
            // pathname: "/ctrldev/ac/logindev",
            pathname: "/ctrldev/ac/devsel",
            _data: {
            // _selBuildingId: this.state.selBuildingId,
            // _selT1Llist:tableMonList[ind]._id,
            },
        });
    }

    fSetState=async(name, value)=>{
        await this.setState({[name]:parseInt(value)});
    }


    async refreshReading(){
        if(this.state.bPauseUpdate) return;
        console.log("Refresh");

        await this.getDataBy_bydevId();     // get data 
        await this.handleDisplayData();     // process data

        await this.handleSchedule();
        this.updateOnOffButton();
        this.updateSmartCtrlCheckbox();
        this.updateSetpointDisplay();
    }

    updateSmartCtrlCheckbox(){
        cssFn.cssShowStatus(!this.state.bSmartCtrlActive, 'smartCtrlCheck', "checkHidden");
        cssFn.cssShowStatus(this.state.bSmartCtrlActive, 'smartCtrlCheck', "greenIcon");
        cssFn.cssShowStatus(false, 'smartCtrlCheck', "pendingIcon");

    }

    updateOnOffButton(){
        // cssFn.cssShowStatus(false, 'onButton', "onOffInactive");
        // cssFn.cssShowStatus(true, 'onButton', "onButtonColor");
        // cssFn.cssShowStatus(true, 'onButton', "pendingColor");
        
        cssFn.cssShowStatus(!this.state.bAcIsOn, 'onButton', "onOffInactive");
        cssFn.cssShowStatus(this.state.bAcIsOn, 'onButton', "onButtonColor");
        cssFn.cssShowStatus(false, 'onButton', 'pendingColor');

        cssFn.cssShowStatus(this.state.bAcIsOn, 'offButton', "onOffInactive");        
        cssFn.cssShowStatus(!this.state.bAcIsOn, 'offButton', "offButtonColor");        
        cssFn.cssShowStatus(false, 'offButton', 'pendingColor');
    }

    updateSetpointDisplay(){
        try {
            document.getElementById('setpointInput').value = this.state.acSetpoint.toFixed(1);            
        } catch (error) {
            toast.error(error.message);
        }
    }

    async getDataBy_bydevId(){
        try {
            // console.log(typeof(this.state.bdDev_id));
            // console.log((this.state.bdDev_id));
            let {statusText, data} = await dataFn.getLastNData(16, this.state.bdDev_id, this.state.devID);
            // console.log(statusText);
            // if(!data[0]) return 
            if(statusText==='OK' && Array.isArray(data)){
                await this.setState({acCtrlData:data[0]});
                if(data[0] && data[0].gwID){
                    await this.setState({gwID: parseInt(data[0].gwID)});                
                    await localStorage.setItem("gwID", parseInt(data[0].gwID));
                }else{
                    await this.setState({gwID: 0});  
                    await localStorage.setItem("gwID", 0);
                }
            }
            // console.log(this.state.acCtrlData);
            // console.log(statusText);
        } catch (error) {
            toast.error(error.message);
        }
    }

    async handleDisplayData(){
        try {
            if(this.state.gwID === 0) return;
            let acCtrlData = {...this.state.acCtrlData};
            // console.log(acCtrlData);
            let displayData={};
            displayData.temp = acCtrlData.pf_0;
            displayData.humid = acCtrlData.pf_1; 
            
            await this.setState({displayData});
            await this.setState({bAcIsOn:(parseInt(acCtrlData.pb_0) === 1)});
            await this.setState({bSmartCtrlActive:(parseInt(acCtrlData.pb_1) === 1)});
            await this.setState({acSetpoint:parseFloat(acCtrlData.pf_2)});
            // acSetpoint
    
            // console.log(displayData);
        } catch (error) {
            toast.error(error.message);
        }
    }

    /** On Button */
    triggerOn=async()=>{
        try {
            // console.log("triggerOn");
            // console.log(this.state.acCtrlData);
            await this.setState({bPauseUpdate:true});
            /**trigger command */ 
            let payload = {};
            let devDetails = {
                devType: 16, // VFD type - = 7
                id: this.state.acCtrlData.devID,  /** Value 1 need to change later */
                dir: 2, // from gateway to node
                fun: 3, // function code, 1=> set parameter 1
            };
            
            let nOnCommand =1;
            if(this.state.bAcIsOn && this.state.bSmartCtrlActive) nOnCommand =4;

            payload.pb = [this.state.bSmartCtrlActive?1:0];
            payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
            payload.pi = [nOnCommand];
            payload.pn = [timeFn.getUnixNow()];

            let package_ = loraPac.genLoRaPackage(devDetails, payload);
            if (package_.error) {
                toast.error(`package_.message`);
                await this.resetInterval();
                return;
            }
            // console.log(package_);
            package_.gwid = this.state.acCtrlData.gwID; // set the gateway ID
            
            /** Change button color here */
            cssFn.cssShowStatus(true, 'onButton', 'pendingColor');
            cssFn.cssShowStatus(false, 'onButton', "onOffInactive");
            cssFn.cssShowStatus(false, 'onButton', "onButtonColor");


            let data = await controlDevFn.send(package_);          
            
            // eslint-disable-next-line
            if(data && data.ft == package_.ft){
                // toast.dark(`Succesful`);
                await this.setState({bAcIsOn:true});
            }else{
                toast.error(`On Failed`);
            }
            
            /**keep last outdated unix. (do not refresh data if the unix is === last outdated data) */ 
    
            this.updateOnOffButton();
            await this.resetInterval();
        } catch (error) {
            await this.resetInterval();
            toast.error(error.message);
        }
    }
    
    /** Off Button */
    triggerOff=async()=>{        
        try {
            // console.log("triggerOff");
            // console.log(this.state.acCtrlData);
            await this.setState({bPauseUpdate:true});
            /**trigger command */ 
            let payload = {};
            let devDetails = {
                devType: 16, // VFD type - = 7
                id: this.state.acCtrlData.devID,  /** Value 1 need to change later */
                dir: 2, // from gateway to node
                fun: 3, // function code, 1=> set parameter 1
            };
            // payload.pb = [false];
            payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
            payload.pi = [2];
            payload.pn = [timeFn.getUnixNow()];
    
            let package_ = loraPac.genLoRaPackage(devDetails, payload);
            if (package_.error) {
                toast.error(`package_.message`);
                await this.resetInterval();
                return;
            }
            // console.log(package_);
            package_.gwid = this.state.acCtrlData.gwID; // set the gateway ID

            /** Change button color here */
            cssFn.cssShowStatus(true, 'offButton', 'pendingColor');
            cssFn.cssShowStatus(false, 'offButton', "onOffInactive");
            cssFn.cssShowStatus(false, 'offButton', "onButtonColor");

            let data = await controlDevFn.send(package_);
          
              // eslint-disable-next-line
            if(data && data.ft == package_.ft){
                // toast.dark(`Succesful`);
                await this.setState({bAcIsOn:false});
            }else{
                toast.error(`Off Failed`);
            }
            /**keep last outdated unix. (do not refresh data if the unix is === last outdated data) */ 
            this.updateOnOffButton();            
            await this.resetInterval();
        } catch (error) {
            toast.error(error.message);       
            await this.resetInterval();
        }
    }

    /**Smart Ctrl Check Box  */
    smartCtrlSel=async()=>{
        try {            
            // console.log("smartCtrlSel");
            await this.setState({bPauseUpdate:true});
            let bSmartCtrlActive = !this.state.bSmartCtrlActive;

            if(this.state.bAcIsOn){
                /** AC is on, trigger smart control immediately */
                /**trigger command */ 
                let payload = {};
                let devDetails = {
                    devType: 16, // VFD type - = 7
                    id: this.state.acCtrlData.devID,  /** Value 1 need to change later */
                    dir: 2, // from gateway to node
                    fun: 3, // function code, 1=> set parameter 1
                };

                payload.pb = [bSmartCtrlActive?1:0];   // smart control off
                payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
                payload.pi = [4];
                payload.pn = [timeFn.getUnixNow()];

                let package_ = loraPac.genLoRaPackage(devDetails, payload);
                if (package_.error) {
                    toast.error(`package_.message`);
                    await this.resetInterval();
                    return;
                }
                // console.log(package_);
                package_.gwid = this.state.acCtrlData.gwID; // set the gateway ID

                /** Change check mark color here */
                cssFn.cssShowStatus(true, 'smartCtrlCheck', 'pendingIcon');
                cssFn.cssShowStatus(false, 'smartCtrlCheck', "checkHidden");
                cssFn.cssShowStatus(false, 'smartCtrlCheck', "greenIcon");

                let data = await controlDevFn.send(package_);
            
                // eslint-disable-next-line
                if(data && data.ft == package_.ft){
                    await this.setState({bSmartCtrlActive});
                }else{
                    toast.error(`Off Failed`);
                }
            }else{
                // AC is not on
                await this.setState({bSmartCtrlActive});
            }
            
            this.updateSmartCtrlCheckbox();
            await this.resetInterval();
        } catch (error) {
            await this.resetInterval();
            toast.error(error.message);
        }
    }

    /** Clink select Insert SP */
    clickChangeSP=async()=>{
        try {
            await this.setState({bPauseUpdate:true});
            let _prevSP = (parseFloat(document.getElementById('setpointInput').value)+0.01).toFixed(1);
            await this.setState({prevSP:parseFloat(_prevSP)});
            // console.log(this.state.prevSP);
        } catch (error) {
            await this.resetInterval();
            toast.error(error.message);
        }        
    }
    
    /** Insert SP */
    changeSP=async()=>{
        // console.log('changeSP text');
        try {
            let sSp = document.getElementById('setpointInput').value;
            // console.log(sSp);
            let setpoint = parseFloat(sSp);
            // let setpoint = (parseFloat(document.getElementById('setpointInput').value)).toFixed(1);
            // console.log(setpoint);
            if(setpoint<16 || setpoint > 32) {
                // console.log(this.state.prevSP);
                setpoint = this.state.prevSP;
            }
            document.getElementById('setpointInput').value = setpoint.toFixed(1);
            await this.setState({acSetpoint:setpoint});
    
            // eslint-disable-next-line
            if(this.state.prevSP == this.state.acSetpoint) {                
                await this.resetInterval();
                return;
            }
            /** update */
            this.updateSP();
            // console.log(setpoint);
        } catch (error) {
            await this.resetInterval();
            toast.error(error.message);
        }
    }

    updateSP=async ()=>{
        try {
            // console.log('Update SP');
            // console.log('prev', this.state.prevSP);
            // console.log('prev', this.state.acSetpoint);
            /** If Ac On and Smart Control is Active, trigger update*/
            if(this.state.bAcIsOn && this.state.bSmartCtrlActive){
                let payload = {};
                let devDetails = {
                    devType: 16, // VFD type - = 7
                    id: this.state.acCtrlData.devID,  /** Value 1 need to change later */
                    dir: 2, // from gateway to node
                    fun: 3, // function code, 1=> set parameter 1
                };
    
                payload.pb = [1];   // smart control on
                payload.pf = [parseFloat(this.state.acSetpoint.toFixed(1))];
                payload.pi = [4];
                payload.pn = [timeFn.getUnixNow()];
                
                let package_ = loraPac.genLoRaPackage(devDetails, payload);
                if (package_.error) {
                    toast.error(`package_.message`);           
                    await this.resetInterval();
                    return;
                }
                // console.log(package_);
                package_.gwid = this.state.acCtrlData.gwID; // set the gateway ID
    
                /** Show update icon here */
                cssFn.cssShowStatus(false, 'changeSPLoading', 'checkHidden');
                // console.log('sending Data');
                let data = await controlDevFn.send(package_);
              
                  // eslint-disable-next-line
                if(data && data.ft == package_.ft){
                    // toast.dark(`Succesful`);
                    // await this.setState({bAcIsOn:false});
                }else{
                    toast.error(`Update Failed`);
                }        
                cssFn.cssShowStatus(true, 'changeSPLoading', 'checkHidden');

            }else{
                /** If AC not on. Keep at database, trigger when next control cmd (fn3) sent*/    

            }
            await this.resetInterval();

        } catch (error) {
            toast.error(error.message);
            await this.resetInterval();
        }
    }

    /** + SP */
    incSP=async()=>{
        try {
            await this.setState({bPauseUpdate:true});
            let acSetpoint = this.state.acSetpoint + 0.1;
            if(acSetpoint > 32) acSetpoint = 32;
            document.getElementById('setpointInput').value = parseFloat(acSetpoint).toFixed(1);
            await this.setState({acSetpoint});
    
            clearTimeout(this.state.sendCmdDelay);  // reset timer
            this.state.sendCmdDelay = setTimeout(   // restart timer
                ()=>this.updateSP(), 
                2000);
        } catch (error) {
            toast.error(error.message);
            clearTimeout(this.state.sendCmdDelay);  // reset timer
            await this.resetInterval();
        }
    }

    /** - SP */
    decSP=async()=>{
        try {
            await this.setState({bPauseUpdate:true});
            let acSetpoint = this.state.acSetpoint - 0.1;
            if(acSetpoint < 16) acSetpoint = 16;
            document.getElementById('setpointInput').value = parseFloat(acSetpoint).toFixed(1);
            await this.setState({acSetpoint});
    
            clearTimeout(this.state.sendCmdDelay);  // reset timer
            this.state.sendCmdDelay = setTimeout(   // restart timer
                ()=>this.updateSP(), 
                2000);
        } catch (error) {
            toast.error(error.message);
            clearTimeout(this.state.sendCmdDelay);  // reset timer
            await this.resetInterval();
        }

    }

    toAcSchePage=()=>{
        // console.log("toAcSchePage");
        /** set local storage here: bddevID bdID*/
        this.props.history.push({
            pathname: "/ctrldev/ac/schelist",
            _data: {
                bdDev_id: this.state.bdDev_id,
                gwID: this.state.gwID,
                devID: this.state.devID,
            },
        });
    }

    toSettingPage=()=>{
        /** set local storage here: bddevID bdID*/
        this.props.history.push({
            pathname: "/ctrldev/ac/setting",
            _data: {
                bdDev_id: this.state.bdDev_id,
                gwID: this.state.gwID,
                devID: this.state.devID,
                // gwID:tableMonList[ind]._id,
                // devID:tableMonList[ind]._id,
            },
        });
    }

    toBack=()=>{
        this.props.history.push({
            // pathname: "/ctrldev/ac/logindev",
            pathname: "/ctrldev/ac/devsel",            
            _data: {
              // _selBuildingId: this.state.selBuildingId,
              // _selT1Llist:tableMonList[ind]._id,
            },
        });
    }
    

    async handleSchedule(){
        try {
            // let acSchedule = [...this.state.acSchedule];
            let existingSche = await acControlFn.getScheBy_beDevid({bdDev_id:this.state.bdDev_id});
            // await this.setState({existingSche})
            // console.log(this.state.existingSche);
            let allAcSchedule = [];
            // let scheListDisplay = acCtrlFn.sche_dbDataToDisplayFormat(existingSche[0]);
            for (const sche of existingSche) {
                allAcSchedule.push(acCtrlFunction.sche_dbDataToDisplayFormat(sche));
            }
            let acSchedule = allAcSchedule.filter((c) => c.inUse === 1);
            // console.log(acSchedule);
            await this.setState({acSchedule});
            // console.log(this.state.acSchedule);
            // await this.setState({acSchedule});
        } catch (error) {
            toast.error(error.message);
        }
    }

    render() { 
        return ( <>
        {<div >
            <div 
                className="spaceBetween"
                style={{margin:'15px 10px'}}
                >
                <div
                    onClick={this.toBack} 
                    className="framelessIcon checkHidden">
                    <i className='fa fa-chevron-left buttonIcon'></i>
                </div>
                <div 
                    className="framelessIcon"
                    onClick={this.toSettingPage}
                    style={{
                        // flex:1,
                        // float:'right',
                        alignSelf:'flex-end',                    
                        // backgroundColor:"green"
                }}>
                    <i className='fa fa-cog buttonIcon'></i>
                    {/* <i className='fa fa-ellipsis-v buttonIcon'></i> */}
                </div>
            </div>
            <div 
                className="flexCenter" 
                style={{margin:'20px 30px'}}
                >
                <div
                    onClick={this.toBack} 
                    style={{
                    // backgroundColor:"blue",
                    fontSize:'25px'
                    }}>
                        {this.state.deviceName}
                    <i style={{marginLeft:'20px'}} className='fa fa-caret-down buttonIcon'></i>
                </div>
                {/* <div style={{
                    marginLeft:'auto',
                    }}>
                    <div className="framelessIcon" 
                        style={{
                            fontSize:'15px',
                            }}>
                        <i className='fa fa-calendar buttonIcon'></i>
                    </div>
                </div> */}
            </div>
            <div className="spaceBetween"
            style={{padding:'0px 20px',
                    margin:'40px 10px 10px',
                }}
                >
                <div 
                    id='changeSPLoading'
                    className="framelessIcon checkHidden"
                    style={{
                        // visibility:'hidden'
                    }}>
                        <i className="fa fa-spinner buttonIcon "></i>
                        {/* <i class="fas fa-satellite-dish"></i> */}
                    </div>
                <div style={{fontSize:'60px'}}>
                    <input 
                        id='setpointInput'
                        defaultValue={this.state.acSetpoint}
                        type='number'
                        step='0.1'
                        onFocus={this.clickChangeSP}
                        onBlur={this.changeSP}
                        style={{
                            width:'120px',
                            border:'none'
                        }}
                        ></input>
                    <span style={{
                        marginLeft:'-5px',                        
                        }}>°C</span>
                </div>
                <div>
                    <div 
                        onClick={this.incSP}
                        style={{padding:"5px 0px",
                        // backgroundColor:'red'
                        }}>
                            <div className="framelessIcon">
                                <i className='fa fa-caret-up buttonIcon'></i>
                            </div>
                        </div>
                    <div
                        onClick={this.decSP}
                        style={{padding:"5px 0px",
                        // backgroundColor:'cyan'
                        }}>
                            <div className="framelessIcon">
                                <i className='fa fa-caret-down buttonIcon'></i>
                            </div>
                        </div>
                </div>
            </div>
            <div className='spaceAround'
                style={{
                    padding:'0px 50px',
                    margin:'0px 0px 60px',
                }}>
                <div >
                    <i className="fa fa-thermometer-quarter buttonIcon"></i>    
                    <span>{`${this.state.displayData.temp} °C`}</span>
                </div>
                <div>
                    <i className="fa fa-tint buttonIcon"></i>
                    <span>{`${this.state.displayData.humid} RH%`}</span>
                </div>
            </div>
            <div className='spaceAround' style={{
                padding:"20px 40px 30px",
                }}>
                <div id='onButton'
                className="onOffPushButton"
                onClick={this.triggerOn}
                style={{
                    // backgroundColor:'green',
                    // backgroundColor:`${this.state.bAcIsOn?"green":"none"}`,
                    // border:`${this.state.bAcIsOn?"none":'3px solid rgb(58, 58, 58)'}`,
                    
                    // border:'3px solid rgb(58, 58, 58)' 
                    // backgroundColor:'none',
                    }}>
                        ON
                        {/* <i className='fa fa-power-off' style={{color:'white'}}></i> */}
                </div>
                <div id='offButton'
                className="onOffPushButton"
                onClick={this.triggerOff}
                style={{
                    // backgroundColor:'rgb(211, 0, 0)',
                    
                    // backgroundColor:`${!this.state.bAcIsOn?"rgb(211, 0, 0)":"none"}`,
                    // border:`${!this.state.bAcIsOn?"none":'3px solid rgb(58, 58, 58)'}`,
                    
                    }}>
                        OFF
                        {/* <i className='fa fa-power-off' style={{color:'white'}}></i> */}
                </div>
            </div>
            {/* <div>
                <div 
                    // className='seperateLine'
                    style={{
                        marginTop:'-15px',
                        width:'50px',                        
                        position:'relative',
                        left:'100px',
                        borderBottom: '5px solid rgb(221, 221, 221)',
                        borderRadius:'5px',
                    }}
                ></div>
            </div> */}
            <div className='spaceBetween'
                style={{
                    margin:'50px 20px 20px',
                }}>
                <div className='flexLeft'
                    onClick={this.smartCtrlSel}
                    style={{
                        // margin:'30px 20px 15px',
                    }}
                >
                    <div className='flexCenter' style={{
                        width:'20px',
                        height:'20px',
                        border:'2px solid',
                        borderRadius:'4px',
                        margin:'3px 5px',
                        // padding:'15px',
                        // backgroundColor:'red',                    
                        }}>
                            <i 
                            id='smartCtrlCheck'
                            style={{
                                // color:'rgb(123, 255, 0)',
                                // visibility:"hidden"
                                // visibility:this.state.bSmartCtrlActive?"none":"hidden"
                                }} className='fa fa-check greenIcon checkHidden'></i>
                        </div>
                    <div>Smart Control</div>
                </div>
                <div style={{
                    marginLeft:'auto',
                    visibility:'hidden',
                    }}>
                    <div className="framelessIcon" 
                        style={{
                            fontSize:'15px',
                            }}>
                        <i className='fa fa-calendar buttonIcon'></i>
                    </div>
                </div>

            </div>

            <div style={{padding:'0px 20px'}}>
                <div 
                    className='seperateLine'
                    // style={{borderBottom:'5px solid black'}}
                ></div>
            </div>


            <div className="spaceBetween"
            style={{padding:'0px 20px',
                    margin:'20px 0px 20px',
                }}
                >
                <div className="framelessIcon"
                    style={{
                        visibility:'hidden'
                        // backgroundColor:'red'
                    }}>
                        <i className='fa fa-refresh buttonIcon'></i>
                        {/* <i class="fas fa-sync-alt"></i> */}
                </div>
                <div className='flexCenter'
                    style={{
                        // margin:'20px 0px 20px',
                        fontSize:'20px',
                        fontWeight:'bold'
                        }}>
                    Schedule
                </div>
                <div 
                    onClick={this.toAcSchePage}
                    className="framelessIcon"
                    style={{
                        // visibility:'hidden'
                    }}>
                        <i className='fa fa-edit buttonIcon'></i>
                </div>

            </div>




            {this.state.bLoaded && this.state.acSchedule.map((u, ind)=>(
                <div key={`lineContainer-${ind}`} >
                    <AcSchedule 
                        // key={`sche-${ind}`}
                        schedule={u}></AcSchedule>
                    <div                    
                        style={{padding:'0px 20px'}}>
                        <div                        
                            // key={`line-${ind}`} 
                            className='seperateLine'
                            style={{borderBottom:'1px solid rgb(60, 60, 60)'}}
                        ></div>
                    </div>  
                </div>
            ))}                      
        </div>}
        <ToastContainer />
        </> );
    }
}
 
export default AcCtrl_Page;