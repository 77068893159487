import React, { useEffect, useState } from 'react';
import authV2 from "../../service/V2_Auth";
import Icon from "./../common/icon";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import tableUti from "../utilities/tableFunction";

function V2_UserList(props) {
    const [G_allUser, setG_allUser] = useState([]);
    const [G_loaded, setG_loaded] = useState(false);

    const history = useHistory();

    useEffect(() => {
        async function startUp() {
            if(!G_loaded) {
                await getAllUser();
                setG_loaded(true);
            }
        }
        startUp();
    });

    const tableColumn = (row) => {
        let column = {
            username: row.cells[2].innerHTML,
            email: row.cells[3].children[0].value,
            active: row.cells[4].children[0].value,
        };
        return column;
    };

    const getAllUser = async () => {
        let { data } = await authV2.getAllUser();
        console.log(data);
        if(data.length) setG_allUser(data);
    }

    const handleAddNew = () => {
        history.push({ pathname: '/v2usermanage/new' });
    }

    const handleActive = async (_id) => {
        let _allUser = [...G_allUser];
        // console.log(_allUser);
        let _idx = _allUser.findIndex(c => c._id === _id);
        if(_idx > -1) {
            _allUser[_idx].active = !_allUser[_idx].active
            let result = await authV2.updateActiveStatus(_allUser[_idx]);
            toast(result.data);
            await getAllUser();
        }
        console.log(_allUser);
    }

    const handleUpdate = () => {
        let modifiedRows = tableUti.detectTableChanges(
            "v2_userTable",
            G_allUser,
            "_id",
            tableColumn
        );
        console.log(modifiedRows);
    }

    // const handleDelete = () => {

    // }

    return ( 
        <div>
            <h1>V2 User Management</h1>
            <Icon
            className="fa fa-plus deactive"
            onClick={() => handleAddNew()}
            />
            <Icon
                className="fa fa-floppy-o deactive"
                onClick={() => handleUpdate()}
            />
            {/* <Icon
            className="fa fa-trash-o deactive"
            onClick={() => handleDelete()}
            /> */}
            <div className="card">
            <div className="card-body" id="flip-scroll">
                <table className="cusTable" id="v2_userTable">
                <thead>
                    <tr>
                    <th scope="col" id="cb"></th>
                    <th scope="col">#</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">Active</th>
                    </tr>
                </thead>
                <tbody>
                    {G_loaded && G_allUser.map((u, ind) => (
                    <tr key={u._id}>
                        <td>
                        <input className="checkbox" type="checkbox"></input>
                        </td>
                        <td>{ind + 1}</td>
                        <td>
                            {u.username}
                        </td>
                        <td>
                        <input type="email" defaultValue={u.email} />
                        </td>
                        <td>
                        <input className="checkbox" type="checkbox" checked={u.active} onChange={() => handleActive(u._id)} />
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
            </div>
        </div>
     );
}

export default V2_UserList;