import notiList from '../../../service/notification/notificationList';
import tool from '../../../checkingTools/tools';
import key from '../constantKeyName';
import unitFn from '../unitType';

async function getByBdDevs(a_bdDev){
    try {
        let result = await notiList.getBy_a_BdDevID(a_bdDev);
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("getOwnerBuildings Err");
        tool.exResponse(error);
        return null;
    }
}

async function update(body){
    try {
        let rel = await notiList.update(body);
        // console.log(rel);
        if (rel.status===200) return true;        
    } catch (error) {        
        tool.exResponse(error);
    }
}

async function reg(body){
    try {
        let rel = await notiList.add(body);
        if (rel.status===200) return true; 
    } catch (error) {
        tool.exResponse(error);
    }
}

async function del(obj){
    try {
        let result = await notiList.del(obj);
        if(result.status!==200) return null;  
        return result.data;        
    } catch (error) {
        console.log("Delete Notification List Err");
        tool.exResponse(error);
        return null;
    }
}

function getDataUnitModule(nodeType, dataKey){
    let curretnKey = getCurrentKey(nodeType);    
    for (const key_ in curretnKey) {
        if (curretnKey.hasOwnProperty(key_)) {
            for (const key_2 in curretnKey[key_]) {
                if(curretnKey[key_][key_2] === dataKey){
                    // console.log(curretnKey[key_]);
                    return curretnKey[key_];
                }
            }
        }
    }
    return null
}

function getCurerntUnitList(nodeType, dataKey, keyListInBd){
    // console.log(keyListInBd);
    let unitType = '';    
    let skipLoop = false;
    let curretnKey = getCurrentKey(nodeType);    
    // if(!curretnKey && Array.isArray(keyListInBd)) {
    //     curretnKey = keyListInBd.filter(c=>c.sensorType === nodeType);
    // }
    console.log(curretnKey);
    for (const key_ in curretnKey) {
        if(skipLoop) break
        if (curretnKey.hasOwnProperty(key_)) {
            for (const key_2 in curretnKey[key_]) {
                if(skipLoop) break
                if(curretnKey[key_][key_2] === dataKey){
                    // console.log(curretnKey[key_]);
                    if( curretnKey[key_].defaultUnit && curretnKey[key_].defaultUnit.type){
                        let unit = curretnKey[key_].defaultUnit.type;
                        if(unit) unitType = unit;
                        skipLoop=true;
                    }
                    // return curretnKey[key_];
                }
            }
        }
    }

    // use unitType to get unitList
    let unitList = [];
    if(!unitType) return unitList;
    
    let unitObjList={};
    skipLoop=false;
    for (const key in unitFn.unitType) {
        if (unitFn.unitType.hasOwnProperty(key)) {
            if (key===unitType) {
                // console.log(unitFn.unitType[key]);
                unitObjList={...unitFn.unitType[key]};
                break;
            }
        }
    }

    for (const key in unitObjList) {
        if (unitObjList.hasOwnProperty(key)) {
            unitList.push({_id: unitObjList[key].symbol, name: unitObjList[key].symbol});            
        }
    }

    // console.log(keyListInBd);
    // console.log(nodeType);
//    let newKeyList = keyListInBd.filter(c=>c.sensorType === nodeType);
    // console.log(newKeyList);


    // console.log(unitList);
    return unitList;
}

function getCurrentKey(nodeType){
    let curretnKey={};
    switch (nodeType) {
        case 1: curretnKey = {...key.rtrhKey, ...key.comKey};   break;    
        case 2: curretnKey = {...key.probeKey, ...key.comKey};  break;    
        case 3: curretnKey = {...key.dpmKey, ...key.comKey};    break;
        case 4: curretnKey = {...key.pressureKey, ...key.comKey};   break;
        case 6: curretnKey = {...key.statusSwitchKey, ...key.comKey};   break;
        case 7: curretnKey = {...key.vfdKey, ...key.comKey};   break;
        case 1001: curretnKey = {...key.usfKey, ...key.comKey};   break;
        default:  return null
    }
    return curretnKey;
}


function getDefaultKey(nodeType){    
    switch (nodeType) {
        case 1: return "temperature";
        case 2: return "temperature";
        case 3: return "ActivePower_Total";
        case 4: return "pressure";
        case 6: return "SwitchOn";
        case 7: return "kW";
        case 1001: return "FlowPHour";

        default:    return '';
    }
}

function getConversionFormula(nodeType, DataKey, DataUnit, UserSetpoint){
    // make sure unit type is corerct
    let unitModule = getDataUnitModule(nodeType, DataKey);
    // console.log(unitModule);
    if(!unitModule) return UserSetpoint;
    if(!unitModule.defaultUnit) return UserSetpoint;
    // if(!unitModule.hasOwnProperty("defaultUnit")) return
    if(!unitModule.defaultUnit.hasOwnProperty("type")) return UserSetpoint
    let unitType=unitModule.defaultUnit.type;
    // console.log(unitType);
    // console.log(unitFn.unitType[unitType]); 
    // let unitExist=false;
    for (const key in unitFn.unitType[unitType]) {
        if (unitFn.unitType[unitType].hasOwnProperty(key)) {
            // console.log(unitFn.unitType[module][key]);
            const element = unitFn.unitType[unitType][key];
            // console.log(element);
            // if(element.type === DataKey) unitExist= true;
            // console.log(DataUnit);
            if(element.symbol === DataUnit) {
                // console.log(element.disToDef);
                if(!element.disToDef) return UserSetpoint;
                return element.disToDef(UserSetpoint);  
            }
            // break;            
        }
    }
    // unit states is not belong to Target data type, return
    // if (!unitExist) return
    // console.log("Data Exist");
// get formula base on unit => F

}

function genCurrentKeyList(nodeType, defaultText, newKeyList){
    let curretnKey = getCurrentKey(nodeType);
    let enmList = [];
    if (defaultText) enmList.push({_id:0, name:defaultText});
    for (const _key in curretnKey) {
        if (curretnKey.hasOwnProperty(_key)) {
            enmList.push({_id:curretnKey[_key].key, name:curretnKey[_key].name});          
        }
    }
    for (const para of newKeyList) {
        // enmList.push({_id:curretnKey[_key].key, name:curretnKey[_key].name});   
        enmList.push({_id:`${para.dataType}_${para.dataIndex}`, name:para.dataName});       
    }
    // console.log(enmList);
    return enmList;
}

function getAllKeylist(defaultText, newKey){
    // console.log(newKey);
    // console.log(key);
    let allKeyList = [];
    for (const para of newKey) {
        allKeyList.push({_id:`${para.dataType}_${para.dataIndex}`, name:para.dataName});
    }
    for (const _key in key) {
        if (Object.hasOwnProperty.call(key, _key)) {
            const element = key[_key];
            if(element.hasOwnProperty("name")){
                // console.log(element);
                allKeyList.push({_id:element.key, name:element.name});
            }
        }
    }
    if(defaultText) allKeyList.splice(0,0,{_id:0, name:defaultText});
    return allKeyList;
}

const notifyConditionList=[
    {_id: "upperLimit", name:"Upper Limit"},
    {_id: "lowerLimit", name:"Lower Limit"},
];

const notifyPrivacyList=[
    {_id: "0", name:"Public"},
    {_id: "-1", name:"Private"},
];

const notifyRecurringList=[
    {_id: "1", name:"Once A Day"},
    {_id: "2", name:"Rising Trigger"},
    // {_id: "0", name:"Once"},
];

function genAlarmTypeEnmList(defaultText){
    let alarmTypeEnm = [...notifyConditionList];
    if(defaultText) alarmTypeEnm.splice(0,0,{_id:0, name:defaultText});
    return alarmTypeEnm;
}


const expModule = {  
    notifyRecurringList,
    notifyPrivacyList,
    notifyConditionList,
    
    genAlarmTypeEnmList,
    getAllKeylist,
    del,
    reg,
    update,    
    getDefaultKey,
    getConversionFormula,
    getCurrentKey,
    // getDataUnitModule,
    getCurerntUnitList,
    genCurrentKeyList,
    getByBdDevs,
};
export default expModule;