import React, { Component } from 'react';
import cssFn from '../utilities/css/cssFunction';
import devFn from '../utilities/deviceFunction/deviceFunction';
import tableFn from "../../component/utilities/tableFunction";
import { toast } from "react-toastify";

class V2_RegDevEdit extends Component {
    state={
        headerFormat : ['No', 'type', 'ID', 'battConst', 'sleep_uA'],      // must same as V2_RegDevice.jsx 
        devExcel:[],
        bRefreshTable:false,
        bRegChecking:false,
        smallintRange:{min:-32768, max:32767},
        intRange: {min:-2147483648, max:2147483647},
    }

    async componentDidMount() {
        if(this.props.location._data){
            if(this.props.location._data.devExcel) await this.setState({devExcel: this.props.location._data.devExcel});
            
        }
        
    }

    async deleteDev(ind){
        // let devExcel = [...this.state.devExcel];        
        
        /** load device from table */
        let devExcel = tableFn.getInfoFromTable('devRegTable', this.tableColumn);
        // console.log(`Delete Line ${ind}`);
        // console.log(devExcel);
        devExcel.splice(ind,1);
        await this.setState({devExcel});
        
        await this.setState({bRefreshTable:true});
        await this.setState({bRefreshTable:false});
    }

    
    /**Table format */
    // headerFormat : ['No', 'ID', 'type', 'battConst', 'sleep_uA'],    
    tableColumn = (row, ind) => {
        let column = {
        No: ind,
        type: parseInt(row.cells[2].children[0].value),
        ID: parseInt(row.cells[3].children[0].value),
        battConst: parseInt(row.cells[4].children[0].value),
        sleep_uA: parseFloat(row.cells[5].children[0].value),
        };
        // console.log(column);
        return column;
    };

    regAll =async()=>{
        try {
            await this.setState({bRegChecking:true});
            /** get info from table */
            let tableInfo = tableFn.getInfoFromTable('devRegTable', this.tableColumn);
            /** Ensure table is not empty */
            if (tableInfo.length < 1) {
                toast.error(`Empty List`);
                await this.setState({bRegChecking:false});
                return;
            }


            /** Ensure ID and type is not empty */
            // let idTypNanList = [];      // to indicate probelm row

            let tableError = false;
            for (let i = 0; i < tableInfo.length; i++) {     
                if(Number.isNaN(tableInfo[i].ID) || Number.isNaN(tableInfo[i].type)) {
                    // idTypNanList.push(i);        
                    // Error (ID type empty)
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');         
                    toast.error(`Item No. ${i+1}, ID/Type Invalid`);
                    tableError = true;
                    continue;
                }
                
                /** check input data duplication */
                let duplicated = tableInfo.filter(c=>(c.ID === tableInfo[i].ID && c.type === tableInfo[i].type));
                if(duplicated.length > 1) {
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Input Duplicate`);
                    tableError = true;
                    continue;
                }    

                /** check ID, type duplication from database */                    
                let rel = await devFn.getRegdevbyTy_devID(tableInfo[i].type , tableInfo[i].ID);
                // console.log(rel);
                if (rel.length>0){
                    // Error (Duplication in DB)
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Device Exist`);
                    tableError = true;
                    continue;
                }
                     
                /** check Input out of range */
                if(!this.checkNan_n_range_pass(tableInfo[i].type, 1, this.state.smallintRange.max)){
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Input(Type) Out Of limit`);
                    tableError = true;
                    continue
                }
                if(!this.checkNan_n_range_pass(tableInfo[i].ID, 1, this.state.intRange.max)){
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Input(ID) Out Of limit`);
                    tableError = true;
                    continue
                }
                if(!this.checkNan_n_range_pass(tableInfo[i].battConst, 0, this.state.intRange.max)){
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Input(Batt Const) Out Of limit`);
                    tableError = true;
                    continue
                }
                if(!this.checkNan_n_range_pass(tableInfo[i].sleep_uA, 0, this.state.intRange.max)){
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Input(Sleep Amp) Out Of limit`);
                    tableError = true;
                    continue
                }

                // No Error 
                cssFn.cssShowStatus(false, `tr_${i}`, 'tableLineWarning');
                
            }
            
            
            // if any error found, do not procees to save databases
            if (tableError) {
                await this.setState({bRegChecking:false});
                return;
            }

            let insertRel = await devFn.V2_regDevByList(tableInfo);
            // console.log(insertRel);

            let dbFailed = false;
            for (let i = 0; i < tableInfo.length; i++) {
                if(!insertRel[i]) {
                    dbFailed=true;
                    cssFn.cssShowStatus(true, `tr_${i}`, 'tableLineWarning');
                    toast.error(`Item No. ${i+1}, Register Not Success (DB error)`);
                    continue
                }                
            }

            if(dbFailed){
                toast.dark(`Partially Registration Done`);
            }else{
                toast.dark(`All Registration Done`);                
            }


            await this.setState({bRegChecking:false});
            
        } catch (error) {
            await this.setState({bRegChecking:false});
            console.log(error.message);
        }
    }

    checkNan_n_range_pass=(input, min, max)=>{
        // if nan, pass
        if(Number.isNaN(input)) return true;
        if(input > max ||  input < min) return false;
        return true;
    }

    addDevice = async ()=>{
        /** load device from table */
        let devExcel = tableFn.getInfoFromTable('devRegTable', this.tableColumn);
        // console.log(tableInfo);

        // let devExcel = [...this.state.devExcel];    

        let emptyObj = {};
        
        for (const column of this.state.headerFormat) {
            emptyObj[column]='';
        }
        devExcel.push(emptyObj);
        await this.setState({devExcel});

        await this.setState({bRefreshTable:true});
        await this.setState({bRefreshTable:false});
    }

    changeValue=(i)=>{
        // console.log(`line ${i} change`);
        cssFn.cssShowStatus(false, `tr_${i}`, 'tableLineWarning');
    }

    render() { 
        return (<div>
            <div className='downloadButton'
            onClick= {this.regAll}>
                {this.state.bRegChecking?`Checking...`:`Register All`}
            </div>
            <div className="card">
                <div className="card-body" id="flip-scroll">
                    <table className="cusTable" id="devRegTable">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">#</th>
                            <th scope="col">Type</th>
                            <th scope="col">ID</th>
                            <th scope="col">Batt Constant</th>
                            <th scope="col">Sleep Amp (uA)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!this.state.bRefreshTable && this.state.devExcel.map((u, ind) => (
                            <tr 
                                key={`Line_${ind}`} 
                                // className='tableLineWarning'
                                id={`tr_${ind}`}
                                >
                                <td>
                                    <i
                                    style={{ color: "tomato", padding: "0px 10px " }}
                                    className="fa fa-trash-o checkbox"
                                    onClick={() => this.deleteDev(ind)}
                                    ></i>
                                </td>
                                <td>{ind + 1}</td>
                                <td>
                                    <input onFocus={() => this.changeValue(ind)} type="number" min="1" max="65535" defaultValue={Number.isNaN(u.type)?null:  u.type} placeholder='<Type>'/>
                                </td>
                                <td>
                                    <input onFocus={() => this.changeValue(ind)} type="number" min="1" max="4294967295" defaultValue={Number.isNaN(u.ID)?null:  u.ID} placeholder='<ID>'/>
                                </td>
                                <td>
                                    <input onFocus={() => this.changeValue(ind)} type="number" min="0" max="4294967295" defaultValue={Number.isNaN(u.battConst)?null:  u.battConst} placeholder='<Batt Constant>'/>
                                </td>
                                <td>
                                    <input onFocus={() => this.changeValue(ind)} type="number" min="0" max="4294967295" defaultValue={Number.isNaN(u.sleep_uA)?null:  u.sleep_uA} placeholder='<Sleep Amp>'/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='downloadButton'
            onClick= {this.addDevice}>
                Add
            </div>
        </div>);
    }
}
 
export default V2_RegDevEdit;