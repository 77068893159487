import React, { Component } from "react";
import dnExcel from "./excelFunction";
import zipFn from "./zipFunction";
import queryDbDev from "../../service/queryBdDevData";
import excelFormatter from "./dataFormatter";
import bdDevService from "../../service/buildingDevicesService";
import buildings from "../../service/setting_Buildings";
import tableFn from "../utilities/tableFunction";
import enm from "../utilities/enum";
import timeFn from "../utilities/timeFn";
import "./dataDownload.css";
// import moment from 'moment';
// import Icon from "./../common/icon";
import sensorFn from '../../function/sensor/sensorManagementFn';
import { toast } from "react-toastify";
import '../../css/appCss.css'
import '../../css/table.css'
// import cssFn from '../utilities/css/cssFunction';

class DataDownloadPage extends Component {
  state = {
    allBdDevices: [],
    buildingList: [],
    buildingEnmObj: [],
    filteredBdDevices: [],
    fromDate: "",
    toDate: "",
    selectedBuildingID: 0,
    bLoaded: false,
    typeEnm:[],
    bDownloading:false,
  };

  async componentDidMount() {
    /** Get type enm */
    let typeEnm =await enm.getDevTypeEnum();
    await this.setState({typeEnm});
    // uncheck the check all box
    this.clearTableCheckBox();
    // get all building devices
    await this.getAllBdDevices();
    // console.log(this.state.allBdDevices);
    await this.getBuildingList();
    // console.log(this.state.buildingList);
    await this._getBuildingEnmList();
    // console.log(this.state.buildingEnmObj);

    // await this.setState({filteredBdDevices:this.state.allBdDevices});
    // console.log(this.state.filteredBdDevices);

    await this.loadBuildingCurrentBuilding();
    this.setState({ bLoaded: true });
  }

  buildingNotSelected=async()=>{
    try {
      // console.log(bdID);      
      toast.error("Building Not Selected");
      this.props.history.push({
        pathname: "/buildingselect",
        _data: {
            // bdDev_id: this.state.bdDev_id,
            // gwID: this.state.gwID,
            // devID: this.state.devID,
        },
      });
      
    } catch (error) {
      console.log("ensureBdIdCorrect");
      toast.error("Building Not Selected");
    }

  }

  async loadBuildingCurrentBuilding() {
    this.clearTableCheckBox();
    /**Get building from local storage*/
    let bdID = await localStorage.getItem("buildingId");
    if(!bdID) {
      this.buildingNotSelected();
      return
    }
    // console.log(bdID);
    // let selValue = document.getElementById("buildingSel").value;
    await this.setState({ selectedBuildingID: parseInt(bdID) });
    let allDevices = [...this.state.allBdDevices];
    // eslint-disable-next-line
    let filteredBdDevices = allDevices.filter((c) => c.buildingID == this.state.selectedBuildingID);
    await this.setState({ filteredBdDevices });

    /** pull sensor keylist */    
    let sensorTyInBd = new Set(filteredBdDevices.map(item => item.type));
    // console.log(sensorTyInBd);
    let newSensorParasInUse = await sensorFn.getParaByTypeList([...sensorTyInBd]);
    // console.log(newSensorParasInUse);
    await this.setState({newSensorParasInUse});
    // console.log(filteredBdDevices);
  }


  async getAllBdDevices() {
    let { data: allBdDevices } = await bdDevService.getAll();
    await this.setState({ allBdDevices });
  }

  async getBuildingList() {
    let { data: buildingList } = await buildings.getAll();
    await this.setState({ buildingList });
  }

  async queryDownloadData(bdDev) {
    try {
      let t1 = this.state.fromDate;
      let t2 = this.state.toDate;
      let result = await queryDbDev.getTimeToTime(
        bdDev.type,
        bdDev.bdDevID,
        t1,
        t2
      );
      return result.data;
    } catch (error) {
      console.log(error.message);
      return { data: [{ EmptyData: " " }] };
    }
  }

  async genDataExcelUrl(bdDev) {
    // console.log(bdDev);
    let data = await this.queryDownloadData(bdDev);
    if (!data[0]) return [{ EmptyData: " " }]; // empty array returned
    // let data = result;\
    // console.log(this.state.newSensorParasInUse);
    // console.log(data[0]);
    let tyPara = this.state.newSensorParasInUse.filter(c=> c.sensorType === data[0].type);
    // console.log(tyPara);
    if (data[0].type === 1001)
      return excelFormatter.formatUsfExcel(data, bdDev, tyPara);
    return await excelFormatter.formatCommonNodeExcel(data, bdDev, tyPara);
  }

  async _getBuildingEnmList() {
    let result = await enm.getBuildingEnmObj(true, "Select Building...");
    await this.setState({ buildingEnmObj: result });
    // console.log(this.state.buildingEnmObj);
  }

  clearTableCheckBox() {
    // uncheck the check all box
    document.getElementById(
      "bdDownloadTable"
    ).rows[0].cells[0].children[0].checked = false;
  }

  tableColumn = (row, ind) => {
    let column = {
      bdDevID: row.cells[2].innerHTML,
      type: enm.getType_n(this.state.typeEnm, row.cells[3].innerHTML),
      name: row.cells[4].innerHTML,
      location: row.cells[5].innerHTML,
      remarks: row.cells[6].innerHTML,
      buildingID: row.cells[7].innerHTML,
    };
    // console.log(column);
    return column;
  };


  async downloadFunction() {
    try {
      this.setState({bDownloading:true});
      let startDate = document.getElementById("startDate").value;
      let endDate = document.getElementById("endDate").value;
      if (!startDate) {
        this.setState({bDownloading:false});
        return toast.error('Start Date Empty'); 
      }
      if (!endDate) {
        this.setState({bDownloading:false});
        return toast.error('End Date Empty'); 
      }
      if (startDate > endDate) {
        this.setState({bDownloading:false});
        return toast.error('Invalid Date Range'); // prevent download in case no building selected
      }
  
      const fromDate = timeFn.getUnix_startDate(startDate);
      await this.setState({ fromDate });
      const toDate = timeFn.getUnix_endDate(endDate);
      await this.setState({ toDate });
  
      // console.log(this.state.fromDate);
      // console.log(unixEnd);
  
      let rowChecked = tableFn.rawChecked("bdDownloadTable", this.tableColumn);
      // console.log(rowChecked);
      
      // return
      let urls = [];
      for (const bdDev of rowChecked) {
        // let url=await this.genDataExcelUrl(bdDev);
  
        let urlLink = await this.genDataExcelUrl(bdDev);
        let _link = dnExcel(urlLink);
        // console.log(bdDev);
        let url = {
          link: _link,        
          sensor: enm.getType_en(this.state.typeEnm, bdDev.type),
          name: bdDev.name,
          location: bdDev.location,
        };
        // urls.push(dnExcel(url));
        urls.push(url);
      }
      if (!urls[0]) {
        this.setState({bDownloading:false});
        return toast.error('No Device Selected'); // prevent download in case no building selected
      }
  
      // console.log(urls);
      // console.log(this.state.selectedBuildingID);
  
      
      let foldername = this.genZipFolderName(startDate, endDate);
      await zipFn(foldername, urls);
      // await zipFn("AploudZip", urls);
  
      /**Download Done*/
      toast.dark('Download Done');
      this.setState({bDownloading:false});
      // let data = await this.getNodeData();
      // // console.log(data);
      // if(data) urls.push(dnExcel(data));
      // // urls.push(dnExcel(data2));
      // if(!urls[0]) return console.log("empty log");
      // await zipFn("AploudZip", urls);
    } catch (error) {
      toast.error('Download Not Success');
      await this.setState({bDownloading:false});
    }
  }

  getBuildingName(buildingID) {
    let building = [...this.state.buildingList];
    // console.log(buildingID);
    // console.log(building);
    // eslint-disable-next-line
    let filteredBd = building.filter((c) => c._id == buildingID);
    if (!filteredBd[0]) return null;
    return filteredBd[0].building;
  }

  genZipFolderName(t1, t2) {
    // let selValue = document.getElementById("buildingSel").value;
    let sTime = `(${t1} to ${t2})`;
    if (t1 === t2) sTime = t1;
    // let buildingName= enm.getType_en(this.state.selectedBuildingID);

    let buildingName = this.getBuildingName(this.state.selectedBuildingID);
    return `${buildingName} ${sTime}`;
  }

  async handleBuildingSel() {
    this.clearTableCheckBox();
    // console.log("Building Select");
    let selValue = document.getElementById("buildingSel").value;
    await this.setState({ selectedBuildingID: selValue });
    // console.log(selValue);
    // filtered building list
    let allDevices = [...this.state.allBdDevices];
    // eslint-disable-next-line
    let filteredBdDevices = allDevices.filter((c) => c.buildingID == selValue);
    await this.setState({ filteredBdDevices });

    /** pull sensor keylist */    
    let sensorTyInBd = new Set(filteredBdDevices.map(item => item.type));
    // console.log(sensorTyInBd);
    let newSensorParasInUse = await sensorFn.getParaByTypeList([...sensorTyInBd]);
    // console.log(newSensorParasInUse);
    await this.setState({newSensorParasInUse});
    // console.log(filteredBdDevices);
  }

  checkBoxMain() {
    // console.log("CHeckBox");
    let checkedStatus = document.getElementById("bdDownloadTable").rows[0]
      .cells[0].children[0].checked;
    // console.log(checkedStatus);
    let len = this.state.filteredBdDevices.length;
    // console.log(len);
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        document.getElementById("bdDownloadTable").rows[
          i + 1
        ].cells[0].children[0].checked = checkedStatus;
      }
    }
    // if (rows[i].cells[0].children[0].checked) {
  }

  render() {
    // let checkBoxSize = 30;

    return (
      <React.Fragment>
        <h1>Data Download</h1>
        <div style={{display:'none'}}>
          {tableFn.tableDropDownKeySel(
            `buildingSel`,
            this.state.buildingEnmObj,
            0,
            "select-dd",
            () => this.handleBuildingSel()
          )}
        </div>
        <div style={{
    // display: flex;
    // flex-flow: row wrap;
            display:'flex', 
            flexFlow:'wrap'
        }}>
          <div className='dataDnDateSelContainer'>
            <div className='dataDnDateTitle'>Start : </div>
            {/* <label className="label-start">Start: </label> */}
            <input type="date" id="startDate"></input>
          </div>
          <div className='dataDnDateSelContainer'>
            <div className='dataDnDateTitle'>End : </div>
            {/* <label className="label-end">End: </label> */}
            <input type="date" id="endDate"></input>
          </div>
        </div>
        <div className='downloadButton'
            onClick={()=>this.downloadFunction()}
          >
          {this.state.bDownloading ? 'Downloading...' : 'Download'}
        </div>
        {/* <Icon
          className="fa fa-download deactive download"
          onClick={() => this.downloadFunction()}
        /> */}
        <div className="card">
          <div className="card-body">
            <table className="cusTable" id="bdDownloadTable">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      className="checkbox"
                      type="checkbox"
                      onClick={() => this.checkBoxMain()}
                    ></input>
                  </th>
                  <th scope="col">#</th>
                  <th style={{ display: "none" }}>_id</th>
                  <th scope="col">Sensor</th>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col" style={{ display: "none" }}>
                    Remarks
                  </th>
                  <th style={{ display: "none" }}>Building</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredBdDevices.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input className="checkbox" type="checkbox"></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>{enm.getType_en(this.state.typeEnm, u.type)}</td>
                    <td>{u.name}</td>
                    <td>{u.location}</td>
                    <td style={{ display: "none" }}>{u.remarks}</td>
                    <td style={{ display: "none" }}>{u.buildingID}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      {/* <ToastContainer /> */}
      </React.Fragment>
    );
  }
}

export default DataDownloadPage;
