// import { Divider } from "@material-ui/core";
import React from "react";
// import AsyncSelect from "react-select/async";
import "./tableDropDownList.css";

function rawChecked(tableName, fReturnUser) {
  let checkedRawList = [];
  let rows = document.getElementById(tableName).rows;
  for (let i = 1; i < rows.length; i++) {
    // starting from 1, 0 is th
    if (rows[i].cells[0].children[0].checked) {
      let user = fReturnUser(rows[i]);
      // console.log(`User: ${user.username}`);
      checkedRawList.push(user);
    }
  }
  return checkedRawList;
}

function getNewCopyRow(tableName, fAssignTableColumn) {
  const rows = document.getElementById(tableName).rows;
  // let ind = 0;
  let newCopy = [];
  for (let i = 1; i < rows.length; i++) {
    // const element = rows[i];
    let row = rows[i];
    let tableInfo = fAssignTableColumn(row, i);
    // console.log(tableInfo);
    if (tableInfo._id < 0) newCopy.push(tableInfo);
  }
  return newCopy;
}

function getDeletedRow(tableFromDB, tableName, fAssignTableColumn) {
  const rows = document.getElementById(tableName).rows;
  // let ind = 0;
  let existingItemsOnTable = [];
  for (let i = 1; i < rows.length; i++) {
    // const element = rows[i];
    let row = rows[i];
    let tableInfo = fAssignTableColumn(row, i);
    // console.log(tableInfo);
    if (tableInfo._id > 0) existingItemsOnTable.push(tableInfo);
  }
  // existingItems;

  let deletedItems = [];
  for (const dbItem of tableFromDB) {
    // eslint-disable-next-line
    let found = existingItemsOnTable.find((c) => c._id == dbItem._id);
    if (!found) deletedItems.push(dbItem);
  }
  return deletedItems;
}

function getEditedRow(tableFromDB, tableName, fAssignTableColumn) {
  const rows = document.getElementById(tableName).rows;
  // let ind = 0;
  let existingItemsOnTable = [];
  for (let i = 1; i < rows.length; i++) {
    // const element = rows[i];
    let row = rows[i];
    let tableInfo = fAssignTableColumn(row, i);
    // console.log(tableInfo);
    if (tableInfo._id > 0) existingItemsOnTable.push(tableInfo);
  }
  // existingItems;
  // console.log(existingItemsOnTable);
  // console.log(tableFromDB);

  let editedItems = [];
  for (const extItem of existingItemsOnTable) {
    // eslint-disable-next-line
    let foundDb = tableFromDB.find((c) => extItem._id == c._id);
    if (foundDb) {
      for (const key in extItem) {
        if (foundDb.hasOwnProperty(key)) {
          // const element = found[key];
          let bothIsNull =
            (foundDb[key] === "" || foundDb[key] === null) &&
            (extItem[key] === "" || extItem[key] === null);
          // eslint-disable-next-line
          if (foundDb[key] != extItem[key] && !bothIsNull) {
            // value is different
            editedItems.push(extItem); // push existing modified item on table
            console.log(`${key} are Different`);
            break;
          }
        }
      }
    }
  }
  return editedItems;
}

function getInfoFromTable(tableName, fAssignTableColumn) {
  const rows = document.getElementById(tableName).rows;
  // let ind = 0;
  let newCopy = [];
  for (let i = 1; i < rows.length; i++) {
    // const element = rows[i];
    let row = rows[i];
    let tableInfo = fAssignTableColumn(row, i);
    // console.log(tableInfo);
    newCopy.push(tableInfo);
  }
  return newCopy;
}

function getInfoFromTableSkipLastN(tableName, fAssignTableColumn, lastNToSkip=0) {
  const rows = document.getElementById(tableName).rows;
  // let ind = 0;
  let newCopy = [];
  for (let i = 1; i < rows.length - lastNToSkip; i++) {
    // const element = rows[i];
    let row = rows[i];
    let tableInfo = fAssignTableColumn(row, i);
    // console.log(tableInfo);
    newCopy.push(tableInfo);
  }
  return newCopy;
}

function detectTableChanges(
  tableName,
  stateData,
  uniqueKey,
  fAssignTableColumn,
  ignoreNegId
) {
  let usersTableList = [];
  let modifiedUsers = [];
  const rows = document.getElementById(tableName).rows;
  for (let i = 1; i < rows.length; i++) {
    let row = rows[i];
    let user = fAssignTableColumn(row, i);
    // console.log(user);
    // let user = {
    //     username: row.cells[1].children[0].value,
    //     company: row.cells[2].children[0].value,
    //     accessLevel: row.cells[3].children[0].value,
    //     active: row.cells[4].children[0].value,
    //     teleID: row.cells[5].children[0].value,
    //     email: row.cells[6].children[0].value,
    //     phone: row.cells[7].children[0].value,
    // }
    if (!ignoreNegId || user._id > 0) usersTableList.push(user);
  }
  // console.log(usersTableList);
  // compare each user[key] in list to this.state,
  // let isDifferent = false;
  // let userAldInModifiedList = [];
  for (const userTable of usersTableList) {
    // eslint-disable-next-line
    let stateUser = stateData.filter((e) => {
      // eslint-disable-next-line
      return e[uniqueKey] == userTable[uniqueKey];
    });

    for (const key in userTable) {
      if (userTable.hasOwnProperty(key)) {
        //teleID from table is "", teleID from state is null, use both is null logic to turn null equal to ""
        // eslint-disable-next-line
        let stateUser = stateData.filter((e) => {
          // eslint-disable-next-line
          return e[uniqueKey] == userTable[uniqueKey];
        }); 
        // console.log(stateUser);
        // eslint-disable-next-line
        let bothIsNull =
          (stateUser[0][key] === "" || stateUser[0][key] === null) &&
          (userTable[key] === "" || userTable[key] === null);
        // eslint-disable-next-line

        for (const key in userTable) {
          if (userTable.hasOwnProperty(key)) {
            //teleID from table is "", teleID from state is null, use both is null logic to turn null equal to ""
            let bothIsNull =
              (stateUser[0][key] === "" || stateUser[0][key] === null) &&
              (userTable[key] === "" || userTable[key] === null);
            // eslint-disable-next-line
            if (stateUser[0][key] != userTable[key] && !bothIsNull) {
              // isDifferent = true;
              // console.log(
              //   `${userTable[uniqueKey]}[${key}] is different, ${stateUser[0][key]}, ${userTable[key]}`
              // );
              let userAldInModifiedList = modifiedUsers.filter((e) => {
                return e[uniqueKey] === userTable[uniqueKey];
              });

              if (!userAldInModifiedList[0]) {
                // user not yet in modified list, add user to list
                modifiedUsers.push(userTable);
                // console.log("User already in modified list");
              }
            }
          }
        }
      }
    }
  }

  return modifiedUsers;
}

function detectTableChangesSkipLastN(
  tableName,
  stateData,
  uniqueKey,
  fAssignTableColumn,
  ignoreNegId,
  skipLastN
) {
  let usersTableList = [];
  let modifiedUsers = [];
  const rows = document.getElementById(tableName).rows;
  for (let i = 1; i < rows.length - skipLastN; i++) {
    let row = rows[i];
    let user = fAssignTableColumn(row, i);
    // let user = {
    //     username: row.cells[1].children[0].value,
    //     company: row.cells[2].children[0].value,
    //     accessLevel: row.cells[3].children[0].value,
    //     active: row.cells[4].children[0].value,
    //     teleID: row.cells[5].children[0].value,
    //     email: row.cells[6].children[0].value,
    //     phone: row.cells[7].children[0].value,
    // }
    if (!ignoreNegId || user._id > 0) usersTableList.push(user);
  }
  // compare each user[key] in list to this.state,
  // let isDifferent = false;
  // let userAldInModifiedList = [];
  for (const userTable of usersTableList) {
    // eslint-disable-next-line
    let stateUser = stateData.filter((e) => {
      // eslint-disable-next-line
      return e[uniqueKey] == userTable[uniqueKey];
    });

    for (const key in userTable) {
      if (userTable.hasOwnProperty(key)) {
        //teleID from table is "", teleID from state is null, use both is null logic to turn null equal to ""
        // eslint-disable-next-line
        let stateUser = stateData.filter((e) => {
          // eslint-disable-next-line
          return e[uniqueKey] == userTable[uniqueKey];
        });
        // eslint-disable-next-line
        let bothIsNull =
          (stateUser[0][key] === "" || stateUser[0][key] === null) &&
          (userTable[key] === "" || userTable[key] === null);
        // eslint-disable-next-line

        for (const key in userTable) {
          if (userTable.hasOwnProperty(key)) {
            //teleID from table is "", teleID from state is null, use both is null logic to turn null equal to ""
            let bothIsNull =
              (stateUser[0][key] === "" || stateUser[0][key] === null) &&
              (userTable[key] === "" || userTable[key] === null);
            // eslint-disable-next-line
            if (stateUser[0][key] != userTable[key] && !bothIsNull) {
              // isDifferent = true;
              // console.log(
              //   `${userTable[uniqueKey]}[${key}] is different, ${stateUser[0][key]}, ${userTable[key]}`
              // );
              let userAldInModifiedList = modifiedUsers.filter((e) => {
                return e[uniqueKey] === userTable[uniqueKey];
              });

              if (!userAldInModifiedList[0]) {
                // user not yet in modified list, add user to list
                modifiedUsers.push(userTable);
                // console.log("User already in modified list");
              }
            }
          }
        }
      }
    }
  }

  return modifiedUsers;
}

const tableDropDownlist = (selID, a_obj, _id, classname, handleChange) => {
  // console.log(a_obj);
  return (
    <select
      id={selID}
      defaultValue={_id}
      className={classname}
      onChange={handleChange}
    >
      {a_obj.map((obj) => (
        <option key={obj._id} value={obj._id}>
          {obj.name}
        </option>
      ))}
    </select>
  );
};

const tableDropDownKeySel = (
  selID,
  a_obj,
  defaultVal,
  classname,
  handleChange,
  handleOnClick,
  keyInd,
) => {
  return (
    <select
      id={selID}
      defaultValue={defaultVal}
      onChange={handleChange}
      className={classname}
      onClick={handleOnClick}
    >
      {a_obj.map((obj, ind) => (
        <option key={`${selID}-${obj._id}-${ind}`} value={obj._id}>
          {obj.name}
        </option>
      ))}
    </select>
  );
};

const dropDownWithFilter = (
  selID,
  a_obj,
  _id,
  toMatch,
  classname,
  handleChange
) => {
  // console.log(toMatch);
  // console.log(a_obj);
  let a_obj_filter = a_obj.filter((o) => {
    // eslint-disable-next-line
    return o.toMatch == toMatch || o.toMatch == "";
  });
  // console.log(a_obj_filter);
  return (
    <select
      id={selID}
      defaultValue={_id}
      onChange={handleChange}
      className={classname}
    >
      {a_obj_filter.map((obj) => (
        <option key={obj._id} value={obj._id}>
          {obj.name}
        </option>
      ))}
    </select>
  );
};

const expModule = {
  getEditedRow,
  getDeletedRow,
  getInfoFromTable,
  getNewCopyRow,
  tableDropDownKeySel,
  dropDownWithFilter,
  dropDown: tableDropDownlist,
  rawChecked,
  detectTableChanges,
  getInfoFromTableSkipLastN,
  detectTableChangesSkipLastN,
};
export default expModule;

// export default{
//     rawChecked,
//     detectTableChanges
// }
