import http from "../httpService";

async function getBy_ty_devIdList(type, devIdList) {
    let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getbddevby_ty_devidlist";
  
    let body = {
      type: type,
      devIdList: devIdList,
    };
    return await http.post(url, body);
}

async function getBdInfoBy_idList(bd_idList) {
    let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getbdList_byBd_idList";
    let body = {
        bd_idList
    };
    return await http.post(url, body);
  }

const expModule = {
    getBy_ty_devIdList,
    getBdInfoBy_idList
};

export default expModule;