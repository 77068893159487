import React, { Component } from 'react';
// import devMgnt_V2Fn from '../../utilities/devMgnt_v2_Fn/devMgnt_V2Fn';
import acFunction from '../Fun/acCtrl_Function'
import { ToastContainer, toast } from "react-toastify";

class AcDevSelPage extends Component {
    state = { 
        totlDev:[],
     }

    async componentDidMount() {
        this.getDevUnderAccount();
    }

    clickAdd=()=>{
        this.props.history.push({
            pathname: "/ctrldev/ac/logindev",           
            _data: {
              // _selBuildingId: this.state.selBuildingId,
              // _selT1Llist:tableMonList[ind]._id,
            },
          });
    }

    getDevUnderAccount=async()=>{
        try {
            let totlDev = await acFunction.getDevUnderAccount();
            this.setState({totlDev});            
        } catch (error) {
            toast.error(error.message);
        }

    }

    SelDev=async(u)=>{
        try {            
            await localStorage.setItem("bdDev_id", u.bdDev_id);
            await localStorage.setItem("devID", u.devID);
            
            this.props.history.push({
                pathname: "/ctrldev/ac",
                _data: {
                    devID:u.devID,
                    bdDev_id:u.bdDev_id, 
                },
            });
        } catch (error) {
            toast.error(error.message);
        }
    }

    render() { 
        return ( <>
            <div 
                className="spaceBetween"
                style={{
                    margin:'15px 10px',
                }}
                >
                <div
                    onClick={this.backTo} 
                    className="framelessIcon checkHidden">
                    <i className='fa fa-chevron-left buttonIcon'></i>
                </div>
                <div style={{
                    // backgroundColor:"blue",
                    fontSize:'25px',
                    margin:'-10px',
                    }}>
                    Devices
                </div>
                <div 
                    className="framelessIcon"
                    onClick={this.clickAdd}
                    style={{
                        // flex:1,
                        // float:'right',
                        alignSelf:'flex-end',                    
                        // backgroundColor:"green"
                }}>
                    <i className='fa fa-plus buttonIcon'></i>
                </div>
            </div>
            <div className='SelectionContainerHor'>
                {this.state.totlDev.map((u, ind)=>(
                    <div
                        onClick={()=>this.SelDev(u)}
                        key={`selTab_${ind}`} 
                        className='SelectionButton'>
                        {u.displayName}
                    </div>
                ))}
                {this.state.totlDev.length%2!==0 && <div
                        key={`selTab_`} 
                        className='SelectionButton checkHidden'>
                        extra, align purpose
                    </div>
                }
            </div>
        <ToastContainer />
        </> );
    }
}
 
export default AcDevSelPage;