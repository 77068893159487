import React, { Component } from 'react';
import resetPassFn from '../../function/userManagement/resetPassReqFn';
import { ToastContainer, toast } from "react-toastify";

class PR_InsertEmail extends Component {
    state={

    }

    rstPassword=async()=>{
        let username = document.getElementById('emailInput').value;
        console.log(`Reset Email : ${username}` );
        let rel = await resetPassFn.resetPassReq(username);
        (rel.data !== "OK") ? toast.error(rel.data.msg) : toast.dark("Recovery link sent through email.")
        console.log(rel);

    }
    render() { 
        return <div>
            <div>email:</div>
            <input id='emailInput'  type="text" />
            <div onClick={this.rstPassword}>Reset Password</div>
        <ToastContainer />
        </div>;
    }
}
 
export default PR_InsertEmail;