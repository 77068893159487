import React, { useState } from "react";
import { Link } from "react-router-dom";

const Submenu = ({ item, user }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <React.Fragment>
      <Link
        to={item.path}
        className="navbarlink"
        onClick={item.subNav && showSubnav}
      >
        <div className={item.cName}>
          {item.icon}
          <span>{item.title}</span>
          <span>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </span>
        </div>
      </Link>
      {subnav &&
        item.subNav.map((item, index) => {
          if (user.accessLevel < item.accessLevel) {
            return (
              <Link to={item.path} key={index} className="sub-navbarlink">
                <div className={item.cName}>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
              </Link>
            );
          }
          return null;
        })}
    </React.Fragment>
  );
};

export default Submenu;
