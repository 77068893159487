import http from "./httpService";


async function getByUserID(userID){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/assignbuilding/get/${userID}`;
    return await http.get(url);    
}


async function add(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/assignbuilding/add`;    
    const body = {   
        userID: obj.userID,
        buildingID: obj.buildingID,
    };
    return await http.post(url, body);    
}


async function del(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/assignbuilding/del`;    
    const body = {   
        _id:obj._id,
        userID: obj.userID,
        buildingID: obj.buildingID,
    };
    return await http.post(url, body);    
}





const expModule = {  
    del,
    add,
    getByUserID,
};
export default expModule;