import acControlSer from '../../../service/controlDev/acControl';

async function insertSche(body){
    try {
        // console.log(body.ts);
        let result = await acControlSer.insertSche(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return false;
        if(!result.data) return false
        return true;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}

async function getScheBy_beDevid(body){
    try {
        // console.log(body.ts);
        let result = await acControlSer.getSche_byBdDevId(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return[]
    }
}

async function updateScheduleList(body){
    try {
        // console.log(body.ts);
        let result = await acControlSer.updateScheduleList(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return[]
    }
}

async function deleteScheduleList(body){
    try {
        // console.log(body.ts);
        let result = await acControlSer.deleteScheduleList(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return[]
    }
}

const expModule = {  
    deleteScheduleList,
    updateScheduleList,
    insertSche,
    getScheBy_beDevid,
};
export default expModule;