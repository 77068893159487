import React, { useEffect, useRef, useState } from 'react';
import devScheFn from '../../../function/V1/V1_Control/V1_ControlFn';
import timeFn from '../../../../src/component/utilities/timeFn';
import TpDateTimeSelDialog from './../../SharedTemplate/InputDialog/TpDateTimeSelDialog';
import TpScheActionSelDialog from '../../SharedTemplate/InputDialog/TpScheActionSelDialog';
import TpDowList from './../../SharedTemplate/DeviceSchedule/TpDowList';
import devScheLib from '../../SharedTemplate/DeviceSchedule/DevScheLib';
import ctrlCmdLib from '../../../function/DevControlLib/DevControlLib';
import v1CtrlOvvFn from '../../../../src/function/V1/V1_CtrlOvvFn/V1_CtrlOvvFn';
import { toast } from 'react-toastify';
import { isEmptyArr } from '../../../function/utilities/utilities';
import TpDiaSending from '../../SharedTemplate/StatusDialog/TpDiaSending';
import TpDiaSuccess from '../../SharedTemplate/StatusDialog/TpDiaSuccess';
import TpDiaFailed from '../../SharedTemplate/StatusDialog/TpDiaFailed';
import socketIOClient from 'socket.io-client';
import TpWcpuOvv from './../DeviceTemplate/TpWcpuOvv';
// import TpAcpuOvv from '../DeviceTemplate/TpAcpuOvv';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import TpGatewayPairDialog from '../../SharedTemplate/InputDialog/TpGatewayPairDialog';

function DedZoom_WCPU(props) {
    // const C_devInfo={ht:36, hi:11, bdDev_id:1569}   /** to be modify */
    const C_totalScheSize = 10;
    const cmdTimeout = 10000;    // 10 sec
    const msgTimeout = 3000;    // 3 sec

    const [G_DeviceInfo, setG_DeviceInfo] = useState({});
    

    const [G_EventList, setG_EventList] = useState([]);
    const [G_WeeklyList, setG_WeeklyList] = useState([]);
    const [G_bDateTimeDialogShow, setG_bDateTimeDialogShow] = useState(false);
    const [G_bActionDialogShow, setG_bActionDialogShow] = useState(false);
    const [G_bSelSche, setG_bSelSche] = useState({});   // prop pass into date time dialog
    const [G_bAction_Props, setG_bAction_Props] = useState({});   // prop pass into date time dialog
    const [G_nSync_Save, setG_nSync_Save] = useState(0);    // 0-Sync, 1-Save, 2-<None>

    const [G_bShowSendStatusDia, setG_bShowSendStatusDia] = useState(false);    
    const [G_nSendStatus_dia, setG_nSendStatus_dia] = useState(0);  // 0 idle, 1 sending, 2 success, 3 failed
    const [G_statusDiaErrMsg, setG_statusDiaErrMsg] = useState("");  // 0 idle, 1 sending, 2 success, 3 failed
    
    const [G_bLoaded, setG_bLoaded] = useState(false);    

    const [G_bShowGwPairDia, setG_bShowGwPairDia] = useState(false);    
    const [G_GwPairDisplay, setG_GwPairDisplay] = useState(0);

    const cmdTimeoutRef = useRef(null);
    const statusTimeoutRef = useRef(null);
    const refCmdLog = useRef({});
    const refSending = useRef(false);
    const refScheList = useRef([]);
    const refDevInfo = useRef({});
    
    useEffect(()=>{
        let socket;
        async function startUp(){
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);
            
            await laodDevInfo();
            await loadGwId();
            await loadSchedule();
            await loadLastdata();

            let topic = `v2_CtrlCmd`;
            socket.on(topic, async(data) => {
                // console.log("data", data);
                let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
                if(sentSuccess) {
                    clearTimeout(cmdTimeoutRef.current);
                    setG_nSendStatus_dia(2);
                    /** trigger pass countdown */
                    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
                    // toast ("Success")
                    await handleServerReq(data);
                }
                /** handle server request */
                
            });
            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            clearTimeout(cmdTimeoutRef.current);
            clearTimeout(statusTimeoutRef.current);
            socket.disconnect();
        }
        // eslint-disable-next-line
    }, []);

    
    const backPrevPage=()=>{
        return props.history.push({pathname: "/ctrlpg/ovv"});
    }

    const loadLastdata=async()=>{
        let lastData = await BdDevFn_Com.loadLastData(refDevInfo.current);
        /** set default setpoint */
        if(isNaN(lastData.pf_1)) return
        document.getElementById('In_Setpoint').value = lastData.pf_1;
        // if(lastData) setG_Lastdata(lastData);    
    }

    const laodDevInfo=async()=>{
        /** set C_devInfo */
        let {ht, hi, bdDev_id} = props.match.params;
        let _devInfo ={
            ht:parseInt(ht),
            hi:parseInt(hi),
            bdDev_id:parseInt(bdDev_id),
        }
        if(!_devInfo.ht || !_devInfo.hi || !_devInfo.bdDev_id) {
            toast("Device Info Invalid");  
            /** navigate to previous page */
            return props.history.push({pathname: "/ctrlpg/ovv"});
        }

        refDevInfo.current={..._devInfo};

        let defInfo = await BdDevFn_Com.loadDeviceInfo(refDevInfo.current.bdDev_id);
        // console.log(defInfo);
        if(_devInfo.ht!== defInfo.type || _devInfo.hi!== defInfo.devID || _devInfo.bdDev_id!== defInfo._id) {
            toast("Device Info Not Match"); 
            return props.history.push({pathname: "/ctrlpg/ovv"});
        }
        if(defInfo) setG_DeviceInfo(defInfo);
    }

    const loadGwId=async()=>{
        let gwPairInfo = await v1CtrlOvvFn.getV1_ctrlGwPair(refDevInfo.current.bdDev_id);

        if(gwPairInfo.errMsg) return toast(`Cmd Err: ${gwPairInfo.errMsg}`);
        let {gwPairList} = gwPairInfo;
        if(isEmptyArr(gwPairList)) return toast(`No gateway pair to this device`);
        // let gwid = gwPairList[0].gwid;
        setG_GwPairDisplay(gwPairList[0].gwid)
    }

    const handleServerReq=async(mqttData)=>{
        let {hf} = mqttData;
        if(hf===20002){     // server request schedule
            /** convert schedule into DB form , {startUnix, endUnix, schType, action, DOW, reserve1, inUse}*/
            let scheList = devScheLib.sche_loraFormat_to_db(mqttData);
            /** store DB into database */
            let setScheRel = await devScheFn.V2_setSche(scheList, refDevInfo.current);
            if(!setScheRel)return toast("Store DB err");            
            /** update device display */
            await loadSchedule();            
            /** set sync into 3, clear sync_save button */
            setG_nSync_Save(3);
        }else if(hf===20001){        // set sche to device
            /** save eventList into DB */
            let setScheRel = await devScheFn.V2_setSche(refScheList.current, refDevInfo.current);
            // console.log("setScheRel", setScheRel);
            if(!setScheRel)return toast("Store DB err");
            /** set sync into 3, clear sync_save button */
            setG_nSync_Save(3);
        }   
    }


    const loadSchedule=async ()=>{
        let scheList = await devScheFn.getV2_schedule(refDevInfo.current.ht, refDevInfo.current.hi)
        let eventRawList = getSchedule_bySchTy(scheList, 2);        
        let eventList = getEventScheInfo(eventRawList);
        // console.log("eventList", eventList);
        setG_EventList(eventList);

        let weeklyRawList = getSchedule_bySchTy(scheList, 1);
        /** convert DB list into display list */
        let weeklyList = getWeeklyScheInfo(weeklyRawList)
        // console.log("weeklyList", weeklyList);
        setG_WeeklyList(weeklyList)        
        
    }

    const getSchedule_bySchTy=(eventList, schTy)=>{
        try {
            return eventList.filter(c=>c.schType === schTy);            
        } catch (error) {
            console.log("error: ", error.message);
            return []
        }
    }

    const getEventScheInfo=(eventScheList)=>{
        let eventSche=[];
        for (const eachSche of eventScheList) {
            let _endUnix = null;
            if(eachSche.action===3) _endUnix=eachSche.endUnix;
            eventSche.push({
                active:eachSche.inUse_dev,
                action:eachSche.action,
                startUnix:eachSche.startUnix,
                endUnix:_endUnix,
            })
        }
        return eventSche;
    }

    const getWeeklyScheInfo=(_weeklyRawList)=>{
        let weekSche = [];
        for (const eachSche of _weeklyRawList) {
            let _starUnix = null;
            if(eachSche.action!==3){
                _starUnix = eachSche.startUnix;
            }
            weekSche.push({
                action:eachSche.action,
                DOW:eachSche.DOW,
                active:eachSche.inUse_dev,
                startUnix:_starUnix,
            })
        }
        return weekSche;
    }

    const fEventActionName=(nAction)=>{
        if(nAction===1) return "On"
        if(nAction===2) return "Off"
        if(nAction===3) return "Holiday"
        return "Invalid"
    }

    const handleSche_toggleActive=(idx, scheType)=>{
        let _ScheList = [];
        if(scheType===1)        _ScheList = [...G_WeeklyList];
        else if(scheType===2)   _ScheList = [...G_EventList];

        if(_ScheList[idx].active===0){
            _ScheList[idx].active = 1;
        }else{
            _ScheList[idx].active = 0;
        }        

        if(scheType===1)        setG_WeeklyList(_ScheList);
        else if(scheType===2)   setG_EventList(_ScheList);
        
        setG_nSync_Save(1);
    }


    const handleEditDateTime=(scheType, scheIdx, prevUnix, start_end)=>{
        setG_bSelSche({scheType, scheIdx, prevUnix, start_end});        
        setG_bDateTimeDialogShow(true);
    }
    
    const closeSelDialog=()=>{
        setG_bDateTimeDialogShow(false);
        setG_bActionDialogShow(false);
        setG_bShowGwPairDia(false);
    }

    const handle_dia_onClickBack=()=>{
        closeSelDialog();
    }
    const handle_dia_onClickSet=(scheType, scheIdx, start_end, setUnix)=>{
        try {
            let _ScheList=[];
            if(scheType===1)    _ScheList = [...G_WeeklyList];
            if(scheType===2)    _ScheList = [...G_EventList];

            if(scheType===2){       /** event type */
                if(start_end===1){      /** set start time */
                    _ScheList[scheIdx].startUnix = setUnix;
                }else if(start_end===2){    /** set end time */
                    _ScheList[scheIdx].endUnix = setUnix;
                }
            }else if(scheType===1){     /** weekly schedule */
                _ScheList[scheIdx].startUnix = setUnix;
            }

            if(scheType===1) setG_WeeklyList(_ScheList);
            if(scheType===2) setG_EventList(_ScheList);
            setG_nSync_Save(1);
        } catch (error) {
            console.log("handle_dia_onClickSet err: ", error.message);
        } finally{
            closeSelDialog();
        }
    }

    const handleSelAction=(scheType, scheIdx, action)=>{
        setG_bActionDialogShow(true);
        setG_bAction_Props({scheType, scheIdx, action});
    }
    const handle_dia_onClickActSel=(scheType, scheIdx, action)=>{
        try {            
            let _EventList=[];
            if(scheType===1) _EventList = [...G_WeeklyList];
            if(scheType===2) _EventList = [...G_EventList];

            if(scheType===1){           /** weekly schedule */
                _EventList[scheIdx].action = action;
                if(_EventList[scheIdx].action!==3){     /** holiday selected */
                    if(!_EventList[scheIdx].startUnix){
                        _EventList[scheIdx].startUnix= timeFn.getUnixNow();
                        
                    }
                }
            }else if(scheType===2){       /** event schedule type */
                _EventList[scheIdx].action = action;
                if(_EventList[scheIdx].action===3){     /** holiday selected */
                    if(!_EventList[scheIdx].endUnix){       /** end time is empty */
                        _EventList[scheIdx].endUnix=_EventList[scheIdx].startUnix;
                    }    
                }
            }

            if(scheType===1) setG_WeeklyList(_EventList);   
            if(scheType===2) setG_EventList(_EventList);   
            
            setG_nSync_Save(1);
        } catch (error) {
            console.log("Sel Action Err:", error.message);
        }finally{
            closeSelDialog();
        }
    }
    
    const handle_dowClick=(scheIdx, newDow)=>{
        try {
            let _WeeklyList = [...G_WeeklyList];            
            _WeeklyList[scheIdx].DOW = newDow;
            setG_WeeklyList(_WeeklyList);
            setG_nSync_Save(1);
        } catch (error) {
            console.log("Click DOW Error : ", error.message);
        }
    }

    const addNewEventSche=()=>{
        let _EventList = [...G_EventList];
        _EventList.push({
            action:1,
            active:0,
            startUnix:timeFn.getUnixNow()
        })
        setG_EventList(_EventList);
        setG_nSync_Save(1);
    }

    const addNewWeelklySche=()=>{
        let _WeeklyList = [...G_WeeklyList];
        _WeeklyList.push({
            DOW:0,
            action:1,
            active:0,
            startUnix:timeFn.getUnixNow()
        })
        setG_WeeklyList(_WeeklyList);
        setG_nSync_Save(1);
    }

    const delSche=(scheType, scheIdx)=>{
        let scheList = [];
        if(scheType===1) scheList = [...G_WeeklyList];  /** weekly */
        if(scheType===2) scheList = [...G_EventList];  /** event */

        scheList.splice(scheIdx, 1);

        if(scheType===1)  setG_WeeklyList(scheList)     /** weekly */
        if(scheType===2)  setG_EventList(scheList)     /** event */

        setG_nSync_Save(1);
    }

    const syncScheWithDevive=async ()=>{
        let gwPairInfo = await v1CtrlOvvFn.getV1_ctrlGwPair(refDevInfo.current.bdDev_id);

        if(gwPairInfo.errMsg) return toast(`Cmd Err: ${gwPairInfo.errMsg}`);
        let {gwPairList} = gwPairInfo;
        if(isEmptyArr(gwPairList)) return toast(`No gateway pair to this device`);
        let gwid = gwPairList[0].gwid;
        /** get device {type, devID} info */
        let devInfo = {type:refDevInfo.current.ht, devID:refDevInfo.current.hi}

        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(20002, gwid, devInfo, [], 1);      // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
        // console.log("cmdRel", cmdRel);
        setG_bShowSendStatusDia(true);
        setG_nSendStatus_dia(1);

        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    const sendScheToDevice=async ()=>{
        let _WeeklyList = [...G_WeeklyList];
        let _EventList = [...G_EventList];
        let scheList_LoRaFormat = devScheLib.getSche_DbFormat(_WeeklyList, _EventList);
        refScheList.current = scheList_LoRaFormat;
        
        /** generate payload */
        let schePayload = devScheLib.genSchePayload(scheList_LoRaFormat);
        
        /** get Gw Info */
        let gwPairInfo = await v1CtrlOvvFn.getV1_ctrlGwPair(refDevInfo.current.bdDev_id);

        if(gwPairInfo.errMsg) return toast(`Cmd Err: ${gwPairInfo.errMsg}`);
        let {gwPairList} = gwPairInfo;
        if(isEmptyArr(gwPairList)) return toast(`No gateway pair to this device`);
        let gwid = gwPairList[0].gwid;
        /** get device {type, devID} info */
        let devInfo = {type:refDevInfo.current.ht, devID:refDevInfo.current.hi}

        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(20001, gwid, devInfo, schePayload, 1);      // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
        // console.log("cmdRel", cmdRel);
        setG_bShowSendStatusDia(true);
        setG_nSendStatus_dia(1);

        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    } 

    const fCmdTimeout=async()=>{
        let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
        if(!checkRel || !checkRel.errMsg) {
            setG_statusDiaErrMsg("Cmd DB Err");
            // toast("Cmd DB Err");
        }
        if(checkRel.errMsg) {
            setG_statusDiaErrMsg(checkRel.errMsg);
            // toast(checkRel.errMsg);
        }
        setG_nSendStatus_dia(3);
        clearTimeout(cmdTimeoutRef.current);
        /** trigger failed countdown */
        statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);        
        
    }

    const statusDiaAutoClose=()=>{
        setG_bShowSendStatusDia(false);
        setG_nSendStatus_dia(0);
    }
    const statusDiaManualClose=()=>{
        setG_bShowSendStatusDia(false);
        setG_nSendStatus_dia(0);
        /** clear 3 sec timer */
        clearTimeout(statusTimeoutRef.current);
    }



    const handle_ClickSave_Sync=async()=>{
        /** determine is save or sync */
        /** save part */
        if(refSending.current) return
        refSending.current=true;
        if(G_nSync_Save===0){       // sync
            await syncScheWithDevive();
        }else if (G_nSync_Save===1){       // save
            await sendScheToDevice();            
        }
        refSending.current=false;
        /** sync part */
    }

    const handleOnXcpuCmd=async ()=>{
        if(refSending.current) return
        // console.log("On");
        refSending.current=true;
        await sendOnCmdToDevice(true);
        refSending.current=false;
    }
    
    const handleOffXcpuCmd=async ()=>{
        if(refSending.current) return
        // console.log("Off");
        refSending.current=true;
        await sendOnCmdToDevice(false);
        refSending.current=false;
    }

    
    const sendOnCmdToDevice=async(bOn)=>{        
        /** generate payload */
        let payload = {pi:[1,1]};
        if(!bOn) payload = {pi:[2,2]};
        
        /** get Gw Info */
        let gwPairInfo = await v1CtrlOvvFn.getV1_ctrlGwPair(refDevInfo.current.bdDev_id);

        if(gwPairInfo.errMsg) return toast(`Cmd Err: ${gwPairInfo.errMsg}`);
        let {gwPairList} = gwPairInfo;
        if(isEmptyArr(gwPairList)) return toast(`No gateway pair to this device`);
        let gwid = gwPairList[0].gwid;
        /** get device {type, devID} info */
        let devInfo = {type:refDevInfo.current.ht, devID:refDevInfo.current.hi}

        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(2, gwid, devInfo, payload, 1);      // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
        // console.log("cmdRel", cmdRel);
        setG_bShowSendStatusDia(true);
        setG_nSendStatus_dia(1);

        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    } 

    const handleCallGwDia=()=>{
        setG_bShowGwPairDia(true);
    }    

    const handleSetGwDisplay=(gwid)=>{
        setG_GwPairDisplay(gwid);
    }

    const handleSetTemp=async()=>{
        let sp = parseInt(document.getElementById('In_Setpoint').value);
        console.log("Set :", sp);        
        let payload = {pi:[sp]};
        
        /** get Gw Info */
        let gwPairInfo = await v1CtrlOvvFn.getV1_ctrlGwPair(refDevInfo.current.bdDev_id);

        if(gwPairInfo.errMsg) return toast(`Cmd Err: ${gwPairInfo.errMsg}`);
        let {gwPairList} = gwPairInfo;
        if(isEmptyArr(gwPairList)) return toast(`No gateway pair to this device`);
        let gwid = gwPairList[0].gwid;
        /** get device {type, devID} info */
        let devInfo = {type:refDevInfo.current.ht, devID:refDevInfo.current.hi}

        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(3, gwid, devInfo, payload, 1);      // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
        // console.log("cmdRel", cmdRel);
        setG_bShowSendStatusDia(true);
        setG_nSendStatus_dia(1);

        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    return (
        <div className='disFlex flexDirVer'>
            
            {(G_bShowGwPairDia || G_bActionDialogShow || G_bDateTimeDialogShow || G_bShowSendStatusDia) && <div className='autumn_dialogBlurBg'> </div>}
            {G_bDateTimeDialogShow && <TpDateTimeSelDialog 
                selBlock={G_bSelSche} 
                dia_onClickBack={handle_dia_onClickBack}
                dia_onClickSet={handle_dia_onClickSet}/>}
            {G_bActionDialogShow && <TpScheActionSelDialog 
                selScheInfo={G_bAction_Props}
                dia_onclikCloseDia={closeSelDialog}
                dia_onClikActionSel = {handle_dia_onClickActSel}/>}

            {G_nSendStatus_dia===1 && G_bShowSendStatusDia && <TpDiaSending/>}
            {G_nSendStatus_dia===2 && G_bShowSendStatusDia && <TpDiaSuccess onClickCloseDia={statusDiaManualClose}/>}
            {G_nSendStatus_dia===3 && G_bShowSendStatusDia && <TpDiaFailed onClickCloseDia={statusDiaManualClose} diaErrMsg={G_statusDiaErrMsg}/>}

            {G_bShowGwPairDia && <TpGatewayPairDialog 
                dia_onclikCloseDia={closeSelDialog}
                divInfo = {G_DeviceInfo}
                gwPair = {G_GwPairDisplay}
                setGwId={handleSetGwDisplay}/>}
            
            <div className='autumn_ZoomDevHeader'>
                <div className='autumn_backIcon hoverPointer'
                onClick={backPrevPage}>
                    <div className='fa fa-angle-left'></div>
                </div>
                <div className='autumn_devTitleContainer  flexVerAllCenter'> 
                    <div className={`autumn_devUpperTitle ${G_DeviceInfo.location?"":"hideDiv_WithSpace"}`}>
                        {G_DeviceInfo.location ? `${G_DeviceInfo.location}`:"Hidden"}
                    </div>
                    <div className='autumn_devTitle'>
                        {G_DeviceInfo.name ? `${G_DeviceInfo.name} [${G_DeviceInfo.devID}]`:""}
                    </div>
                    <div className={`autumn_devUpperTitle hideDiv_WithSpace`}>
                        {G_DeviceInfo.location ? `${G_DeviceInfo.location}`:"Hidden"}
                    </div>
                </div>
                <div className='hideDiv_WithSpace'>
                    <div className='fa fa-angle-left'></div>
                </div>
            </div>
            <div className={"selfHorCenter autumn_zoomDevCtrlContainer"}>
                {G_bLoaded && <TpWcpuOvv devInfo={refDevInfo.current} 
                callFromDevDetails={true}
                fOnClick={handleOnXcpuCmd} 
                fOffClick={handleOffXcpuCmd}/>}
            </div>

            <div className='autumn_seperateLine'></div>
            <div className={"autumn_detailCard selfHorCenter"}>
                <div className='autumn_subTitleContainer sortHor verCenter'>
                    <div className='autumn_subTitle'> Temperature Setpoint</div>
                </div>
                <div className='sortHor autumn_spContainer'>
                    <input id="In_Setpoint" type='number' className='autumn_blueText autumn_SpInput'/>
                    <div>°C</div>
                    <div className='autumn_devButton stayRight btnColor_Green hoverPointer'
                    onClick={handleSetTemp}> 
                        Set
                    </div>
                </div>
            </div>            

            <div className='autumn_seperateLine'></div>
            <div className={"autumn_detailCard selfHorCenter"}>                
                <div className='autumn_subTitleContainer sortHor verCenter'>
                    <div className='autumn_subTitle'> Weekly schedule</div>
                    {G_nSync_Save !== 3 && <div className='autumn_devButton stayRight btnColor_Green hoverPointer'
                    onClick={handle_ClickSave_Sync}> 
                        {G_nSync_Save===0?"Sync":"Save"}
                    </div>}
                </div>
                <div className='autumm_eventGroup'>
                    {G_WeeklyList.map((u, ind)=>(
                        <div key={`weekSche_${ind}`}>
                            <div className=''>
                                <div className='sortHor autumn_scheTitle'>
                                    <div className='autumn_scheDelButton btnColor_Red hoverPointer'
                                    onClick={()=>delSche(1, ind)}>
                                        <div className='fa fa-trash-o autumn_trashColor'></div>
                                    </div>
                                    <div className=''>{`Schedule ${ind+1}`}</div>
                                    <div className='stayRight autumn_blueText autumn_boldText_x1'
                                    onClick={()=>handleSche_toggleActive(ind, 1)}>
                                        {u.active===1 && <div className='autumn_activeButton btnColor_Green hoverPointer'>Active</div>}
                                        {u.active===0 && <div className='autumn_activeButton btnColor_Grey hoverPointer'>Idle</div>}
                                    </div>
                                </div>

                                <div className='sortHor'>
                                    <div className='autumn_valName'>Action</div>
                                    <div>:</div>
                                    <div className='stayRight autumn_blueText autumn_boldText_x1 autumn_actionSel hoverPointer'
                                    onClick={()=>handleSelAction(1, ind, u.action)}>
                                        {fEventActionName(u.action)}
                                    </div>
                                </div>
                                {u.action !== 3 && <div className='sortHor'>
                                    <div className='autumn_valName'>Start</div>
                                    <div>:</div>
                                    <div className='stayRight autumn_blueText autumn_boldText_x1 autumn_actionSel hoverPointer'
                                    onClick={()=>handleEditDateTime(1, ind, u.startUnix, 1)}>
                                        {timeFn.getWeeklyScheTimeFormat(u.startUnix)}
                                    </div>
                                </div>}          
                                <TpDowList 
                                scheIdx={ind}
                                scheDow={u.DOW}
                                dowClick={handle_dowClick}/>              
                            </div>    
                            <div className='autumn_subSeperateLine'></div>
                        </div>
                    ))} 

                    {(G_EventList.length + G_WeeklyList.length < C_totalScheSize) ? <div className='autumm_addNewScheBtn hoverPointer'
                    onClick={addNewWeelklySche}> + </div>:
                    <div className='autumm_scheReachMax'>Schedule reach max</div>
                    }
                </div>

            </div>

            <div className='autumn_seperateLine'></div>

            <div className={"autumn_detailCard selfHorCenter"}>                
                <div className='autumn_subTitleContainer sortHor verCenter'>
                    <div className='autumn_subTitle'> Event schedule</div>
                    {G_nSync_Save !== 3 && <div className='autumn_devButton stayRight btnColor_Green hoverPointer'
                    onClick={handle_ClickSave_Sync}> 
                        {G_nSync_Save===0?"Sync":"Save"}
                    </div>}
                </div>
                
                <div className='autumm_eventGroup'>
                    
                    {G_EventList.map((u, ind)=>(
                    <div key={`eventScheKey_${ind}`}>
                        <div className=''>
                            <div className='sortHor autumn_scheTitle'>
                                <div className='autumn_scheDelButton btnColor_Red hoverPointer'
                                onClick={()=>delSche(2, ind)}>
                                    <div className='fa fa-trash-o autumn_trashColor'></div>
                                </div>
                                <div className=''>{`Schedule ${ind+1}`}</div>
                                <div className='stayRight autumn_blueText autumn_boldText_x1 hoverPointer'
                                onClick={()=>handleSche_toggleActive(ind, 2)}>
                                    {u.active===1 && <div className='autumn_activeButton btnColor_Green '>Active</div>}
                                    {u.active===0 && <div className='autumn_activeButton btnColor_Grey'>Idle</div>}
                                </div>
                            </div>
                            <div className='sortHor'>
                                <div className='autumn_valName'>Action</div>
                                <div>:</div>
                                <div className='stayRight autumn_blueText autumn_boldText_x1 autumn_actionSel hoverPointer'
                                onClick={()=>handleSelAction(2, ind, u.action)}>
                                    {fEventActionName(u.action)}
                                </div>
                            </div>
                            <div className='sortHor'>
                                <div className='autumn_valName'>Start</div>
                                <div>:</div>
                                <div className='stayRight autumn_blueText autumn_boldText_x1 hoverPointer'
                                onClick={()=>handleEditDateTime(2, ind, u.startUnix, 1)}>
                                    {timeFn.getEventScheTimeFormat(u.startUnix)}
                                </div>
                            </div>                        
                            {u.action ===3 && <div className='sortHor'>
                                <div className='autumn_valName'>End</div>
                                <div>:</div>
                                <div className='stayRight autumn_blueText autumn_boldText_x1 hoverPointer'
                                onClick={()=>handleEditDateTime(2, ind, u.endUnix, 2)}>
                                    {timeFn.getEventScheTimeFormat(u.endUnix)}
                                </div>
                            </div>   }                     
                        </div>   

                        <div className='autumn_subSeperateLine'></div>
                    </div> 
                    ))} 

                    {(G_EventList.length + G_WeeklyList.length < C_totalScheSize) ? <div className='autumm_addNewScheBtn hoverPointer'
                    onClick={addNewEventSche}> + </div>:
                    <div className='autumm_scheReachMax'>Schedule reach max</div>
                    }
                </div>

            </div>

            <div className='autumn_seperateLine'></div>
            <div className={"autumn_detailCard selfHorCenter"}>
                <div className='autumn_subTitleContainer sortHor verCenter'>
                    <div className='autumn_subTitle'> Gateway pair</div>
                </div>
                <div className='autumm_eventGroup'>
                    <div className='sortHor'>
                        <div className='autumn_valName'>Gw ID</div>
                        <div>:</div>
                        <div className='stayRight autumn_blueText autumn_boldText_x1 autumn_actionSel hoverPointer'
                        onClick={handleCallGwDia}>
                            {G_GwPairDisplay===0?"none":G_GwPairDisplay}
                        </div>
                    </div>
                </div>
            </div>

            

        </div>
    );
}

export default DedZoom_WCPU;
