import statusThresSer from './../../service/statusThreshold/statusThresholdService';


async function getStatusThreshold(devID){
    try {
        let body = {devID};
        // console.log("Get Function");
        let result = await statusThresSer.getThreshold(body);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}

async function setStatusThreshold(body){    
    try {
        // let body = {devID, threshold, invert};
        let {data:result, status} = await statusThresSer.setThreshold(body);
        // console.log(result);
        // if(result.status!==200) return false
        if (status===200 && result.affectedRows > 0 && result.affectedRows >0) {
            return true;
        }
        return false;
    } catch (error) {
        console.log(error.message);
        return false
    }
}

const expModule = {  
    getStatusThreshold,
    setStatusThreshold,
};
export default expModule;