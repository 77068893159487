import React, { Component } from "react";
import buildings from "../../service/setting_Buildings";
import tableFn from "../utilities/tableFunction";
import {
  sortByName,
  sortByTy_Loc_Name,
  sortTemplate,
} from "../utilities/ArrayFn/arrayFn";
import bdDevFn from "../utilities/getDevices";
import { genBdDevName } from "../utilities/conversion";
import devActFn from "../utilities/devActFun/devActFn";
import Icon from "../common/icon";
import { trueFalseTo_1_0, timeToTimeFmt } from "../utilities/conversion";
import { ToastContainer, toast } from "react-toastify";
import enumFn from "../utilities/enum";

class DeviceActivePage extends Component {
  state = {
    allBuildings: [],
    allBdEnum: [],
    bd_id: 0,
    displayTable: [], // display table
    initialTable: [], // compare with this table, find the modified item
    icon: "fa fa-floppy-o deactive",
    typeEnm:[],
  };

  async componentDidMount() {
    /** Get type enm */
    let typeEnm =await enumFn.getDevTypeEnum();
    await this.setState({typeEnm});
    await this._getAllBuildings();
    this.getBuildingEnm();
    console.log(this.state.allBuildings);

    let sortKeys = [
      { key: "owner", descOrder: true },
      { key: "building", descOrder: false },
    ];
    let _bds = [...this.state.allBuildings];
    _bds.sort((a, b) => sortTemplate(a, b, sortKeys));
    // console.log(_bds);
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      await this.setState({ allBuildings });
      // console.log(this.state.allBuildings);
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };
  getBuildingEnm = () => {
    let allBuildings = [...this.state.allBuildings];
    let enmItem = {};
    let allBdEnum = [];
    for (const singleBd of allBuildings) {
      enmItem = {
        name: singleBd.building,
        _id: singleBd._id,
      };
      allBdEnum.push(enmItem);
    }
    allBdEnum.sort((a, b) => sortByName(a, b));
    this.setState({ allBdEnum });
  };

  getDevUnderBuilding = async (bd_id) => {
    /** select * from where Building_id = 23 */
    /**Gen enum list */
    let bdDevs = await bdDevFn.getBdDevBybdID(bd_id);
    // console.log(bdDevs);
    // eslint-disable-next-line
    let devUnderBuilidng = bdDevs.filter((c) => c.type != 5);
    /** Sort Device by type by location by name */
    devUnderBuilidng.sort((a, b) => sortByTy_Loc_Name(a, b));
    // console.log(devUnderBuilidng);
    return devUnderBuilidng;
  };

  bdDevicesToDisplayFmt = (devicesList) => {
    let dislayList = [];
    let disItem = {};
    for (const device of devicesList) {
      disItem = {
        type: enumFn.getType_en(this.state.typeEnm, device.type),
        bdDevID: device._id,
        active: 0,
        building: this.state.bd_id, // no need to display
        deviceName: genBdDevName(device),
        startTime: 100000,
        endTime: 100000,
        bufferTime: 30, // in mins
        userAmmend: "",
      };
      dislayList.push(disItem);
    }
    return dislayList;
  };

  loadNCombineChecklist = async (bd_id) => {
    try {
      /** load the device under building, exclude gateway */
      let bdDevices = await this.getDevUnderBuilding(bd_id);
      // console.log(bdDevices);
      /** Convert bdDevices into display table format */
      let initDisplayTable = this.bdDevicesToDisplayFmt(bdDevices);
      // console.log(initDisplayTable);
      /** load existing device cheking list */
      let dbChecklist = await devActFn.getDevAcListByBd_id(bd_id);
      // console.log(initDisplayTable);
      // console.log(dbChecklist);
      for (const dbItem of dbChecklist) {
        // = myArray.findIndex((obj => obj.id == 1));
        let objIdx = initDisplayTable.findIndex(
          (c) => c.bdDevID === dbItem.bdDevID
        );
        console.log(dbItem);
        if(objIdx < 0) {
          /** trigger delete, unused item in database */
          continue;

        }
        // console.log(objIdx);
        /** Ammend the display list */
        initDisplayTable[objIdx].endTime = dbItem.endTime;
        initDisplayTable[objIdx].active = dbItem.active;
        initDisplayTable[objIdx].bufferTime = parseFloat(
          dbItem.bufferTime / 60
        ).toFixed(2);
        initDisplayTable[objIdx].startTime = dbItem.startTime;
        initDisplayTable[objIdx].userAmmend = dbItem.userAmmend;
      }
      return initDisplayTable;
    } catch (error) {
      console.log(error.message);
      return []
    }
  };

  setCheckBoxDefaultValue = () => {
    let initialTable = [...this.state.initialTable];
    for (let index = 0; index < initialTable.length; index++) {
      if (initialTable[index].active) {
        document.getElementById("devActTable").rows[
          index + 1
        ].cells[3].children[0].checked = true;
      }
    }
  };

  refreshTable = async () => {
    try {
      let bd_id = this.state.bd_id;
      let displayTable = await this.loadNCombineChecklist(bd_id);
      // console.log(displayTable);
      let initialTable = [...displayTable];
      await this.setState({ initialTable });
      /**After store the initial value, modify display data */
      // for (const displayItem of displayTable) {
      //     displayItem.startTime =this.cvrtTimeFmt_toTime(displayItem.startTime);
      //     displayItem.endTime =this.cvrtTimeFmt_toTime(displayItem.endTime);
      // }
      // displayTable.startTime =this.cvrtTimeFmt_toTime(displayTable.startTime);
      // console.log(displayTable.startTime);
      // displayTable.endTime =this.cvrtTimeFmt_toTime(displayTable.endTime);
      await this.setState({ displayTable });
      this.setCheckBoxDefaultValue();
      this.checkEvery_SelStatus();
    } catch (error) {
      console.log(error.message);
    }
  };

  eachItemSel = () => {
    this.checkEvery_SelStatus();
  };

  handleBuildingSel = async () => {
    try {
      let bd_id = parseInt(document.getElementById("buildingSel").value);
      await this.setState({ bd_id });
      await this.refreshTable();
      /** Load and device under building, and update the status according database 
            let displayTable = await this.loadNCombineChecklist(bd_id);
            console.log(displayTable);
            let initialTable =[...displayTable];
            await this.setState({displayTable});
            await this.setState({initialTable});                
            this.setCheckBoxDefaultValue();*/
    } catch (error) {
      console.log(error.message);
    }
  };

  cvrtTimeFmt_toTime = (timeFmt) => {
    let _h = parseInt(timeFmt / 10000);
    let _m = parseInt((timeFmt % 10000) / 100);
    let sTime = "";
    if (_h < 10) sTime = `0${_h}`;
    else sTime = `${_h}`;

    if (_m < 10) sTime = sTime + `:0${_m}`;
    else sTime = sTime + `:${_m}`;

    return sTime;
  };

  /**Table format */
  tableColumn = (row, ind) => {
    let column = {
      bdDevID: parseInt(row.cells[1].innerHTML),
      building: parseInt(row.cells[2].innerHTML),
      active: trueFalseTo_1_0(row.cells[3].children[0].checked),
      deviceName: row.cells[5].innerHTML,
      startTime: timeToTimeFmt(row.cells[6].children[0].value),
      endTime: timeToTimeFmt(row.cells[7].children[0].value),
      bufferTime: row.cells[8].children[0].value,
      // bufferTime: parseFloat(row.cells[7].children[0].value)*60,
    };
    return column;
  };

  getTableDifferent = () => {
    // console.log(this.state.initialTable);

    let modifiedRows = tableFn.detectTableChanges(
      "devActTable",
      this.state.initialTable,
      "bdDevID",
      this.tableColumn
    );
    return modifiedRows;
  };

  saveTable = async () => {
    this.setState({ icon: "fa fa-spinner fa-spin" });
    let initialTable = [...this.state.initialTable];
    /** remove userAmmend in db info */
    for (const initItem of initialTable) {
      delete initItem.userAmmend;
      delete initItem.type;
    }
    let modifiedRows = this.getTableDifferent();
    if (!modifiedRows[0]) return;
    // console.log(modifiedRows);
    /** call service, update database */
    /**Convert buffer time before send */
    for (const modifiedItem of modifiedRows) {
      modifiedItem.bufferTime = parseFloat(modifiedItem.bufferTime) * 60;
    }
    let updateResult = await devActFn.updateDevActiveList(modifiedRows);

    updateResult ? toast.dark(`Saved`) : toast.error(`Failed`);

    /** Refresh page */
    this.setState({ icon: "fa fa-floppy-o deactive" });
    await this.refreshTable();
  };

  checkEvery_SelStatus = () => {
    let selAll = true;
    for (let index = 0; index < this.state.displayTable.length; index++) {
      if (
        !document.getElementById("devActTable").rows[index + 1].cells[3]
          .children[0].checked
      ) {
        selAll = false;
        break;
      }
    }
    /** Set main select status */
    document.getElementById("Act_SelAll").checked = selAll;
  };

  selAll = () => {
    let selAll = document.getElementById("Act_SelAll").checked;
    // console.log(`Sel All Status : ${selAll}`);
    for (let index = 0; index < this.state.displayTable.length; index++) {
      document.getElementById("devActTable").rows[
        index + 1
      ].cells[3].children[0].checked = selAll;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <h1>Device Connectivity</h1>
          <div style={{ fontSize: "25px" }}>
            {tableFn.tableDropDownKeySel(
              `buildingSel`,
              this.state.allBdEnum,
              1,
              "select-dd",
              () => this.handleBuildingSel()
            )}
          </div>

          <Icon
            className={this.state.icon}
            onClick={() => this.saveTable()}
          ></Icon>

          <div className="card">
            <div className="card-body" id="flip-scroll">
              <table className="cusTable" id="devActTable">
                <thead>
                  <tr>
                    {/* <th scope="col" id="cb"></th> */}
                    <th scope="col">#</th>
                    <th style={{ display: "none" }}>bdDevID</th>
                    <th style={{ display: "none" }}>buildingID</th>
                    <th scope="col">
                      <input
                        className="checkbox"
                        id="Act_SelAll"
                        type="checkbox"
                        onClick={this.selAll}
                      ></input>
                    </th>
                    <th scope="col">Sensor</th>
                    <th scope="col">Name</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Delay(mins)</th>
                    <th scope="col">Set By</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.displayTable.map((u, ind) => (
                    <tr key={u.bdDevID}>
                      <td>{ind + 1}</td>
                      <td style={{ display: "none" }}>{u.bdDevID}</td>
                      <td style={{ display: "none" }}>{u.building}</td>
                      <td>
                        <input
                          className="checkbox"
                          id="Active_c"
                          type="checkbox"
                          onClick={this.eachItemSel}
                        ></input>
                      </td>
                      <td>{u.type}</td>
                      <td>{u.deviceName}</td>
                      <td>
                        <input
                          id="startT"
                          type="time"
                          defaultValue={this.cvrtTimeFmt_toTime(u.startTime)}
                        ></input>
                      </td>
                      <td>
                        <input
                          id="endT"
                          type="time"
                          defaultValue={this.cvrtTimeFmt_toTime(u.endTime)}
                        ></input>
                      </td>
                      <td>
                        <input type="text" defaultValue={u.bufferTime} />
                      </td>
                      <td>{u.userAmmend}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default DeviceActivePage;
