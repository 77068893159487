import React, { Component } from "react";
import bdDevicesService from "../../service/buildingDevicesService";
import deviceService from "../../service/deviceManage";
import enm from "../utilities/enum";
// import "./table.css"
import Icon from "../common/icon";
import tableFn from "../utilities/tableFunction";
import tool from "../../checkingTools/tools";
import { ToastContainer, toast } from "react-toastify";

class BuildingDeviceManagement extends Component {
  state = {
    buildingDevices: [],
    currentBdDevices: [],
    buildingEnmObj: [],
    devicesAssignedToThisBuilding: [],
    devBdEnum: [],
    buildingID: "",
    typeEnm:[],
  };

  async componentDidMount() {
    /** Get type enm */
    let typeEnm =await enm.getDevTypeEnum();
    console.log(typeEnm);
    await this.setState({typeEnm});
    let buildingID = this.props.match.params.bdID;
    await this.setState({ buildingID });
    // get building DB from service
    let devBdEnum = await this._getDevOfBd_Enum();
    await this.setState({ devBdEnum });
    // console.log(devBdEnum);
    // console.log(this.state.currentBdDevices);
    await this._getCurrentBd_Devices(this.state.buildingID);    
  }

  _getCurrentBd_Devices = async (buildingID) => {
    await this._getBuildingEnmList();
    await this._getBdDevices();
    let currentBdDevices = this.state.buildingDevices.filter((c) => {
      // eslint-disable-next-line
      return c.buildingID == buildingID;
    });
    this.setState({ currentBdDevices });
  };

  _getDevOfBd_Enum = async () => {
    let devsInBuilding = [];
    try {
      let { data: allDevices } = await deviceService.getAll();
      let devicesAssignedToThisBuilding = allDevices.filter((d) => {
        // eslint-disable-next-line
        return d.buildingID == this.state.buildingID;
      });
      devsInBuilding.push({ _id: 0, name: "Idle", toMatch: "" });
      // let bdDevEnum = [];
      for (const dbDev of devicesAssignedToThisBuilding) {
        let newBdDev = {
          _id: dbDev.devID,
          name: dbDev.devID,
          toMatch: dbDev.type,
        };
        devsInBuilding.push(newBdDev);
      }
      return devsInBuilding;
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  _getBuildingEnmList = async () => {
    let result = await enm.getBuildingEnmObj(true);
    this.setState({ buildingEnmObj: result });
    // console.log(this.state.buildingEnmObj);
  };

  _getBdDevices = async () => {
    try {
      let { data: buildingDevices } = await bdDevicesService.getAll();
      // tool.displaya_obj(buildingDevices)
      // console.log("Get All User : ",allUsers);
      this.setState({ buildingDevices });
      // console.log(this.state.buildingDevices[0]);
    } catch (error) {
      console.log("Get User DB Error: ", error.message);
    }
  };

  // Update
  handleSave = async (tableName, stateData) => {
    console.log("Save");

    let modifiedRows = tableFn.detectTableChanges(
      "bdDeviceTable",
      this.state.currentBdDevices,
      "_id",
      this.tableColumn
    );
    if (!modifiedRows[0]) return console.log("No Changes");
    //
    // console.log(modifiedRows);
    for (const row of modifiedRows) {
      try {
        let rel = await bdDevicesService.update(row);
        if (rel.status === 200) {
          console.log(
            `${rel.data.name}, ${rel.data.location} update succesful`
          );
          toast.dark("Updated Succesfully!");
        } else {
          console.log(`${rel.data.name}, ${rel.data.location} update failed`);
          toast.error("Update Failed!");
        }
      } catch (ex) {
        tool.exResponse(ex);
      }
    }
  };

  handleDelete = async () => {
    // console.log("Delete loa");
    let rowChecked = tableFn.rawChecked("bdDeviceTable", this.tableColumn_del);
    if (!rowChecked[0]) return console.log("Non row checked");
    console.log(rowChecked);
    for (const row of rowChecked) {
      try {
        let rel = await bdDevicesService.del(row);
        if (rel.status === 200) {
          console.log(`${rel.data.building} delete succesful`);
          toast.dark("Deleted Successfully!");
        } else {
          console.log(`${rel.data.building} delete failed`);
          toast.error("Failed to Delete!");
        }
      } catch (ex) {
        tool.exResponse(ex);
      }
    }
    // await this._getAllBuildings();
    await this._getCurrentBd_Devices(this.state.buildingID);
  };

  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      type: enm.getType_n(this.state.typeEnm, row.cells[3].innerHTML),
      // devID:row.cells[4].children[0].value,
      devID: document.getElementById(`selRow${ind - 1}`).value,
      name: row.cells[5].children[0].value,
      location: row.cells[6].children[0].value,
      remarks: row.cells[7].children[0].value,
      active: row.cells[8].innerHTML,
      buildingID: row.cells[9].innerHTML,
      userAmmend: row.cells[10].innerHTML,
    };
    // console.log(column);
    return column;
  };

  tableColumn_del = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      type: enm.getType_n(this.state.typeEnm, row.cells[3].innerHTML),
      // devID:row.cells[4].children[0].value,
      // devID:document.getElementById(`selRow${ind-1}`).value,
      name: row.cells[5].children[0].value,
      location: row.cells[6].children[0].value,
      remarks: row.cells[7].children[0].value,
      active: row.cells[8].innerHTML,
      buildingID: row.cells[9].innerHTML,
      userAmmend: row.cells[10].innerHTML,
    };
    // console.log(column);
    return column;
  };

  currentBuilding = () => {
    // console.log(this.state.buildingEnmObj);
    let building = enm.displayEnm(
      this.state.buildingEnmObj,
      this.state.buildingID
    );
    return building;
  };

  changePage = () => {
    this.props.history.push(`/buildings/regdevice/${this.state.buildingID}`);
  };

  render() {
    return (
      <React.Fragment>
        <h1> Devices In {this.currentBuilding()}</h1>
        <Icon className="fa fa-plus deactive" onClick={this.changePage}></Icon>
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleSave()}
        />
        <Icon
          className="fa fa-trash-o deactive"
          onClick={() => this.handleDelete()}
        />
        {/* <Icon className="fa fa-floppy-o deactive"  onClick={()=>this.handleSave("userTable", this.state.allUsers)}/>
                <Icon className="fa fa-trash-o deactive"  onClick={()=>this.handleDelete()}/> */}
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table className="cusTable" id="bdDeviceTable">
              <thead>
                <tr>
                  <th scope="col" id="cb"></th>
                  <th scope="col">#</th>
                  <th style={{ display: "none" }}>_id</th>
                  <th scope="col">Sensor</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Remarks</th>
                  <th style={{ display: "none" }}>Active</th>
                  <th style={{ display: "none" }}>Building</th>
                  <th scope="col">Amend By</th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentBdDevices.map((u, ind) => (
                  <tr key={u._id}>
                    <td>
                      <input type="checkbox"></input>
                    </td>
                    <td>{ind + 1}</td>
                    <td style={{ display: "none" }}>{u._id}</td>
                    <td>{enm.getType_en(this.state.typeEnm, u.type)}</td>
                    {/* <td><input type="text" defaultValue = {u.devID}/></td> */}
                    <td>
                      {tableFn.dropDownWithFilter(
                        `selRow${ind}`,
                        this.state.devBdEnum,
                        u.devID,
                        u.type,
                        "select-custom"
                      )}
                    </td>
                    <td>
                      <input type="text" defaultValue={u.name} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.location} />
                    </td>
                    <td>
                      <input type="text" defaultValue={u.remarks} />
                    </td>
                    <td style={{ display: "none" }}>{u.active}</td>
                    <td style={{ display: "none" }}>{u.buildingID}</td>
                    <td>{u.userAmmend}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default BuildingDeviceManagement;
