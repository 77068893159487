import React from 'react';
import './icon.css';

const Icon=({ name, error, ...rest })  =>{
        return (
            <div className="cusIcon">
                <i {...rest}> </i>
            </div>
        );
}
 
export default Icon;