import http from "./httpService";



async function updateBuilding(building) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/building/update";  
  const body = {
      owner: building.owner,
      building: building.building,
      country: building.country,
      state: building.state,
      area: building.area,
      postcode: building.postcode,
      accessLevel: building.accessLevel,
      _id:building._id,
    };
    console.log("body", body);
  return await http.post(url, body);     
}

async function getAllBuildings() {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/building/get";
  return await http.get(url);    
}

async function getBuildingById(_id) {  
  let url = process.env.REACT_APP_PUBLIC_URL+`/building/getbyid/${_id}`;
  return await http.get(url);    
}

async function regBuilding(building) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/building/register";
  const body = {
      owner: building.owner,
      building: building.building,
      country: building.country,
      state: building.state,
      area: building.area,
      postcode: building.postcode,
    };
  return await http.post(url, body);    
}

async function delBuilding(building) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/building/del";
  const body = {
      _id:building._id,
      // owner: building.owner,
      // building: building.building,
      // country: building.country,
      // state: building.state,
      // area: building.area,
      // postcode: building.postcode,
    };
  return await http.post(url, body);    
}


const expModule = {
  getBuildingById,
  del:delBuilding,
  getAll:getAllBuildings,
  reg:regBuilding,
  update:updateBuilding,
};
export default expModule;
