import React, { useEffect, useRef, useState } from 'react';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import socketIOClient from "socket.io-client";
import { isEmptyObj } from '../../../function/utilities/utilities';
import TpLoadingBar from './../../SharedTemplate/LoadingPattern/TpLoadingBar';
import timeFn from "../../../component/utilities/timeFn";

function TpAcpuOvv(props) {
    const tempReturnMap = [
        { acpu: 1758, return: 2578, return_ht: 42 },
        { acpu: 1759, return: 2577, return_ht: 42 },
        { acpu: 1569, return: 1390, return_ht: 23 },
        { acpu: 1760, return: 2580, return_ht: 42 }
    ]
    const [G_Lastdata, setG_Lastdata] = useState([]);
    const [G_DeviceInfo, setG_DeviceInfo] = useState({});
    const [G_SensorTimeOut, setG_SensorTimeOut] = useState(false);
    const [G_HideOvvItem, setG_HideOvvItem] = useState(false);
    const [G_LoadDone, setG_LoadDone] = useState(false);
    const [G_DisplayName, setG_DisplayName] = useState("");
    const [returnTemp, setReturnTemp] = useState();

    const timerRef = useRef(null);
    const pageClose = useRef(false);

    useEffect(()=>{
        timerRef.current = setInterval(async() => {
            // console.log("Refresh");
            await reloadData();
        }, 300000);       /** 300*1000s , 5 mins*/

        let socket;
        async function startUp(){
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            
            if(!props.devInfo) return;
            setG_DisplayName(props.devInfo.displayName);
            let defInfo = await BdDevFn_Com.loadDeviceInfo(props.devInfo.bdDev_id);
            if(pageClose.current) return
            if(defInfo) setG_DeviceInfo(defInfo);

            if(props.callFromDevDetails){
                setG_HideOvvItem(true);
            }
            await reloadData();
            setG_LoadDone(true);
            let{type, _id}= defInfo;
            // let topic = `v2_${type}_${_id}`;
            let topic = `v1_${type}_${_id}`;
            // console.log("Socket IO topic: ", topic);
            socket.on(topic, async (data) => {                                
                // let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
                // if(lastData) setG_Lastdata(lastData);
                await reloadData();
            });
        }
        startUp();

        return ()=>{
            socket.disconnect();
            clearInterval(timerRef.current);
            pageClose.current = true;
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    
    const reloadData=async ()=>{
        try {
            let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
            let idx = tempReturnMap.findIndex(c => c.acpu === props.devInfo.bdDev_id);
            if(idx !== -1) {
                let lastTemp = await BdDevFn_Com.loadLastData({ht: tempReturnMap[idx].return_ht, bdDev_id: tempReturnMap[idx].return});
                // console.log(lastTemp);
                if(lastTemp.unix + 10*60 < timeFn.getUnixNow()) setReturnTemp("offline");
                else setReturnTemp(`${lastTemp.pf_0} °C`);
            }
            if(pageClose.current) return
            if(lastData) setG_Lastdata(lastData);            
            let sensorTimeout = BdDevFn_Com.sensorTimeout(lastData);
            setG_SensorTimeOut(sensorTimeout)
            // console.log("sensorTimeout", sensorTimeout);
        } catch (error) {
            console.log("reloadData err: ", error.message);
        }        
    }

    const handleOnCmd=async ()=>{
        if (!props.fOnClick) return;
        if(isEmptyObj(G_DeviceInfo)) return;

        await props.fOnClick(G_DeviceInfo);
    }
    
    const handleOffCmd=async ()=>{
        if (!props.fOffClick) return;
        if(isEmptyObj(G_DeviceInfo)) return;

        await props.fOffClick(G_DeviceInfo);
    }
    
    const handleZoomToDetail=(dev)=>{
        // console.log("dev", dev);
        if(!props.handleZoomToDetail) return;
        // console.log("Pass Internal");
        props.handleZoomToDetail(dev.type, dev.devID, dev._id);
    }

    return (
        <div>
            <div className={`${G_SensorTimeOut?"autumn_WarningCard":"autumn_Card"} ${G_HideOvvItem?"autumn_ZoomCardSize":""}`}>
                {!G_HideOvvItem && <div className='autumn_CardTitle sortHor bothCenter'>
                    <div>
                        <div className={`autumn_smallTitle ${G_DeviceInfo.location?"":"hideDiv_WithSpace"}`}>
                            {G_DeviceInfo.location ? `${G_DeviceInfo.location}`:"Hidden"}
                        </div>
                        <div>
                            {G_DeviceInfo.name ? `${G_DeviceInfo.name} [${G_DeviceInfo.devID}]`:""}
                        </div>
                        <div className={`autumn_smallTitle autumn_smallTitleColor ${G_DisplayName?"":"hideDiv_WithSpace"}`}>
                            {G_DisplayName}
                        </div>
                    </div>
                    <div className='stayRight autumnBarIcon fa fa-bars hoverPointer'
                    style={{ color: "rgb(180, 180, 180)" }}
                    onClick={()=>handleZoomToDetail(G_DeviceInfo)}></div>
                </div>}                
                
                {!G_LoadDone &&<div style={{marginTop:"90px"}}><TpLoadingBar/></div>}
                {G_LoadDone && <div>
                    <div className='autumn_statusContainer'>
                        <div className='sortHor verCenter'>
                            <div className=''>
                                Run 
                            </div>
                            <div className='stayRight'>
                                <div className={G_Lastdata.pb_0===1?"greenIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div>
                        
                        <div className='sortHor verCenter'>
                            <div className=''>
                                Auto 
                            </div>
                            <div className='stayRight'>
                                <div className={G_Lastdata.pb_2===1?"greenIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div>
                        <div className='sortHor verCenter'>
                            <div className=''>
                                Trip 
                            </div>
                            <div className='stayRight'>
                                {/* <div className='redIndicator'></div> */}
                                <div className={G_Lastdata.pb_1===1?"redIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div>
                        {/* <div className='sortHor verCenter hideDiv_WithSpace'>
                            <div className=''>
                                Hide
                            </div>
                            <div className='stayRight'>
                                <div className={G_Lastdata.pb_1===1?"redIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div> */}
                        <div className='sortHor verCenter hideDiv_WithSpace'>
                            <div className=''>
                                Hide
                            </div>
                            <div className='stayRight'>
                                {/* <div className='redIndicator'></div> */}
                                <div className={G_Lastdata.pb_1===1?"redIndicator":"idleIndicator"}></div>
                            </div>                        
                        </div>          
                        <div className="sortHor verCenter">
                            <div className="">
                                Return Temp.
                            </div>
                            <div className={returnTemp === "offline"? 'stayRight sortHor autumn_tempDis autumnIndicatorSpace pdc_warningFont' : 'stayRight sortHor autumn_tempDis autumnIndicatorSpace'}>
                                <div>{returnTemp}</div>
                            </div>                        
                        </div>          
                        
                    </div>
                    
                    <div className='autumn_CtrlArea sortHor spaceAround'>
                        <div className='autumn_OnButton bothCenter hoverPointer'
                        onClick={handleOnCmd}>
                            <div className='fa fa-power-off fa-lg autumnButton'
                            style={{ color: "rgb(220, 220, 220)" }}></div>
                        </div>                    
                        <div className='autumn_OffButton bothCenter hoverPointer'
                        onClick={handleOffCmd}>
                            <div className='fa fa-power-off fa-lg'
                            style={{ color: "rgb(220, 220, 220)" }}></div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default TpAcpuOvv;