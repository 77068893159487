import React from "react";
import Joi from "joi-browser";
import enm from "../utilities/enum";
import keyFn from "../utilities/sensorKey";
import offsetFn from "../utilities/offsetValue/offsetValueFn";
// import devFunction from '../utilities/deviceFunction/deviceFunction';
// import userFn from "../utilities/getUser";
// import buildingFn from "../utilities/getBuilding";
import tableFn from "../utilities/tableFunction";
import CustomForm from "../common/customForm";
// import CustomForm from "./../common/customForm";
// import "  ./bDeviceReg.css";
import Icon from "./../common/icon";
import tool from "../../checkingTools/tools";
// import teleIdFn from "../utilities/notificaton/telegramID";
// import "./notification.css";
import "./regOffSetPage.css";
import sensorFn from '../../function/sensor/sensorManagementFn'

class RegTelegramPage extends CustomForm {
  state = {
    deviceEnmObj: [],
    displayDataKeyList: [],
    data: {
      //   type: 0,
      devID: 1,
      //   dataKey: " ",
      offsetValue: 0,
    },
    errors: {},
    // newSensorParasInUse:[],
  };

  schema = {
    devID: Joi.number().required().min(1).label("ID"),
    offsetValue: Joi.number().required().label("Offset Value"),
  };

  async componentDidMount() {
    await this.getSensorList();
    // console.log(this.state.deviceEnmObj);
    await this.refreshDataKey(this.state.deviceEnmObj[0]._id);
  }

  async getSensorList() {
    let deviceEnmObj = await enm.getSensorEnmObj();
    // console.log(deviceEnmObj);
    this.setState({ deviceEnmObj });
  }

  async refreshDataKey(selType) {
    let displayDataKeyList = keyFn.getKeyListByType(parseInt(selType));
    if (!displayDataKeyList[0]) {
      displayDataKeyList = await sensorFn.getParaList_inid_name_byTyList([selType]);
      // console.log(displayDataKeyList);
    }
    // console.log(displayDataKeyList);
    this.setState({ displayDataKeyList });
  }

  typeSelChange = async () => {
    let selType = this.getSensorType();
    // let selType = document.getElementById("tySel").value;
    // /** search DB get device id with select type */
    // let devList = await devFunction.getByType(selType);
    // console.log(selType);
    await this.refreshDataKey(selType);
  };

  getSensorType = () => {
    return document.getElementById("tySel").value;
  };
  getDataKey = () => {
    return document.getElementById("dataKeySel").value;
  };

  doSubmit = async () => {
    try {
      console.log(`Continue`);

      let data = { ...this.state.data };
      data.type = this.getSensorType();
      data.DataKey = this.getDataKey();
      //   console.log(data);
      /** summit this value to service and store into database */
      let insertError = await offsetFn.addOffset(data);
      //   console.log(inserStatus);
      if (!insertError) {
        console.log("Insert Succesful");
        window.location = `/offset`;
      } else {
        console.log(insertError);
      }
    } catch (ex) {
      tool.exResponse(ex);
    }
  };

  changePage = () => {
    // console.log("CP");
    this.props.history.push("/offset");
  };

  render() {
    return (
      <React.Fragment>
        <Icon
          className="fa fa-arrow-left deactive back"
          onClick={this.changePage}
        />
        <div className="regOffset">
          <div className="regOffsetCard">
            <h1>Register Offset</h1>
            <form onSubmit={this.handleSubmit} className="form-regOffset">
              <div>
                <label className="user">Sensor</label>
              </div>
              <span className="sensor-dd">
                {tableFn.dropDown(
                  `tySel`,
                  this.state.deviceEnmObj,
                  1,
                  "select-dd",
                  this.typeSelChange
                )}
              </span>

              {this.renderCustomInput("devID", "ID")}

              <div>
                <label className="user">Data Key</label>
              </div>
              <span className="temp-dd">
                {tableFn.dropDown(
                  `dataKeySel`,
                  this.state.displayDataKeyList,
                  0,
                  "select-dd"
                  // this.typeSelChange
                )}
              </span>
              {this.renderCustomInput("offsetValue", "Offset Value")}
              <div align="center">{this.renderCustomButton("Register")}</div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegTelegramPage;
