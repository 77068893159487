import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
  
    if (!expectedError) {
      // log the error message for unexpected error
      console.log("Interceptor Logging Error: ", error);
      toast("Connection Error");
    }
    return Promise.reject(error);
  });
  
function setJwt(jwt) {
    //set token to all header, common = all, post = only to post method
    axios.defaults.headers.common["aploud-auth-token"] = jwt;
  }
  
  
const expModule = {  
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
export default expModule;

  // export default {
  //   get: axios.get,
  //   post: axios.post,
  //   put: axios.put,
  //   delete: axios.delete,
  //   setJwt,
  // };
  