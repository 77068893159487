import React, { Component } from 'react';
import TpInput from './../template/InputText/TpInput';
import tableFn from '../utilities/tableFunction';
import { trueFalseTo_1_0 } from "../utilities/conversion";
import sensorFn from '../../function/sensor/sensorManagementFn';
import { ToastContainer, toast } from "react-toastify";

class sensorAddNewPage extends Component {
    state = { 
        maxDefDisplayLine:2,    // default line to display on line graph
        tableDisplay:[],
        tableCol:{
            dataName:2,
            dType:3,
            dataUnit:4,
            scaleBottom:5,
            scaleTop:6,
            defaultDisplay:7,
        },
        typeList:[
            {_id:"pb", name:'BOOL [pb]'},
            {_id:"pf", name:'FLOAT [pf]'},
            {_id:"pi", name:'LONG [pi]'},
            {_id:"pn", name:'INT64 [pn]'},
        ],
        bTableLoaded:true,
        bBattInUse:false,
        paraEnm_dd:[],        
        // Error display
        emptyNameErr:false,
        paraErrMsg:'',
        // timer
        errMsgTimer:0,
        bAddInprogress:false,
     }

    componentWillUnmount() {
        // console.log("Page CLose");    
        clearTimeout(this.state.errMsgTimer);
    }
    
    handleSave=async()=>{
        /** Click save and  */
        // console.log("Save");
        /** check on the paraEnm,  */
        if(this.state.bAddInprogress) return    // saving in progress
        let Err = await this.checkParaAndReturnErrMsg(true);
        if(Err) return       // resume this line 
        /** Gen Sensor List */
        let SensorInfo = this.genSensorListItem();
        // console.log(SensorInfo);

        /** Gen each para list */
        let paraList = this.genParaList();
        // console.log(paraList);
        /** Make sure sensor list at least 1 default value checked */
        let defPara =  paraList.filter(c=>c.defaultDisplay > 0);
        // console.log(defPara.length);
        if(defPara.length===0){        // no def display selected
            /** Auto select  */
            paraList[0].defaultDisplay = 1;
            if(paraList.length>1) paraList[1].defaultDisplay = 1;
        }else if(defPara.length>this.state.maxDefDisplayLine){     // more than 2 def display selected    
            let cnt = 0;        
            for (let i = 0; i < paraList.length; i++) {
                if(paraList[i].defaultDisplay ===1){
                    cnt++;
                    if(cnt>this.state.maxDefDisplayLine) paraList[i].defaultDisplay=0;
                }
            }
        }

        paraList.push({
            dataIndex: -1,
            dataName: "RSSI",
            dataType: "RSSI",
            dataUnit: "",
            defaultDisplay: 0,
            inUse: 1,
            scaleBottom: -120,
            scaleTop: 0});
        paraList.push({
            dataIndex: -1,
            dataName: "SNR",
            dataType: "SNR",
            dataUnit: "",
            defaultDisplay: 0,
            inUse: 1,
            scaleBottom: 0,
            scaleTop: 12,
        })
        // console.log(paraList);      
        await this.setState({bAddInprogress:true});   // set saving in progress
        let rel = await sensorFn.regNewSensor(SensorInfo, paraList);    // write into database
        if(rel){    // succesful
            toast.dark("Add Succesful!");
            /** Turn to other page */
            this.props.history.push({
                pathname: "/sensormng/sensorlist",
                _data: {},
            });
        }else{      // failed
            toast.error("Add Failed!");
            await this.setState({bAddInprogress:false});
        }
    }

    genParaList=()=>{
        try {
            let info = this.getTableInfo();
            // console.log(info);
            let list=[];
            let cnt={
                pi:0,
                pf:0,
                pb:0,
                pn:0,
            }
            for (const item of info) {
                let para = {
                    // sensorType ,
                    dataType:item.dType,
                    dataIndex: cnt[item.dType],
                    dataName: item.dataName,
                    dataUnit : item.dataUnit,
                    scaleTop : item.scaleTop,
                    scaleBottom : item.scaleBottom,
                    defaultDisplay : item.defaultDisplay,
                    inUse:1,
                }
                list.push(para);
                cnt[item.dType]++
            }
            return list
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }

    genSensorListItem=()=>{
        try {
            let name= document.getElementById("sensorName").value;
            let info = this.getTableInfo();
            let pb=0;
            let pi=0;
            let pf=0;
            let pn=0;
            for (const item of info) {
                switch (item.dType) {
                    case 'pb':  pb++;    break;
                    case 'pi':  pi++;    break;
                    case 'pf':  pf++;    break;
                    case 'pn':  pn++;    break;
                    default:
                        break;
                }
            }
            // console.log(info);
            // if(Err) document.getElementById('runOnBatt').checked = false;
            let battKey;
            if(!document.getElementById('runOnBatt').checked){
                battKey='';
            }else{
                let battKeyIdx = parseInt(document.getElementById('battPara').value);
                // console.log(battKeyIdx);
                // console.log(info[battKeyIdx]);
                let keyCnt=0;
                for (let index = 0; index < battKeyIdx; index++) {
                    if(info[index].dType === info[battKeyIdx].dType){
                        keyCnt++;
                    }                
                }
                battKey = sensorFn.genSensorKey_Unpacked(info[battKeyIdx].dType, keyCnt);
            }
            let item={
                name,
                // type:<--- type is auto generate by system            
                vendor_id:0,
                sensorVersion:2,
                pb,
                pi,
                pf,
                pn,
                battKey,
            }
            // console.log(item);
            return item;
        } catch (error) {
            console.log(error.message);
            return 
        }
    }

    addPara=async()=>{
        let tableDisplay = [...this.state.tableDisplay];
        let para = {
            dataName: '',
            dType:'pf',
            dataUnit:'',
            scaleBottom:0,
            scaleTop:100,
            defaultDisplay:0,
        }
        tableDisplay.push(para);
        await this.setState({tableDisplay});
    }

    /**Table format */
    tableColumn = (row, ind) => {
        let tableCol = {...this.state.tableCol};

        let column = {
            dataName: (row.cells[tableCol.dataName].children[0].value),
            // DataKey: document.getElementById(`selKeyRow${ind - 1}`).value,
            dType: document.getElementById(`para_${ind-1}`).value,
            dataUnit: (row.cells[tableCol.dataUnit].children[0].value),
            // dataUnit: parseInt(row.cells[tableCol.dataUnit].children[0].checked),
            scaleBottom: parseFloat(row.cells[tableCol.scaleBottom].children[0].value),
            scaleTop: parseFloat(row.cells[tableCol.scaleTop].children[0].value),
            // scaleTop: parseInt(row.cells[tableCol.scaleTop].children[0].value),
            defaultDisplay:trueFalseTo_1_0(row.cells[tableCol.defaultDisplay].children[0].checked),
            // defaultDisplay: parseInt(row.cells[tableCol.defaultDisplay].children[0].value),
        };
        return column;
    };

    getTableInfo=()=>{
        let info = tableFn.getInfoFromTableSkipLastN('sensorParaTable', this.tableColumn);
        return info;
    }

    delPara=async(ind)=>{
        try {
            this.setState({bTableLoaded:false});
            /** Get current table info */
            // let info = tableFn.getInfoFromTableSkipLastN('sensorParaTable', this.tableColumn);
            let info = this.getTableInfo();
            /** Remove the select row */
            // console.log(info);
            info.splice(ind, 1);
            /** Update table */
            await this.setState({tableDisplay:info});
            this.setState({bTableLoaded:true});
            /** Update check box display in table */
            this.updateCheckBoxDisplay();
            /** Disable Run On batt selection */
            await this.disableRunOnBattSel();
        } catch (error) {
            console.log(error.message);
            this.setState({bTableLoaded:true});
        }
    }

    updateCheckBoxDisplay=()=>{
        // console.log(this.state.tableDisplay.length);
        for (let index = 0; index < this.state.tableDisplay.length; index++) {
            // dType: document.getElementById(`para_${ind-1}`).value,
            // document.getElementById(`defDis_${index+1}`).checked = this.state.tableDisplay[index].defaultDisplay===1;
            // defaultDisplay:trueFalseTo_1_0(row.cells[tableCol.defaultDisplay].children[0].checked),
            
            // defaultDisplay:trueFalseTo_1_0(row.cells[tableCol.defaultDisplay].children[0].checked),
            document.getElementById("sensorParaTable").rows[index + 1].cells[this.state.tableCol.defaultDisplay].children[0].checked = this.state.tableDisplay[index].defaultDisplay===1;
        }
    }
    
    genParaListEnm=()=>{
        try {
            let info = this.getTableInfo();
            // console.log(info);
            let list =[];
            let index=0;
            for (const para of info) {
                list.push({
                    _id:index,
                    name:para.dataName,
                });
                index++;
            }
            return list;            
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }

    paraEmptyNameErr=()=>{
        try {
            let info = this.getTableInfo();
            // console.log(info);
            for (const enm of info) {
                // console.log(enm);
                if(enm.dataName.trim() ==='') return true
            }
            return false;            
        } catch (error) {
            console.log(error.message);
            return true
        }
    }

    clearErrMsg=async()=>{
        await this.setState({paraErrMsg:''});
    }

    checkParaAndReturnErrMsg=async(checkNama)=>{
        let runOnBatt = document.getElementById('runOnBatt').checked;
        let paraEnm_dd = [...this.state.paraEnm_dd];
        let errMsg = "";
        // console.log(paraEnm_dd);
        let name= document.getElementById("sensorName").value;
        if(!name && checkNama===true){
            errMsg = "Sensor Name Cannot Be Empty";
        } 
        else if(!paraEnm_dd[0] && runOnBatt){
            errMsg = "Para List Cannot Be Empty";
        } else{
            let emptyNameErr = await this.paraEmptyNameErr(paraEnm_dd);
            if(emptyNameErr) errMsg = "Name Field Cannot Be Empty";
            // await this.setState({emptyNameErr});
        }
        if(!errMsg) return
        await this.setState({paraErrMsg:`**${errMsg}`});
        // console.log(emptyNameErr);
        this.state.errMsgTimer = setTimeout(
            ()=>this.clearErrMsg(), 
            3000);
        return true;    
    }

    disableRunOnBattSel=async ()=>{
        document.getElementById('runOnBatt').checked = false;
        await this.setState({bBattInUse:false});
    }

    checkRunOnBatt=async ()=>{
        try {
            let checkStatus = document.getElementById('runOnBatt').checked;
            // console.log(checkStatus);            
            if(!checkStatus) {
                await this.setState({bBattInUse:checkStatus});
                return;    // uncehck, update then finish
            }
            /** Generate para list let user to select battery parameter */
            let paraEnm_dd = this.genParaListEnm();
            await this.setState({paraEnm_dd});
            /** check on the paraEnm,  */
            let Err = await this.checkParaAndReturnErrMsg();
            if(Err) document.getElementById('runOnBatt').checked = false;

            checkStatus = document.getElementById('runOnBatt').checked;
            await this.setState({bBattInUse:checkStatus});
        } catch (error) {
            console.log(error.message);
        }
    }

    battParaSel=async()=>{        
        // console.log("Batt Sel");
        let paraEnm_dd = this.genParaListEnm();
        await this.setState({paraEnm_dd});
    }

    paraNameChange=async ()=>{
        if(document.getElementById('runOnBatt').checked){
            await this.disableRunOnBattSel();
        }
    }

    backToPage=()=>{
        this.props.history.push({
            pathname: "/sensormng/sensorlist",
            _data: {},
        });
    }

    render() { 
        return ( <>
            <h1>Configure New Sensor Type</h1>
            <button className="universal-btn vfd" onClick={this.backToPage} style={{ fontSize: "25px", display: 'inline-block', }}>
                BACK
            </button>

            <TpInput id="sensorName" title={'Sensor Name:'}></TpInput>
            <h3>Parameter</h3>
            <button className="universal-btn vfd" onClick={this.addPara} style={{ fontSize: "25px", display: 'inline-block', }}>
                Add Para
            </button>
            <div className="card">
                <div className="card-body">
                    <table className="cusTable" id="sensorParaTable">
                        <thead>
                        <tr>
                            <th scope="col"> </th>
                            <th scope="col">#</th>
                            <th style={{ display: "none" }}>_id</th>
                            <th scope="col" style={{
                                // justifyContent:'center',
                                // alignItems:'center',
                                }}>
                                    Name                                
                            </th>
                            <th scope="col">Data Type</th>
                            <th scope="col">Data Unit</th>
                            <th scope="col">Scale Bottom</th>
                            <th scope="col">Scale Top</th>
                            <th scope="col">Default Display</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.bTableLoaded && this.state.tableDisplay.map((u, ind) => (
                        <tr key={`row_${ind}`}>     
                                <td onClick={()=>this.delPara(ind)}>
                                    <i
                                        style={{color:'tomato', padding:'5px 10px'}}
                                        className="fa fa-trash"
                                    ></i>
                                </td>   
                                <td>{ind + 1}</td>        
                                {/* <td>{u.dataName}</td>      */}
                                <td>
                                    <input defaultValue={u.dataName} placeholder="name" onChange={this.paraNameChange}></input>                                    
                                </td>   
                                {/* <td>{u.dType}</td>      */}
                                <td>
                                    {tableFn.tableDropDownKeySel(
                                    `para_${ind}`,
                                    this.state.typeList,
                                    u.dType,
                                    "select-custom",
                                    // () => this.handleBuildingSel()
                                    )}
                                </td>     
                                <td>
                                    <input defaultValue={u.dataUnit} placeholder="<blank>"></input>        
                                </td>   
                                <td>
                                    <input type='number' defaultValue={u.scaleBottom}></input>                                    
                                </td>   
                                <td>
                                    <input type='number' defaultValue={u.scaleTop}></input>
                                </td>   
                                <td>
                                    {/* {u.defaultDisplay} */}
                                    <input type='checkbox'></input>
                                </td>   
                                {/* <td onClick={()=>this.clickInfo(ind)}>
                                    <Icon
                                        style={{color:this.genInfoColor(u.infoStatus)}}
                                        className="fa fa-info-circle"
                                    ></Icon>
                                </td>    */}
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
            <h6 style={{color:'tomato', height:'10px'}}>{`${this.state.paraErrMsg}`}</h6>
            <div style={{ alignItems:'center'}}>
                <h3 style={{display:'inline-flex',}}>Running On Battery</h3><input id='runOnBatt' type='checkbox' onClick={this.checkRunOnBatt}></input>                
            </div>
            {this.state.bBattInUse &&<div style={{alignItems:'center'}}>
                <h6 style={{display:'inline-flex',}} >Battery Parameter: </h6>                
                    {tableFn.tableDropDownKeySel(
                        `battPara`,
                        this.state.paraEnm_dd,
                        0,
                        "select-custom",
                        null,
                        () => this.battParaSel()
                    )}      
            </div>}
            <button className="universal-btn vfd" onClick={this.handleSave} style={{ fontSize: "25px", display: 'inline-block', }}>
                {this.state.bAddInprogress? 'Saving...':'Save'}
            </button>
        <ToastContainer />
        </> );
    }
}
 
export default sensorAddNewPage;