import monListService from "../../../service/monList/monList";
import tool from "../../../checkingTools/tools";

async function getEleListByT1_id(T1_id) {
  try {
    let result = await monListService.getElementByT1_ID(T1_id);
    if (result.status !== 200) return [];
    return result.data;
  } catch (error) {
    console.log("Get Element List Err");
    tool.exResponse(error);
    return [];
  }
}

async function getAssignedMonListByUserID(UserID) {
  try {
    let result = await monListService.getAssignedMonListByUserID(UserID);
    if (result.status !== 200) return [];
    if (result.data) return result.data;
    return [];
  } catch (error) {
    console.log("Get Element List Err");
    tool.exResponse(error);
    return [];
  }
}

async function getT1ByMonList_ID(_id) {
  try {
    let result = await monListService.getT1ByMonList_ID(_id);
    if (result.status !== 200) return [];
    return result.data;
  } catch (error) {
    console.log("Get T1 Err");
    tool.exResponse(error);
    return [];
  }
}

async function getT1By_id(_id) {
  try {
    let result = await monListService.getT1By_id(_id);
    if (result.status !== 200) return [];
    return result.data;
  } catch (error) {
    console.log("Get T1 Err");
    tool.exResponse(error);
    return [];
  }
}

async function getMonlistByBuildingID(ID) {
  try {
    let result = await monListService.getMonListByBdID(ID);
    if (result.status !== 200) return null;
    return result.data;
  } catch (error) {
    console.log("Get Mon List Err");
    tool.exResponse(error);
    return null;
  }
}

async function getMonlistBy_id(_id) {
  try {
    let result = await monListService.getMonListBy_id(_id);
    if (result.status !== 200) return null;
    return result.data;
  } catch (error) {
    console.log("Get Mon List Err");
    tool.exResponse(error);
    return null;
  }
}

async function addMonList(body) {
  try {
    let rel = await monListService.addMonList(body);
    if (rel.status === 200) return true;
  } catch (error) {
    tool.exResponse(error);
  }
}

async function update(body) {
  try {
    let rel = await monListService.updateMonList(body);
    // console.log(rel);
    if (rel.status === 200) return true;
  } catch (error) {
    tool.exResponse(error);
    return null;
  }
}

async function del(obj) {
  try {
    let result = await monListService.delMonList(obj);
    if (result.status !== 200) return null;
    // console.log(result);
    return true;
  } catch (error) {
    console.log("Delete Assigned Monitoring List Err");
    tool.exResponse(error);
    return null;
  }
}

async function addAssignedMonList(body) {
  try {
    let rel = await monListService.addAssignedMonList(body);
    if (rel.status === 200) return true;
  } catch (error) {
    tool.exResponse(error);
  }
}
async function delAssignedMonList(obj) {
  try {
    let result = await monListService.delAssignedMonList(obj);
    if (result.status !== 200) return null;
    // console.log(result);
    return true;
  } catch (error) {
    console.log("Delete Assigned Monitoring List Err");
    tool.exResponse(error);
    return null;
  }
}

const expModule = {
  getT1By_id,
  getMonlistBy_id,
  delAssignedMonList,
  getMonlistByBuildingID,
  addAssignedMonList,
  getT1ByMonList_ID,
  getEleListByT1_id,
  update,
  del,
  addMonList,
  getAssignedMonListByUserID,
};
export default expModule;
