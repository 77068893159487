import React, { useEffect, useRef } from 'react';
import BdDevFn_Com from '../../SharedTemplate/BdDevFn';
import cssFn from "../../../component/utilities/css/cssFunction";
import socketIOClient from "socket.io-client";

function TpCtPumpLayout(props) {
    const timerRef = useRef(null);
    const pageClose = useRef(false);

    useEffect(() => {
        timerRef.current = setInterval(async() => {
            // console.log("Refresh");
            await reloadStatus();
        }, 300000);       /** 300*1000s , 5 mins*/

        let socket;
        async function startUp() {
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            await reloadStatus();

            let{ type, bdDev_id }= props;
            // let topic = `v2_${type}_${_id}`;
            let topic = `v1_${type}_${bdDev_id}`;
            // console.log("Socket IO topic: ", topic);
            socket.on(topic, async (data) => {
                // console.log("reload");
                // console.log(data);                                
                // let lastData = await BdDevFn_Com.loadLastData(props.devInfo);
                // if(lastData) setG_Lastdata(lastData);
                await reloadStatus();
            });
        }
        
        startUp();
        
        return ()=>{
            clearInterval(timerRef.current);
            socket.disconnect();
            pageClose.current = true;
            // alert("AddDevicePg Page Close");
        }
    });

    const reloadStatus = async () => {
        let devInfo = { ht: props.type, bdDev_id: props.bdDev_id };
        let lastData = await BdDevFn_Com.loadLastData(devInfo);
        // console.log(lastData);
        let sensorTimeout = BdDevFn_Com.sensorTimeout(lastData);

        cssFn.cssShowStatus(false, `ctpump_${props.bdDev_id}`, "pdc_run_bg");
        cssFn.cssShowStatus(false, `ctpump_${props.bdDev_id}`, "pdc_run_border");
        // cssFn.cssShowStatus(false, `ctpump_${props.bdDev_id}`, "pdc_error");
        // cssFn.cssShowStatus(false, `ctpump_${props.bdDev_id}`, "pdc_resetError");
        cssFn.cssShowStatus(false, `ctpump_${props.bdDev_id}`, "pdc_offline");
        // cssFn.cssShowStatus(false, `ctpump_${props.bdDev_id}`, "pdc_comError");

        if(sensorTimeout) {
            cssFn.cssShowStatus(true, `ctpump_${props.bdDev_id}`, "pdc_offline");
        // } else if(lastData.pf_2 > 0) { //com error
        //     cssFn.cssShowStatus(true, `ctpump_${props.bdDev_id}`, "pdc_comError");
        } else if(lastData.CurrentA >= 2){
            // check running
            cssFn.cssShowStatus(true, `ctpump_${props.bdDev_id}`, "pdc_run_bg");
            cssFn.cssShowStatus(true, `ctpump_${props.bdDev_id}`, "pdc_run_border");
            // check err
            // if(lastData.pb_9 === 1){  // error
            //     cssFn.cssShowStatus(true, `ctpump_${props.bdDev_id}`, "pdc_error");
            // }else if(lastData.pb_11 === 1){  // resettable err
            //     cssFn.cssShowStatus(true, `ctpump_${props.bdDev_id}`, "pdc_resetError");
            // } else {  // run
            // }
        }
    }

    return ( <div>
        <div id={`ctpump_${props.bdDev_id}`} className='pdc_nodeCard'>{props.name}</div>
    </div> );
}

export default TpCtPumpLayout;