import http from "../../httpService";


async function getV1_CtrlOvv_area_dev(bd_id, ctrlPg) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v1ctrl/ovv/getareandevice";
    let body = {bd_id, ctrlPg};
    return await http.post(url, body);  
}


async function getV1_ctrlGwPair(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v1ctrl/ovv/getgwpair";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV1_ctrlGwPair(bdDev_id, gwid) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v1ctrl/ovv/setgwpair";
    let body = {bdDev_id, gwid};
    return await http.post(url, body);  
}

const expModule = {
    getV1_CtrlOvv_area_dev,
    getV1_ctrlGwPair,
    setV1_ctrlGwPair,
};
export default expModule;