import React, { Component } from "react";
import buildings from "../../service/setting_Buildings";

class BuildingSelect_BdDev extends Component {
  state = {
    allBuildings: [],
    ownBuildings: [],
  };

  async componentDidMount() {
    await this._getAllBuildings();
    this._getOwnBuildings();
  }

  sortBuildingByOwnerBuilding = (a, b) => {
    if (a.owner > b.owner) return 1;
    if (a.owner < b.owner) return -1;
    if (a.building > b.building) return 1;
    if (a.building < b.building) return -1;
    return 0;
  };

  _getOwnBuildings = async () => {
    let allBuildings = [...this.state.allBuildings];
    let ownBuildings = allBuildings.sort(this.sortBuildingByOwnerBuilding);
    await this.setState({ ownBuildings });
    // console.log(allBuildings);
    // console.log(this.state.allBuildings);
    // console.log(ownBuilding);
  };

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      await this.setState({ allBuildings });
      // console.log(this.state.allBuildings);
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  toBuildingDev = (_id) => {
    this.props.history.push({
      pathname: `/buildings/management/${_id}`,
      // pathname: "/datapresent/building",
      _data: {
        // _selBuildingId: this.state.selBuildingId,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
    // window.location = `/monlist/${monList_id}`; // call a full reload
  };

  render() {
    let ownBuildings = [...this.state.ownBuildings];

    return (
      <React.Fragment>
        <h1>Device Assign</h1>
        <ul className="build-sel">
          {ownBuildings.map((a, ind) => (
            <li key={ind} onClick={() => this.toBuildingDev(a._id)}>
              {a.building}
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}

export default BuildingSelect_BdDev;
