import timeFn from '../../utilities/timeFn';
import bitFn from '../../../function/bitFn/bitFn';
import devMgnt_V2Fn from '../../utilities/devMgnt_v2_Fn/devMgnt_V2Fn';

function sche_dbDataToDisplayFormat(dbFormat){
    let displayFmt = dbFormat;
    displayFmt.startTime = timeFn.getUnixTime(dbFormat.startUnix, false, false);
    displayFmt.endTime = timeFn.getUnixTime(dbFormat.endUnix, false, false);
    displayFmt.startDate = timeFn.getUnixDate_Fmt2 (dbFormat.startUnix);
    displayFmt.endDate = timeFn.getUnixDate_Fmt2(dbFormat.endUnix);
    let dow = [];
    for (let i = 0; i < 7; i++) {
        dow[i] = bitFn.getBit(dbFormat.ndow, i)===1;
    }
    displayFmt.dow = dow;
    return displayFmt;
}

function convertWeekScheToRaw(sche){
    // Setpoint: 23.44999
    // dataAction: 3
    // dow: (7) [true, false, true, false, false, false, false]
    // endUnix_ms: 1628939161000
    // repeatWeekly: 1
    // startUnix_ms: 1628939160000

    if(sche.startUnix && !sche.startUnix_ms) sche.startUnix_ms = sche.startUnix*1000;
    if(sche.endUnix && !sche.endUnix_ms) sche.endUnix_ms = sche.endUnix*1000;

    let dow = sche.ndow;
    // for (let i = 0; i < 7; i++) {
    //     if (sche.ndow[i]) dow = bitFn.setBit(dow, i);            
    // }
    
    //cmd_wSche_SP4_Ac2_Dow3[i]
    let wSch_2 = Math.round(sche.Setpoint*10)*100000 +
    sche.dataAction * 1000 + 
    dow;
    
    let ST = timeFn.getTimeInInt_nonTz(sche.startUnix_ms);
    let ET = timeFn.getTimeInInt_nonTz(sche.endUnix_ms);
    // cmd_wSche_Iu1_Tf1_St4_Et4[i]
    let wSch_1 = 1000000000 + 
    0 + 
    ST * 10000 + 
    ET;
    
    return [wSch_1, wSch_2]
}


function convertEventScheToRaw(sche){
    // console.log(sche);
//   cmd_eSche_Tf1_Ac2_Sd6[i] = F_GetSinglePi((byte*)&buf, i*3);
//   cmd_eSche_Iu1_Sp3_Ed6[i] = F_GetSinglePi((byte*)&buf, (i*3)+1);
//   cmd_eSche_Sp1_St4_Et4[i] = F_GetSinglePi((byte*)&buf, (i*3)+2);
    if(sche.startUnix && !sche.startUnix_ms) sche.startUnix_ms = sche.startUnix*1000;
    if(sche.endUnix && !sche.endUnix_ms) sche.endUnix_ms = sche.endUnix*1000;

    let SDate = timeFn.getDateInInt_nonTz(sche.startUnix_ms);
    let EDate = timeFn.getDateInInt_nonTz(sche.endUnix_ms);
    let STime = timeFn.getTimeInInt_nonTz(sche.startUnix_ms);
    let ETime = timeFn.getTimeInInt_nonTz(sche.endUnix_ms);

    //   cmd_eSche_Tf1_Ac2_Sd6[i]
    let eSche_1 = sche.dataAction * 1000000 +
    SDate;
    // let sp_1 = Math.round(sche.Setpoint*10)%1000;
    // console.log(sp_1);
    //   cmd_eSche_Iu1_Sp3_Ed6[i]
    let eSche_2 = 1000000000 + 
    (Math.round(sche.Setpoint*10)%1000)*1000000 +
    EDate;
    //   cmd_eSche_Sp1_St4_Et4[i]
    let eSche_3 = parseInt(sche.Setpoint/100)*100000000 + 
    STime*10000+
    ETime;
    // console.log(eSche_1);
    // console.log(eSche_2);
    // console.log(eSche_3);

    return [eSche_1, eSche_2, eSche_3]
}

function validNumber(devID){
    let num = parseInt(devID);
    return (!Number.isNaN(num) && num!==0);
}

async function loadRequiredInitValue(propsLoc, a_dataList, fSetState, fChangePg){        
    let _data = propsLoc;
    for (const data of a_dataList) {
        if(_data && _data[data]) {
            // console.log("Valid");
            await fSetState(data, _data[data]);
            // await this.setState({[data]:_data[data]})
        }else{  // not found in pass down, search in localStorage
            let localData = await localStorage.getItem(data);
            // console.log(localData);    
            if(!localData) {
                // console.log("Not Found");            
                // this.props.history.push({
                //     pathname: "/ctrldev/ac/logindev",
                //     _data: {
                //     // _selBuildingId: this.state.selBuildingId,
                //     // _selT1Llist:tableMonList[ind]._id,
                //     },
                // });
                fChangePg();
                return;
            }else{
                // console.log('Found In Local');
                let nLocalData = parseInt(localData);
                if(Number.isNaN(nLocalData)) {
                    fChangePg();
                    return;
                }
                await fSetState(data, localData);
                // await this.setState({[data]:localData})
            }                
        }
    }
    // console.log(this.state.devID);
    return true;
}


async function getDevUnderAccount(){
    let totlDev = [];
    let devInvolve = await devMgnt_V2Fn.getInvolveBdDev_2();
    let devOwned = await devMgnt_V2Fn.getOwnDev_2();
    totlDev=[...devOwned, ...devInvolve];
    // console.log(devInvolve);
    // console.log(devOwned);
    
    return totlDev;
}

const expModule = {
    getDevUnderAccount,
    loadRequiredInitValue,
    validNumber,
    convertEventScheToRaw,
    sche_dbDataToDisplayFormat,
    convertWeekScheToRaw,
};
export default expModule;
