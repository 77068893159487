import React, { Component } from "react";
import monListService from "../../service/monList/monList";
import buildingDevService from "../../service/buildingDevicesService";
import lineListFn from "../utilities/lineListFn";
import Graph from "../graph/graph";
import sensorFn from '../../function/sensor/sensorManagementFn'

class MonListElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monListElement: [],
      bdDevices: [],
      allGraph: [],
      iconCName: [],
      displayStatus: [],
      selT1_id: 0,
      graphLoaded: false,
    };
    this.setLinePara = this.setLinePara.bind(this);
  }

  _isMounted = false;

  async componentDidMount() {
    try {
      this._isMounted = true;
      // console.log('1');
      await this.setState({ graphLoaded: false });
      let selT1_id = parseInt(this.props._id);
      if (this._isMounted) this.setState({ selT1_id });
      // console.log('2');
      await this._getMonListElement(selT1_id);
      // let graphList = await this._getGraphList();
      // console.log('3');
      let bdDevices = await this._getBdDevices();
      if (this._isMounted) await this.setState({ bdDevices });
      // console.log('4');
      let lineList = await this.genLineList();
      // console.log('5');
      let graphList = await this.genGraphList();
      // console.log('6');
      let allGraph = this.insertLineToGraph(lineList, graphList);
      // console.log('7');
      if (this._isMounted) await this.setState({ allGraph });
      // console.log(`8 ${this._isMounted}`);
      if (this._isMounted) await this.setState({ graphLoaded: true });
    } catch (error) {
      console.log(error.message);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log("Change");
    if (this.props._id !== prevProps._id) await this.refreshPage();
    if (this.props.displayStatus !== prevProps.displayStatus) {
      await this.setState({ graphLoaded: false });
      //   // await this.refreshPage();
      await this.setState({ graphLoaded: true });
    }
  }

  refreshPage = async () => {
    try {
      await this.setState({ graphLoaded: false });
      let _id = this.props._id;
      await this._getMonListElement(_id);
      // let graphList = await this._getGraphList();
      let bdDevices = await this._getBdDevices();
      await this.setState({ bdDevices });
      let lineList = await this.genLineList();
      let graphList = await this.genGraphList();
      let allGraph = this.insertLineToGraph(lineList, graphList);
      await this.setState({ allGraph });
      await this.setState({ graphLoaded: true });
    } catch (error) {
      console.log(error.message);
    }
  };

  _getMonListElement = async (id) => {
    try {
      let { data: monListElement } = await monListService.getElementByT1_ID(id);
      if (monListElement)
        monListElement.sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
      if (this._isMounted) this.setState({ monListElement });
    } catch (error) {
      console.log("Get Monitoring List DB Error: ", error.message);
    }
  };

  _getBdDevices = async () => {
    let monListElement = [...this.state.monListElement];
    let bdDevices = [];
    for (const ele of monListElement) {
      try {
        let { data } = await buildingDevService.getById(ele.bdDev_id);
        for (const line of data) bdDevices.push(line);
      } catch (error) {
        console.log("Get Monitoring List DB Error: ", error.message);
      }
    }
    // console.log(bdDevices);
    return bdDevices;
  };

  async genLineList() {
    let defaultLineList = [];
    let graphIndex = 0;

    let bdDevices = [...this.state.bdDevices];
    // console.log(bdDevices);
    let sensorTyInBd = new Set(bdDevices.map(item => item.type));
    let newSensorParasInUse = await sensorFn.getParaByTypeList([...sensorTyInBd]);
    await this.setState({newSensorParasInUse});
    // console.log(newSensorParasInUse);

    for (const bdDev of this.state.bdDevices) {
      let allLineList = lineListFn.genLineListFromBdDevice(bdDev, newSensorParasInUse);
      // console.log(allLineList);
      for (const lineList of allLineList) {
        lineList.gphIdx = graphIndex;
        defaultLineList.push(lineList);
      }
      graphIndex++;
    }
    // console.log(defaultLineList);
    return defaultLineList;
  }

  async genGraphList() {
    let bdDevices = [...this.state.bdDevices];
    let displayStatus = [...this.state.displayStatus];
    let iconCName = [...this.state.iconCName];
    let forIndex = 0;
    let graphLists = [];
    let idx = 0;
    for (const bdDev of bdDevices) {
      // console.log(bdDev);
      let loc = "";
      if (bdDev.location) {
        loc = `${bdDev.location}-`;
      }
      idx++;
      let graphList = {
        userID: 0,
        bdDev_id: bdDev._id,
        pageID: 0,
        orderIdx: idx,
        // name: bdDev.name,  // previous coding
        name: `${loc}${bdDev.name} [${bdDev.devID}]`,
        graphType: 0, //default, full view
      };
      graphLists.push(graphList);
      displayStatus[forIndex] = "none";
      iconCName[forIndex] = "fa fa-chevron-down";
      forIndex++;
    }
    if (this._isMounted) await this.setState({ displayStatus });
    if (this._isMounted) await this.setState({ iconCName });
    // console.log(graphLists);
    return graphLists;
  }
  // eslint-disable-next-line
  insertLineToGraph(lineList, graphList) {
    // console.log("Enter liao leh");
    let allGraph = [];
    for (let index = 0; index < graphList.length; index++) {
      let currentGraphKey = lineList.filter(
        (ln) =>
          // eslint-disable-next-line
          index === ln.gphIdx && ln.bdDev_id == graphList[index].bdDev_id
      );
      if (currentGraphKey[0]) graphList[index].line1_id = currentGraphKey[0];
      // console.log(currentGraphKey[0]);
      if (currentGraphKey[1]) graphList[index].line2_id = currentGraphKey[1];
      if (currentGraphKey[2]) graphList[index].line3_id = currentGraphKey[2];
      allGraph.push(graphList[index]);
    }
    return allGraph;
  }

  async setLinePara(graph) {
    // console.log(graph);
    let allGraph = [...this.state.allGraph];
    // console.log(allGraph);
    let index = allGraph.findIndex((gr) => gr.orderIdx === graph.orderIdx);
    // console.log(index);
    allGraph[index] = { ...graph };
    await this.setState({ allGraph });
    // console.log(this.state.displayGraph);
  }

  handleDropdown = async (_idx) => {
    let iconCName = [...this.state.iconCName];
    iconCName[_idx] === "fa fa-chevron-down"
      ? (iconCName[_idx] = "fa fa-chevron-up")
      : (iconCName[_idx] = "fa fa-chevron-down");
    await this.setState({ iconCName });

    this.editDisplayStatus(_idx);
  };

  async editDisplayStatus(_idx) {
    let displayStatus = [...this.state.displayStatus];
    // console.log(display);
    displayStatus[_idx] === "none"
      ? (displayStatus[_idx] = "")
      : (displayStatus[_idx] = "none");
    await this.setState({ displayStatus });
    // display[_idx] === "none" ? this.setState({ displayStatus[_idx]: "" }) : this.setState({ displayStatus[_idx]: "none" });
    // console.log(this.state.displayStatus);
  }

  render() {
    let iconName = [...this.state.iconCName];
    let displayStatus = this.props.displayStatus;
    // console.log(this.props.unixStart);
    // console.log(this.props.unixEnd);
    // console.log(this.state.allGraph);
    return (
      <React.Fragment>
        <div className="row">
          {this.state.graphLoaded &&
            this.state.allGraph.map((u, ind) => (
              <div className="column" key={ind}>
                <div
                  className="card element"
                  style={{ display: displayStatus }}
                >
                  <h5 className="card-header">
                    <div style={{ maxWidth: "88%", display: "inline-block" }}>
                      {`${this.props.t1_idx + 1}.${ind + 1}`}&nbsp;&nbsp;
                      {u.name}
                    </div>
                    <span id="header-dd">
                      <i
                        className={iconName[ind]}
                        onClick={() => this.handleDropdown(ind)}
                      />
                    </span>
                  </h5>
                  <div className="card-body">
                    <Graph
                      gphIndex={ind}
                      T1_id={this.props._id}
                      graphPara={u}
                      handleLinePara={this.setLinePara}
                      displayStatus={this.state.displayStatus[ind]}
                      _index={`${u.bdDev_id}_${this.props._id}_${ind}`}
                      unixStart={this.props.unixStart}
                      unixEnd={this.props.unixEnd}
                      newSenPara = {this.state.newSensorParasInUse}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default MonListElement;
