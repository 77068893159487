import http from "../httpService";

/** Get T1 list is located at mon list */
async function updateT1List(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/t1list/updatet1list`;
    const body = {   
      _id: parseInt(obj._id),
      name: obj.name,
      MonitorList_id: parseInt(obj.MonitorList_id),
      SortIndex: parseInt(obj.SortIndex),
    };

    return await http.post(url, body);    
}

async function delT1List(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/t1list/delt1list";
    const body = {
        _id:obj._id
      };
    return await http.post(url, body);    
}

async function addT1List(obj) {
    let url = process.env.REACT_APP_PUBLIC_URL+"/t1list/addt1list";
    //   console.log(obj);
    const body = {   
        // _id: parseInt(obj._id),
        name: obj.name,
        MonitorList_id: parseInt(obj.MonitorList_id),
        SortIndex: parseInt(obj.SortIndex),
      };
      // console.log("did enter");
    return await http.post(url, body); 
}

const expModule = {  
    updateT1List,
    delT1List,
    addT1List,
};
export default expModule;