import React, { Component } from "react";
import monListService from "../../service/monList/monList";
import MonListElement from "./monListElement";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import Icon from "../common/icon";
// import NcForm from "../common/ncForm";

class MonitorList extends Component {
  state = {
    monID: "",
    monList: [],
    monListT1: [],
    buildingName: "",
    iconCName: [],
    displayStatus: [],
    bLoaded: false,
    settingDisplay: "none",
    unixStart: "",
    unixEnd: "",
  };

  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
    await this.setState({ bLoaded: false });
    let buildingName = await localStorage.getItem("buildingName");
    await this.setState({ buildingName });
    let monID = this.props.match.params.monID;
    if (this._isMounted) await this.setState({ monID });
    await this._getMonList();
    await this._getMonListT1();
    if (this._isMounted) this.setIconDisStatus();
    if (this._isMounted) await this.setState({ bLoaded: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.monID !== prevProps.match.params.monID)
      await this.refreshPage();
  }

  refreshPage = async () => {
    await this.setState({ bLoaded: false });
    let monID = this.props.match.params.monID;
    await this.setState({ monID });
    await this._getMonList();
    await this._getMonListT1();
    this.setIconDisStatus();
    await this.setState({ bLoaded: true });
  };

  _getMonList = async () => {
    try {
      let { data: monList } = await monListService.getMonListBy_id(
        this.state.monID
      );
      await this.setState({ monList });
    } catch (error) {
      console.log("Get Monitoring List DB Error: ", error.message);
    }
  };

  _getMonListT1 = async () => {
    try {
      let { data: monListT1 } = await monListService.getT1ByMonList_ID(
        this.state.monID
      );
      await this.setState({ monListT1 });
    } catch (error) {
      console.log("Get Monitoring List DB Error: ", error.message);
    }
  };

  async setIconDisStatus() {
    let iconCName = [...this.state.iconCName];
    let displayStatus = [...this.state.displayStatus];

    for (let i = 0; i < this.state.monListT1.length; i++) {
      iconCName[i] = "fa fa-chevron-down";
      displayStatus[i] = "none";
    }

    await this.setState({ iconCName });
    await this.setState({ displayStatus });
  }

  handleDropdown = async (_idx) => {
    // console.log("onclick");
    // await this.setState({ bLoaded: false });
    let iconCName = [...this.state.iconCName];
    iconCName[_idx] === "fa fa-chevron-down"
      ? (iconCName[_idx] = "fa fa-chevron-up")
      : (iconCName[_idx] = "fa fa-chevron-down");
    await this.setState({ iconCName });

    await this.editDisplayStatus(_idx);
    // await this.setState({ bLoaded: true });
  };

  async editDisplayStatus(_idx) {
    let displayStatus = [...this.state.displayStatus];
    // console.log(display);
    displayStatus[_idx] === "none"
      ? (displayStatus[_idx] = "")
      : (displayStatus[_idx] = "none");
    await this.setState({ displayStatus });
    // display[_idx] === "none" ? this.setState({ displayStatus[_idx]: "" }) : this.setState({ displayStatus[_idx]: "none" });
    // console.log(this.state.displayStatus);
  }

  handleSetting = () => {
    const setting = this.state.settingDisplay;
    setting === "none"
      ? this.setState({ settingDisplay: "block" })
      : this.setState({ settingDisplay: "none" });
  };

  handleSet = () => {
    try {
      let start = document.getElementById("startDate").value;
      let end = document.getElementById("endDate").value;
      let unixStart = new Date(start).valueOf() / 1000;
      let unixEnd = new Date(end).valueOf() / 1000;
      this.setState({ unixStart });
      this.setState({ unixEnd });
    } catch (ex) {
      console.log(ex);
    }
    this.handleSetting();
  };

  handleReset = () => {
    this.setState({ unixStart: "" });
    this.setState({ unixEnd: "" });
    this.handleSetting();
  };

  render() {
    // if (this.state.bSelList) await this.refreshPage();
    let monList = [...this.state.monList];
    let monListT1 = [...this.state.monListT1];
    monListT1.sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
    let iconCName = [...this.state.iconCName];
    // console.log(this.state.unixStart);
    // console.log(this.state.unixEnd);
    // console.log(monListT1);
    return (
      <React.Fragment>
        <div style={{ display: "inline-block" }}>
          <h1>Data Monitoring</h1>
        </div>
        <Icon
          className="fa fa-clock-o deactive"
          onClick={() => this.handleSetting()}
        />
        <div className="modal" style={{ display: this.state.settingDisplay }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Time Selection</h4>
                <span
                  className="close-btn"
                  onClick={() => this.handleSetting()}
                >
                  &times;
                </span>
              </div>
              <div className="modal-body">
                <div>
                  <label>Start: </label>
                  <input type="datetime-local" id="startDate"></input>
                </div>
                <div>
                  <label>End: </label>
                  <input type="datetime-local" id="endDate"></input>
                </div>
                <div style={{ display: "inline-block" }}>
                  <button
                    className="universal-btn"
                    onClick={() => this.handleSet()}
                  >
                    Set
                  </button>
                </div>
                <div className="reset-btn" style={{ display: "inline-block" }}>
                  <button
                    className="universal-btn reset"
                    onClick={() => this.handleReset()}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!this.state.bLoaded && (
          <h5 className="monlist-title">{this.state.buildingName}</h5>
        )}
        {!this.state.bLoaded && <Loader />}
        {this.state.bLoaded && !monList[0] && (
          <h2>Please contact admin to configure Monitor List</h2>
        )}
        {this.state.bLoaded && monList[0] && monList[0].name && (
          <div>
            <h5 className="monlist-title">
              <Link to="/selectpage" className="monlist-link">
                {this.state.buildingName}
              </Link>
              <div className="monlist-arr">{`>`}</div>
              {monList[0].name}
            </h5>
          </div>
        )}
        {this.state.bLoaded &&
          monListT1.map((u, ind) => (
            <div className="card outer" key={ind}>
              <h4
                className="card-header"
                onClick={() => this.handleDropdown(ind)}
              >
                <div style={{ maxWidth: "88%", display: "inline-block" }}>
                  {`${ind + 1}.`}&nbsp;&nbsp;&nbsp;{u.name}
                </div>
                <span id="header-dd">
                  <i className={iconCName[ind]} />
                </span>
              </h4>
              <div className="card-body">
                <MonListElement
                  _id={u._id}
                  t1_idx={ind}
                  displayStatus={this.state.displayStatus[ind]}
                  unixStart={this.state.unixStart}
                  unixEnd={this.state.unixEnd}
                />
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default MonitorList;
