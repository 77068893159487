import React, { Component } from "react";
import bdFn from "../../function/Building/getAllBd_Enm";
import tableFn from "../../component/utilities/tableFunction";
import { genBdDevName } from "../../component/utilities/conversion";
import battDiagFn from "../../function/BattDiag/battDiagFn";
import timeFn from "../../component/utilities/timeFn";
import TpPopUp from "./../template/PopUp/TpPopUp";
import Icon from "../common/icon";
import TpInput from "./../template/InputText/TpInput";
import TpTableHeaderTab from "./../template/Table/HeaderTab/TpTableHeader";
import { sortTemplate } from "../../component/utilities/ArrayFn/arrayFn";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import sensorFn from "../../function/sensor/sensorManagementFn";

// import TpInput from "./../template/InputText/TpInput";
// import TpTableHeaderTab from "./../template/Table/HeaderTab/TpTableHeader";
// import { sortTemplate } from "../../component/utilities/ArrayFn/arrayFn";
import PopUpTitle from "../template/PopUp/PopUpTitle";
import PopUpSubTitle from "../template/PopUp/PopUpSubTitle";
import PopUpSubContent from "./../template/PopUp/PopUpSubContent";
import Separator from "../template/Lines/Separator";
import CloseIcon from "../template/Icons/CloseIcon";

class BattDiagPage extends Component {
  state = {
    allSensorType: [],
    allBdEnum: [],
    displayTable: [],
    diagResultTable: [],
    rawDiagResult: [],
    cell: {
      checkbox: 2,
    },
    bChecking: false,
    nShowPage: 0,
    bShowPopUp: false,
    zoomResultItem: {},
    checkPeriod: 30,
    resultSortKeys: [],
    sensorList: [],
    newSensorParasInUse: [],
  };

  async componentDidMount() {
    let allSensorType = await sensorFn.getSensorByVendor_id(0);
    // console.log(allSensorType);
    await this.setState({ allSensorType });

    await this.setBuildingEnm();
  }

  setBuildingEnm = async () => {
    let allBdEnum = await bdFn.getAllBdEnm(true);
    // console.log(allBdEnum);
    await this.setState({ allBdEnum });
  };

  // handleBuildingSel = async () => {
  //   let bd_id = parseInt(document.getElementById("buildingSel").value);
  //   let bdDevices = await bdFn.getDevUnderBuilding(bd_id);
  //   await this.setState({ displayTable: bdDevices });
  //   await this.setState({ nShowPage: 0 });
  //   // console.log(bdDevices);
  //   this.fSetUnsetAll(true);
  //   this.checkEvery_SelStatus();
  // };

  checkEvery_SelStatus = () => {
    let selAll = true;
    for (let index = 0; index < this.state.displayTable.length; index++) {
      if (
        !document.getElementById("battDiagTable").rows[index + 1].cells[
          this.state.cell.checkbox
        ].children[0].checked
      ) {
        selAll = false;
        break;
      }
    }
    /** Set main select status */
    document.getElementById("Act_SelAll").checked = selAll;
  };

  eachItemSel = () => {
    try {
      this.checkEvery_SelStatus();
    } catch (error) {
      console.log(error.message);
    }
  };

  selAll = () => {
    try {
      let selAll = document.getElementById("Act_SelAll").checked;
      this.fSetUnsetAll(selAll);
    } catch (error) {
      console.log(error.message);
    }
  };

  handleBuildingSel = async () => {
    let bd_id = parseInt(document.getElementById("buildingSel").value);
    let bdDevices = await bdFn.getDevUnderBuilding(bd_id);

    /** Filter device running on battery */
    let allSensorType = [...this.state.allSensorType];
    let sensorTypeRunBatt = allSensorType.filter(
      (c) => c.battKey || c.battKey.trim() !== ""
    );
    let devRunBatt = [];
    for (const device of bdDevices) {
      let found = sensorTypeRunBatt.find((c) => c.type === device.type);
      if (found) devRunBatt.push(device);
    }

    await this.setState({ displayTable: devRunBatt });
    await this.setState({ nShowPage: 0 });
    // console.log(bdDevices);
    this.fSetUnsetAll(true);
    this.checkEvery_SelStatus();
  };

  fSetUnsetAll = (selAll) => {
    for (let index = 0; index < this.state.displayTable.length; index++) {
      document.getElementById("battDiagTable").rows[index + 1].cells[
        this.state.cell.checkbox
      ].children[0].checked = selAll;
    }
  };

  /** Press check button */
  diagBatt = async () => {
    try {
      let checkPeriod = document.getElementById("txPeriod").value;
      await this.setState({ checkPeriod });
      // console.log(checkPeriod);

      if (this.state.bChecking) return;
      // console.log("diagBatt");
      await this.setState({ bChecking: true });
      /**Generate the selected device list */
      let selDevList = [];
      for (let index = 0; index < this.state.displayTable.length; index++) {
        let rowSelected =
          document.getElementById("battDiagTable").rows[index + 1].cells[
            this.state.cell.checkbox
          ].children[0].checked;
        if (rowSelected) {
          selDevList.push(this.state.displayTable[index]);
        }
      }

      // console.log(selDevList);

      /**Press check button
       * done 1. send device list to backend
       * done 2. back end return the diagnostic result
       * 3. display the diagnostic result on another table
       * 4. Frontend set time frame, eg, 7 days.
       */
      let result = await battDiagFn.getDiag_byList(
        selDevList,
        10,
        this.state.checkPeriod * 86400
      );
      // console.log(result);
      await this.returnResultToTable(result);
      await this.setState({ bChecking: false });
      await this.setState({ nShowPage: 2 });
      // console.log(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  returnResultToTable = async (result) => {
    let diagResultTable = [];
    for (const item of result) {
      let _diagRel = {
        sensorName: this.genDevNameby_id(item._id),
        startT: item.preUnix,
        endT: item.curUnix,
        startV: item.preBattAve.toFixed(3),
        endV: item.curBattAve.toFixed(3),
        delV: "N/A",
        infoStatus: 0,
        _id: item._id,
      };
      // console.log(_diagRel);
      if (
        parseFloat(_diagRel.startV) === 0 &&
        parseFloat(_diagRel.endV) === 0
      ) {
        /** device never connected to server */
        // console.log("Both 0");
        _diagRel.startT = "N/A";
        _diagRel.startV = "N/A";
        _diagRel.endT = "N/A";
        _diagRel.endV = "N/A";
        _diagRel.infoStatus = 2; // 2 = error
        diagResultTable.push(_diagRel);
        continue;
      } else if (parseFloat(_diagRel.startV) === 0) {
        /** device disconnected longer than checking period */
        _diagRel.startT = _diagRel.endT;
        _diagRel.startV = _diagRel.endV;
        _diagRel.endT = "N/A";
        _diagRel.endV = "N/A";
        _diagRel.infoStatus = 2; // 2 = error
        diagResultTable.push(_diagRel);
        continue;
      }
      /**Determine Info Status for warning, testing time shorter*/
      let timeBuffer = 3600; // 3600s = 1h
      // let startBuffer =_diagRel.startT- (timeFn.getUnixNow()-(7*86400));
      // console.log(startBuffer);
      _diagRel.startWarning =
        _diagRel.startT -
          (timeFn.getUnixNow() - this.state.checkPeriod * 86400) >
        timeBuffer;
      _diagRel.endWarning = timeFn.getUnixNow() - _diagRel.endT > timeBuffer;
      if (_diagRel.endWarning || _diagRel.startWarning) _diagRel.infoStatus = 1;

      _diagRel.delV = this.calcDelV(_diagRel);
      diagResultTable.push(_diagRel);
    }
    await this.setState({ diagResultTable });
  };

  calcDelV = (u) => {
    if (u.startV === "N/A" || u.endV === "N/A") return "N/A";

    let del = u.startV - u.endV;
    return del.toFixed(3);
  };

  genDevNameby_id = (_id) => {
    try {
      let displayTable = this.state.displayTable;
      let found = displayTable.find((c) => c._id === _id);
      // console.log(found);
      return genBdDevName(found);
    } catch (error) {
      console.log(error.message);
    }
  };

  backToInitPg = async () => {
    await this.setState({ resultSortKeys: [] });
    await this.setState({ nShowPage: 0 });
  };
  closePopUp = async () => {
    this.setState({ bShowPopUp: false });
  };

  clickInfo = async (ind) => {
    try {
      let diagResultTable = [...this.state.diagResultTable];
      // console.log(ind);
      // console.log(diagResultTable[ind]);
      let displayTable = [...this.state.displayTable];
      let found = displayTable.find((c) => c._id === diagResultTable[ind]._id);
      // console.log(found);
      let zoomResultItem = { ...diagResultTable[ind] };
      zoomResultItem.name = genBdDevName(found);
      await this.setState({ zoomResultItem });
      // console.log(this.state.zoomResultItem);
      this.setState({ bShowPopUp: true });
    } catch (error) {
      console.log(error.message);
    }
  };

  genInfoColor = (infoStatus) => {
    switch (infoStatus) {
      case 1:
        return "orange";
      case 2:
        return "red";
      default:
        return "cyan";
    }
  };

  genDisplayDateTime = (unix) => {
    if (unix === "N/A") return unix;
    return `${timeFn.getUnixDate(unix)} ${timeFn.getUnixTime(unix)}`;
  };

  genDisplayVoltage = (voltage) => {
    if (voltage === "N/A") return voltage;
    return `${parseFloat(voltage).toFixed(3)} V`;
  };

  genDeltaDisplay = (zomRel) => {
    if (zomRel.startV === "N/A" || zomRel.endV === "N/A") return "N/A";
    return `${(zomRel.startV - zomRel.endV).toFixed(3)} V`;
  };

  calcDuration = (zomRel) => {
    if (zomRel.startT === "N/A" || zomRel.endT === "N/A") return "N/A";
    // let unixDiff = zomRel.startV - zomRel.endV;
    return timeFn.fGetTimeDiff_InDTMS(zomRel.startT, zomRel.endT);
  };

  getSortOrder = (nSortOrder, sortInvert) => {
    if (nSortOrder === 2) return !sortInvert;
    return sortInvert;
  };

  sortRow = async (nSortOrder, sortKey, sortInvert) => {
    try {
      // console.log(nSortOrder, sortKey);

      let diagResultTable = [...this.state.diagResultTable];
      let resultSortKeys = [...this.state.resultSortKeys];
      // console.log(diagResultTable);
      /** Update sort keys */
      let _sortKey = {
        key: sortKey,
        descOrder: this.getSortOrder(nSortOrder, sortInvert),
      };
      /** In case no sort key before*/

      resultSortKeys = resultSortKeys.filter((c) => c.key !== sortKey); // remove existing key
      if (nSortOrder !== 0) resultSortKeys.push(_sortKey); // add existing if sort active

      // console.log(resultSortKeys);
      await this.setState({ resultSortKeys });
      diagResultTable.sort((a, b) => sortTemplate(a, b, resultSortKeys));
      await this.setState({ diagResultTable });
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    return (
      <>
        {this.state.bShowPopUp && (
          <TpPopUp handleClickOutside={this.closePopUp}>
            {
              <div>
                <CloseIcon close={this.closePopUp}></CloseIcon>
                <PopUpTitle title={this.state.zoomResultItem.name}></PopUpTitle>
                <div>
                  <PopUpSubTitle subtitle="Before"></PopUpSubTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      <PopUpSubContent
                        subcontent={this.genDisplayDateTime(
                          this.state.zoomResultItem.startT
                        )}
                      ></PopUpSubContent>
                      <PopUpSubContent
                        subcontent={this.genDisplayVoltage(
                          this.state.zoomResultItem.startV
                        )}
                      ></PopUpSubContent>
                    </div>
                    {(this.state.zoomResultItem.startWarning ||
                      this.state.zoomResultItem.infoStatus === 2) && (
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: 20,
                          verticalAlign: "middle",
                        }}
                      >
                        <Icon
                          style={{
                            color: this.genInfoColor(
                              this.state.zoomResultItem.infoStatus
                            ),
                          }}
                          className="fa fa-exclamation-triangle"
                        ></Icon>
                      </div>
                    )}
                  </div>
                </div>
                <Separator />
                <div>
                  <PopUpSubTitle subtitle="Now"></PopUpSubTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      <PopUpSubContent
                        subcontent={this.genDisplayDateTime(
                          this.state.zoomResultItem.endT
                        )}
                      ></PopUpSubContent>
                      <PopUpSubContent
                        subcontent={this.genDisplayVoltage(
                          this.state.zoomResultItem.endV
                        )}
                      ></PopUpSubContent>
                    </div>
                    {(this.state.zoomResultItem.endWarning ||
                      this.state.zoomResultItem.infoStatus === 2) && (
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: 20,
                          verticalAlign: "middle",
                        }}
                      >
                        <Icon
                          style={{
                            color: this.genInfoColor(
                              this.state.zoomResultItem.infoStatus
                            ),
                          }}
                          className="fa fa-exclamation-triangle"
                        ></Icon>
                      </div>
                    )}
                  </div>
                </div>
                <Separator />
                <PopUpSubTitle subtitle="Summary"></PopUpSubTitle>
                <PopUpSubContent
                  subcontent={this.genDeltaDisplay(this.state.zoomResultItem)}
                ></PopUpSubContent>
                <PopUpSubContent
                  subcontent={this.calcDuration(this.state.zoomResultItem)}
                  style={{ marginBottom: "15px" }}
                ></PopUpSubContent>
              </div>
            }
          </TpPopUp>
        )}
        <h1>Battery Diagnostic</h1>
        <div style={{ fontSize: "25px", display: "inline-block" }}>
          <label className="sel-label">Select building</label>
          {tableFn.tableDropDownKeySel(
            `buildingSel`,
            this.state.allBdEnum,
            1,
            "select-dd",
            () => this.handleBuildingSel()
          )}
        </div>
        {this.state.nShowPage !== 2 && (
          <button
            className="universal-btn vfd"
            onClick={this.diagBatt}
            style={{ fontSize: "25px", display: "inline-block" }}
          >
            {this.state.bChecking ? "Checking" : "Check"}
          </button>
        )}
        {this.state.nShowPage === 2 && (
          <button
            className="universal-btn vfd"
            onClick={this.backToInitPg}
            style={{ fontSize: "25px", display: "inline-block" }}
          >
            Back
          </button>
        )}
        <TpInput
          id="txPeriod"
          type="number"
          title="Period"
          defaultValue={this.state.checkPeriod}
          unit="day(s)"
        />
        {this.state.nShowPage === 0 && (
          <div className="card">
            <div className="card-body">
              <table className="cusTable" id="battDiagTable">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th style={{ display: "none" }}>_id</th>
                    {/* <th style={{ display: "none" }}>buildingID</th>  */}
                    <th scope="col">
                      <input
                        id="Act_SelAll"
                        type="checkbox"
                        onClick={this.selAll}
                      ></input>
                    </th>
                    <th scope="col">Sensor</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.displayTable.map((u, ind) => (
                    <tr key={u._id}>
                      <td>{ind + 1}</td>
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td>
                        <input
                          className="checkbox"
                          id="Active_c"
                          type="checkbox"
                          onClick={this.eachItemSel}
                        ></input>
                      </td>
                      <td>{genBdDevName(u)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {this.state.nShowPage === 2 && (
          <div className="card">
            <div className="card-body">
              <table className="cusTable" id="diagResultTable">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th style={{ display: "none" }}>_id</th>
                    {/* <th style={{ display: "none" }}>buildingID</th>  */}
                    <th
                      scope="col"
                      style={
                        {
                          // justifyContent:'center',
                          // alignItems:'center',
                        }
                      }
                    >
                      <TpTableHeaderTab
                        sortKey="sensorName"
                        onClick={(text, sortKey) => {
                          this.sortRow(text, sortKey);
                        }}
                      >
                        Sensor
                      </TpTableHeaderTab>
                    </th>
                    <th scope="col">
                      <TpTableHeaderTab
                        sortKey="startV"
                        onClick={(text, sortKey) => {
                          this.sortRow(text, sortKey, true);
                        }}
                      >
                        Before(V)
                      </TpTableHeaderTab>
                    </th>
                    <th scope="col">
                      <TpTableHeaderTab
                        sortKey="endV"
                        onClick={(text, sortKey) => {
                          this.sortRow(text, sortKey, true);
                        }}
                      >
                        Now(V)
                      </TpTableHeaderTab>
                    </th>
                    <th scope="col">
                      <TpTableHeaderTab
                        sortKey="delV"
                        onClick={(text, sortKey) => {
                          this.sortRow(text, sortKey, true);
                        }}
                      >
                        ΔV
                      </TpTableHeaderTab>
                    </th>
                    <th scope="col">
                      <TpTableHeaderTab
                        sortKey="infoStatus"
                        onClick={(text, sortKey) => {
                          this.sortRow(text, sortKey, true);
                        }}
                      >
                        Info
                      </TpTableHeaderTab>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.diagResultTable.map((u, ind) => (
                    <tr key={u._id}>
                      <td>{ind + 1}</td>
                      {/* <td style={{ display: "none" }}>{u._id}</td>     */}
                      <td>{u.sensorName}</td>
                      <td>{u.startV}</td>
                      <td>{u.endV}</td>
                      <td>{u.delV}</td>
                      <td onClick={() => this.clickInfo(ind)}>
                        <Icon
                          style={{ color: this.genInfoColor(u.infoStatus) }}
                          className="fa fa-info-circle"
                        ></Icon>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <ToastContainer />
      </>
    );
  }
}

export default BattDiagPage;
