
function genBdDevName(bdDev){
    let loc="";
    if(bdDev.location){
      loc = `${bdDev.location}-`;
    }

    return `${loc}${bdDev.name} [${bdDev.devID}]`;
}

function roundDecimalPoint(num, decimalPoint){
  let ePower = (Math.pow(10, decimalPoint));
  // eslint-disable-next-line
    if (ePower==0) return num;
    return Math.round((num + Number.EPSILON) * ePower) / ePower;
}

function trueFalseTo_1_0(bool){
  let result = 0;
  if (bool) result = 1;
  return result;
}

function timeToTimeFmt(sTime){
  let aTime = sTime.split(":");
  let nTime = parseInt(aTime[0])*10000;
  nTime += parseInt(aTime[1])*100;
  return nTime;
}

exports.timeToTimeFmt = timeToTimeFmt;
exports.trueFalseTo_1_0=trueFalseTo_1_0;
exports.genBdDevName=genBdDevName;
exports.roundDecimalPoint = roundDecimalPoint;