import http from "./httpService";




async function resetPassReq(username) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/users/changepassreq";
  //   console.log(user);
  const body = {
      username: username,
    };
    // console.log("did enter");
  return await http.post(url, body);    
}
  
async function getByRecoveryCode(recoveryCode) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/users/getbyrecoverycode";
  //   console.log(user);
  const body = {
    recoveryCode: recoveryCode,
    };
    // console.log("did enter");
  return await http.post(url, body);    
}

async function setNewPassword(info) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/users/setnewpass";
  
  return await http.post(url, info);    
}


async function getAllUser() {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/users/all";
  return await http.get(url);    
}

async function updateUser(user) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/users/update";
  //   console.log(user);
  const body = {
      username: user.username,
      email: user.email,
      accessLevel: user.accessLevel,
      // password: user.password,
      name: user.name,
      teleID: user.teleID,
      company: user.company,
      phone: user.phone,
      active: user.active,
    };
    // console.log("did enter");
  return await http.post(url, body);    
}
  
async function deleteUser(user) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/users/del";
  //   console.log(user);
  const body = {
      username: user.username,
      email: user.email,
      accessLevel: user.accessLevel,
      // password: user.password,
      name: user.name,
      teleID: user.teleID,
      company: user.company,
      phone: user.phone,
      active: user.active,
    };
    // console.log("did enter");
  return await http.post(url, body);
  
}


const expModule = {  
    getAll:getAllUser,
    update: updateUser,
    delete: deleteUser,
    resetPassReq,
    getByRecoveryCode,
    setNewPassword
};
export default expModule;

  // export default{
  //   update: updateUser,
  //   delete: deleteUser,
  // }