import React, { Component } from "react";
import "./icon.css";

class CloseIcon extends Component {
  close = () => {
    this.props.close();
  };

  render() {
    return (
      <div className="close-btn" onClick={this.close}>
        &times;
      </div>
    );
  }
}

export default CloseIcon;
