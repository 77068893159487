import React, { Component } from "react";
import tableFn from "../utilities/tableFunction";
import bdFn from "../utilities/getBuilding";
import enm from "../utilities/enum";
import bdDevFn from "../utilities/getDevices";
import notifyFb from "../utilities/notificaton/notificationList";
import timeFn from "../utilities/timeFn";
import auth from "../../service/authService";
import Icon from "../common/icon";
import cssFn from "../utilities/css/cssFunction";
import { ToastContainer, toast } from "react-toastify";
import "./notification.css";
// import notifyService from '../../service/notification/notificationList';
// import unit from '../utilities/unitType';
import sensorFn from '../../function/sensor/sensorManagementFn';
import sensorKeyFn from '../utilities/sensorKey';

class NotificationPage extends Component {
  state = {
    // trigOnceADay:[],
    filteredBdEnum: [],
    notiListOfBuidling: [],
    bdDevInBd: [],

    bdDevsEnum: [],
    eachRowDevType: [],
    eachRowDevType_int: [],
    eachRowDataKey: [],

    typeEachRow: [],
    bCopyMode: false,
    bFilterMode: false,

    someBuildingSelected: false,
    bPageLoaded: false,
    selectBdID: 0,
    bPageLoaded1stTime: false,
    copyIndex: 0,
    bLoadDevice: true,
    bLoadKey: true,
    filterCurrent: {
      sensor: 0,
      bdDev: 0,
      dataKey: 0,
      alarmType: 0,
    },
    filterEnm: {
      sensor: [],
      // sensor: [enm.getSensorEnmObj("All")],
      bdDev: [],
      dataKey: [],
      alarmType: [],
    },
    indexDisplay: [],
    typeEnm: [],
    newSensorParasInUse:[],
  };

  async componentDidMount() {
    try {
      /** Get type enm */
      await this.ensureBdIdCorrect();

      let filterEnm={...this.state.filterEnm};
      filterEnm.sensor = await enm.getSensorEnmObj("All");
      await this.setState({filterEnm});
      
      let typeEnm = await enm.getDevTypeEnum();
      await this.setState({typeEnm});

      // console.log(typeEnm);

      await this.setState({ bPageLoaded: false });
      // console.log("did mount");
      // await this.setState({ selectBdID: this.props.match.params.bdID });      
      await this.loadBuildingID();
      // console.log(this.state.selectBdID);
      // document.getElementById("bdSel").value = this.state.selectBdID;
      await this.getBdEnum();
      await this.detectEachRowtype_1stLoad();

      await this.setState({ bPageLoaded: true });
      await this.setState({ bPageLoaded1stTime: true });

      /**------Filter------ */
      await this.genFilterBdDev();
      this.getFilterPara();
      /**=======Filter====== */
      // let Fvalue = unit.unitType.temperature.ferenheight.disToDef(87);
      // console.log(Fvalue);
    } catch (error) {
      toast.error(error.message);
    }
  }

  ensureBdIdCorrect=async()=>{
    try {
      let bdID = await localStorage.getItem("buildingId");
  
      // console.log(bdID);
      if(parseInt(bdID) < 1 || !bdID){
        this.props.history.push({
          pathname: "/buildingselect",
          _data: {
              // bdDev_id: this.state.bdDev_id,
              // gwID: this.state.gwID,
              // devID: this.state.devID,
          },
        });
      }
    } catch (error) {
      console.log("ensureBdIdCorrect");
      toast.error(error.message);
    }

  }

  loadBuildingID=async ()=>{
    let bdID = await localStorage.getItem("buildingId");
    
    
    await this.setState({ bPageLoaded: false });
    // let bdSel = document.getElementById("bdSel").value;
    // window.location = `/notification/list/${bdID}`;
    await this.setState({ selectBdID: parseInt(bdID) });
    await this.detectEachRowtype_1stLoad();
    await this.setState({ bPageLoaded: true });
  }

  async getAllBdDevInBd() {
    if (this.state.selectBdID === 0) return [];
    let bdDevInBd = await bdDevFn.getBdDevBybdID(this.state.selectBdID);
    await this.setState({ bdDevInBd });
    // console.log(bdDevInBd);
    let sensorTyInBd = new Set(bdDevInBd.map(item => item.type));
    // console.log(sensorTyInBd);
    let newSensorParasInUse = await sensorFn.getParaByTypeList([...sensorTyInBd]);
    this.setState({newSensorParasInUse});
    // console.log(newSensorParasInUse);

    await this.getBdDevsEnum();
    let a_bd_devId = [];
    for (const dev of bdDevInBd) {
      a_bd_devId.push(dev._id);
    }
    return a_bd_devId;
  }

  

  async getBdDevsEnum() {
    let bdDevsEnum = bdDevFn.turnBdDevIntoEnm(this.state.bdDevInBd);
    // console.log(bdDevsEnum);
    await this.setState({ bdDevsEnum });
  }

  async getBdEnum() {
    let buildings = await bdFn.getAllBuilding();
    let filteredBdEnum = enm.turnBuildingsToEnms(
      buildings,
      "Select Building...",
      false
    );
    this.setState({ filteredBdEnum });
    // console.log(filteredBdEnum);
  }

  tableColumn_del = (row) => {
    let column = {
      _id: row.cells[2].innerHTML,
    };
    // console.log(column);
    return column;
  };


  async getNotiListFromDB_FilterUser() {
    // let notiListOfBuidling=[];
    let a_bdDev = await this.getAllBdDevInBd();
    let notiListOfBuidling_AllUser = await notifyFb.getByBdDevs(a_bdDev);
    return notiListOfBuidling_AllUser.filter(
      (c) => c.userID === this.getCurrentUserId() || c.userID === 0
    );
  }

  async detectEachRowtype_1stLoad(bLoadByCopyFunction) {
    let notiListOfBuidling = [];
    if (!bLoadByCopyFunction) {
      notiListOfBuidling = await this.getNotiListFromDB_FilterUser();
      // console.log(notiListOfBuidling);
      await this.setState({ notiListOfBuidling });
    }

    // let notiListOfBuidling =[...this.state.notiListOfBuidling];
    
    if (!notiListOfBuidling[0]) return //toast.error("Empty Array");
    // console.log(notiListOfBuidling);
    let typeEachRow = [];
    let eachRowDevType_int = [];
    let eachRowDataKey = [];
    for (const notifyItem of notiListOfBuidling) {
      eachRowDevType_int.push(notifyItem.type);
      
      let targetEnum = sensorKeyFn.getKeyListByType_V2(this.state.newSensorParasInUse , notifyItem.type);
      if(!targetEnum[0]) targetEnum = sensorKeyFn.getKeyListByType(notifyItem.type);
      typeEachRow.push(targetEnum);
    }
    // console.log(typeEachRow);
    await this.setState({ typeEachRow });
    await this.setState({ eachRowDevType_int });

    await this.setState({ bLoadDevice: true });
    // await this.setState({bLoadKey:true});

    for (const notifyItem of notiListOfBuidling) {
      eachRowDataKey.push(notifyItem.DataKey);
    }
    this.setState({ bLoadKey: false });
    await this.setState({ eachRowDataKey });
    this.setState({ bLoadKey: true });
    // console.log(typeEachRow);
  }

  async detectEachRowtype() {
    // this.setState({bPageLoaded:false});
    let rowLen = document.getElementById("tableNotificationList").rows.length;
    // console.log(rowLen);
    if (rowLen < 2) return toast.error("No notification configured");
    let bdDevs = [...this.state.bdDevInBd];
    let typeEachRow = [];
    let eachRowDevType_int = [];
    let eachRowDataKey = [];
    // console.log(bdDevs);
    for (let i = 0; i < rowLen - 1; i++) {
      let bdDev_id = document.getElementById(`selBdDevRow${i}`).value;
      // eslint-disable-next-line
      let currentDev = bdDevs.find((c) => c._id == bdDev_id);
      if (!currentDev) return toast.error("No Matching Device");
      // console.log(currentDev.type);
      eachRowDevType_int.push(currentDev.type);
      
      let targetEnum = sensorKeyFn.getKeyListByType_V2(this.state.newSensorParasInUse , currentDev.type);
      if(!targetEnum[0]) targetEnum = sensorKeyFn.getKeyListByType(currentDev.type);
      typeEachRow.push(targetEnum);
    }

    // console.log(eachRowDevType_int);
    await this.setState({ typeEachRow });
    await this.setState({ eachRowDevType_int });

    for (let i = 0; i < rowLen - 1; i++) {
      let dataKey = document.getElementById(`selKeyRow${i}`).value;
      // console.log(dataKey);
      eachRowDataKey.push(dataKey);
    }

    // console.log(eachRowDataKey);
    this.setState({ bLoadKey: false });
    await this.setState({ eachRowDataKey });
    this.setState({ bLoadKey: true });
    // this.setState({bPageLoaded:true});
  }

  /** Select Building */
  async handleBdSel() {
    await this.setState({ bPageLoaded: false });
    let bdSel = document.getElementById("bdSel").value;
    window.location = `/notification/list/${bdSel}`;
    await this.setState({ selectBdID: bdSel });
    await this.detectEachRowtype_1stLoad();
    await this.setState({ bPageLoaded: true });
  }

  async refreshPage() {
    await this.detectEachRowtype_1stLoad();
  }

  getCurrentUserId() {
    return auth.getCurrentUser()._id;
  }

  /** select device  */
  async selBdDev(ind) {
    // console.log(`row no ${ind}`);

    await this.detectEachRowtype();
    // need to set eachRowDataKey in case it got changes, coz Data Key will change by Dev sel
    let previousDatakey = this.state.eachRowDataKey[ind];
    let currentDevType = this.state.eachRowDevType_int[ind];
    // console.log(previousDatakey);
    // console.log(currentDevType);
    /**write logic to detect if prev Data type is not iin current devtype, use default unit */
    let currentKeyList = notifyFb.getCurrentKey(currentDevType);
    // console.log(currentKeyList);
    let bMatching = false;
    for (const key in currentKeyList) {
      if (currentKeyList.hasOwnProperty(key)) {
        const element = currentKeyList[key];
        if (element.key === previousDatakey) {
          // console.log(element);
          bMatching = true;
        }
      }
    }
    if (bMatching) return;
    let newDataKey = notifyFb.getDefaultKey(currentDevType);
    // console.log(newDataKey);
    let eachRowDataKey = [...this.state.eachRowDataKey];
    eachRowDataKey[ind] = newDataKey;
    await this.setState({ eachRowDataKey });
    // console.log(this.state.eachRowDevType_int);
    // console.log(this.state.eachRowDataKey);
  }

  async handleSelKey(ind) {
    // console.log(`row no ${ind}`);

    await this.detectEachRowtype();
    // console.log(this.state.eachRowDevType_int);
    // console.log(this.state.eachRowDataKey);
  }

  currentRowKeys(ind) {
    if (!this.state.typeEachRow[ind]) return [];
    // console.log(this.state.typeEachRow[ind]);
    return this.state.typeEachRow[ind];
  }

  turnIntoPrivacyLabel(userID) {
    // eslint-disable-next-line
    return userID == 0 ? 0 : -1;
  }

  getTypeByDevId(devId) {
    let bdDevs = [...this.state.bdDevInBd];
    // eslint-disable-next-line
    let found = bdDevs.find((c) => c._id == devId);
    if (found && found.type) return found.type;
    return null;
  }

  convertToDbCompatible(oriRows) {
    let ind = 0;
    let mdfRowForDb = [];
    // console.log(this.state.bdDevInBd);
    for (const row of oriRows) {
      let mdfRow = { ...row };
      // console.log(mdfRow);
      // eslint-disable-next-line
      if (row.userID != 0) mdfRow.userID = auth.getCurrentUser()._id;
      mdfRow.StartUnix = timeFn.cHH_mmToUnix(row.StartUnix);
      mdfRow.EndUnix = timeFn.cHH_mmToUnix(row.EndUnix);
      mdfRow.type = this.getTypeByDevId(row.bdDev_id);
      // console.log(mdfRow.type);
      // mdfRow.type = this.state.eachRowDevType_int[ind];
      let list = [...this.state.typeEachRow[ind]];
      let selKey = list.find((c) => c._id === row.DataKey);
      // console.log(selKey);
      if (selKey && selKey.name) {
        mdfRow.name = selKey.name;
      } else {
        mdfRow.name = "";
      }
      mdfRow.AlarmSetpoint = row.UserSetpoint;
      // mdfRow.AlarmSetpoint = notifyFb.getConversionFormula(
      //   mdfRow.type,
      //   row.DataKey,
      //   row.DataUnit,
      //   row.UserSetpoint
      // );
      // console.log(mdfRow);
      mdfRowForDb.push(mdfRow);
      ind++;
    }
    return mdfRowForDb;
  }

  getBdDevName(devID) {
    let bdInBuilding = [...this.state.bdDevInBd];
    // eslint-disable-next-line
    let found = bdInBuilding.find((c) => c._id == devID);
    if (found && found.name) return found.name;
    return null;
  }

  async insertCopyItemToDb() {
    let newCopy = tableFn.getNewCopyRow(
      "tableNotificationList",
      this.tableColumn
    );
    // console.log(newCopy);
    /**convert each data to DB compatible*/
    let dbCompatible = this.convertToDbCompatible(newCopy);
    // console.log(dbCompatible);
    /**insert each row of new copy into DB*/
    // let logAllSuccess = true;
    for (const item of dbCompatible) {
      // let result = null;
      let result = await notifyFb.reg(item);
      if (!result) {
        let name = this.getBdDevName(item.bdDev_id);
        toast.error(`${name}, ${item.name} notification insert failed`);
        // logAllSuccess = false;
      }
    }
    // if (logAllSuccess) console.log("All new notification insert succesful");
  }

  async handleSave() {
    // console.log("Save Clicked");

    /**  --------------convert raw DB to table display value for comparison purpose---------- */
    let notifyListForomDB = await this.getNotiListFromDB_FilterUser();
    // console.log(notifyListForomDB);

    /**Get deleted item */
    let deletedItems = tableFn.getDeletedRow(
      notifyListForomDB,
      "tableNotificationList",
      this.tableColumn
    );
    // console.log(deletedItems);
    for (const item of deletedItems) {
      let result = await notifyFb.del(item);
      // console.log(result);
      if (!result) {
        // console.log("Delete failed");
        toast.error("Failed to Delete!");
        continue;
      }
      // console.log("Delete Succesful");
      toast.dark("Deleted Successfully!");
    }

    // need to convert db table to table form
    /**Get Edited Item */
    let manipulateArr = [];
    let newCopyArr = [];
    for (const item of notifyListForomDB) {
      let obj = { ...item };
      obj.userID = this.turnIntoPrivacyLabel(item.userID);
      obj.StartUnix = timeFn.getUnixTime(item.StartUnix);
      obj.EndUnix = timeFn.getUnixTime(item.EndUnix);
      if (obj._id > 0) {
        manipulateArr.push(obj);
      } else {
        newCopyArr.push(obj);
      }
    }
    let modifiedRows = tableFn.getEditedRow(
      manipulateArr,
      "tableNotificationList",
      this.tableColumn
    );
    // console.log(modifiedRows);

    if (modifiedRows[0]) {
      /* -----------------------Convert table value into DB form, send to Backend-----------------  */
      let ind = 0;
      let mdfRowForDb = [];
      for (const row of modifiedRows) {
        let mdfRow = { ...row };
        // console.log(mdfRow);
        // eslint-disable-next-line
        if (row.userID != 0) mdfRow.userID = auth.getCurrentUser()._id;
        mdfRow.StartUnix = timeFn.cHH_mmToUnix(row.StartUnix);
        mdfRow.EndUnix = timeFn.cHH_mmToUnix(row.EndUnix);
        mdfRow.type = this.getTypeByDevId(row.bdDev_id);
        // mdfRow.type = this.state.eachRowDevType_int[ind];
        let list = [...this.state.typeEachRow[ind]];
        let selKey = list.find((c) => c._id === row.DataKey);
        // console.log(selKey);
        if (selKey && selKey.name) {
          mdfRow.name = selKey.name;
        } else {
          mdfRow.name = "";
        }
        mdfRow.AlarmSetpoint = row.UserSetpoint;
        // mdfRow.AlarmSetpoint = notifyFb.getConversionFormula(
        //   mdfRow.type,
        //   row.DataKey,
        //   row.DataUnit,
        //   row.UserSetpoint
        // );
        // console.log(mdfRow);
        mdfRowForDb.push(mdfRow);
        ind++;
      }

      for (const row of mdfRowForDb) {
        // mysql update is here
        let result = await notifyFb.update(row);
        if (result) {
          // console.log("Update succesful");
          toast.dark("Updated Succesfully!");
        } else {
          // console.log("Update failed");
          toast.error("Update Failed!");
        }
      }
    }

    /**Handle new add item */
    // console.log(this.state.eachRowDevType_int);
    await this.insertCopyItemToDb();

    this.refreshPage();
    this.setState({ bCopyMode: false });
    this.editModeStatus();

    this.cancelFilter();

    await this.setState({ bLoadDevice: false }); // need to add this
    // await this.setState({bLoadDevice:true});     // if add this line will cause all row below become  chiller plant room Kw
  }

  handleAdd = () => {
    this.props.history.push(`/notification/regNotify/${this.state.selectBdID}`);
  };

  handleEdit = async () => {
    await this.setState({ bCopyMode: !this.state.bCopyMode });
    // console.log(this.state.bCopyMode);

    // cssFn.cssShowStatus(this.state.bCopyMode, "editBtn", "copyMode");

    this.editModeStatus();
  };

  editModeStatus() {
    cssFn.cssShowStatus(this.state.bCopyMode, "editBtn", "copyMode");
    let display = this.state.bCopyMode ? "" : "none";
    let allElement = document.getElementsByClassName("editClass");
    for (const ele of allElement) {
      ele.style.display = display;
    }
  }

  deleteElement = async (ind) => {
    // console.log(`Delete no ${ind}`);
    let objsFromTable = tableFn.getInfoFromTable(
      "tableNotificationList",
      this.tableColumn
    );
    if (!objsFromTable[ind]) return toast.error("Selected object not exist");
    let tableItemsDbForm = this.convertToDbCompatible(objsFromTable);

    tableItemsDbForm.splice(ind, 1); // remove sekect item from list
    await this.setState({ notiListOfBuidling: tableItemsDbForm });

    /**Update Datakey */
    let eachRowDataKey = [...this.state.eachRowDataKey];
    eachRowDataKey.splice(ind, 1);
    await this.setState({ eachRowDataKey });
    // console.log(eachRowDataKey);

    /**insert dev type */
    let eachRowDevType_int = [...this.state.eachRowDevType_int];
    eachRowDevType_int.splice(ind, 1);
    await this.setState({ eachRowDevType_int });
    // console.log(eachRowDevType_int);

    /**insert Target list */
    let typeEachRow = [...this.state.typeEachRow];
    typeEachRow.splice(ind, 1);
    await this.setState({ typeEachRow });
    // console.log(typeEachRow);

    await this.setState({ bLoadDevice: false });
    await this.setState({ bLoadDevice: true });

    this.editModeStatus();
  };

  copyElement = async (ind) => {
    // console.log(`copy ind: ${ind}`);
    // console.log(this.state.notiListOfBuidling);
    // let notiListOfBuidling = [...this.state.notiListOfBuidling];
    // console.log(notiListOfBuidling[0]);
    // console.log(ind);
    let objsFromTable = tableFn.getInfoFromTable(
      "tableNotificationList",
      this.tableColumn
    );
    // console.log(objsFromTable);
    if (!objsFromTable[ind]) return toast.error("Select object not exist");
    // console.log(objsFromTable[ind]);
    let tableItemsDbForm = this.convertToDbCompatible(objsFromTable);
    // console.log(tableItemsDbForm);
    // console.log(ind);
    let newObj = { ...tableItemsDbForm[ind] };
    // console.log(newObj);
    let copyIndex = this.state.copyIndex;
    newObj._id = --copyIndex;
    // console.log(newObj._id);
    // console.log(copyIndex);
    this.setState({ copyIndex });

    tableItemsDbForm.splice(ind + 1, 0, newObj);
    await this.setState({ notiListOfBuidling: tableItemsDbForm });

    /**Insert Datakey */
    let eachRowDataKey = [...this.state.eachRowDataKey];
    eachRowDataKey.splice(ind + 1, 0, newObj.DataKey);
    await this.setState({ eachRowDataKey });
    // console.log(eachRowDataKey);

    /**insert dev type */
    let eachRowDevType_int = [...this.state.eachRowDevType_int];
    eachRowDevType_int.splice(ind + 1, 0, newObj.type);
    await this.setState({ eachRowDevType_int });
    // console.log(eachRowDevType_int);

    /**insert Target list */
    let typeEachRow = [...this.state.typeEachRow];
    
    
    let targetEnum = sensorKeyFn.getKeyListByType_V2(this.state.newSensorParasInUse , newObj.type);
    if(!targetEnum[0]) targetEnum = sensorKeyFn.getKeyListByType(newObj.type);
    // typeEachRow.push(targetEnum);

    typeEachRow.splice(ind + 1, 0, targetEnum);
    await this.setState({ typeEachRow });
    // console.log(typeEachRow);

    /**Update Display index */
    // ind
    // console.log(ind);
    let indexDisplay = [...this.state.indexDisplay];
    // console.log(indexDisplay[ind]);
    indexDisplay.splice(ind, 0, indexDisplay[ind]);

    for (let i = ind + 1; i < indexDisplay.length; i++) {
      indexDisplay[i]++;
    }
    this.setState({ indexDisplay });

    await this.setState({ bLoadDevice: false });
    // console.log("false");
    await this.setState({ bLoadDevice: true });
    // console.log("true");

    // console.log(this.state.notiListOfBuidling);
    // console.log(this.state.eachRowDevType_int);
    // console.log(this.state.typeEachRow);
    // console.log(this.state.eachRowDataKey);

    this.editModeStatus();
  };

  getActiveStateInInt(bCheckedStatus) {
    return bCheckedStatus ? 1 : 0;
  }

  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[3].innerHTML,
      bdDev_id: document.getElementById(`selBdDevRow${ind - 1}`).value,
      DataKey: document.getElementById(`selKeyRow${ind - 1}`).value,
      AlarmType: document.getElementById(`selAlmTyRow${ind - 1}`).value,
      UserSetpoint: row.cells[8].children[0].value,
      // DataUnit: document.getElementById(`selUnit${ind - 1}`).value,
      StartUnix: row.cells[10].children[0].value,
      EndUnix: row.cells[11].children[0].value,
      Sensitivity: row.cells[12].children[0].value,
      AlarmRepeat: document.getElementById(`selRecurring${ind - 1}`).value,
      Active: this.getActiveStateInInt(row.cells[14].children[0].checked),
      userID: document.getElementById(`selPrivacy${ind - 1}`).value,
    };
    // console.log(column);
    return column;
  };

  handleSearch = async () => {
    // console.log("search clicked");
    await this.setState({ bFilterMode: !this.state.bFilterMode });

    this.editFilterStatus();
  };

  editFilterStatus() {
    cssFn.cssShowStatus(this.state.bFilterMode, "searchBtn", "copyMode");
    let display = this.state.bFilterMode ? "inline-block" : "none";
    let allElement = document.getElementsByClassName("filterClass");
    for (const ele of allElement) {
      ele.style.display = display;
    }
  }

  /**--------------Filter---------------- */
  filterSensorSel = async () => {
    try {
      // console.log("Filter Sensor");
      await this.genFilterBdDev();
      this.getFilterPara();
      let filterCurrent = { ...this.state.filterCurrent };
      filterCurrent.sensor = document.getElementById("fil_Sensor").value;
      this.setState({ filterCurrent });
    } catch (error) {
      toast.error(error.message);
      // console.log(error.message);
    }
  };

  filterBdDevSel = () => {
    // console.log("Filter Bd Dev");
    this.getFilterPara();
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.bdDev = document.getElementById("fil_bdDev").value;
    this.setState({ filterCurrent });
  };

  filterDataKeySel = () => {
    // console.log("Filter Data Key");
    this.getFilterPara();
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.dataKey = document.getElementById("fil_dataKey").value;
    this.setState({ filterCurrent });
  };

  filteralArmTypeSel = () => {
    // console.log("Filter Alarm Type");
    this.getFilterPara();
    let filterCurrent = { ...this.state.filterCurrent };
    filterCurrent.alarmType = document.getElementById("fil_alarmType").value;
    this.setState({ filterCurrent });
  };

  genFilterBdDev = async () => {
    /**get building list from table */

    // let objsFromTable = tableFn.getInfoFromTable("tableNotificationList", this.tableColumn);
    // if(!objsFromTable[ind]) return console.log("Select object not exist");
    // let tableItemsDbForm = this.convertToDbCompatible(objsFromTable);

    let bdDevInBd = [...this.state.bdDevInBd];
    /**Add filter option here, if sensor type selected, filter bd Dev */
    // console.log(bdDevInBd);
    let filSensor = document.getElementById("fil_Sensor").value;
    // console.log(filSensor);
    let filBdDev = [];
    if (filSensor > 0) {
      // eslint-disable-next-line
      filBdDev = bdDevInBd.filter((c) => c.type == filSensor);
    } else {
      filBdDev = [...bdDevInBd];
    }
    let defaultSel = { _id: 0, name: "All" };
    filBdDev.splice(0, 0, defaultSel);
    // console.log(filBdDev);

    /**Gen DataKey */
    let filterKey = [];
    if (filSensor <= 0) {
      filterKey = notifyFb.getAllKeylist("All", this.state.newSensorParasInUse);
    } else {
      
      // let filterKey = sensorKeyFn.getKeyListByType_V2(this.state.newSensorParasInUse , parseInt(filSensor));
      // if(!filterKey[0]) filterKey = sensorKeyFn.getKeyListByType(parseInt(filSensor));
      // filterKey.unshift({_id:0, name:'All'});
      filterKey = notifyFb.genCurrentKeyList(parseInt(filSensor), "All", this.state.newSensorParasInUse);
    }
    // console.log(filterKey);

    /**Gen Alarm Type */
    let alarmType = notifyFb.genAlarmTypeEnmList("All");

    let filterEnm = { ...this.state.filterEnm };
    filterEnm.bdDev = filBdDev;
    filterEnm.dataKey = filterKey;
    filterEnm.alarmType = alarmType;
    await this.setState({ filterEnm });

    // this.getFilterPara();
  };

  // filterFunction
  getFilterPara = () => {
    /**Get filter parameter */
    let filter = {};
    filter.sensor = parseInt(document.getElementById("fil_Sensor").value);
    filter.bdDev = parseInt(document.getElementById("fil_bdDev").value);
    filter.dataKey = document.getElementById("fil_dataKey").value;
    filter.alarmType = document.getElementById("fil_alarmType").value;
    // console.log(filter);

    /**filter the notification list via filter parameter */
    let notiListOfBuidling = [...this.state.notiListOfBuidling];
    // console.log(notiListOfBuidling);

    let filteredList = notiListOfBuidling.filter(
      (c) =>
        // eslint-disable-next-line
        (c.AlarmType === filter.alarmType || filter.alarmType == 0) &&
        (c.bdDev_id === filter.bdDev || filter.bdDev === 0) &&
        (c.type === filter.sensor || filter.sensor === 0) &&
        // eslint-disable-next-line
        (c.DataKey == filter.dataKey || filter.dataKey == 0)
    );
    // console.log(filteredList);

    /**Hide the row not in list */
    let indexDisplay = [];
    let matchingCnt = 0;
    let cnt = 0;
    for (const item of notiListOfBuidling) {
      let matching = filteredList.find((c) => c._id === item._id);
      if (matching) {
        ++matchingCnt;
        // console.log(`rowNo${cnt}`);
        cssFn.cssShowStatus(false, `rowNo${cnt}`, "hide");
      } else {
        cssFn.cssShowStatus(true, `rowNo${cnt}`, "hide");
      }
      indexDisplay[cnt] = matchingCnt;
      ++cnt;
    }
    // console.log(indexDisplay);
    this.setState({ indexDisplay });
  };

  getDisplayIndex = () => {
    try {
      /**Get row len */
      if (!document.getElementById("tableNotificationList")) return [];
      let rowLen = document.getElementById("tableNotificationList").rows.length;
      let idxDisplay = [];
      let cnt = 0;
      for (let i = 0; i < rowLen - 1; i++) {
        // const element = array[i];
        let display = document.getElementById(`rowNo${i}`).className;
        let rel = display.search("hide");
        // console.log(rel);
        if (rel < 0) {
          //not found
          idxDisplay[i] = cnt;
        } else {
          idxDisplay[i] = ++cnt;
        }
      }
      // console.log(idxDisplay);
      return idxDisplay;
    } catch (error) {
      toast.error(error.message);
    }
  };

  cancelFilter = () => {
    document.getElementById("fil_Sensor").value = "0";
    document.getElementById("fil_bdDev").value = "0";
    document.getElementById("fil_dataKey").value = "0";
    document.getElementById("fil_alarmType").value = "0";
    this.getFilterPara();
  };
  /**==============Filter end=============== */

  

  render() {
    this.getDisplayIndex();
    let idxDisplay = this.state.indexDisplay;
    let rowType = [...this.state.typeEachRow];
    // console.log(rowType);
    let inCopyMode = this.state.bCopyMode;
    // let inFilterMode = this.state.bFilterMode;

    // console.log(this.state.notiListOfBuidling);
    return (
      <React.Fragment>
        <h1>Notification</h1>
        {this.state.bPageLoaded1stTime && (
          <div style={{display:"none"}}>
            {tableFn.tableDropDownKeySel(
              `bdSel`,
              this.state.filteredBdEnum,
              this.state.selectBdID,
              "select-dd",
              () => this.handleBdSel()
            )}
          </div>
        )}
        {/* <h3>Trigger Once a day</h3>             */}

        {this.state.selectBdID > 0 && (
          <Icon className="fa fa-plus deactive" onClick={this.handleAdd}></Icon>
        )}
        <Icon
          className="fa fa-floppy-o deactive"
          onClick={() => this.handleSave()}
        />
        <Icon
          id="editBtn"
          className="fa fa-pencil-square-o"
          onClick={this.handleEdit}
        ></Icon>
        <div>
          <Icon
            id="searchBtn"
            className="fa fa-filter"
            onClick={this.handleSearch}
          ></Icon>
          <Icon
            className="fa fa-times filterClass"
            style={{ display: "none" }}
            onClick={() => this.cancelFilter()}
          />
          <div className="filterClass" style={{ display: "none" }}>
            <label>Sensor</label>
            {tableFn.tableDropDownKeySel(
              `fil_Sensor`,
              this.state.filterEnm.sensor,
              this.state.filterCurrent.sensor,
              "select-dd",
              () => this.filterSensorSel()
            )}
          </div>
          <div className="filterClass" style={{ display: "none" }}>
            <label>Device</label>
            {tableFn.tableDropDownKeySel(
              `fil_bdDev`,
              this.state.filterEnm.bdDev,
              this.state.filterCurrent.bdDev,
              // 0,
              "select-dd",
              () => this.filterBdDevSel()
            )}
          </div>
          <div className="filterClass" style={{ display: "none" }}>
            <label>Target</label>
            {tableFn.tableDropDownKeySel(
              `fil_dataKey`,
              this.state.filterEnm.dataKey,
              this.state.filterCurrent.dataKey,
              // 0,
              "select-dd",
              () => this.filterDataKeySel()
            )}
          </div>
          <div className="filterClass" style={{ display: "none" }}>
            <label>Type</label>
            {tableFn.tableDropDownKeySel(
              `fil_alarmType`,
              this.state.filterEnm.alarmType,
              this.state.filterCurrent.alarmType,
              // 0,
              "select-dd",
              () => this.filteralArmTypeSel()
            )}
          </div>
        </div>
        <div className="card">
          <div className="card-body" id="flip-scroll">
            <table
              className="cusTable"
              id="tableNotificationList"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th
                    className="editClass"
                    style={{ display: "none" }}
                    id="edit-cb"
                  ></th>
                  <th
                    className="editClass"
                    style={{ display: "none" }}
                    id="edit-cb"
                  ></th>
                  {/* <th onClick={()=>this.handleDelete()}>
                            {(deleteButtontVisible) && <i style={{color:"ButtonFace", paddingLeft:"8px"}} className="fa fa-trash-o deactive checkbox"></i>}
                        </th>
                        <th onClick={()=>this.handleDelete()}>
                            {(deleteButtontVisible) && <i style={{color:"ButtonFace", paddingLeft:"8px"}} className="fa fa-trash-o deactive checkbox"></i>}
                        </th> */}
                  <th>#</th>
                  <th style={{ display: "none" }}>ID</th>
                  <th style={{ display: "none" }}>Sensor</th>
                  <th>Device</th>
                  <th>Target</th>
                  <th>Type</th>
                  <th>Setpoint</th>
                  <th style={{ display: "true" }}>Unit</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Sensitivity</th>
                  <th>Recurring</th>
                  <th>Monitoring</th>
                  <th style={{ display: "none" }}>Privacy</th>
                  <th>Last Amend</th>
                  {/* <th style={{display:"none"}}>type</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.bPageLoaded &&
                  this.state.notiListOfBuidling.map((u, ind) => (
                    <tr key={u._id} id={`rowNo${ind}`}>
                      <td className="editClass" style={{ display: "none" }}>
                        {inCopyMode && (
                          <i
                            style={{ color: "white", paddingLeft: "7px" }}
                            className="fa fa-trash-o checkbox"
                            onClick={() => this.deleteElement(ind)}
                          ></i>
                        )}
                      </td>
                      <td className="editClass" style={{ display: "none" }}>
                        {inCopyMode && (
                          <i
                            style={{ color: "white", paddingLeft: "7px" }}
                            className="fa fa-copy checkbox"
                            onClick={() => this.copyElement(ind)}
                          ></i>
                        )}
                      </td>
                      {/* <td>{ind+1}</td> */}
                      {<td>{idxDisplay[ind]}</td>}
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td style={{ display: "none" }}>
                        {enm.getType_en(this.state.typeEnm, u.type)}
                      </td>
                      {/* <td>{u.bdDev_id}</td> */}
                      {this.state.bLoadDevice && (
                        <td>
                          {tableFn.tableDropDownKeySel(
                            `selBdDevRow${ind}`,
                            this.state.bdDevsEnum,
                            u.bdDev_id,
                            "select-custom",
                            () => this.selBdDev(ind)
                          )}
                        </td>
                      )}
                      {/* <td style={{display:"none"}}>{u.type}</td>   */}
                      {this.state.bLoadDevice && (
                        <td>
                          {tableFn.tableDropDownKeySel(
                            `selKeyRow${ind}`,
                            rowType[ind] ? rowType[ind] : [],
                            u.DataKey,
                            "select-custom",
                            () => this.handleSelKey(ind)
                          )}
                        </td>
                      )}
                      {/* <td>{u.DataKey}</td> */}
                      {this.state.bLoadDevice && (
                        <td>
                          {tableFn.tableDropDownKeySel(
                            `selAlmTyRow${ind}`,
                            notifyFb.notifyConditionList,
                            u.AlarmType,
                            "select-custom"
                            // ()=>this.selBdDev(ind)
                          )}
                        </td>
                      )}
                      <td>
                        <input type="text" defaultValue={u.UserSetpoint} />
                      </td>
                      <td>
                        {sensorFn.getParaByKey(this.state.newSensorParasInUse, u.type, u.DataKey, 'dataUnit')}
                      </td>
                      <td>
                        <input
                          type="time"
                          defaultValue={timeFn.getUnixTime(u.StartUnix)}
                        ></input>
                      </td>
                      {/* <td>{u.StartUnix}</td> */}
                      <td>
                        <input
                          type="time"
                          defaultValue={timeFn.getUnixTime(u.EndUnix)}
                        ></input>
                      </td>
                      {/* <td>{u.EndUnix}</td> */}
                      <td>
                        <input type="text" defaultValue={u.Sensitivity} />
                      </td>
                      {this.state.bLoadDevice && (
                        <td>
                          {tableFn.tableDropDownKeySel(
                            `selRecurring${ind}`,
                            notifyFb.notifyRecurringList,
                            u.AlarmRepeat,
                            "select-custom"
                            // ()=>this.selBdDev(ind)
                          )}
                        </td>
                      )}

                      <td>
                        <input
                          className="checkbox"
                          type="checkbox"
                          defaultChecked={u.Active}
                        ></input>
                      </td>

                      {this.state.bLoadDevice && (
                        <td style={{ display: "none" }}>
                          {tableFn.tableDropDownKeySel(
                            `selPrivacy${ind}`,
                            notifyFb.notifyPrivacyList,
                            this.turnIntoPrivacyLabel(u.userID),
                            "select-custom"
                            // ()=>this.selBdDev(ind)
                          )}
                        </td>
                      )}
                      {/* <td>{u.userID}</td> */}
                      <td>{u.userAmmend}</td>
                      {/* <td style={{display:"none"}}>{u.type}</td>   */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default NotificationPage;
