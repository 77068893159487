import React, { Component } from 'react';


class ToggleButton extends Component {
    state = { 
        status:0, // 0 = off, 1 = on, 2 = processing
    }

    async componentDidMount() {
        await this.updateStatus();
        // console.log("ToggleButtonOn");
    }

    async updateStatus(){
        if(this.props.status){
            let status = this.props.status;
            await this.setState({status});
        }
    }

    render() { 
        return ( <>        
            {this.state.status === 0 &&
            <i className="fa fa-toggle-off" style={{
                color:'grey',
                fontSize:'25px'
                }} ></i>}
            {this.state.status === 1 &&
                <i className="fa fa-toggle-on" style={{
                    color:'green',
                    fontSize:'25px'
                    }} ></i>}
            {this.state.status === 2 &&
                <i className="fa fa-toggle-on" style={{
                    color:'yellow',
                    fontSize:'25px'
                    }} ></i>}
        </> );
    }
}
 
export default ToggleButton;
