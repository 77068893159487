import devMgntSer from '../../../service/sensor/devMgnt_v2';

async function regBdDev_V2(body){
    try {
        // console.log(body.ts);
        let result = await devMgntSer.regBdDev_V2(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return false;
        if(!result.data) return false
        if(result.data !== 'OK') return result.data;
        return result.data;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}
async function getInvolveBdDev_2(body){
    try {
        
        // console.log(body.ts);
        let result = await devMgntSer.getInvolveBdDev_2(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;   

    } catch (error) {
        console.log(error.message);
        return
    }
}
async function getOwnDev_2(body){
    try {
        let result = await devMgntSer.getOwnDev_2(body);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;   

    } catch (error) {
        console.log(error.message);
        return
    }
}

const expModule = {  
    getOwnDev_2,
    regBdDev_V2,
    getInvolveBdDev_2,
};
export default expModule;