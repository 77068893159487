import http from "./httpService";

async function getAll() {
  let url = process.env.REACT_APP_PUBLIC_URL + "/buildingdevices/get";
  return await http.get(url);
}

async function getById(id) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/buildingdevices/getbyid/${id}`;
  return await http.get(url);
}

async function getBy_idList(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/buildingdevices/getbylist";
  return await http.post(url, obj);
}

async function update(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/buildingdevices/update";
  //   console.log(obj);
  const body = {
    _id: obj._id,
    type: obj.type,
    devID: obj.devID,
    buildingID: obj.buildingID,
    location: obj.location,
    name: obj.name,
    remarks: obj.remarks,
    active: obj.active,
  };
  // console.log("did enter");
  return await http.post(url, body);
}

async function add(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/buildingdevices/register";
  //   console.log(obj);
  const body = {
    type: obj.type,
    devID: obj.devID,
    name: obj.name,
    location: obj.location,
    buildingID: obj.buildingID,
    remarks: obj.remarks,
    active: obj.active,
  };
  // console.log("did enter");
  return await http.post(url, body);
}

async function del(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/buildingdevices/del";
  const body = {
    _id: obj._id,
    type: obj.type,
    devID: obj.devID,
    // owner: building.owner,
    // building: building.building,
    // country: building.country,
    // state: building.state,
    // area: building.area,
    // postcode: building.postcode,
  };
  return await http.post(url, body);
}

const expModule = {
  del,
  add,
  getAll,
  update,
  getById,
  getBy_idList,
};
export default expModule;

// export default{
//   update: updateUser,
//   delete: deleteUser,
// }
