import http from "../httpService";

async function getAll(){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/teleid/getall`;
    return await http.get(url);    
}

async function add(obj) {
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/teleid/register`;
    //   console.log(obj);
    const body = {   
        _id: obj._id,
        name: obj.name,
        userID: obj.userID,
        bd_id: obj.bd_id,
        telegramID: obj.telegramID,
      };
      // console.log("did enter");
    return await http.post(url, body); 
  }

async function update(obj){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/notify/teleid/update`;
    const body = {   
        _id: obj._id,
        name: obj.name,
        userID: obj.userID,
        bd_id: obj.bd_id,
        telegramID: obj.telegramID,
      };
    return await http.post(url, body);    
}


async function del(obj) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/notify/teleid/del";
    const body = {
        _id:obj._id
      };
    return await http.post(url, body);    
  }

const expModule = {  
    del,
    add,
    update,
    getAll,
};
export default expModule;