// import jwtDecode from "jwt-decode";
// import { toast } from "react-toastify";
import http from "./httpService";

let url = process.env.REACT_APP_PUBLIC_URL+"/user/getall";
let regUrl = process.env.REACT_APP_PUBLIC_URL + "/user/register";
let statusUrl = process.env.REACT_APP_PUBLIC_URL + "/user/updateactive";

export async function getAllUser() {
    try {
        return await http.post(url, {});      
    } catch (error) {
        // console.log(error.message);
        // toast('Get User Info Error');
        return null
    }
}

export async function getUser_byIdList(idList) {
    try {
        const body = {
            idList
        }
        return await http.post(process.env.REACT_APP_PUBLIC_URL+"/user/getuser_byIdList", body);      
    } catch (error) {
        // console.log(error.message);
        // toast('Get User Info Error');
        return null
    }
}

export async function regUser_V2(user) {
    //   console.log(user);
    const body = {
      // username: user.username,
      username: user.username,
      password: user.password,
    };
  
    return await http.post(regUrl, body);
}

export async function updateActiveStatus(user) {
    //   console.log(user);
    const body = {
      // username: user.username,
      _id: user._id,
      active: user.active,
    };
  
    return await http.post(statusUrl, body);
}

const expModule = { 
    getAllUser,
    getUser_byIdList,
    regUser_V2,
    updateActiveStatus
};

export default expModule;