import React, { Component } from "react";
import monlistFn from "../../component/utilities/monList/monList";
import tableFn from "../utilities/tableFunction";
import Icon from "../common/icon";
import {
  getDeletedArrItem,
  sortByName,
  swapArray,
} from "../utilities/ArrayFn/arrayFn";
import buildings from "../../service/setting_Buildings";
import { ToastContainer, toast } from "react-toastify";

class Add_MonList extends Component {
  state = {
    dbMonList: [],
    tableMonList: [],
    allBuildings: [],
    allBdEnum: [],
    selBuildingID: 0,
  };

  async componentDidMount() {
    try {
      await this._getAllBuildings();
      this.getBuildingEnm();

      /**Check where _selBuildingId been pass in */
      let sselBuildingID = localStorage.getItem("buildingId");
      await this.setState({ selBuildingID: parseInt(sselBuildingID) });
      document.getElementById("buildingSel").value = parseInt(sselBuildingID);

      let { _data: inheritData } = this.props.location;
      // console.log(inheritData);
      if (inheritData && inheritData._selBuildingID) {
        // console.log(inheritData);
        document.getElementById("buildingSel").value = parseInt(
          inheritData._selBuildingID
        );
        this.setState({ selBuildingID: parseInt(inheritData._selBuildingID) });
        // console.log(inheritData._selBuildingID);
      }

      await this.loadDbMonlistToTable();

      // await this.setBuildingSelected();
    } catch (error) {
      console.log(error.message);
    }
  }

  _getAllBuildings = async () => {
    try {
      let { data: allBuildings } = await buildings.getAll();
      await this.setState({ allBuildings });
      // console.log(this.state.allBuildings);
    } catch (error) {
      console.log("Get Building DB Error: ", error.message);
    }
  };

  getBuildingEnm = () => {
    let allBuildings = [...this.state.allBuildings];
    let enmItem = {};
    let allBdEnum = [];
    for (const singleBd of allBuildings) {
      enmItem = {
        name: singleBd.building,
        _id: singleBd._id,
      };
      allBdEnum.push(enmItem);
    }
    allBdEnum.sort((a, b) => sortByName(a, b));
    this.setState({ allBdEnum });
  };

  async loadDbMonlistToTable() {
    try {
      /**load DB monlist into table */
      // console.log(this.state.selBuildingID);
      let dbMonList = await this.getMonList(this.state.selBuildingID);
      // console.log(dbMonList);
      let tableMonList = [...dbMonList];
      /**Sort by SortIndex */
      // if (Array.isArray(tableMonList))
      tableMonList.sort(function (a, b) {
        return a.SortIndex - b.SortIndex;
      });

      this.setState({ dbMonList });
      this.setState({ tableMonList });
    } catch (error) {
      console.log(error.message);
    }
  }

  getMonList = async (buildingID) => {
    try {
      let result = await monlistFn.getMonlistByBuildingID(buildingID);
      if (!result) return [];
      return result;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  /**Get Table Info */
  getTableInfo = () => {
    try {
      let tableInfo = tableFn.getInfoFromTableSkipLastN(
        "monListTable",
        this.tableColumn,
        1
      );
      // console.log(tableInfo);
      return tableInfo;
    } catch (error) {
      console.log(error.message);
    }
  };

  /**Table format */
  tableColumn = (row, ind) => {
    let column = {
      _id: row.cells[2].innerHTML,
      name: row.cells[3].children[0].value,
      //   name: row.cells[3].innerHTML,
      SortIndex: row.cells[7].innerHTML,
      buildingID: row.cells[8].innerHTML,
    };
    // console.log(column);
    return column;
  };

  /**Click Up Down */
  clickUp = (index) => {
    /**Switch index position */
    try {
      let tableMonList = this.getTableInfo();
      if (index > 0) tableMonList = swapArray(tableMonList, index, index - 1);
      /**Modify sort index */
      let idxp = 0;
      for (const monItem of tableMonList) {
        monItem.SortIndex = idxp;
        idxp++;
      }
      // console.log(tableMonList);
      this.setState({ tableMonList });
    } catch (error) {
      console.log(error.message);
    }
  };

  clickDn = (index) => {
    try {
      // console.log(`Click Dn ${index}`);
      let tableMonList = this.getTableInfo();
      // console.log(tableMonList);
      if (index + 1 < tableMonList.length)
        tableMonList = swapArray(tableMonList, index, index + 1);
      /**Modify sort index */
      let idxp = 0;
      for (const monItem of tableMonList) {
        monItem.SortIndex = idxp;
        idxp++;
      }
      this.setState({ tableMonList });
    } catch (error) {
      console.log(error.message);
    }
  };

  insertNewList = async () => {
    // console.log("Insert New List");
    try {
      let tableMonList = this.getTableInfo();
      let newListName = document.getElementById("newListName").children[0]
        .value;
      let nMin_id = 0;
      for (const listItem of tableMonList) {
        if (parseInt(listItem._id) <= nMin_id) {
          nMin_id = listItem._id;
        }
      }
      if (newListName) {
        tableMonList.push({
          buildingID: this.state.selBuildingID,
          name: newListName,
          SortIndex: tableMonList.length,
          _id: nMin_id - 1,
        });
      }
      // console.log(tableMonList);
      let idxp = 0;
      for (const monItem of tableMonList) {
        monItem.SortIndex = idxp;
        idxp++;
      }
      await this.setState({ tableMonList });
      /**Clear name */
      document.getElementById("newListName").children[0].value = "";

      await this.save();
    } catch (error) {
      console.log(error.message);
    }
  };

  delList = (ind) => {
    // console.log(`${ind} been deleted`);
    try {
      let tableMonList = this.getTableInfo();
      tableMonList.splice(ind, 1);
      // console.log(tableMonList);
      let idxp = 0;
      for (const monItem of tableMonList) {
        monItem.SortIndex = idxp;
        idxp++;
      }
      this.setState({ tableMonList });
    } catch (error) {
      console.log(error.message);
    }
  };

  save = async () => {
    // console.log("Save");
    try {
      /**Get table info */
      let tableMonList = this.getTableInfo();
      let dbMonList = [...this.state.dbMonList];

      /**Handle existing item*/
      let modifiedRow = tableFn.detectTableChangesSkipLastN(
        "monListTable",
        dbMonList,
        "_id",
        this.tableColumn,
        true,
        1
      );
      // console.log(modifiedRow);
      for (const modifyItem of modifiedRow) {
        let updateSeccess = await monlistFn.update(modifyItem);
        updateSeccess
          ? toast.dark(`${modifyItem.name} update succesful`)
          : toast.error(`${modifyItem.name} update failed`);
      }

      /**Handle new add item */
      let newAddList = tableMonList.filter((c) => c._id < 0);
      // console.log(newAddList);
      for (const newItem of newAddList) {
        let addSuccess = await monlistFn.addMonList(newItem);
        addSuccess
          ? toast.dark(`${newItem.name} add succesful`)
          : toast.error(`${newItem.name} add failed`);
      }

      /**hande delete */
      let deletedRow = getDeletedArrItem(dbMonList, tableMonList, "_id");
      // console.log(deletedRow);
      for (const delItem of deletedRow) {
        let delSuccess = await monlistFn.del(delItem);
        delSuccess
          ? toast.dark(`${delItem.name} delete succesful`)
          : toast.error(`${delItem.name} delete succesful`);
        // console.log(delSuccess);
      }

      /** load new mon list */
      await this.loadDbMonlistToTable();
    } catch (error) {
      console.log(error.message);
    }
  };

  setBuildingSelected = async () => {
    let selBuildingID = document.getElementById("buildingSel").value;
    let allBdEnum = [...this.state.allBdEnum];
    let found = allBdEnum.find((c) => c._id === parseInt(selBuildingID));
    if (found) await localStorage.setItem("selBuildingName", found.name);
    // console.log(found.name);
    await this.setState({ selBuildingID });
  };

  handleBuildingSel = async () => {
    // console.log("Sel");
    await this.setBuildingSelected();
    await this.loadDbMonlistToTable();
  };

  toT1 = async (ind) => {
    // console.log(`To T1 Page ${ind}`);
    try {
      let tableMonList = this.getTableInfo();
      // console.log(tableMonList[ind]);
      await localStorage.setItem("selBuildingID", this.state.selBuildingID);
      await localStorage.setItem("selT1Llist", tableMonList[ind]._id);
      await localStorage.setItem("selT1Name", tableMonList[ind].name);
      // console.log(tableMonList[ind].name);
      this.props.history.push({
        pathname: "/monlist/addt1",
        _data: {
          // _selBuildingId: this.state.selBuildingId,
          // _selT1Llist:tableMonList[ind]._id,
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    // console.log(this.state.selBuildingID);
    return (
      <React.Fragment>
        <div>
          <h1>Monitoring List Setting</h1>
          <div style={{ fontSize: "25px" }}>
            {tableFn.tableDropDownKeySel(
              `buildingSel`,
              this.state.allBdEnum,
              -1,
              "select-dd",
              () => this.handleBuildingSel()
            )}
          </div>
          {/* <Icon className="fa fa-plus deactive" onClick={this.getTableInfo}></Icon> */}
          <Icon className="fa fa-save deactive" onClick={this.save}></Icon>
          <div className="card">
            <div className="card-body" id="flip-scroll">
              <table className="cusTable" id="monListTable">
                <thead>
                  <tr>
                    <th scope="col" id="cb"></th>
                    <th scope="col">#</th>
                    {/* <th >_id</th> */}
                    <th style={{ display: "none" }}>_id</th>
                    <th scope="col">Name</th>
                    <th scope="col">List</th>
                    <th scope="col">Up</th>
                    <th scope="col">Dn</th>
                    <th style={{ display: "none" }}>SortIndex</th>
                    {/* <th>SortIndex</th> */}
                    <th style={{ display: "none" }}>buildingID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tableMonList.map((u, ind) => (
                    <tr key={u._id}>
                      {/* <tr key={`key_${ind}`}> */}
                      <td>
                        {/* <input type="checkbox"></input> */}
                        <Icon
                          className="fa fa-trash deactive"
                          onClick={() => this.delList(ind)}
                        ></Icon>
                      </td>
                      <td>{ind + 1}</td>
                      {/* <td >{u._id}</td> */}
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td>
                        <input type="text" defaultValue={u.name} />
                      </td>
                      <td>
                        <Icon
                          className="fa fa-list"
                          onClick={() => this.toT1(ind)}
                        ></Icon>
                        {/* <Icon className="fa fa-plus deactive" onClick={this.temp}></Icon> */}
                      </td>
                      <td>
                        <Icon
                          className="fa fa-arrow-up"
                          onClick={() => this.clickUp(ind)}
                        ></Icon>
                        {/* <Icon className="fa fa-plus deactive" onClick={this.temp}></Icon> */}
                      </td>
                      <td>
                        <Icon
                          className="fa fa-arrow-down"
                          onClick={() => this.clickDn(ind)}
                        ></Icon>
                      </td>
                      {/* <td >{u.SortIndex}</td> */}
                      <td style={{ display: "none" }}>{u.SortIndex}</td>
                      <td style={{ display: "none" }}>{u.buildingID}</td>
                      {/* 
                                <td>{u.userAmmend}</td> */}
                    </tr>
                  ))}
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td id="newListName">
                      <input type="text" placeholder="New List..." />
                    </td>
                    <td> </td>
                    <td colSpan="2">
                      <Icon
                        className="fa fa-plus deactive"
                        onClick={() => this.insertNewList()}
                      ></Icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default Add_MonList;
