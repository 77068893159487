// import key from '../utilities/constantKeyName';
import SensorFn from '../../function/sensor/sensorManagementFn';

//data line to show in default table, 
const defaultLineListPara = [
    // {type:1, name :key.rtrh_temp.name, dataKey:key.rtrh_temp.key, scaleTop:40, scaleBottom: 20, upperLimit: 37, lowerLimit: 26, dataUnit: "°C"},
    // {type:1, name :key.rtrh_humid.name,  dataKey:key.rtrh_humid.key, scaleTop:100, scaleBottom: 40, upperLimit: null, lowerLimit: null, dataUnit: "RH %"},
    // {type:2, name :key.porbe_temp.name, dataKey:key.porbe_temp.key, scaleTop:30, scaleBottom: 5, upperLimit: 20, lowerLimit: 5, dataUnit: "°C"},
    // {type:2, name :key.com_Batt.name, dataKey:key.com_Batt.key, scaleTop:5, scaleBottom: 0, upperLimit: null, lowerLimit: 3.55, dataUnit: "V"},
    // {type:3, name :key.dpm_apt.name, dataKey:key.dpm_apt.key, scaleTop:500, scaleBottom: 0, upperLimit: 800, lowerLimit: 0, dataUnit: "kW"},
    // {type:3, name :key.dpm_pft.name, dataKey:key.dpm_pft.key, scaleTop:2, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:4, name :key.press_p.name, dataKey:key.press_p.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: 0, dataUnit: "bar"},
    // {type:4, name :key.com_Batt.name, dataKey:key.com_Batt.key, scaleTop:5, scaleBottom: 0, upperLimit: null, lowerLimit: 3.55, dataUnit: "V"},
    // {type:6, name :key.ss_on.name, dataKey:key.ss_on.key, scaleTop:1, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:6, name :key.com_Batt.name, dataKey:key.com_Batt.key, scaleTop:5, scaleBottom: 0, upperLimit: null, lowerLimit: 3.55, dataUnit: "V"},
    // {type:7, name :key.vfd_actHz.name, dataKey:key.vfd_actHz.key, scaleTop:60, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_kW.name, dataKey:key.vfd_kW.key, scaleTop:50, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kW"},
    // {type:1001, name :key.usf_fr.name, dataKey:key.usf_fr.key, scaleTop:300, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "m³/h"},
    // {type:1001, name :key.usf_t1.name, dataKey:key.usf_t1.key, scaleTop:30, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "°C"},
    // {type:1002, name :key.af_fr.name, dataKey:key.af_fr.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "m³/s"},
    // {type:1002, name :key.com_Batt.name, dataKey:key.com_Batt.key, scaleTop:5, scaleBottom: 0, upperLimit: null, lowerLimit: 3.55, dataUnit: "V"},
]

const defaultKeyPara = [
    // {name :key.com_Batt.name,  dataKey:key.com_Batt.key, scaleTop:4.5, scaleBottom: 3.5, upperLimit: null, lowerLimit: 3.6, dataUnit: "V"},
    // {name :key.com_RSSI.name,  dataKey:key.com_RSSI.key, scaleTop:0, scaleBottom: -120, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {name :key.com_SNR.name,  dataKey:key.com_SNR.key, scaleTop:12, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:1, name :key.rtrh_temp.name, dataKey:key.rtrh_temp.key, scaleTop:40, scaleBottom: 20, upperLimit: 37, lowerLimit: 26, dataUnit: "°C"},
    // {type:1, name :key.rtrh_humid.name,  dataKey:key.rtrh_humid.key, scaleTop:100, scaleBottom: 40, upperLimit: null, lowerLimit: null, dataUnit: "RH %"},
    // {type:2, name :key.porbe_temp.name,  dataKey:key.porbe_temp.key, scaleTop:30, scaleBottom: 5, upperLimit: null, lowerLimit: null, dataUnit: "°C"},
    // {type:3, name :key.dpm_ca.name,  dataKey:key.dpm_ca.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "A"},
    // {type:3, name :key.dpm_cb.name,  dataKey:key.dpm_cb.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "A"},
    // {type:3, name :key.dpm_cc.name,  dataKey:key.dpm_cc.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "A"},
    // {type:3, name :key.dpm_va.name,  dataKey:key.dpm_va.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "V"},
    // {type:3, name :key.dpm_vb.name,  dataKey:key.dpm_vb.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "V"},
    // {type:3, name :key.dpm_vc.name,  dataKey:key.dpm_vc.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "V"},
    // {type:3, name :key.dpm_f.name,  dataKey:key.dpm_f.key, scaleTop:100, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:3, name :key.dpm_apa.name,  dataKey:key.dpm_apa.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kW"},
    // {type:3, name :key.dpm_apb.name,  dataKey:key.dpm_apb.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kW"},
    // {type:3, name :key.dpm_apc.name,  dataKey:key.dpm_apc.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kW"},
    // {type:3, name :key.dpm_apt.name,  dataKey:key.dpm_apt.key, scaleTop:500, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kW"},
    // {type:3, name :key.dpm_pfa.name,  dataKey:key.dpm_pfa.key, scaleTop:2, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:3, name :key.dpm_pfb.name,  dataKey:key.dpm_pfb.key, scaleTop:2, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:3, name :key.dpm_pfc.name,  dataKey:key.dpm_pfc.key, scaleTop:2, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:3, name :key.dpm_pft.name,  dataKey:key.dpm_pft.key, scaleTop:2, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:3, name :key.dpm_e.name,  dataKey:key.dpm_e.key, scaleTop:2, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kWh"},

    // {type:4, name :key.press_mA.name,  dataKey:key.press_mA.key, scaleTop:20, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "mA"},
    // {type:4, name :key.press_p.name,  dataKey:key.press_p.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "bar"},
    // {type:6, name :key.ss_on.name,  dataKey:key.ss_on.key, scaleTop:1, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:6, name :key.ss_rawAo.name,  dataKey:key.ss_rawAo.key, scaleTop:1024, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},

    // {type:7, name :key.vfd_actHz.name,  dataKey:key.vfd_actHz.key, scaleTop:50, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_kW.name,  dataKey:key.vfd_kW.key, scaleTop:60, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "kW"},
    // {type:7, name :key.vfd_Hz_lowerLimit.name,  dataKey:key.vfd_Hz_lowerLimit.key, scaleTop:50, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_pidKp.name,  dataKey:key.vfd_pidKp.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:7, name :key.vfd_pidKi.name,  dataKey:key.vfd_pidKi.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:7, name :key.vfd_pidKd.name,  dataKey:key.vfd_pidKd.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:7, name :key.vfd_pidOutMin.name,  dataKey:key.vfd_pidOutMin.key, scaleTop:50, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_pidOutMax.name,  dataKey:key.vfd_pidOutMax.key, scaleTop:50, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_hzOutput.name,  dataKey:key.vfd_hzOutput.key, scaleTop:50, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_pidSetpoint.name,  dataKey:key.vfd_pidSetpoint.key, scaleTop:30, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "°C"},
    // {type:7, name :key.vfd_feedbackVal.name,  dataKey:key.vfd_feedbackVal.key, scaleTop:30, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "°C"},
    // {type:7, name :key.vfd_ManualOutput.name,  dataKey:key.vfd_ManualOutput.key, scaleTop:30, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "Hz"},
    // {type:7, name :key.vfd_modbusId.name,  dataKey:key.vfd_modbusId.key, scaleTop:255, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    // {type:7, name :key.vfd_nodeOpMode.name,  dataKey:key.vfd_nodeOpMode.key, scaleTop:3, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: " "},
    
    // {type:1001, name :key.usf_fr.name,  dataKey:key.usf_fr.key, scaleTop:300, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "m³/h"},
    // {type:1001, name :key.usf_t1.name,  dataKey:key.usf_t1.key, scaleTop:30, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "°C"},
    // {type:1001, name :key.usf_t2.name,  dataKey:key.usf_t2.key, scaleTop:30, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "°C"},

    // {type:1002, name :key.af_fr.name,  dataKey:key.af_fr.key, scaleTop:10, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "m³/s"},
    // {type:1002, name :key.af_mA.name,  dataKey:key.af_mA.key, scaleTop:20, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "mA"},
    // {type:1002, name :key.af_12v.name,  dataKey:key.af_12v.key, scaleTop:20, scaleBottom: 0, upperLimit: null, lowerLimit: null, dataUnit: "V"},
]


function genLineListFromBdDevice(bdDev, newTypeLineListPara){
    /** Previous type */
    // eslint-disable-next-line
    let lineDatas = defaultLineListPara.filter((a)=>{return a.type==bdDev.type});

    let lineLists = [];
    for (const line of lineDatas) {
        let lineList = {
            type: bdDev.type,
            bdDev_id: bdDev._id,            
            name: line.name,
            scaleTop: line.scaleTop,
            scaleBottom: line.scaleBottom,
            upperLimit: line.upperLimit,
            lowerLimit: line.lowerLimit,
            dataKey: line.dataKey,
            dataUnit: line.dataUnit,
            userID: 0,  // 0 = common, for building use
        }
        lineLists.push(lineList);
    }
    
    /** New Type */
    // console.log(newTypeLineListPara);
    let lineListNew = newTypeLineListPara.filter(c=>(c.sensorType === bdDev.type && c.defaultDisplay ===1));
    for (const line of lineListNew) {
        let _line = {
            type: line.sensorType,
            bdDev_id: bdDev._id,            
            name: line.dataName,
            scaleTop: line.scaleTop,
            scaleBottom: line.scaleBottom,
            upperLimit: line.upperLimit,
            lowerLimit: line.lowerLimit,
            dataKey: SensorFn.getDataKey(line),
            dataUnit: line.dataUnit,
            userID: 0,  // 0 = common, for building use
        }
        lineLists.push(_line);
    }


    return lineLists;
}

function lineDataCast(_newPara){
    // console.log(_newPara);
    return{
        dataKey: _newPara.dataIndex===-1?`${_newPara.dataType}`:`${_newPara.dataType}_${_newPara.dataIndex}`,
        dataUnit: _newPara.dataUnit,
        // lowerLimit: null,
        name: _newPara.dataName,
        scaleBottom: _newPara.scaleBottom,
        scaleTop: _newPara.scaleTop,
        type: _newPara.sensorType,
        // upperLimit: null,
    }
}

const expModule = {  
    defaultKeyPara,
    genLineListFromBdDevice,
    // split_NewSensor,
    lineDataCast,
    // getCurrAccountLineList,
};
export default expModule;