import React, { Component } from "react";
import monlistFn from "../../component/utilities/monList/monList";
import bdDevFn from "../../component/utilities/getDevices";
import Icon from "../common/icon";
import enumFn from "../../component/utilities/enum";
import tableFn from "../utilities/tableFunction";
import { genBdDevName } from "../../component/utilities/conversion";
import {
  getDeletedArrItem,
  swapArray,
  sortByName,
} from "../utilities/ArrayFn/arrayFn";
import elelistFn from "../../component/utilities/monList/eleList";
import monListNavFn from "../utilities/monList/monListNavBar";
import { ToastContainer, toast } from "react-toastify";

class AddElePage extends Component {
  state = {
    table_eleList: [], // table ele list is matching with Db data
    db_EleList: [],
    // table_display:[],   // table display is different with tabel ele list, table display is extended version

    bdDev_InUseList: [],
    bdDevList_enm: [],
    bLoaded: false,
    bloaded_newSel: false,
    dev_InBuilding: [],
    selBuildingID: 0,
    selT1Llist: 0,
    selEleList: 0,
    navBarInfo: {},
  };

  async componentDidMount() {
    /** Get type enm */
    let typeEnm =await enumFn.getDevTypeEnum();
    await this.setState({typeEnm});
    let navBarInfo = await monListNavFn.getMonListNavBarName();
    this.setState({ navBarInfo });
    let selBuildingID = await localStorage.getItem(`selBuildingID`);
    await this.setState({ selBuildingID });
    let selT1Llist = await localStorage.getItem(`selT1Llist`);
    await this.setState({ selT1Llist });
    let selEleList = await localStorage.getItem(`selEleList`);
    await this.setState({ selEleList });    

    await this.genTableFromDb();
    await this.setBdDev_InBuilding();
    await this.genNewBdSelEnmList();
  }
  
  genTableFromDb = async () => {
    await this.loadEleListFromDb();
    await this.loadBdDevInBuilding();
    await this.getBdDevList();
    this.convertEleListToTable();
  };

  genNewBdSelEnmList = async () => {
    // console.log(this.state.bdDevList_enm);
    let newSelEnmList = [
      { _id: 0, name: "Select..." },
      ...this.state.bdDevList_enm,
    ];
    await this.setState({ bloaded_newSel: false });
    await this.setState({ newSelEnmList });
    await this.setState({ bloaded_newSel: true });
  };

  loadEleListFromDb = async () => {
    try {
      let db_EleList = await monlistFn.getEleListByT1_id(
        this.state.selEleList
      ); /**replace here by dynamic id */
      // let table_EleList = db_EleList;
      // table_EleList.sort(function(a,b){return a.SortIndex-b.SortIndex});
      this.setState({ db_EleList });
      // this.setState({table_EleList});
      // console.log(db_EleList);
    } catch (error) {
      console.log(error.message);
    }
  };

  getBdDevList = async () => {
    let dbEle_List = [...this.state.db_EleList];
    let ndDev_idList = [];
    for (const eleItem of dbEle_List) {
      ndDev_idList.push(eleItem.bdDev_id);
    }
    let bdDev_InUseList = await bdDevFn.getBdDevicesBy_idList(ndDev_idList);
    this.setState({ bdDev_InUseList });
    // console.log(bdDev_InUseList);
  };

  convertEleListToTable = () => {
    try {
      let db_EleList = [...this.state.db_EleList];
      let bdDev_InUseList = [...this.state.bdDev_InUseList];
      let table_eleList = [];
      let idx = 0;
      let matchingBdDev = {};
      // console.log(db_EleList);
      // console.log(table_eleList);
      for (const eleItem of db_EleList) {
        matchingBdDev = bdDev_InUseList.find((c) => c._id === eleItem.bdDev_id);
        // console.log(matchingBdDev);
        if(matchingBdDev){
          db_EleList[idx].type = matchingBdDev.type;
          table_eleList.push(db_EleList[idx]);
          idx++;
        }
      }
      // console.log(table_eleList);
      table_eleList.sort((a, b) => a.SortIndex - b.SortIndex);
      this.setState({ table_eleList });
    } catch (error) {
      console.log(error.message);
    }
  };

  loadBdDevInBuilding = async () => {
    // let selBuildingID = this.state.selBuildingID;
    let dev_InBuilding = await bdDevFn.getBdDevBybdID(
      this.state.selBuildingID
    ); /**replace building id here */
    // console.log(dev_InBuilding);
    this.setState({ dev_InBuilding });
  };

  genBdDevEnum = (bdDevList) => {
    let enmList = [];
    let enmItem = {};
    for (const bdDev of bdDevList) {
      enmItem = {
        _id: bdDev._id,
        name: genBdDevName(bdDev),
      };
      enmList.push(enmItem);
    }
    // console.log(enmList);
    enmList.sort((a, b) => sortByName(a, b));
    return enmList;
    // console.log(enmList);
  };

  setBdDev_InBuilding = async () => {
    await this.setState({ bLoaded: false });
    // let enumList = await this.loadBdDevInBuilding();
    let dev_InBuilding = [...this.state.dev_InBuilding];
    let bdDevList_enm = this.genBdDevEnum(dev_InBuilding);
    this.setState({ bdDevList_enm });
    await this.setState({ bLoaded: true });
  };

  /**Table format */
  tableColumn = (row, ind) => {
    let column = {
      _id: parseInt(row.cells[2].innerHTML),
      type: enumFn.getType_n(this.state.typeEnm, row.cells[3].innerHTML),
      bdDev_id: parseInt(row.cells[4].children[0].value),
      SortIndex: parseInt(row.cells[7].innerHTML),
      T1_id: parseInt(row.cells[8].innerHTML),
    };
    // console.log(column);
    return column;
  };

  /**Get Table Info */
  getTableInfo = () => {
    try {
      let tableInfo = tableFn.getInfoFromTableSkipLastN(
        "eleListTable",
        this.tableColumn,
        1
      );
      // console.log(tableInfo);
      return tableInfo;
    } catch (error) {
      console.log(error.message);
    }

    /** logic to detect table change */
    // let db_EleList = [...this.state.db_EleList];
    // let modifiedRow = tableFn.detectTableChangesSkipLastN('eleListTable', db_EleList, "_id",this.tableColumn,true, 1);
    // console.log(modifiedRow);
  };

  delEle = async (ind) => {
    // console.log("Delete");
    try {
      let table_eleList = await this.getTableInfo();
      table_eleList.splice(ind, 1);
      let idxp = 0;
      for (const eleItem of table_eleList) {
        eleItem.SortIndex = idxp;
        idxp++;
      }
      // this.setState({table_eleList});
      this.refreshTableSelbar(table_eleList);
      // console.log(table_T1_List);
    } catch (error) {
      console.log(error.message);
    }
  };

  /**Click Up Down */
  clickUp = async (index) => {
    /**Switch index position */
    try {
      let table_eleList = this.getTableInfo();
      if (index > 0) table_eleList = swapArray(table_eleList, index, index - 1);
      /**Modify sort index */
      let idxp = 0;
      for (const eleItem of table_eleList) {
        eleItem.SortIndex = idxp;
        idxp++;
      }
      await this.refreshTableSelbar(table_eleList);
      // console.log(table_eleList);
    } catch (error) {
      console.log(error.message);
    }
  };

  refreshTableSelbar = async (table_eleList) => {
    await this.setState({ bLoaded: false });
    await this.setState({ table_eleList });
    await this.setState({ bLoaded: true });
  };

  clickDn = async (index) => {
    try {
      // console.log(`Click Dn ${index}`);
      let table_eleList = this.getTableInfo();
      // console.log(tableMonList);
      if (index + 1 < table_eleList.length)
        table_eleList = swapArray(table_eleList, index, index + 1);
      /**Modify sort index */
      let idxp = 0;
      for (const eleItem of table_eleList) {
        eleItem.SortIndex = idxp;
        idxp++;
      }
      await this.refreshTableSelbar(table_eleList);
      console.log(table_eleList);
    } catch (error) {
      console.log(error.message);
    }
  };

  selEleBdDev = () => {
    // console.log(`selEleBdDev ${ind}`);
    let prevTable_eleList = this.getTableInfo();
    let dev_InBuilding = [...this.state.dev_InBuilding];
    let matchingBdDev = {};
    let table_eleList = [];
    for (const tableItem of prevTable_eleList) {
      matchingBdDev = dev_InBuilding.find((c) => c._id === tableItem.bdDev_id);
      tableItem.type = matchingBdDev.type;
      table_eleList.push(tableItem);
    }
    console.log(table_eleList);
    this.setState({ table_eleList });
  };

  // selNewBdDev=()=>{
  //     console.log("New Bd Dev");
  // }

  getTypeByBdID = async (bd_id) => {
    let a_list = [bd_id];
    let matchBdDev = await bdDevFn.getBdDevicesBy_idList(a_list);
    if (matchBdDev[0]) return matchBdDev[0].type;
    return null;
  };

  insertNewList = async () => {
    try {
      let table_eleList = this.getTableInfo();
      // let newEleBdDev =document.getElementById("newAddBdDev").children[0].value;
      let newEleBdDev = document.getElementById("newAddBdDev").value;
      // eslint-disable-next-line
      if (newEleBdDev == 0) return;
      // console.log(newEleBdDev);
      let nMin_id = 0;
      for (const eleItem of table_eleList) {
        if (parseInt(eleItem._id) <= nMin_id) {
          nMin_id = eleItem._id;
        }
      }

      /**Get type base on bdDevId */
      let bdDev_id = parseInt(newEleBdDev);
      let nType = await this.getTypeByBdID(bdDev_id);

      if (newEleBdDev) {
        table_eleList.push({
          T1_id: this.state.selEleList /** replace 2 here */,
          bdDev_id: parseInt(newEleBdDev),
          SortIndex: table_eleList.length,
          _id: nMin_id - 1,
          type: nType,
        });
      }
      let idxp = 0;
      for (const t1Item of table_eleList) {
        t1Item.SortIndex = idxp;
        idxp++;
      }
      await this.setState({ table_eleList });
      /**Clear name */
      document.getElementById("newAddBdDev").value = 0;

      await this.save();
    } catch (error) {
      console.log(error.message);
    }
  };

  save = async () => {
    // console.log("Save");
    try {
      /**Get table info */
      let table_eleList = this.getTableInfo();
      let db_EleList = [...this.state.db_EleList];
      let dev_InBuilding = [...this.state.dev_InBuilding];
      /**Handle existing item*/
      let modifiedRow = tableFn.detectTableChangesSkipLastN(
        "eleListTable",
        db_EleList,
        "_id",
        this.tableColumn,
        true,
        1
      );
      // console.log(modifiedRow);
      let bdDev = {};
      for (const modifyItem of modifiedRow) {
        let updateSeccess = await elelistFn.updateEleList(modifyItem);
        // console.log(modifyItem);
        // eslint-disable-next-line
        bdDev = dev_InBuilding.find((c) => c._id == modifyItem.bdDev_id);
        // console.log(bdDev);
        updateSeccess
          ? toast.dark(`${genBdDevName(bdDev)} update succesful`)
          : toast.error(`${genBdDevName(bdDev)} update failed`);
      }

      /**Handle new add item */
      let newAddList = table_eleList.filter((c) => c._id < 0);
      // console.log(newAddList);
      for (const newItem of newAddList) {
        let addSuccess = await elelistFn.addEleList(newItem);
        // eslint-disable-next-line
        bdDev = dev_InBuilding.find((c) => c._id == newItem.bdDev_id);
        addSuccess
          ? toast.dark(`${genBdDevName(bdDev)} add succesful`)
          : toast.error(`${genBdDevName(bdDev)} add failed`);
      }

      /**hande delete */
      let deletedRow = getDeletedArrItem(db_EleList, table_eleList, "_id");
      // console.log(deletedRow);
      for (const delItem of deletedRow) {
        let delSuccess = await elelistFn.delEleList(delItem);
        // eslint-disable-next-line
        bdDev = dev_InBuilding.find((c) => c._id == delItem.bdDev_id);
        delSuccess
          ? toast.dark(`${genBdDevName(bdDev)} delete succesful`)
          : toast.error(`${genBdDevName(bdDev)} delete succesful`);
      }

      /** load new mon list */
      await this.genTableFromDb();
    } catch (error) {
      console.log(error.message);
    }
  };

  backToMonList = () => {
    // console.log("Back to Mon List");
    this.props.history.push({
      pathname: "/monlist/addlist",
      _data: {
        _selBuildingID: this.state.selBuildingID,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
  };

  backToT1 = () => {
    // console.log("Back to T1List");
    this.props.history.push({
      pathname: "/monlist/addt1",
      _data: {
        //   _selBuildingID: this.state.selBuildingID,
        // _selT1Llist:tableMonList[ind]._id,
      },
    });
  };

  render() {
    let _navInfo = this.state.navBarInfo;
    return (
      <React.Fragment>
        <div>
          <h1>Monitoring List Setting</h1>
          {_navInfo.t1Name ? (
            <h5 className="monlist-display">
              <div
                className="monlist-back"
                onClick={this.backToMonList}
              >{`${_navInfo.buildingName}`}</div>
              <div className="monlist-arr">{`>`}</div>
              <div
                className="monlist-back"
                onClick={this.backToT1}
              >{`${_navInfo.mmonListName}`}</div>
              <div className="monlist-arr">{`>`}</div>
              <div className="monlist-cur">{`${_navInfo.t1Name}`}</div>
            </h5>
          ) : (
            <div>
              <div>{"Loading..."}</div>
            </div>
          )}
          <Icon className="fa fa-save deactive" onClick={this.save}></Icon>
          <div className="card">
            <div className="card-body" id="flip-scroll">
              <table className="cusTable" id="eleListTable">
                <thead>
                  <tr>
                    <th scope="col" id="cb"></th>
                    <th scope="col">#</th>
                    <th style={{ display: "none" }}>_id</th>
                    <th scope="col">Sensor</th>
                    <th scope="col">Name</th>
                    <th scope="col">up</th>
                    <th scope="col">dn</th>
                    <th style={{ display: "none" }}>SortIndex</th>
                    <th style={{ display: "none" }}>MonitorList_id</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_eleList.map((u, ind) => (
                    <tr key={u._id}>
                      <td>
                        <Icon
                          className="fa fa-trash deactive"
                          onClick={() => this.delEle(ind)}
                        ></Icon>
                      </td>
                      <td>{ind + 1}</td>
                      <td style={{ display: "none" }}>{u._id}</td>
                      <td>{enumFn.getType_en(this.state.typeEnm, u.type)}</td>
                      {this.state.bLoaded ? (
                        <td>
                          {tableFn.tableDropDownKeySel(
                            `selEleBdDevRow${ind}`,
                            this.state.bdDevList_enm,
                            u.bdDev_id,
                            "select-custom",
                            () => this.selEleBdDev()
                          )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>
                        <Icon
                          className="fa fa-arrow-up"
                          onClick={() => this.clickUp(ind)}
                        ></Icon>
                      </td>
                      <td>
                        <Icon
                          className="fa fa-arrow-down"
                          onClick={() => this.clickDn(ind)}
                        ></Icon>
                      </td>
                      <td style={{ display: "none" }}>{u.SortIndex}</td>
                      <td style={{ display: "none" }}>{u.T1_id}</td>
                    </tr>
                  ))}
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    {this.state.bloaded_newSel ? (
                      <td>
                        {tableFn.tableDropDownKeySel(
                          `newAddBdDev`,
                          this.state.newSelEnmList,
                          0,
                          "select-custom"
                          // () => this.selNewBdDev()
                        )}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {/* <td id="newT1Name">
                                    <input type="text" placeholder="New List..." />
                                </td> */}
                    <td colSpan="2">
                      <Icon
                        className="fa fa-plus deactive"
                        onClick={() => this.insertNewList()}
                      ></Icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default AddElePage;
