import timeFn from '../utilities/timeFn'
import enm from '../utilities/enum'
import offsetFn from '../utilities/offsetValue/offsetValueFn';
import { roundDecimalPoint } from '../utilities/conversion';
import sensorFn from '../../function/sensor/sensorManagementFn';

async function addColumnCommon(bdDev, singleData, enmList){    
    // console.log(bdDev);
    // console.log(enmList);
    let compileData = {};
    // let enmList = await enm.getDevTypeEnum();
    compileData.Sensor = enm.getType_en(enmList, singleData.type);  
    compileData.Name = bdDev.name;
    compileData.Location = bdDev.location;
    compileData.Date = timeFn.getUnixDate(singleData.unix);
    compileData.Time = timeFn.getUnixTime(singleData.unix, false, true);
    return compileData;
}

function deleteColumnCommon(compileData){
    // let _compileData=compileData;
    // delete compileData.type;
    delete compileData.timestamp;
    delete compileData._id;        
    delete compileData.lc;
    return compileData;
}

async function formatCommonNodeExcel(data, bdDev, sensorParas){    
    // console.log(sensorParas);
    let enmList = await enm.getDevTypeEnum();
    // console.log(enmList);
    let rtnRel = await formatCommonTemplate(data, bdDev, addColumnCommon, deleteColumnCommon, enmList, sensorParas);
    /** modify the colum name here*/

    return rtnRel;
}


function deleteColumnUsf(compileData){
    // remove unwanted
    delete compileData.timestamp;
    delete compileData._id;        
    delete compileData.type;     
    delete compileData.lc;
    //extra
    delete compileData.FlowPSec;    
    delete compileData.FlowPMin;    
    delete compileData.Velocity;    
    delete compileData.VeloUnit;    
    delete compileData.FlowrateUnit;    
    return compileData;
}

async function formatUsfExcel(data, bdDev, sensorParas){    
    // console.log("Come in USF");
    let enmList = await enm.getDevTypeEnum();
    // console.log(enmList);
    return await formatCommonTemplate(data, bdDev, addColumnCommon, deleteColumnUsf, enmList, sensorParas);
}


async function formatCommonTemplate(data, bdDev, fAddColumn, fDelColumn, enmList, sensorParas){    
    // console.log(sensorParas);
    let compileDatas = [];
    let offsetList = [];
    for (const singleData of data) {
        let compileData= await fAddColumn(bdDev, singleData, enmList);

        compileData= {...compileData, ...singleData};
        /**Get offset list through type and devID */
        let found = offsetList.find(c=>(c.type === compileData.type && c.devID === compileData.devID));
        if(!found){
            // console.log(compileData);
            let dev = {
                type : compileData.type,
                devID : compileData.devID,
            }
            let offset = await offsetFn.getOffsetByIdnKey(dev);
            // console.log(offset);
            if(offset[0]) offsetList.push(...offset);
            else offsetList.push({...dev});
        }

        compileData = fDelColumn(compileData);
        // compileData = deleteColumn(compileData);

        // console.log(compileData);

        // Special Take Care Tesco Kulim
        if(bdDev.bdDevID==="21"){
            compileData.ActivePower_A = compileData.ActivePower_A/1000;
            compileData.ActivePower_B = compileData.ActivePower_B/1000;
            compileData.ActivePower_C = compileData.ActivePower_C/1000;
            compileData.ActivePower_Total = compileData.ActivePower_Total/1000;
        }
        /**Modify value follow offset here */
        for (const offsetItem of offsetList) {
            if(compileData.hasOwnProperty(offsetItem.DataKey)){
                // compileData[offsetItem.DataKey] += offsetItem.offsetValue;                
                let valueAfterOffset = compileData[offsetItem.DataKey] + offsetItem.offsetValue;
                compileData[offsetItem.DataKey]=roundDecimalPoint(valueAfterOffset,2);
            }
        }

        compileDatas.push(compileData);
        // console.log(compileData);
        /** modify name here */        
        for (const para of sensorParas) {
            /** Inside data got such key name */
            if (Object.hasOwnProperty.call(compileData, sensorFn.genSensorKey(para))){
                compileData[para.dataName]=compileData[sensorFn.genSensorKey(para)];
                // compileData[(para.dataName).trim()]=compileData[sensorFn.genSensorKey(para)];
                delete compileData[sensorFn.genSensorKey(para)];
            }            
        }
    }

    return compileDatas
}



const expModule = {  
    formatCommonNodeExcel,
    formatUsfExcel,

};
export default expModule;